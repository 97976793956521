import { Stack, Typography, Box, useMediaQuery } from '@mui/material';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { borderWithTransition } from 'config/customCSS.js';

import { CustomAvatar, CustomChip } from 'UI';

const customTheme = (outerTheme) =>
    createTheme({
        components: {
            MuiTypography: {
                styleOverrides: {
                    noWrap(styles) {
                        return {
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: String(
                                styles.ownerState['data-lines'] || '2'
                            ),
                            WebkitBoxOrient: 'vertical',
                        };
                    },
                },
            },
        },
    });

export default function ArticleCard({
    articleData = {},
    viewType = 'vertical',
    ...otherProps
}) {
    let {
        _id = Math.random() * 1000,
        category = [],
        slug = '',
        webName = '',
        description = '',
        primaryImage = {},
        modifiedAt = null,
    } = articleData;
    const navigate = useNavigate();
    const theme = useTheme();
    const isBiggerSmScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('sm')
    );

    const handleClick = () => {
        navigate(`/bai-viet${slug}`);
    };

    if (viewType === 'vertical') {
        return (
            <Stack
                key={_id + '-card-article-item'}
                sx={{
                    backgroundColor: 'white',
                    ...borderWithTransition,
                    width: '100%',
                    borderRadius: '20px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.01)',
                    },
                }}
                onClick={handleClick}>
                <Box
                    sx={{
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <CustomAvatar
                        sx={{
                            width: '100%',
                            height: 160,
                            objectFit: 'cover',
                            borderRadius: '20px 20px 0 0',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        }}
                        src={primaryImage?.mainSrc || ''}
                        alt={primaryImage?.altText || ''}
                    />
                </Box>
                <Box
                    sx={{
                        overflow: 'hidden',
                        px: 2,
                        pt: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mb={1}>
                        <CustomChip
                            color="secondary"
                            clickable={false}
                            sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                px: 1.5,
                                borderRadius: '4px',
                            }}
                            label={
                                category.find((i) => i.level === 2)?.name ?? ''
                            }
                        />
                        <Typography
                            noWrap
                            color="disabled"
                            sx={{
                                textTransform: 'initial',
                                textAlign: 'left',
                                fontSize: '14px',
                                lineHeight: '1.4rem',
                            }}>
                            •&nbsp;
                            {modifiedAt &&
                                moment(modifiedAt).format('DD/MM/YYYY')}
                        </Typography>
                    </Stack>
                    <ThemeProvider theme={customTheme(theme)}>
                        <Typography
                            component="h3"
                            noWrap
                            data-lines={'2'}
                            sx={{
                                textTransform: 'initial',
                                textAlign: 'justify',
                                maxHeight: 48,
                                lineHeight: '1.5rem',
                                letterSpacing: '0.0025em',
                                fontSize: 18,
                                fontWeight: 600,
                            }}>
                            {webName}
                        </Typography>
                        <Typography
                            noWrap
                            data-lines={'3'}
                            sx={{
                                color: 'text.secondary',
                                textTransform: 'initial',
                                textAlign: 'left',
                                fontSize: '14px',
                                lineHeight: '1.4rem',
                                maxHeight: 73,
                                mt: 1,
                                mb: 2,
                            }}>
                            {description}
                        </Typography>
                    </ThemeProvider>
                </Box>
            </Stack>
        );
    } else
        return (
            <Stack
                key={_id + '-card-article-item'}
                sx={{
                    backgroundColor: 'white',
                    ...borderWithTransition,
                    width: '100%',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    p: 2,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.01)',
                    },
                }}
                spacing={1.5}
                onClick={handleClick}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch">
                    <Box sx={{ width: 180 }}>
                        <CustomAvatar
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'fill',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                            src={primaryImage?.mainSrc || ''}
                            alt={primaryImage?.altText || ''}
                        />
                    </Box>
                    <Stack
                        sx={{ width: '100%', px: 1.5 }}
                        spacing={1}
                        alignItems="flex-start">
                        <CustomChip
                            color="secondary"
                            clickable={false}
                            sx={{
                                fontWeight: 500,
                                fontSize: '14px',
                                px: 1.5,
                                borderRadius: '4px',
                            }}
                            label={category.find((i) => i.level === 2).name}
                        />
                        <ThemeProvider theme={customTheme(theme)}>
                            <Typography
                                noWrap
                                component="h3"
                                data-lines={isBiggerSmScreen ? '1' : '2'}
                                sx={{
                                    textTransform: 'initial',
                                    textAlign: 'left',
                                    maxHeight: 48,
                                    overflow: 'hidden',
                                    lineHeight: '1.5rem',
                                    letterSpacing: '0.0025em',
                                    fontSize: { xs: 16, sm: 18 },
                                    fontWeight: 600,
                                }}>
                                {webName}
                            </Typography>
                            <Typography
                                noWrap
                                data-lines={isBiggerSmScreen ? '2' : '3'}
                                color="disabled"
                                sx={{
                                    textTransform: 'initial',
                                    textAlign: 'left',
                                    maxHeight: 73,
                                    fontSize: '14px',
                                    lineHeight: '1.4rem',
                                }}>
                                {modifiedAt && (
                                    <AccessTimeIcon
                                        sx={(theme) => ({
                                            color: 'disabled.light',
                                            width: 16,
                                            height: 16,
                                            verticalAlign: 'middle',
                                            mt: -0.25,
                                            mr: 0.5,
                                        })}
                                    />
                                )}
                                <span>
                                    {modifiedAt &&
                                        moment(modifiedAt).format('DD/MM/YYYY')}
                                </span>
                                {description && isBiggerSmScreen && (
                                    <span>&nbsp;•&nbsp;</span>
                                )}
                                {isBiggerSmScreen && description}
                            </Typography>
                        </ThemeProvider>
                    </Stack>
                </Stack>
                {!isBiggerSmScreen && (
                    <ThemeProvider theme={customTheme(theme)}>
                        <Typography
                            noWrap
                            data-lines={'3'}
                            sx={(theme) => ({
                                color: 'disabled.light',
                                textTransform: 'initial',
                                textAlign: 'left',
                                maxHeight: 73,
                                fontSize: '14px',
                                lineHeight: '1.4rem',
                            })}>
                            {description}
                        </Typography>
                    </ThemeProvider>
                )}
            </Stack>
        );
}
