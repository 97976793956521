import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

/**
 * CustomHighlightText renders a label with highlighted parts that match a given keyword.
 * Useful for search results or highlighting terms within text.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.label - The text to display and check for keyword matches.
 * @param {string} props.keyword - The keyword used to find matching parts within the label.
 * @param {Function} props.getStyleProps - Function to customize the style of matching and non-matching parts.
 *    - Called with a boolean parameter (`isHighlighted`):
 *      - `true`: Highlighted (matching) part of the text.
 *      - `false`: Non-highlighted (non-matching) part of the text.
 *    - Should return a style object to apply to the part.
 * @returns {JSX.Element} Rendered component with highlighted text.
 */
function CustomHighlightText({
    label = '',
    keyword = '',
    getStyleProps = (isHighlighted) => {},
    ...otherProps
}) {
    const matches = match(label, keyword, {
        insideWords: true,
    });
    const parts = parse(label, matches);

    return (
        <Box
            component={'div'}
            sx={{
                flexWrap: 'wrap',
            }}>
            {parts.map((part, index) => (
                <Typography
                    key={'parts-' + part.text + '-' + index}
                    sx={{
                        fontWeight: part.highlight ? 600 : 400,
                        color: part.highlight ? 'warning.main' : 'text.primary',
                        ...getStyleProps(part.highlight),
                    }}>
                    {part.text}
                </Typography>
            ))}
        </Box>
    );
}

CustomHighlightText.propTypes = {
    label: PropTypes.string,
    keyword: PropTypes.string,
    getStyleProps: PropTypes.func,
};

CustomHighlightText.defaultProps = {
    label: '',
    keyword: '',
    getStyleProps: () => ({}),
};

export default CustomHighlightText;
