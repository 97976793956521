import PropTypes from 'prop-types';

import { keyframes } from '@mui/system';
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';

import useCustomColor from 'hooks/useCustomColor';

/**
 * CustomLinearProgress component that displays a linear progress indicator.
 *
 * @param {Object} props - The props for the component.
 * @param {number} [props.height=4] - The height of the linear progress indicator.
 * @param {string} [props.color='primary'] - The color of the progress indicator.
 * @param {string} [props.backgroundColor] - The background color for the progress linear.
 * @param {Object} [otherProps] - Any other props to pass to the Box component.
 * @returns {JSX.Element} The rendered linear progress component.
 */

function CustomLinearProgress({
    height = 4,
    color = 'primary',
    backgroundColor = undefined,
    ...otherProps
}) {
    let { main, light, lighter, dark } = useCustomColor(color);
    
    return (
        <LinearProgress
            variant="determinate"
            value={100}
            sx={{
                position: 'relative',
                height,
                borderRadius: 5,
                overflow: 'hidden',
                backgroundColor: backgroundColor || lighter,
                [`& .${linearProgressClasses.bar}`]: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'transparent',
                    display: 'flex',
                },
                [`& .${linearProgressClasses.bar}::before, & .${linearProgressClasses.bar}::after`]:
                    {
                        content: '""',
                        position: 'absolute',
                        height: '100%',
                        width: '10%',
                        borderRadius: 5,
                    },
                [`& .${linearProgressClasses.bar}::before`]: {
                    left: 0,
                    animation: `${keyframes`
                         0% { transform: translate(0, 0); width: 2%; opacity: 1; background-color: ${lighter} }
                         25% { transform: translate(0, 0); width: 10%; opacity: 1; background-color: ${light} }
                         50% { transform: translate(420%, 0); background-color: ${light}; opacity: 1 }
                         70% { transform: translate(0, 2000%); opacity: 0.8 }
                         85% { opacity: 0.4 }
                         100% { opacity: 0 }
                      `} 2000ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite`,
                },
                [`& .${linearProgressClasses.bar}::after`]: {
                    left: '50%',
                    animation: `${keyframes`
                         0% { transform: translate(0, 0); width: 2%; opacity: 0.4; background-color: ${light} }
                         25% { transform: translate(0, 0); width: 10%; opacity: 0.8; background-color: ${main} }
                         50% { transform: translate(500%, 0); background-color: ${dark}; opacity: 1 }
                         70% { transform: translate(0, 2000%); opacity: 0.8 }
                         85% { opacity: 0.4 }
                         100% { opacity: 0 }
                       `} 2000ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite`,
                    animationDelay: '1020ms',
                },
            }}
            {...otherProps}
        />
    );
}

CustomLinearProgress.propTypes = {
    size: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
};

CustomLinearProgress.defaultProps = {
    height: 4,
    color: 'primary',
    backgroundColor: undefined,
};

export default CustomLinearProgress;
