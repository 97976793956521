import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/system';

import useCustomColor from 'hooks/useCustomColor';

/**
 * CustomButton component that provides styled button or icon button functionality.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.id] - An optional identifier for the button.
 * @param {boolean} [props.useIconButton=false] - If true, renders an IconButton instead of a Button.
 * @param {React.ReactNode} props.children - The content of the button, usually text or an icon.
 * @param {function} [props.onClick] - Function to handle button click events.
 * @param {'text' | 'contained' | 'outlined'} [props.variant='text'] - The variant of the button.
 * @param {string} [props.color='primary'] - The color of the button.
 * @param {'small' | 'medium' | 'large'} [props.size='small'] - The size of the button.
 *  * @param {SxProps<Theme>} [props.sx] - Additional styles to apply to the button.
 * @param {boolean} [props.disableEffect=false] - If true, disables all button effects.
 * @param {Object} [otherProps] - Any other props to pass to the button component.
 * @returns {JSX.Element} The rendered button or icon button.
 */

function CustomButton({
    id,
    useIconButton = false,
    children,
    onClick = () => {},
    variant = 'text',
    color = 'primary',
    size = 'small',
    sx = {},
    disableEffect = false,
    ...otherProps
}) {
    const theme = useTheme();
    let customPalleteColor = useCustomColor(color);

    let buttonStyles = useMemo(() => {
        let { main, light, lighter, contrastText } = customPalleteColor;

        let css = {
            contained: {
                backgroundColor: main,
                color: contrastText,
                textTransform: 'initial',
                ':hover': {
                    boxShadow: 1,
                    color: contrastText,
                    backgroundColor: disableEffect ? main : light,
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? main : light,
                    color: contrastText,
                },
                '&:focus': {
                    boxShadow: disableEffect ? 'none' : 1,
                    backgroundColor: disableEffect ? main : light,
                    color: contrastText,
                },
                '&.Mui-disabled': {
                    boxShadow: 'none',
                    color: 'disabled.light',
                    backgroundColor: 'disabled.lighter',
                },
            },
            text: {
                backgroundColor: 'transparent',
                color: main,
                textTransform: 'initial',
                ':hover': {
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: disableEffect ? main : contrastText,
                },
                '&:focus': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: disableEffect ? main : contrastText,
                },
                '&.Mui-disabled': {
                    boxShadow: 'none',
                    color: 'disabled.light',
                    backgroundColor: 'transparent',
                },
            },
            outlined: {
                backgroundColor: 'transparent',
                borderColor: light,
                color: main,
                textTransform: 'initial',
                ':hover': {
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                    borderColor: light,
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: disableEffect ? main : contrastText,
                },
                '&:focus': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: disableEffect ? main : contrastText,
                },
                '&.Mui-disabled': {
                    color: 'disabled.light',
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                },
            },
            iconButton: {
                backgroundColor: 'transparent',
                color: main,
                ':hover': {
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&:active': {
                    boxShadow: 'none',
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&:focus': {
                    boxShadow: 'none',
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&.Mui-disabled': {
                    color: 'disabled.light',
                    backgroundColor: 'transparent',
                },
            },
        };

        return useIconButton ? css.iconButton : css[variant];
    }, [customPalleteColor, disableEffect, useIconButton, variant]);

    const ComponentButton = useIconButton ? IconButton : Button;
    const { startIcon, endIcon, ...buttonProps } = otherProps;

    const buttonId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    return (
        <ComponentButton
            id={buttonId}
            aria-label="custom-button"
            variant={variant}
            size={size}
            disableRipple={disableEffect}
            disableFocusRipple={disableEffect}
            onClick={onClick}
            sx={{
                ...buttonStyles,
                ...(typeof sx === 'function' ? sx(theme) : sx),
            }}
            {...(!useIconButton && {
                startIcon,
                endIcon,
                disableElevation: disableEffect,
                disableTouchRipple: disableEffect,
            })}
            {...buttonProps}>
            {children}
        </ComponentButton>
    );
}

CustomButton.propTypes = {
    id: PropTypes.string,
    useIconButton: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string, // For custom colors
    ]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    disableEffect: PropTypes.bool,
};

CustomButton.defaultProps = {
    id: undefined,
    useIconButton: false,
    children: undefined,
    onClick: () => {},
    variant: 'text',
    color: 'primary',
    size: 'small',
    sx: {},
    disableEffect: false,
};

export default CustomButton;
