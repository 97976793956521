import { Stack, Typography, useMediaQuery } from '@mui/material';
import {
    KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
    MenuBook as MenuBookIcon,
} from '@mui/icons-material';

import { useParams, useLoaderData, useLocation } from 'react-router-dom';
import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query';
import { getSimpleQuery } from 'utils/http.js';

import { CustomHelmet, CustomMasonry, CustomButton, CustomAvatar } from 'UI';
import { LoadingModal } from 'template';

import SectionBreadcrumbs from 'layout/section/SectionBreadcrumbs.jsx';
import Page404 from 'shop/404/Page404.jsx';
import ArticleCard from './ArticleCard.jsx';
import CategorySection from './CategorySection.jsx';
import PageArticleSkeleton from './PageArticleSkeleton.jsx';

import articleLv1Json from 'data/articleLv1.json';
import articleLv2Json from 'data/articleLv2.json';

const PAGE_SIZE = 12;

export default function PageArticleList({ ...otherProps }) {
    const isBiggerSmScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('sm') // >=600
    );
    let location = useLocation();
    const { subCategorySlug } = useParams();
    const {
        mainCategoryData,
        subCategoryData,
        currentCategoryLevel,
        currentCategoryTitle,
        breadcrumbsData,
    } = useLoaderData();

    const {
        data,
        isLoading, // True only on the initial load.
        isFetching, // True when fetching new data (subsequent requests).
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: [
            'article-item',
            {
                subCategorySlug,
            },
        ],
        queryFn: ({ pageParam = 0, signal }) =>
            getSimpleQuery({
                signal,
                apiUrl: `/ecom/article-item/data?${
                    subCategorySlug ? `subCategorySlug=${subCategorySlug}&` : ''
                }type=1&skip=${pageParam}&take=${PAGE_SIZE}`,
            }),
        getNextPageParam: (lastPage, allPages) => {
            const totalFetchedItems = allPages.flatMap(
                (page) => page.data
            ).length;
            return totalFetchedItems < lastPage.totalCount
                ? totalFetchedItems
                : undefined;
        },
        placeholderData: keepPreviousData,
        staleTime: 30000,
        retry: 0,
    });

    if (subCategorySlug && !subCategoryData) {
        return <Page404 />;
    }

    if (isLoading) {
        return <PageArticleSkeleton />;
    }
    const mergedArticles = data?.pages?.flatMap((page) => page.data) || [];
    const totalLoadedArticles = mergedArticles.length;
    const totalArticles = parseInt(data?.pages?.[0]?.totalCount || 0);

    return (
        <>
            <CustomHelmet
                title={
                    (currentCategoryTitle === 'Bài viết sức khỏe'
                        ? currentCategoryTitle
                        : 'Các bài viết chủ đề ' +
                          currentCategoryTitle.toLowerCase()) +
                    ' - Nhà thuốc Khánh Trang'
                }
                description={
                    'Tổng hợp các bài viết của Nhà thuốc Khánh Trang' +
                    (currentCategoryTitle === 'Bài viết sức khỏe'
                        ? ''
                        : ' về ' + currentCategoryTitle.toLowerCase()) +
                    ', nhằm cung cấp thông tin sức khỏe, tư vấn dinh dưỡng và cách chăm sóc, phòng tránh bệnh cho gia đình '
                }
                slug={'https://nhathuockhanhtrang.com.vn' + location.pathname}
            />
            <Stack
                spacing={1}
                p={{ xs: 1.5, sm: 2 }}
                sx={{ maxWidth: 1200, mx: 'auto' }}>
                <LoadingModal isLoading={isFetching} />
                <SectionBreadcrumbs breadcrumbsData={breadcrumbsData} />
                {currentCategoryLevel === 1 && (
                    <Typography
                        component="h1"
                        sx={{ fontSize: 18, fontWeight: 600 }}>
                        {`Danh mục: ${currentCategoryTitle.toLowerCase()}`}
                    </Typography>
                )}
                <CategorySection
                    currentCategoryLevel={currentCategoryLevel}
                    currentCategoryTitle={currentCategoryTitle}
                />
                {totalLoadedArticles === 0 ? (
                    <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="center">
                        <CustomAvatar
                            src="../assets/images/pharmacy-illustration.png"
                            sx={{
                                width: '100%',
                                maxWidth: 500,
                                opacity: 0.5,
                                height: 200,
                            }}
                        />
                        <div>
                            <Typography
                                sx={{
                                    fontSize: 26,
                                    fontWeight: 600,
                                    textAlign: 'center',
                                }}>
                                Chưa có bài viết nào trong chủ đề này
                            </Typography>
                            <Typography sx={{ textAlign: 'center' }}>
                                Dữ liệu đang cập nhật, bạn hãy quay lại sau
                                nhé...
                            </Typography>
                        </div>
                    </Stack>
                ) : (
                    <>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <MenuBookIcon color="primary" />
                            <Typography
                                color="primary"
                                component="h1"
                                sx={{ fontSize: 18, fontWeight: 600 }}>
                                Các bài viết liên quan
                            </Typography>
                        </Stack>
                        <CustomMasonry
                            spacing={isBiggerSmScreen ? 2 : 1.25}
                            elevation={1}
                            id="page-article-list-masonryId"
                            columns={{ xs: 1, sm: 2, lg: 3, xl: 4 }}
                            items={mergedArticles.map((articleData) => ({
                                id: articleData._id,
                                content: (
                                    <ArticleCard articleData={articleData} />
                                ),
                            }))}
                        />
                    </>
                )}
                {hasNextPage ? (
                    <CustomButton
                        id="see-more-articles-buttonId"
                        color="disabled"
                        fullWidth
                        disableEffect
                        onClick={fetchNextPage}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 0,
                        }}
                        startIcon={
                            !isFetchingNextPage && (
                                <KeyboardDoubleArrowDownIcon />
                            )
                        }
                        children={
                            isFetchingNextPage
                                ? 'Đang tải...'
                                : `Xem thêm ${Number(
                                      totalArticles - totalLoadedArticles
                                  ).toLocaleString()} bài viết`
                        }
                    />
                ) : (
                    totalArticles > PAGE_SIZE && (
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                textAlign: 'center',
                            }}>
                            Bạn đã xem hết danh sách
                        </Typography>
                    )
                )}
            </Stack>
        </>
    );
}

export const loader = ({ params }) => {
    let { subCategorySlug } = params;
    const findSubCategory =
        [...articleLv1Json, ...articleLv2Json].find(
            (item) => item.slug === '/' + subCategorySlug
        ) || null;
    const currentCategoryLevel = Number(findSubCategory?.level ?? 0) + 1;
    let breadcrumbsData = [
        {
            to: `/bai-viet-suc-khoe`,
            name: 'Bài viết sức khỏe',
            isNavLink: currentCategoryLevel !== 1,
        },
    ];
    if (currentCategoryLevel === 2) {
        breadcrumbsData.push({
            to: `/bai-viet-suc-khoe/${subCategorySlug}`,
            name: findSubCategory.name,
            isNavLink: false,
        });
    }
    if (currentCategoryLevel === 3) {
        const parentCategory = articleLv1Json.find(
            (i) => i.name === findSubCategory.parentName
        );
        breadcrumbsData.push(
            {
                to: `/bai-viet-suc-khoe${parentCategory.slug}`,
                name: findSubCategory.parentName,
            },
            {
                to: `/bai-viet-suc-khoe/${subCategorySlug}`,
                name: findSubCategory.name,
                isNavLink: false,
            }
        );
    }
    let currentCategoryTitle = findSubCategory
        ? findSubCategory.name
        : 'Bài viết sức khỏe';
    return {
        mainCategoryData: {
            name: 'Bài viết sức khỏe',
            slug: '/bai-viet-suc-khoe',
        },
        subCategoryData: findSubCategory,
        currentCategoryLevel,
        currentCategoryTitle,
        breadcrumbsData,
    };
};
