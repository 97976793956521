import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Stack, Typography, Tab, useMediaQuery } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import {
    ArrowCircleRight as ArrowCircleRightIcon,
    Edit as EditIcon,
    Close as CloseIcon,
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    Sms as SmsIcon,
    PhonelinkRing as PhonelinkRingIcon,
    Announcement as AnnouncementIcon,
    PersonSearch as PersonSearchIcon,
    Info as InfoIcon,
} from '@mui/icons-material';

import { useDispatch } from 'react-redux';
import { userInfoActions } from 'store/userInfo-slice.js';

import { LoginContext } from 'context/login-context.js';

import { useMutation } from '@tanstack/react-query';
import { postModifyData } from 'utils/http.js';
import { login } from 'utils/auth.js';
import useCountdown from 'hooks/useCountDown.js';

import breadcrumbNameMapping from 'data/breadcrumbNameMapping.json';

import { POLICIES } from 'shop/policy/PagePolicy';

import {
    CustomDialog,
    CustomInput,
    CustomButton,
    CustomCircularProgress,
    CustomAvatar,
} from 'UI';
import { enqueueCustomSnackbar } from 'UI/AlertBlock';

import RenderContent from 'template/RenderContent.js';

function CustomTelInput({ ...otherProps }) {
    const { telError } = useContext(LoginContext);
    return (
        <Stack
            width="100%"
            maxWidth={350}
            sx={{ mx: 'auto' }}
            direction="row"
            alignItems={telError ? 'center' : 'flex-end'}
            justifyContent="flex-start"
            spacing={1}>
            <Typography noWrap sx={{ minWidth: '100px', fontSize: 14 }}>
                {'Số điện thoại:'}
            </Typography>
            <CustomInput
                name="tel"
                id={'tel-inputId'}
                inputProps={{
                    pattern: '[0-9]*', // Allows only numeric input
                }}
                error={telError}
                placeholder={`0•••••••••`}
                {...otherProps}
            />
        </Stack>
    );
}
function CustomOTPInput({
    name = 'name',
    error = '',
    value = '',
    onChange = () => {},
    prevRef,
    nextRef,
    ...otherProps
}) {
    const inputRef = useRef(null);

    const handleKeyDown = (event) => {
        const key = event.key;
        if ((key === 'ArrowRight' || key === value) && nextRef) {
            nextRef.focus();
        } else if (key === 'ArrowLeft' && prevRef) {
            prevRef.focus();
        } else if (key === 'Backspace' || key === 'Delete') {
            onChange('');
            if (key === 'Backspace' && prevRef) {
                prevRef.focus();
            }
        } else if (key === 'Tab' || key === 'Enter') {
            return;
        }

        // Prevent non-numeric keys, value >= 10
        if (!/[0-9]/.test(key) || value.length > 1) {
            event.preventDefault();
        }
    };
    const handleChange = (newValue) => {
        if (/^\d*$/.test(newValue)) {
            onChange(newValue);
            if (newValue === '' && prevRef && prevRef.current) {
                setTimeout(() => {
                    prevRef.current.focus();
                }, 0);
            } else if (newValue && nextRef && nextRef.current) {
                setTimeout(() => {
                    nextRef.current.focus();
                }, 0);
            }
        }
    };
    useEffect(() => {
        if (value && nextRef && nextRef.current) {
            nextRef.current.focus(); // auto jumpt to next input and focus after user press valid key
        }
    }, [value, nextRef]);

    return (
        <CustomInput
            name={name}
            id={name + '-inputId'}
            variant="outlined"
            inputRef={inputRef}
            value={value}
            onChange={handleChange}
            error={error}
            onKeyDown={handleKeyDown}
            onFocus={(event) => event.target.select()}
            {...otherProps}
            sx={{ width: 40 }}
            FormHelperTextProps={{ sx: { display: 'none' } }}
            inputProps={{
                pattern: '[0-9]*',
                style: {
                    textAlign: 'center',
                    fontWeight: 600,
                    fontSize: 18,
                    padding: 8.5,
                },
                maxLength: 1,
            }}
            debounceTime={0}
        />
    );
}
function validateInputValue(identifier, value) {
    if (identifier === 'tel') {
        return value?.trim()?.length < 3 ? 'Số điện thoại chưa hợp lệ!' : '';
    } else if (identifier === 'otp') {
        return Object.values(value).filter((i) => i === '').length > 0
            ? 'Vui lòng nhập đủ 6 chữ số.'
            : '';
    } else return '';
}
const Header = ({ children = 'Đăng nhập thành viên', ...otherProps }) => {
    return (
        <Typography
            color="disabled"
            sx={{
                textAlign: 'center',
                fontWeight: 700,
                fontSize: 20,
            }}>
            {children}
        </Typography>
    );
};
const ChangeTelButton = () => {
    const { resetTel } = useContext(LoginContext);
    return (
        <CustomButton
            id="change-tel-custom-button"
            disableEffect
            fullWidth
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                ':hover': {
                    textDecoration: 'underline',
                },
            }}
            onClick={resetTel}
            startIcon={<EditIcon sx={{ width: 15, height: 15 }} />}
            children="Đổi số điện thoại"
        />
    );
};
const PhoneLinkText = () => {
    return (
        <Typography
            color="info"
            component="a"
            href="tel:0704430919"
            sx={{
                fontSize: 14,
                fontWeight: 600,
                '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                },
                textDecoration: 'none',
            }}>
            070.443.0919
        </Typography>
    );
};
const HelperFooter = () => {
    const theme = useTheme();
    return (
        <Stack
            sx={{
                borderTopWidth: '1px',
                borderTopStyle: 'dashed',
                borderTopColor: 'disabled.light',
                mt: 'auto',
            }}
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="flex-start">
            <InfoIcon
                color="info"
                sx={{
                    width: 14,
                    height: 14,
                    mr: 1,
                    mt: 0.75,
                }}
            />
            <div
                style={{
                    textAlign: 'left',
                }}>
                <Typography
                    component="span"
                    sx={{ lineHeight: 1, fontSize: 14 }}>
                    Nếu chưa có&nbsp;
                    <b style={{ color: theme.palette.text.link }}>Zalo</b>, vui
                    lòng liên hệ hotline&nbsp;
                </Typography>
                <PhoneLinkText />
                <Typography
                    component="span"
                    sx={{ lineHeight: 1, fontSize: 14 }}>
                    {' để được hỗ trợ.'}
                </Typography>
            </div>
        </Stack>
    );
};
const defaultUserData = {
    gender: 'male',
    username: '',
    tel: '',
};
const defaultOtpDigits = {
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
};
function RenderTelInput() {
    const {
        tel,
        changeTel,
        telError,
        checkTelError,
        changeTelError,
        changeStepName,
    } = useContext(LoginContext);
    const { mutate, isPending: isCheckingTel } = useMutation({
        mutationFn: postModifyData,
        onSuccess: (data, variables, context) => {
            let isTelExisted = data.data.isExisted;
            if (isTelExisted) changeStepName('policy-agreement');
            else changeStepName('not-signup');
        },
        onError: (err, variables, context) => {
            let newError = err.message + '. \n' + err.errorInfo;
            changeTelError(newError);
        },
    });

    const validateAndCheckExistedTel = async () => {
        if (telError) {
            return;
        }
        mutate({
            apiUrl: '/user/check-tel',
            formData: {
                data: {
                    tel,
                },
            },
        });
    };

    return (
        <Box px={{ xs: 0, sm: 2 }} mt={4}>
            <Header children="Đăng nhập hoặc Đăng ký" />
            <Typography
                sx={{ textAlign: 'center', maxWidth: 350, mx: 'auto', mt: 2 }}>
                Vui lòng đăng nhập để xem điểm tích lũy của bạn và các chương
                trình ưu đãi dành riêng cho thành viên.
            </Typography>
            <Stack spacing={3} my={2} alignItems="center" width="100%">
                <CustomTelInput
                    onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                            await validateAndCheckExistedTel();
                        }
                    }}
                    endAdornment={
                        isCheckingTel ? (
                            <CustomCircularProgress />
                        ) : (
                            <CustomButton
                                id={`tel-login-next-custom-button`}
                                useIconButton
                                disabled={Boolean(telError)}
                                onClick={validateAndCheckExistedTel}
                                children={<ArrowCircleRightIcon />}
                            />
                        )
                    }
                    value={tel}
                    onChange={(newValue) => {
                        let newError = checkTelError(newValue);
                        if (newError.length > 0) {
                            changeTelError(newError);
                        }
                        changeTel(newValue);
                    }}
                />
                <CustomButton
                    id="check-tel-buttonId"
                    variant="contained"
                    fullWidth
                    sx={{
                        borderRadius: '20px',
                        py: 1,
                        maxWidth: 400,
                    }}
                    onClick={validateAndCheckExistedTel}
                    children={<b>Tiếp tục</b>}
                />
            </Stack>
        </Box>
    );
}
function RenderNotSignup() {
    const { tel } = useContext(LoginContext);
    const theme = useTheme();

    let CustomBackgroundImage = (props) => {
        return (
            <Stack py={1.5} justifyContent="center" direction="row">
                <CustomAvatar avatarSize={150} sx={{ position: 'relative' }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '38%',
                            left: '28%',
                            transform: 'skewY(-20deg)',
                        }}>
                        <PersonSearchIcon
                            sx={(theme) => ({
                                color: 'error.light',
                                width: 40,
                                height: 40,
                            })}
                        />
                    </Box>
                    <PhonelinkRingIcon
                        color="primary"
                        sx={{
                            width: 120,
                            height: 120,
                            transform: 'skewY(-20deg)',
                        }}
                    />
                </CustomAvatar>
                <CustomAvatar avatarSize={50} sx={{ ml: -1 }}>
                    <AnnouncementIcon
                        sx={(theme) => ({
                            width: 60,
                            height: 60,
                            color: 'error.light',
                            borderRadius: '25px 25px 25px 0',
                        })}
                    />
                </CustomAvatar>
            </Stack>
        );
    };
    return (
        <Stack px={{ xs: 0, sm: 2 }} mt={4} spacing={1} justifyContent="center">
            <CustomBackgroundImage />
            <div style={{ textAlign: 'center' }}>
                <Typography component="span">
                    Số điện thoại <b>{tel}</b> chưa được đăng kí trên website.
                    Vui lòng liên hệ hotline&nbsp;
                    <b style={{ color: theme.palette.text.link }}>Zalo</b>
                    &nbsp;
                </Typography>
                <PhoneLinkText />
                <Typography component="span">{' để được hỗ trợ.'}</Typography>
                <Box my={2}>
                    <ChangeTelButton />
                </Box>
            </div>
        </Stack>
    );
}

function PolicyInfoDialog({ open = false, onClose = () => {}, ...otherProps }) {
    const [tabValue, setTabValue] = useState(0);

    return (
        <CustomDialog
            isDraggable={false}
            isChildDialog
            id="policy-agreement-dialogId"
            title="Thông tin quy chế và chính sách"
            open={open}
            onClose={onClose}
            fullScreen
            hideNoButton
            hideYesButton>
            <Box
                sx={{
                    flexGrow: 1,
                    maxWidth: { xs: '100%', sm: '100%' },
                    backgroundColor: 'disabled.lighter',
                    pt: 0.5,
                }}>
                <Tabs
                    value={tabValue}
                    onChange={(event, newValue) => {
                        setTabValue(newValue);
                    }}
                    variant="scrollable"
                    scrollButtons
                    aria-label="visible arrows tabs example"
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'warning.main',
                            height: '1px',
                            bottom: 9,
                        },
                        mt: 1,
                    }}>
                    {POLICIES.map(({ pathname }, idx) => {
                        const policyName = breadcrumbNameMapping[pathname];

                        return (
                            <Tab
                                key={pathname}
                                label={policyName}
                                sx={(theme) => ({
                                    borderRadius: '15px 15px 0 0',
                                    border:
                                        tabValue === idx
                                            ? `1px solid ${theme.palette.primary.main}`
                                            : 'none',
                                    borderBottom:
                                        tabValue === idx
                                            ? 'none'
                                            : `1px solid ${theme.palette.disabled.light}`,
                                    boxShadow: tabValue === idx ? 3 : 0,
                                    textTransform: 'initial',
                                    color: `${theme.palette.primary.main} !important`,
                                    p: '10px 5px',
                                    minHeight: 0,
                                    mx: tabValue === idx ? 2 : 1,
                                    ml: tabValue === 0 ? 0 : 'auto',
                                    mr:
                                        tabValue === POLICIES.length
                                            ? 0
                                            : 'auto',
                                })}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            <RenderContent
                newContent={POLICIES?.[tabValue]?.content ?? []}
                isShowMore={false}
                isBiggerText={false}
            />
        </CustomDialog>
    );
}
function RenderPolicyAgreement() {
    const theme = useTheme();
    const { tel, changeStepName } = useContext(LoginContext);
    const [isReadPolicies, setIsReadPolicies] = useState(true);
    const [isOpenPolicyInfoDialog, setIsOpenPolicyInfoDialog] = useState(false);

    if (isOpenPolicyInfoDialog)
        return (
            <PolicyInfoDialog
                onClose={() => setIsOpenPolicyInfoDialog(false)}
                open={isOpenPolicyInfoDialog}
            />
        );

    const CustomBackgroundImage = ({ ...props }) => {
        return (
            <Stack py={1.5} justifyContent="center" direction="row">
                <CustomAvatar avatarSize={150} sx={{ position: 'relative' }}>
                    <Typography
                        color="info"
                        variant="caption"
                        sx={{
                            fontSize: 15,
                            fontWeight: 600,
                            position: 'absolute',
                            top: '45%',
                            left: '30%',
                            transform: 'skewY(-20deg)',
                        }}>
                        Zalo
                    </Typography>
                    <PhonelinkRingIcon
                        color="primary"
                        sx={{
                            width: 120,
                            height: 120,
                            transform: 'skewY(-20deg)',
                        }}
                    />
                </CustomAvatar>
                <CustomAvatar avatarSize={50} sx={{ ml: -1 }}>
                    <SmsIcon
                        color="primary"
                        sx={{
                            width: 60,
                            height: 60,
                            borderRadius: '25px 25px 25px 0',
                        }}
                    />
                </CustomAvatar>
            </Stack>
        );
    };
    return (
        <Box sx={{ height: '100%' }}>
            <Stack
                height="100%"
                px={{ xs: 0, sm: 2 }}
                spacing={1}
                justifyContent="space-between">
                <Box mt={4}>
                    <CustomBackgroundImage />
                    <Typography sx={{ textAlign: 'center', my: 1 }}>
                        Mã xác thực sẽ được gửi qua{' '}
                        <b style={{ color: theme.palette.text.link }}>Zalo</b>{' '}
                        cho số điện thoại <b>{tel}</b>
                    </Typography>
                    <ChangeTelButton />
                    <Stack
                        my={1}
                        direction="row"
                        alignItems="flex-start"
                        spacing={0.5}
                        py={1}
                        sx={{ backgroundColor: 'disabled.lighter' }}>
                        <CustomButton
                            id="checkbox-policy-agreement-buttonId"
                            useIconButton
                            onClick={() => setIsReadPolicies((v) => !v)}
                            children={
                                isReadPolicies ? (
                                    <CheckBoxIcon />
                                ) : (
                                    <CheckBoxOutlineBlankIcon />
                                )
                            }
                        />
                        <Typography
                            variant="overline"
                            sx={{
                                fontSize: 13,
                                textTransform: 'initial',
                                lineHeight: 1.5,
                                fontStyle: 'italic',
                            }}>
                            Tôi đồng ý với điều khoản dịch vụ, chính sách thu
                            thập và xử lý dữ liệu cá nhân của NT Khánh Trang{' '}
                            <span
                                style={{
                                    color: theme.palette.text.link,
                                    ':hover': {
                                        textDecoration: 'underline',
                                    },
                                    cursor: 'pointer',
                                }}
                                onClick={() => setIsOpenPolicyInfoDialog(true)}>
                                {'(Xem chi tiết)'}
                            </span>
                        </Typography>
                    </Stack>
                    <Stack justifyContent="center" alignItems="center">
                        <CustomButton
                            id="zalo-otp-custom-button"
                            variant="contained"
                            fullWidth
                            sx={{
                                borderRadius: '20px',
                                py: 1,
                                my: 1,
                                maxWidth: 400,
                                mx: 'auto',
                            }}
                            disabled={!isReadPolicies}
                            onClick={() => changeStepName('zalo-otp-input')}
                            children={<b>Nhận mã xác thực qua Zalo</b>}
                        />
                    </Stack>
                </Box>
                <HelperFooter />
            </Stack>
        </Box>
    );
}
function RenderOTPInput({
    setUserData = () => {},
    handleLogin = () => {},
    ...otherProps
}) {
    const { tel } = useContext(LoginContext);
    const [otpDigits, setOtpDigits] = useState(defaultOtpDigits);
    const [otpInputError, setOtpInputError] = useState('');

    const inputRefs = useRef([null, null, null, null, null, null]);

    const [isKeepingValidOtp, setIsKeepingValidOtp] = useState(false); // use to keep run otp valid count down
    const [
        otpValidMinuteRemaining,
        otpValidSecondRemaining,
        restartValidOtpCountdown,
    ] = useCountdown(5, 0, isKeepingValidOtp);
    const [isOtpCreationAllowed, setIsOtpCreationAllowed] = useState(true); // use to keep run count down of send new otp
    const [, otpCreationSecondRemaining, restartOtpGenerationCountdown] =
        useCountdown(0, 30, !isOtpCreationAllowed);

    //function generate OTP
    const { mutate: generateNewOtp, isPending: isGettingOTP } = useMutation({
        mutationFn: postModifyData,
        onSuccess: (data, variables, context) => {
            setIsKeepingValidOtp(true);
            restartValidOtpCountdown();
            setIsOtpCreationAllowed(false);
            restartOtpGenerationCountdown();
            setTimeout(() => {
                setIsOtpCreationAllowed(true);
            }, 31000); // accept to request new otp after 31s
        },
        onError: (err, variables, context) => {
            setOtpInputError(
                `${err.message} ${err.errorInfo ? `(${err.errorInfo})` : ''}`
            );
        },
    });
    const handleGenerateNewOtp = () => {
        if (!isOtpCreationAllowed || !tel) {
            return;
        }
        generateNewOtp({
            apiUrl: `/user/send-otp`,
            formData: {
                data: {
                    tel,
                },
            },
        });
    };

    useEffect(() => {
        let timeout = setTimeout(() => handleGenerateNewOtp(), 200);
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let remainingValidOTP = useMemo(() => {
        let min = otpValidMinuteRemaining;
        let sec = otpValidSecondRemaining;
        if (min > 0 && sec > 0) {
            return ` có hiệu lực trong ${min} phút ${sec} giây`;
        } else if (min === 0 && sec > 0) {
            return ` có hiệu lực trong ${sec} giây`;
        } else {
            return ' đã hết hiệu lực';
        }
    }, [otpValidMinuteRemaining, otpValidSecondRemaining]);

    let countdownText = useMemo(() => {
        let sec = otpCreationSecondRemaining;
        if (sec > 0) {
            return `Gửi lại mã xác thực cho tôi sau ${sec} giây`;
        } else return '';
    }, [otpCreationSecondRemaining]);

    // function submit OTP
    const { mutate: matchTelWithOtp, isPending: isSubmittingOTP } = useMutation(
        {
            mutationFn: postModifyData,
            onSuccess: (data, variables, context) => {
                if (data.data.isValid) {
                    handleLogin(data.data);
                    setUserData(data.data.userInfo);
                    enqueueCustomSnackbar({
                        id: 'login-otp-success-snackbar',
                        title: 'Đăng nhập thành công',
                        severity: 'success',
                    });
                } else {
                    setOtpInputError(data.data.message);
                }
            },
            onError: (err, variables, context) => {
                setOtpInputError(
                    `${err.message} ${
                        err.errorInfo ? `(${err.errorInfo})` : ''
                    }`
                );
            },
        }
    );

    const handleMatchTelWithOtp = () => {
        let validateOtp = validateInputValue('otp', otpDigits);
        if (validateOtp) {
            setOtpInputError(validateOtp);
            return;
        }
        matchTelWithOtp({
            apiUrl: '/user/check-otp',
            formData: {
                data: {
                    tel,
                    otp: Object.values(otpDigits).join(''),
                },
            },
        });
    };
    const otpInputs = useMemo(
        () =>
            Object.keys(otpDigits).map((key, index) => (
                <CustomOTPInput
                    key={index + '-CustomOTPInput'}
                    name={key + '-CustomOTPInput'}
                    value={otpDigits[key]}
                    onChange={(value) => {
                        setOtpInputError('');
                        const newOtpDigits = { ...otpDigits, [key]: value };
                        setOtpDigits(newOtpDigits);
                        if (value && inputRefs.current[index + 1]) {
                            inputRefs.current[index + 1].focus();
                        }
                    }}
                    error={otpInputError}
                    prevRef={inputRefs.current[index - 1] || null}
                    nextRef={inputRefs.current[index + 1] || null}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                />
            )),
        [inputRefs, otpDigits, otpInputError]
    );

    return (
        <Stack px={{ xs: 0, sm: 2 }} height="100%">
            <Box mt={4}>
                <Header children="Nhập mã xác thực" />
                <Typography
                    sx={{
                        textAlign: 'center',
                        maxWidth: 350,
                        mx: 'auto',
                        mt: 2,
                    }}>
                    {`Mã xác thực được gửi đến tài khoản Zalo `}
                    <b>{tel}</b>
                    {remainingValidOTP}
                </Typography>
                <ChangeTelButton />
                <Stack
                    justifyContent="center"
                    spacing={2}
                    direction="row"
                    my={2}>
                    {otpInputs}
                </Stack>
                {otpInputError && (
                    <Typography
                        color="error"
                        sx={{
                            textAlign: 'center',
                            fontSize: 14,
                        }}>
                        {otpInputError}
                    </Typography>
                )}
                <Stack spacing={2} justifyContent="center" my={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '100%',
                        }}>
                        <CustomButton
                            id="check-otp-custom-button"
                            variant="contained"
                            sx={{
                                borderRadius: '20px',
                                py: 1,
                                maxWidth: 350,
                            }}
                            fullWidth
                            disabled={isSubmittingOTP || isGettingOTP}
                            onClick={handleMatchTelWithOtp}
                            children={
                                <b>
                                    {isSubmittingOTP
                                        ? 'Đang xác thực...'
                                        : 'Xác nhận'}
                                </b>
                            }
                        />
                    </Box>
                    {isGettingOTP ? (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                            spacing={2}>
                            <CustomCircularProgress />
                            <Typography color="primary">
                                Đang gửi OTP...
                            </Typography>
                        </Stack>
                    ) : !isOtpCreationAllowed && countdownText ? (
                        <Typography
                            color="primary"
                            sx={{
                                textAlign: 'center',
                                fontWeight: 500,
                            }}>
                            {countdownText}
                        </Typography>
                    ) : (
                        <Typography
                            color="primary"
                            sx={{
                                textAlign: 'center',
                                fontWeight: 500,
                                ':hover': {
                                    backgroundColor: 'transparent',
                                    textDecoration: 'underline',
                                },
                                cursor: 'pointer',
                            }}
                            onClick={handleGenerateNewOtp}>
                            Gửi lại mã xác thực cho tôi
                        </Typography>
                    )}
                </Stack>
            </Box>
            <HelperFooter />
        </Stack>
    );
}
export default function LoginDialog({
    open = false,
    onClose = () => {},
    ...props
}) {
    const isBiggerSmScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('sm') // >=600
    );

    const dispatch = useDispatch();
    const [stepName, setStepName] = useState('tel-input');
    const [userData, setUserData] = useState(defaultUserData);
    const [telError, setTelError] = useState('');

    const checkTelErrorWithNewValue = (newValue) => {
        let newError = validateInputValue('tel', newValue);
        return newError;
    };
    const changeTelError = (newError) => {
        if (newError.length > 0) {
            setTelError(newError);
        }
    };
    const changeTelValue = (newValue) => {
        setUserData((prev) => {
            let updatedData = { ...prev };
            updatedData.tel = newValue;
            return updatedData;
        });
    };
    const resetTelValue = () => {
        changeTelValue('');
        setStepName('tel-input');
    };

    return (
        <LoginContext.Provider
            value={{
                tel: userData.tel,
                changeTel: changeTelValue,
                telError,
                checkTelError: checkTelErrorWithNewValue,
                changeTelError,
                resetTel: resetTelValue,
                stepName,
                changeStepName: setStepName,
            }}>
            <CustomDialog
                isDraggable={false}
                id="login-custom-dialog"
                color="warning"
                title=""
                open={open}
                fullScreen={isBiggerSmScreen ? false : true}
                maxWidth={'xs'}
                onClose={onClose}
                hideNoButton
                hideYesButton>
                <CustomButton
                    id="close-custom-button"
                    useIconButton
                    color="disabled"
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                    children={<CloseIcon />}
                />
                {stepName === 'tel-input' && <RenderTelInput />}
                {stepName === 'not-signup' && <RenderNotSignup />}
                {stepName === 'policy-agreement' && <RenderPolicyAgreement />}
                {stepName === 'zalo-otp-input' && (
                    <RenderOTPInput
                        setUserData={setUserData}
                        handleLogin={(data) => {
                            login(data);
                            onClose();
                            dispatch(
                                userInfoActions.updateUserInfo(data.userInfo)
                            );
                        }}
                    />
                )}
            </CustomDialog>
        </LoginContext.Provider>
    );
}
