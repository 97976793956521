import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { SxProps, Theme } from '@mui/system';

import useCustomColor from 'hooks/useCustomColor';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

/**
 * CustomUploadImageButton renders a button or icon button for uploading images.
 * It supports various styles, sizes, and effects.
 *
 * @param {Object} props - Component properties.
 * @param {string} [props.id] - Optional unique ID for the button.
 * @param {boolean} [props.useIconButton=false] - If true, renders an IconButton instead of a Button.
 * @param {React.ReactNode} [props.children='Tải lên'] - The content of the button, required for Button.
 * @param {function} props.onUpload - Callback function triggered when a file is uploaded.
 * @param {'text' | 'contained' | 'outlined'} [props.variant='text'] - The variant of the button.
 * @param {string} [props.color='primary'] - The color of the button. Supports standard MUI colors or custom colors.
 * @param {'small' | 'medium' | 'large'} [props.size='small'] - The size of the button.
 * @param {SxProps<Theme>} [props.sx] - Additional styles to apply to the button.
 * @param {boolean} [props.disableEffect=false] - If true, disables elevation, ripples, and focus effects.
 * @returns {JSX.Element} Rendered upload button or icon button.
 */
function CustomUploadImageButton({
    id,
    useIconButton = false,
    children = 'Tải lên',
    onUpload = () => {},
    variant = 'text',
    color = 'primary',
    size = 'small',
    sx = {},
    disableEffect = false,
    startIcon, // not supported
    endIcon, // not supported
    ...otherProps
}) {
    const theme = useTheme();
    let customPalleteColor = useCustomColor(color);

    let buttonStyles = useMemo(() => {
        let { main, light, lighter, contrastText } = customPalleteColor;
        let css = {
            contained: {
                backgroundColor: main,
                color: contrastText,
                textTransform: 'initial',
                ':hover': {
                    boxShadow: 1,
                    color: contrastText,
                    backgroundColor: disableEffect ? main : light,
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? main : light,
                    color: contrastText,
                },
                '&:focus': {
                    boxShadow: disableEffect ? 'none' : 1,
                    backgroundColor: disableEffect ? main : light,
                    color: contrastText,
                },
                '&.Mui-disabled': {
                    boxShadow: 'none',
                    color: 'disabled.light',
                    backgroundColor: 'disabled.lighter',
                },
            },
            text: {
                backgroundColor: 'transparent',
                color: main,
                textTransform: 'initial',
                ':hover': {
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: contrastText,
                },
                '&:focus': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: contrastText,
                },
                '&.Mui-disabled': {
                    boxShadow: 'none',
                    color: 'disabled.light',
                    backgroundColor: 'transparent',
                },
            },
            outlined: {
                backgroundColor: 'transparent',
                borderColor: light,
                color: main,
                textTransform: 'initial',
                ':hover': {
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                    borderColor: light,
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: contrastText,
                },
                '&:focus': {
                    boxShadow: 'none',
                    backgroundColor: disableEffect ? 'transparent' : light,
                    color: contrastText,
                },
                '&.Mui-disabled': {
                    color: 'disabled.light',
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                },
            },
            iconButton: {
                backgroundColor: 'transparent',
                color: main,
                ':hover': {
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&:active': {
                    boxShadow: 'none',
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&:focus': {
                    boxShadow: 'none',
                    color: main,
                    backgroundColor: disableEffect ? 'transparent' : lighter,
                },
                '&.Mui-disabled': {
                    color: 'disabled.light',
                    backgroundColor: 'transparent',
                },
            },
        };

        return useIconButton ? css.iconButton : css[variant];
    }, [customPalleteColor, disableEffect, useIconButton, variant]);
    const buttonId = useMemo(() => {
        return id || Math.random().toString(36).slice(2, 11);
    }, [id]);

    if (useIconButton) {
        return (
            <IconButton
                id={buttonId}
                component="label"
                aria-label="upload-file-icon-button"
                role={undefined}
                size={size}
                tabIndex={-1}
                disableElevation={disableEffect}
                disableRipple={disableEffect}
                disableFocusRipple={disableEffect}
                disableTouchRipple={disableEffect}
                sx={{
                    ...buttonStyles,
                    ...(typeof sx === 'function' ? sx(theme) : sx),
                }}
                {...otherProps}>
                <CloudUploadIcon />
                <VisuallyHiddenInput
                    type="file"
                    name="file"
                    accept="image/*"
                    onChange={onUpload}
                    data-testid="hidden-input"
                />
            </IconButton>
        );
    }

    return (
        <Button
            id={buttonId}
            component="label"
            aria-label="upload-file-button"
            role={undefined}
            variant={variant}
            size={size}
            tabIndex={-1}
            disableElevation={disableEffect}
            disableRipple={disableEffect}
            disableFocusRipple={disableEffect}
            disableTouchRipple={disableEffect}
            sx={{
                ...buttonStyles,
                ...(typeof sx === 'function' ? sx(theme) : sx),
            }}
            startIcon={<CloudUploadIcon />}
            {...otherProps}>
            {children}
            <VisuallyHiddenInput
                type="file"
                name="file"
                accept="image/*"
                onChange={onUpload}
                data-testid="hidden-input"
            />
        </Button>
    );
}

CustomUploadImageButton.propTypes = {
    id: PropTypes.string,
    useIconButton: PropTypes.bool,
    children: PropTypes.node,
    onUpload: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string,
    ]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    disableEffect: PropTypes.bool,
};

CustomUploadImageButton.defaultProps = {
    id: undefined,
    useIconButton: false,
    children: 'Tải lên',
    variant: 'text',
    color: 'primary',
    size: 'small',
    sx: {},
    disableEffect: false,
};

export default CustomUploadImageButton;
