import { useState, useEffect, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography, TextField, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import { darken, SxProps, Theme } from '@mui/system';
import { Add as AddIcon } from '@mui/icons-material';

import { useQuery } from '@tanstack/react-query';
import { getSimpleQuery } from 'utils/http.js';

import useCustomColor from 'hooks/useCustomColor';
import useDebounce from 'hooks/useDebounce.js';

import { CustomButton, CustomChip } from 'UI';
import { OptionRow } from 'template';
import { enqueueCustomSnackbar } from './AlertBlock';

/**
 * Styled input component that supports different variants: 'standard', 'outlined', and 'filled'.
 */
const StyledTextField = styled(TextField)(({ theme, ...props }) => {
    let { main, lighter } = useCustomColor(props.color);
    let { sx } = props;
    return {
        '&.MuiTextField-root ': {
            height: '100%',
            ...(props.variant === 'standard' && {
                '& .MuiInputBase-root': {
                    color: main,
                    height: '100%',
                    alignItems: 'flex-start',
                    ':hover:not(.Mui-disabled, .Mui-error):before': {
                        borderWidth: '1px',
                        borderColor: main,
                    },
                    '::after': {
                        borderBottom: `2px solid ${main}`,
                    },
                },
            }),
            ...(props.variant === 'filled' && {
                '& .MuiFilledInput-root': {
                    backgroundColor: lighter,
                    color: 'text.primary',
                    height: '100%',
                    alignItems: 'flex-start',
                    ':hover:not(.Mui-disabled, .Mui-focused)': {
                        backgroundColor: darken(lighter, 0.03),
                        color: 'text.primary',
                    },
                    '.Mui-disabled': {
                        backgroundColor: 'disabled.lighter',
                    },
                },
            }),
            ...(props.variant === 'outlined' && {
                '& .MuiOutlinedInput-root': {
                    color: main,
                    height: '100%',
                    alignItems: 'center',
                    '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
                        borderColor: main,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: main,
                    },
                    '&.Mui-error fieldset': {
                        borderColor: 'error.main',
                    },
                    '&.Mui-disabled fieldset': {
                        borderColor: 'disabled.light',
                    },
                },
            }),
            ...sx,
        },
        ...(props.type === 'number' && {
            '& input[type=number]::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
                display: 'none',
            },
            '& input[type=number]::-webkit-outer-spin-button ': {
                WebkitAppearance: 'none',
                margin: 0,
                display: 'none',
            },
        }),
    };
});

/**
 * CustomSearch component for enhanced search functionality with dynamic suggestions.
 * Supports different variants, autocomplete options, loading states, and error handling.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.name - Unique identifier for the component.
 * @param {string} [props.id] - Custom ID for the Autocomplete component.
 * @param {string} [props.type='text'] - Input type.
 * @param {string} props.pathname - API path for data fetching.
 * @param {function} props.convertArrOptions - Converts fetched data to the required format {id, value, ...}
 * @param {boolean} [props.multiple=false] - Allows multiple selections if true.
 * @param {Array|Object} props.value - Controlled value of the Autocomplete component.
 * @param {function} props.onChange - Callback function on selection change.
 * @param {string} [props.label=''] - Input label.
 * @param {string} [props.error=''] - Error message.
 * @param {string} [props.helperText=''] - Helper text.
 * @param {string} [props.placeholder=''] - Placeholder text.
 * @param {Object} [props.inputProps={}] - Props for the input element.
 * @param {Object} [props.InputLabelProps={ shrink: true }] - Props for the InputLabel component.
 * @param {Object} [props.FormHelperTextProps={}] - Props for the FormHelperText component.
 * @param {boolean} [props.fullWidth=true] - Makes the component take full width.
 * @param {boolean} [props.required=false] - Adds a required attribute.
 * @param {boolean} [props.readOnly=false] - Makes the input read-only.
 * @param {boolean} [props.disabled=false] - Disables the input.
 * @param {boolean} [props.disableCloseOnSelect=true] - Prevents closing on select.
 * @param {boolean} [props.clearOnEscape=true] - Clears input on pressing escape.
 * @param {string} [props.variant='standard'] - Variant of the text field ('standard', 'outlined', 'filled').
 * @param {string} [props.size='small'] - Size of the input ('small', 'medium').
 * @param {string} [props.color='primary'] - Color of the input field.
 * @param {string} [props.margin='none'] - Margin around the input field.
 * @param {number} [props.limitTags=10] - Limits the number of tags shown in the input.
 * @param {React.ReactNode} [props.initialText=<Typography>Nhập từ khóa tìm kiếm...</Typography>] - Initial text when no options are present.
 * @param {boolean} [props.displayHighlight=true] - Highlights matched characters in options.
 * @param {boolean} [props.allowAddNewValue=false] - Allows adding new values if no option matches.
 * @param {function} props.onAddNewValue - Callback for adding a new value.
 * @param {function} props.renderTemplateOption - Custom rendering function for options.
 * @param {React.ReactNode} [props.startAdornment=null] - Start adornment.
 * @param {React.ReactNode} [props.endAdornment=null] - End adornment.
 * @param {SxProps<Theme>} [props.sx] - Additional styles to apply to the root component.
 * @param {function} props.getInputValue - Callback for capturing input value.
 * @param {function} props.initialInputValue - The default input value at first mount.
 * @param {Object} [props.modifiedQueryOptions={}] - Additional options for the useQuery hook.
 */

function CustomSearch({
    name = 'name',
    id,
    type = 'text',
    pathname = '/...',
    convertArrOptions = (options) => options,
    multiple = false,
    value: valueProps = multiple ? [] : null,
    onChange = () => {},
    label = '',
    error = '',
    helperText = '',
    placeholder = '',

    inputProps = {},
    InputLabelProps = { shrink: true },
    FormHelperTextProps = {},

    fullWidth = true,
    required = false,
    readOnly = false,
    disabled = false,
    disableCloseOnSelect = false,
    clearOnEscape = false,

    variant = 'standard',
    size = 'small',
    color = 'primary',
    margin = 'none',
    limitTags = 10,

    initialText = <Typography>Nhập từ khóa tìm kiếm...</Typography>,

    displayHighlight = true,
    allowAddNewValue = false,
    onAddNewValue = () => {},
    renderTemplateOption = (props, option, { selected, inputValue }) => (
        <li {...props} style={{ padding: 0 }} key={props.id}>
            <OptionRow
                compareString={inputValue}
                selected={selected}
                {...option}
            />
        </li>
    ),
    startAdornment = null,
    endAdornment = null,
    sx = {},
    getInputValue = () => {},
    initialInputValue = '',
    modifiedQueryOptions = {},
    ...otherProps
}) {
    const [inputValue, setInputValue] = useState(initialInputValue);
    const debouncedInputValue = useDebounce(inputValue, 500);

    const {
        data: dataFetching,
        isLoading: isFetching,
        isError: isErrorFetching,
        error: errorFetching,
    } = useQuery({
        queryKey: ['search-data', { pathname, debouncedInputValue }],
        queryFn: ({ signal }) =>
            getSimpleQuery({
                signal,
                apiUrl: `${
                    pathname.includes('?') ? pathname + '&' : pathname + '?'
                }keyword=${debouncedInputValue.trim()}`,
            }),

        staleTime: 5000,
        retry: 0,
        enabled: debouncedInputValue.length > 0,
        ...modifiedQueryOptions,
    });
    useEffect(() => {
        if (isErrorFetching && errorFetching) {
            enqueueCustomSnackbar({
                id: 'search-fail-snackbar',
                title: errorFetching.message,
                children: errorFetching.errorInfo,
            });
        }
    }, [dataFetching, errorFetching, isErrorFetching]);

    const comboBoxId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    const noOptionsText = useMemo(() => {
        return debouncedInputValue.trim() ? (
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                width="100%"
                justifyContent={'space-between'}
                px={1}>
                <Typography>Không có kết quả phù hợp.</Typography>
                {allowAddNewValue && (
                    <CustomButton
                        id="add-new-data-custom-button"
                        variant="contained"
                        onClick={onAddNewValue}
                        startIcon={<AddIcon />}
                        children="Thêm"
                    />
                )}
            </Stack>
        ) : (
            initialText
        );
    }, [allowAddNewValue, debouncedInputValue, initialText, onAddNewValue]);

    const defaultAutocompleteProps = {
        id: comboBoxId,
        label,
        size,
        disablePortal: true,
        clearOnBlur: true,
        autoHighlight: true,
        disableListWrap: true,
        disabled,
        readOnly,
        required,
        multiple,
        fullWidth,
        limitTags,
        disableCloseOnSelect,
        clearOnEscape,
        noOptionsText,
        openText: 'Mở',
        closeText: 'Đóng',
        clearText: 'Xóa',
        loadingText: 'Đang tải...',
    };
    const validatedOptions = useMemo(() => {
        const result = convertArrOptions(dataFetching?.data || []);

        if (!Array.isArray(result)) {
            console.error('convertArrOptions must return an array');
            return [];
        }

        for (const option of result) {
            if (typeof option !== 'object' || !option.id) {
                console.error(
                    "Each item returned by convertArrOptions must be an object with 'id' and 'value' properties"
                );
                return [];
            }
        }

        return result;
    }, [convertArrOptions, dataFetching?.data]);

    const mergeAdornments = (...adornments) => {
        const nonNullAdornments = adornments.filter((el) => el != null);
        if (nonNullAdornments.length === 0) {
            return null;
        }

        if (nonNullAdornments.length === 1) {
            return nonNullAdornments[0];
        }

        return (
            <Stack direction="row">
                {nonNullAdornments.map((adornment, index) => (
                    <Fragment key={index}>{adornment}</Fragment>
                ))}
            </Stack>
        );
    };

    return (
        <Autocomplete
            {...defaultAutocompleteProps}
            loading={isFetching}
            getOptionLabel={(option) => option?.value || ''}
            isOptionEqualToValue={(option, value) =>
                option.value === value.value
            }
            options={validatedOptions}
            value={valueProps}
            onChange={(event, newValue, reason) => {
                onChange(newValue, reason);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                getInputValue(newInputValue);
            }}
            renderInput={(params) => {
                params.InputProps.startAdornment = mergeAdornments(
                    startAdornment,
                    params.InputProps.startAdornment
                );
                params.InputProps.endAdornment = mergeAdornments(
                    endAdornment,
                    params.InputProps.endAdornment
                );
                let defaultInputProps = {
                    type,
                    label,
                    error: Boolean(error),
                    helperText: error || helperText,
                    placeholder,
                    slotProps: {
                        inputLabel: InputLabelProps,
                    },
                    fullWidth,
                    required,
                    variant,
                    color,
                    margin,
                    sx: (theme) => ({
                        ...(typeof sx === 'function' ? sx(theme) : sx),
                    }),
                };
                return <StyledTextField {...defaultInputProps} {...params} />;
            }}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <CustomChip
                        key={option.id}
                        size={size}
                        variant={variant !== 'standard' ? variant : 'outlined'}
                        color={color}
                        label={option?.chipLabel || option.value}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderOption={(props, option, { selected }) =>
                renderTemplateOption(props, option, {
                    selected,
                    inputValue: debouncedInputValue,
                })
            }
            {...otherProps}
        />
    );
}

CustomSearch.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    convertArrOptions: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    helperText: PropTypes.string,
    placeholder: PropTypes.string,
    inputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    clearOnEscape: PropTypes.bool,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    size: PropTypes.oneOf(['small', 'medium']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string, // For custom colors
    ]),
    margin: PropTypes.oneOf(['none', 'dense']),
    limitTags: PropTypes.number,
    initialText: PropTypes.node,
    displayHighlight: PropTypes.bool,
    allowAddNewValue: PropTypes.bool,
    onAddNewValue: PropTypes.func,
    renderTemplateOption: PropTypes.func,
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    getInputValue: PropTypes.func,
    initialInputValue: PropTypes.string,
    modifiedQueryOptions: PropTypes.object,
};

CustomSearch.defaultProps = {
    type: 'text',
    multiple: false,
    value: null,
    pathname: '/',
    convertArrOptions: (options) => options,
    label: '',
    error: '',
    helperText: '',
    placeholder: '',
    inputProps: {},
    InputLabelProps: { shrink: true },
    FormHelperTextProps: {},
    fullWidth: true,
    required: false,
    readOnly: false,
    disabled: false,
    disableCloseOnSelect: true,
    clearOnEscape: true,
    variant: 'standard',
    size: 'small',
    color: 'primary',
    margin: 'none',
    limitTags: 10,
    initialText: <Typography>Nhập từ khóa tìm kiếm...</Typography>,
    displayHighlight: true,
    allowAddNewValue: false,
    onAddNewValue: () => {},
    renderTemplateOption: (props, option, { selected, inputValue }) => (
        <li {...props} style={{ padding: 0 }} key={props.id}>
            <OptionRow
                label={option.value}
                compareString={inputValue}
                selected={selected}
                {...option}
            />
        </li>
    ),
    startAdornment: null,
    endAdornment: null,
    sx: {},
    getInputValue: () => {},
    initialInputValue: '',
    modifiedQueryOptions: {},
};

export default CustomSearch;
