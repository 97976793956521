import { useState } from 'react';

import { AppBar, Typography, Stack, useMediaQuery, Box } from '@mui/material';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Menu as MenuIcon,
    Person as PersonIcon,
    Logout as LogoutIcon,
    ShoppingBasket as ShoppingBasketIcon,
    PinDrop as PinDropIcon,
    EmojiEvents as EmojiEventsIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
    Face as FaceIcon,
    Face2 as Face2Icon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { systemActions } from 'store/system-slice.js';

import SearchAppbar from './SearchAppbar.jsx';
import { CustomMenu, CustomAvatar, CustomButton } from 'UI';

const customTheme = (outerTheme) =>
    createTheme({
        components: {
            MuiTypography: {
                styleOverrides: {
                    noWrap(styles) {
                        return {
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: String(
                                styles.ownerState['data-lines'] || '3'
                            ),
                            WebkitBoxOrient: 'vertical',
                        };
                    },
                },
            },
        },
    });
const StyledAccountAvatar = ({ isLoggined = false, gender = 'male' }) => {
    if (!isLoggined) {
        return <PersonIcon color="primary" />;
    }
    const Icon = gender === 'male' ? FaceIcon : Face2Icon;
    return (
        <CustomAvatar avatarSize={28}>
            <Icon
                color="primary"
                sx={{
                    width: 26,
                    height: 26,
                }}
            />
        </CustomAvatar>
    );
};
function Logo({ route = 'shop', ...props }) {
    const navigate = useNavigate();
    return (
        <CustomAvatar
            variant="square"
            src="../assets/images/khanhtranglogo-02.png"
            onClick={() => {
                if (route === 'admin') navigate('/admin');
                else navigate('/');
            }}
            sx={{
                cursor: 'pointer',
                width: 200,
                height: 80,
            }}
        />
    );
}
function MenuButton({ ...props }) {
    return (
        <CustomButton
            id="menu-buttonId"
            disableEffect
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
            {...props}>
            <MenuIcon />
            <Typography variant="caption" color="primary">
                Menu
            </Typography>
        </CustomButton>
    );
}
function AppbarContainer({ openSearch = false, children, ...props }) {
    return (
        <AppBar
            component="nav"
            sx={{
                '&.MuiAppBar-root': {
                    backgroundColor: openSearch ? 'aliceblue' : 'white',
                    top: 0,
                },
            }}
            elevation={0}
            position="absolute">
            {children}
        </AppBar>
    );
}
function AccountAvatar({
    userInfo = {},
    openLoginDialog = () => {},
    openLogoutDialog = () => {},
    ...props
}) {
    const theme = useTheme();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    let menuOpen = Boolean(menuAnchorEl);

    let {
        _id = null,
        username = '',
        gender = 'male',
        tel = '',
        rewardPoint = null,
    } = userInfo;

    return (
        <>
            <CustomButton
                id="account-avatar-buttonId"
                disableEffect
                onClick={(event) => {
                    if (!_id) openLoginDialog();
                    else setMenuAnchorEl(event.currentTarget);
                }}
                aria-controls={menuOpen ? 'account-menuId' : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? 'true' : undefined}
                sx={{
                    p: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}>
                <StyledAccountAvatar
                    isLoggined={Boolean(_id)}
                    gender={gender}
                />
                <ThemeProvider theme={customTheme(theme)}>
                    <Typography
                        variant="caption"
                        noWrap
                        data-lines="2"
                        sx={{
                            color: 'primary.main',
                            textTransform: 'initial',
                            textAlign: 'center',
                            maxHeight: 34,
                            maxWidth: 80,
                            lineHeight: 1.2,
                        }}>
                        {_id ? username || tel : 'Đăng nhập'}
                    </Typography>
                </ThemeProvider>
            </CustomButton>
            {menuOpen && (
                <CustomMenu
                    id="account-menuId"
                    anchorEl={menuAnchorEl}
                    open={menuOpen}
                    density="comfortable"
                    autoFocus={false}
                    margin="dense"
                    alignItems="center"
                    onClose={() => setMenuAnchorEl(null)}
                    items={(rewardPoint
                        ? [
                              {
                                  name: 'reward-point',
                                  Icon: EmojiEventsIcon,
                                  label: `Điểm tích lũy: $
                  {Number(rewardPoint).toLocaleString()} điểm`,
                                  divider: true,
                              },
                          ]
                        : []
                    ).concat(
                        [
                            {
                                name: 'account-information',
                                Icon: PersonIcon,
                                label: 'Thông tin tài khoản',
                            },
                            {
                                name: 'shipping-address',
                                Icon: PinDropIcon,
                                label: ' Địa chỉ nhận hàng',
                            },
                            {
                                name: 'shipping-history',
                                Icon: ShoppingBasketIcon,
                                label: 'Lịch sử mua hàng',
                                divider: true,
                            },
                            {
                                name: 'logout',
                                Icon: LogoutIcon,
                                label: 'Đăng xuất',
                            },
                        ].map(({ label, Icon, ...i }) => {
                            let color =
                                i.name === 'logout'
                                    ? 'warning'
                                    : i.name === 'reward-point'
                                    ? 'success'
                                    : 'primary';

                            return {
                                ...i,
                                icon: <Icon fontSize="small" color={color} />,
                                label: (
                                    <Typography color={color} sx={{ pr: 2 }}>
                                        {label}
                                    </Typography>
                                ),
                                subLabel: null,
                            };
                        })
                    )}
                    onSelectItem={(item) => {
                        if (item.name === 'logout') openLogoutDialog();
                        return;
                    }}
                />
            )}
        </>
    );
}
export default function SectionAppbar({
    route = 'shop',
    openLogoutDialog = () => {},
    openLoginDialog = () => {},
    openDrawer = () => {},
    ...props
}) {
    const isBiggerMdScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('md')
    );

    const userInfo = useSelector((state) => state.userInfo.value);
    const dispatch = useDispatch();

    const [openSearch, setOpenSearch] = useState(false);

    if (isBiggerMdScreen) {
        return (
            <AppbarContainer openSearch={openSearch}>
                <Stack
                    sx={{
                        width: '100%',
                        maxWidth: 1200,
                        mx: 'auto',
                        mt: 2,
                        px: 3,
                        height: 80,
                    }}
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch">
                    <Box
                        sx={{
                            overflow: 'hidden',
                            width: { md: '30%', lg: '25%', xl: '20%' },
                        }}>
                        <Logo route={route} />
                    </Box>
                    <Box sx={{ my: 'auto', width: '100%' }}>
                        <SearchAppbar
                            open={openSearch}
                            onOpen={setOpenSearch}
                            sx={{
                                borderColor: 'primary.main',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderRadius: '30px !important',
                                backgroundColor: 'white',
                                '& .MuiOutlinedInput-root fieldset': {
                                    border: 'none',
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                    display: 'none',
                                },
                            }}
                        />
                    </Box>
                    <Stack
                        sx={{ width: '15%', mt: 2, mx: 1 }}
                        justifyContent="center"
                        alignItems="center">
                        <AccountAvatar
                            userInfo={userInfo}
                            openLoginDialog={openLoginDialog}
                            openLogoutDialog={openLogoutDialog}
                        />
                    </Stack>
                </Stack>
            </AppbarContainer>
        );
    }

    return (
        <AppbarContainer openSearch={openSearch}>
            {!openSearch && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ height: 80, px: 2, width: '100%' }}>
                    <MenuButton onClick={openDrawer} />
                    <Logo route={route} />
                    <AccountAvatar
                        userInfo={userInfo}
                        openLoginDialog={openLoginDialog}
                        openLogoutDialog={openLogoutDialog}
                    />
                </Stack>
            )}
            <Box p={2} pt={openSearch ? 2 : 0}>
                <SearchAppbar
                    open={openSearch}
                    onOpen={setOpenSearch}
                    inputProps={{ style: { fontSize: 14 } }}
                    sx={{
                        borderColor: 'primary.main',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: '30px !important',
                        backgroundColor: 'white',
                        '& .MuiOutlinedInput-root fieldset': {
                            border: 'none',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                            display: 'none',
                        },
                        '& .MuiOutlinedInput-root': {
                            color: 'primary.main',
                            height: '100%',
                            alignItems: 'center',
                            ...(!openSearch && {
                                padding: '0px 30px 0px 8px',
                            }),
                            '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
                                borderColor: 'primary.main',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary.main',
                            },
                            '&.Mui-error fieldset': {
                                borderColor: 'error.main',
                            },
                            '&.Mui-disabled fieldset': {
                                borderColor: 'disabled.light',
                            },
                        },
                    }}
                    startAdornment={
                        openSearch && (
                            <CustomButton
                                id="close-search-buttonId"
                                useIconButton
                                disableEffect
                                onClick={() => {
                                    setOpenSearch(false);
                                    dispatch(
                                        systemActions.toggleDimBackground(false)
                                    );
                                    document.body.style.overflow = '';
                                }}>
                                <ArrowBackIosNewIcon
                                    sx={{ color: 'primary.main' }}
                                />
                            </CustomButton>
                        )
                    }
                />
            </Box>
        </AppbarContainer>
    );
}
