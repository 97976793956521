import * as React from 'react';

import {
    Box,
    Typography,
    CardContent,
    Grid2,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { customScrollbar } from 'config/customCSS.js';
import {
    typeBoxSx,
    cellBoxSx,
    cellTypographySx,
    lineBoxSx,
    lineTypographySx,
    boxPropsNameMap,
    typographyPropsNameMap,
} from 'template/CustomBoxDataElement.js';

import { useNavigate } from 'react-router-dom';
const numberToRoman = (num, uppercase = false) => {
    // num = 0 => return ''
    // num = 1 => return 'i'
    // num = 3 => return 'iii'
    const romanNumerals = [
        { value: 1000, numeral: 'M' },
        { value: 900, numeral: 'CM' },
        { value: 500, numeral: 'D' },
        { value: 400, numeral: 'CD' },
        { value: 100, numeral: 'C' },
        { value: 90, numeral: 'XC' },
        { value: 50, numeral: 'L' },
        { value: 40, numeral: 'XL' },
        { value: 10, numeral: 'X' },
        { value: 9, numeral: 'IX' },
        { value: 5, numeral: 'V' },
        { value: 4, numeral: 'IV' },
        { value: 1, numeral: 'I' },
    ];

    let result = '';

    for (let i = 0; i < romanNumerals.length; i++) {
        const { value, numeral } = romanNumerals[i];
        while (num >= value) {
            result += uppercase
                ? `${numeral}`.toString().toLocaleUpperCase()
                : `${numeral}`.toString().toLocaleLowerCase();
            num -= value;
        }
    }

    return result;
};
const numberToAlphabet = (num, uppercase = false) => {
    // num = 0 => return 'a'
    // num = 3 => return 'd'
    let result = '';

    while (num >= 0) {
        result = String.fromCharCode((num % 26) + 97) + result;
        num = Math.floor(num / 26) - 1;
    }

    return uppercase ? result.toLocaleUpperCase() : result.toLocaleLowerCase();
};
function htmlParser(string) {
    if (string.includes('<a')) {
        let list_a_tag = string.split('<a');
        let arrResult = [];
        for (let index = 0; index < list_a_tag.length; index++) {
            const element = list_a_tag[index];
            if (element.includes('</a>')) {
                let href =
                    element
                        .split('</a>')[0]
                        .split('>')?.[0]
                        ?.split('href=')?.[1]
                        ?.replaceAll("'", '')
                        ?.replaceAll('"', '') ?? '/';
                arrResult.push(
                    <span>
                        <a href={href}>
                            {htmlParser(element.split('</a>')[0].split('>')[1])}
                        </a>
                        <span>{htmlParser(element.split('</a>')[1])}</span>
                    </span>
                );
            } else {
                arrResult.push(<span>{htmlParser(element)}</span>);
            }
        }
        let parserResult = Array.from(
            { length: list_a_tag.length },
            (x, i) => i
        ).map((i) => {
            return arrResult[i];
        });

        return parserResult;
    } else if (string.includes('<br>')) {
        return string.split('<br>').map((i) => {
            return <p>{htmlParser(i)}</p>;
        });
    } else if (string.includes('<sup>')) {
        let list_sup_tag = string.split('<sup>');
        let arrResult = [];
        for (let index = 0; index < list_sup_tag.length; index++) {
            const element = list_sup_tag[index];
            if (element.includes('</sup>')) {
                arrResult.push(
                    <span>
                        <sup>{htmlParser(element.split('</sup>')[0])}</sup>
                        <span>{htmlParser(element.split('</sup>')[1])}</span>
                    </span>
                );
            } else {
                arrResult.push(<span>{htmlParser(element)}</span>);
            }
        }
        let parserResult = Array.from(
            { length: list_sup_tag.length },
            (x, i) => i
        ).map((i) => {
            return arrResult[i];
        });

        return parserResult;
    } else if (string.includes('<sub>')) {
        let list_sub_tag = string.split('<sub>');
        let arrResult = [];
        for (let index = 0; index < list_sub_tag.length; index++) {
            const element = list_sub_tag[index];
            if (element.includes('</sub>')) {
                arrResult.push(
                    <span>
                        <sub>{htmlParser(element.split('</sub>')[0])}</sub>
                        <span>{htmlParser(element.split('</sub>')[1])}</span>
                    </span>
                );
            } else {
                arrResult.push(<span>{htmlParser(element)}</span>);
            }
        }
        let parserResult = Array.from(
            { length: list_sub_tag.length },
            (x, i) => i
        ).map((i) => {
            return arrResult[i];
        });

        return parserResult;
    } else if (string.includes('<ins>')) {
        let list_ins_tag = string.split('<ins>');
        let arrResult = [];
        for (let index = 0; index < list_ins_tag.length; index++) {
            const element = list_ins_tag[index];
            if (element.includes('</ins>')) {
                arrResult.push(
                    <span>
                        <ins>{htmlParser(element.split('</ins>')[0])}</ins>
                        <span>{htmlParser(element.split('</ins>')[1])}</span>
                    </span>
                );
            } else {
                arrResult.push(<span>{htmlParser(element)}</span>);
            }
        }
        let parserResult = Array.from(
            { length: list_ins_tag.length },
            (x, i) => i
        ).map((i) => {
            return arrResult[i];
        });

        return parserResult;
    } else if (string.includes('<i>')) {
        let list_i_tag = string.split('<i>');
        let arrResult = [];
        for (let index = 0; index < list_i_tag.length; index++) {
            const element = list_i_tag[index];
            if (element.includes('</i>')) {
                arrResult.push(
                    <span>
                        <i>{htmlParser(element.split('</i>')[0])}</i>
                        <span>{htmlParser(element.split('</i>')[1])}</span>
                    </span>
                );
            } else {
                arrResult.push(<span>{htmlParser(element)}</span>);
            }
        }
        let parserResult = Array.from(
            { length: list_i_tag.length },
            (x, i) => i
        ).map((i) => {
            return arrResult[i];
        });

        return parserResult;
    } else if (string.includes('<b>')) {
        let list_b_tag = string.split('<b>');
        let arrResult = [];
        for (let index = 0; index < list_b_tag.length; index++) {
            const element = list_b_tag[index];
            if (element.includes('</b>')) {
                arrResult.push(
                    <span>
                        <b>{htmlParser(element.split('</b>')[0])}</b>
                        <span>{htmlParser(element.split('</b>')[1])}</span>
                    </span>
                );
            } else {
                arrResult.push(<span>{htmlParser(element)}</span>);
            }
        }
        let parserResult = Array.from(
            { length: list_b_tag.length },
            (x, i) => i
        ).map((i) => {
            return arrResult[i];
        });

        return parserResult;
    } else {
        return string;
    }
}
export default function RenderContent(props) {
    const { newContent = [], isShowMore = true, isBiggerText = false } = props;

    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >= 600
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >= 800
    const biggerXL = useMediaQuery(theme.breakpoints.up('md')); // >= 1200

    return (
        <CardContent
            sx={
                isShowMore
                    ? {
                          maxHeight: '100%',
                          pt: 0,
                          pb: 4,
                          px: { xs: 1, sm: 3 },
                          ...customScrollbar,
                      }
                    : {
                          maxHeight: 440,
                          overflow: 'visible',
                          pt: 0,
                          pb: 4,
                          px: { xs: 1, sm: 3 },
                      }
            }
            style={{ zIndex: -99 }}>
            {newContent.length === 0 ? (
                <Typography variant="caption" sx={{ fontSize: 14 }}>
                    {`Nội dung chi tiết đang được cập nhật...`}
                </Typography>
            ) : (
                newContent.map((data, idxLv1) => {
                    if (!data || !data?.type) {
                        return null;
                    } else if (data?.type === 'table') {
                        if (!data?.rows) {
                            return null;
                        }
                        return (
                            <Box
                                key={idxLv1}
                                component={'div'}
                                sx={{
                                    ...typeBoxSx,
                                    ...(data?.boxProps ? data?.boxProps : {}),
                                }}>
                                {Object.keys(data?.rows).map((rowNumber, j) => {
                                    return (
                                        <Grid2 container key={idxLv1 + j}>
                                            {data.rows[rowNumber].map(
                                                (cell, k) => {
                                                    const { content } = cell;
                                                    const {
                                                        width,
                                                        mergeCount,
                                                        ...otherBoxProps
                                                    } = cell?.boxProps ?? {};
                                                    return (
                                                        <Grid2
                                                            key={idxLv1 + j + k}
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            size="grow"
                                                            sx={{
                                                                ...cellBoxSx,
                                                                width:
                                                                    width ??
                                                                    (Number(
                                                                        mergeCount ??
                                                                            0
                                                                    ) >= 1
                                                                        ? `calc(${
                                                                              100 *
                                                                              (Number(
                                                                                  mergeCount
                                                                              ) +
                                                                                  1)
                                                                          }% + ${
                                                                              58 *
                                                                              Number(
                                                                                  mergeCount
                                                                              )
                                                                          }px)`
                                                                        : '100%'),
                                                                borderRadius:
                                                                    j === 0 &&
                                                                    k === 0
                                                                        ? '3px 0 0 0'
                                                                        : j ===
                                                                              Object.keys(
                                                                                  data?.rows
                                                                              )
                                                                                  .length -
                                                                                  1 &&
                                                                          k ===
                                                                              0
                                                                        ? '0 0 0 3px'
                                                                        : j ===
                                                                              0 &&
                                                                          k ===
                                                                              data
                                                                                  ?.rows[
                                                                                  rowNumber
                                                                              ]
                                                                                  .length -
                                                                                  1
                                                                        ? '0 3px 0 0'
                                                                        : j ===
                                                                              Object.keys(
                                                                                  data?.rows
                                                                              )
                                                                                  .length -
                                                                                  1 &&
                                                                          k ===
                                                                              data
                                                                                  ?.rows[
                                                                                  rowNumber
                                                                              ]
                                                                                  .length -
                                                                                  1
                                                                        ? '0 0 3px 0'
                                                                        : '0 0 0 0',
                                                                backgroundColor:
                                                                    rowNumber ===
                                                                    '0'
                                                                        ? 'aliceblue'
                                                                        : 'transparent',
                                                                ...(otherBoxProps ??
                                                                    {}),
                                                            }}>
                                                            <Typography
                                                                {...{
                                                                    ...cellTypographySx,
                                                                    fontWeight:
                                                                        rowNumber ===
                                                                        '0'
                                                                            ? 500
                                                                            : 400,
                                                                    ...(cell?.typographyProps
                                                                        ? cell?.typographyProps
                                                                        : {}),
                                                                    ...(isBiggerText && {
                                                                        fontSize:
                                                                            cell
                                                                                ?.typographyProps
                                                                                ?.fontSize
                                                                                ? cell
                                                                                      ?.typographyProps
                                                                                      ?.fontSize +
                                                                                  2
                                                                                : cellTypographySx.fontSize +
                                                                                  2,
                                                                    }),
                                                                }}>
                                                                {htmlParser(
                                                                    content ??
                                                                        ''
                                                                )}
                                                            </Typography>
                                                        </Grid2>
                                                    );
                                                }
                                            )}
                                        </Grid2>
                                    );
                                })}
                            </Box>
                        );
                    } else if (data?.type === 'list') {
                        if (!data?.listcontent) {
                            return null;
                        }
                        return (
                            <Box
                                key={idxLv1}
                                sx={{
                                    ...typeBoxSx,
                                    justifyContent: 'flex-start',
                                    ...(data?.boxProps ? data?.boxProps : {}),
                                }}
                                component={'div'}>
                                {data?.listcontent.map((element, k) => {
                                    let newBulletPoint =
                                        data?.bulletPoint === 'uppercaseRoman'
                                            ? numberToRoman(k + 1, true) + '.'
                                            : data?.bulletPoint ===
                                              'lowercaseRoman'
                                            ? numberToRoman(k + 1) + '.'
                                            : data?.bulletPoint ===
                                              'uppercaseAlphabet'
                                            ? numberToAlphabet(k, true) + '.'
                                            : data?.bulletPoint ===
                                              'lowercaseAlphabet'
                                            ? numberToAlphabet(k) + '.'
                                            : data?.bulletPoint === 'number'
                                            ? Number(k + 1).toString() + '.'
                                            : data?.bulletPoint === 'circle'
                                            ? '● '
                                            : data?.bulletPoint === 'radio'
                                            ? '⦿ '
                                            : data?.bulletPoint === 'square'
                                            ? '▪ '
                                            : data?.bulletPoint === 'check'
                                            ? '✔ '
                                            : data?.bulletPoint === 'add'
                                            ? '﹢ '
                                            : data?.bulletPoint === 'minus'
                                            ? '﹣ '
                                            : data?.bulletPoint === 'diamond'
                                            ? '◆ '
                                            : '';
                                    return (
                                        <Box
                                            key={idxLv1 + k}
                                            sx={{
                                                ...lineBoxSx,
                                                ...(!biggerSM && {
                                                    pl: 0.5,
                                                }),
                                                ...(element?.boxProps
                                                    ? element?.boxProps
                                                    : {}),
                                            }}
                                            component={'div'}>
                                            <Typography
                                                {...{
                                                    ...lineTypographySx,
                                                    ...(element?.typographyProps
                                                        ? element?.typographyProps
                                                        : {}),
                                                    ...(isBiggerText && {
                                                        fontSize: element
                                                            ?.typographyProps
                                                            ?.fontSize
                                                            ? element
                                                                  ?.typographyProps
                                                                  ?.fontSize + 2
                                                            : lineTypographySx.fontSize +
                                                              2,
                                                    }),
                                                }}>
                                                {htmlParser(
                                                    `${newBulletPoint} ${
                                                        element?.content ?? ''
                                                    }`
                                                )}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                        );
                    } else if (data?.type === 'image') {
                        if (!data?.src) {
                            return null;
                        }
                        let { width, ...styleProps } = data?.imageProps
                            ? data?.imageProps
                            : {};
                        let finalWidth = biggerXL
                            ? width
                                ? parseInt(width) * (0.4).toString() + '%'
                                : '40%'
                            : biggerMD
                            ? width
                                ? parseInt(width) * (0.6).toString() + '%'
                                : '60%'
                            : biggerSM
                            ? width
                                ? parseInt(width) * (0.8).toString() + '%'
                                : '80%'
                            : width && width !== '150%' && width !== '200%'
                            ? parseInt(width).toString() + '%'
                            : '100%';
                        return (
                            <Box
                                key={idxLv1}
                                sx={{
                                    ...typeBoxSx,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    my: 1,
                                    ...(data?.boxProps ? data?.boxProps : {}),
                                }}
                                component={'div'}>
                                {data?.src && (
                                    <img
                                        src={data?.src}
                                        loading="lazy"
                                        alt={'abstract'}
                                        width={finalWidth}
                                        height="auto"
                                        style={{
                                            borderRadius: '3px',
                                            ...styleProps,
                                        }}
                                    />
                                )}
                            </Box>
                        );
                    } else {
                        if (!data?.type || !data?.content) {
                            return null;
                        }
                        return (
                            <Box
                                key={idxLv1}
                                sx={{
                                    ...boxPropsNameMap[data?.type],
                                    ...(data?.boxProps ? data?.boxProps : {}),
                                    ...(data?.type === 'quote' &&
                                        !biggerSM && { width: '100%' }),
                                }}
                                component={'div'}>
                                <Typography
                                    {...{
                                        ...typographyPropsNameMap[data?.type],
                                        ...(data?.typographyProps
                                            ? data?.typographyProps
                                            : {}),
                                        ...(isBiggerText && {
                                            fontSize: data?.typographyProps
                                                ?.fontSize
                                                ? data?.typographyProps
                                                      ?.fontSize + 2
                                                : typographyPropsNameMap[
                                                      data?.type
                                                  ].fontSize + 2,
                                        }),
                                    }}>
                                    {htmlParser(data?.content)}
                                </Typography>
                            </Box>
                        );
                    }
                })
            )}
        </CardContent>
    );
}
