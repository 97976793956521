import { useEffect, useState } from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import {
    Delete as DeleteIcon,
    SwitchAccessShortcutRounded as SwitchAccessShortcutRoundedIcon,
} from '@mui/icons-material';

import {
    CustomDialog,
    CustomButton,
    CustomList,
    CustomSelect,
    CustomAvatar,
} from 'UI';

function CustomStandardSelect({
    name = 'name',
    options = [],
    data = {},
    value = data[name],
    onChange = () => {},
    ...otherProps
}) {
    return (
        <CustomSelect
            name={name}
            id={name + '-selectId'}
            options={options}
            multiple={false}
            value={value}
            onChange={onChange}
            FormHelperTextProps={{
                sx: { fontStyle: 'italic', color: 'text.link' },
            }}
            {...otherProps}
        />
    );
}

export default function SortDialog({
    open = false,
    onClose = () => {},
    initialValue = [],
    onSubmit = () => {},
    sortData = {},
    ...otherProps
}) {
    const [sortModel, setSortModel] = useState([]);
    // apply current sort if present, only run once!
    useEffect(() => {
        let getData;
        if (open) {
            getData = setTimeout(() => {
                setSortModel(
                    initialValue.length > 0
                        ? initialValue.map((i) => ({
                              id: Math.random() * 1000,
                              sortName: Object.keys(i)[0],
                              sortValue: Object.values(i)[0],
                          }))
                        : [
                              {
                                  id: Math.random() * 1000,
                                  sortName: Object.keys(sortData)[0],
                                  sortValue: 1,
                              },
                          ]
                );
            }, 200);
        }
        return () => {
            clearTimeout(getData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (open) {
        return (
            <CustomDialog
                id="sort-data-custom-dialog"
                title={'Sắp xếp dữ liệu'}
                open={open}
                onClose={onClose}
                maxWidth="md"
                labelNoButton="Thêm"
                hideNoButton={sortModel.length === Object.keys(sortData).length}
                onNo={() => {
                    setSortModel((data) => {
                        let updatedData = [...data];
                        return [
                            ...updatedData,
                            {
                                id: Math.random() * 1000,
                                sortName: Object.keys(sortData).filter(
                                    (name) =>
                                        !updatedData
                                            .map((i) => i.sortName)
                                            .includes(name)
                                )[0],
                                sortValue: 1,
                            },
                        ];
                    });
                }}
                labelYesButton={sortModel.length === 0 ? 'Mặc định' : 'Sắp xếp'}
                onYes={() => {
                    onSubmit(sortModel);
                    onClose();
                }}>
                <Typography variant="caption">
                    Sắp xếp dữ liệu theo thứ tự ưu tiên từ trên xuống dưới:
                </Typography>
                <CustomList
                    id="sort-list-listId"
                    items={sortModel.map((sort, index) => {
                        return {
                            name: sort.id,
                            label: null,
                            subLabel: (
                                <Stack
                                    pl={{ xs: 0, sm: 2 }}
                                    direction={{ xs: 'column', sm: 'row' }}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    width="100%">
                                    <Stack
                                        width="100%"
                                        direction="row"
                                        alignItems="flex-end"
                                        spacing={1}>
                                        <CustomAvatar
                                            sx={{
                                                p: '2px !important',
                                                backgroundColor: '#ffe3c2',
                                                color: '#3c3c3c',
                                                fontSize: 14,
                                            }}>
                                            {index + 1}
                                        </CustomAvatar>
                                        <CustomStandardSelect
                                            name="sortName"
                                            options={[sort.sortName].concat(
                                                Object.keys(sortData).filter(
                                                    (name) =>
                                                        !sortModel
                                                            .map(
                                                                (i) =>
                                                                    i.sortName
                                                            )
                                                            .includes(name) &&
                                                        name !== sort.sortName
                                                )
                                            )}
                                            formatOption={(option) => ({
                                                name: option,
                                                label: sortData[option],
                                                subLabel: null,
                                            })}
                                            data={sort}
                                            onChange={(newValue) => {
                                                setSortModel((data) => {
                                                    let updatedData = [...data];
                                                    updatedData =
                                                        updatedData.map(
                                                            (item) =>
                                                                item.id ===
                                                                sort.id
                                                                    ? {
                                                                          ...item,
                                                                          sortName:
                                                                              newValue,
                                                                      }
                                                                    : item
                                                        );
                                                    return updatedData;
                                                });
                                            }}
                                        />
                                    </Stack>
                                    <Stack
                                        width="100%"
                                        height="100%"
                                        direction="row"
                                        justifyContent={{
                                            xs: 'flex-end',
                                            sm: 'flex-start',
                                        }}
                                        alignItems="flex-end"
                                        spacing={1}
                                        divider={
                                            <Divider
                                                component={'span'}
                                                orientation="vertical"
                                                variant="middle"
                                                flexItem
                                                style={{
                                                    marginTop: 'auto',
                                                    height: '30px',
                                                }}
                                            />
                                        }>
                                        <CustomStandardSelect
                                            name="sortValue"
                                            options={[1, -1]}
                                            FormControlProps={{
                                                sx: {
                                                    width: 130,
                                                },
                                            }}
                                            formatOption={(option) => ({
                                                name: option,
                                                label:
                                                    option === 1
                                                        ? 'Tăng dần'
                                                        : 'Giảm dần',
                                            })}
                                            data={sort}
                                            onChange={(newValue) => {
                                                setSortModel((data) => {
                                                    let updatedData = [...data];
                                                    updatedData =
                                                        updatedData.map(
                                                            (item) =>
                                                                item.id ===
                                                                sort.id
                                                                    ? {
                                                                          ...item,
                                                                          sortValue:
                                                                              newValue,
                                                                      }
                                                                    : item
                                                        );
                                                    return updatedData;
                                                });
                                            }}
                                        />
                                        <CustomButton
                                            id={sort.id + '-delete-button-'}
                                            color="disabled"
                                            useIconButton
                                            onClick={() => {
                                                setSortModel((data) => {
                                                    let updatedData = [...data];
                                                    return updatedData.filter(
                                                        (i) => i.id !== sort.id
                                                    );
                                                });
                                            }}
                                            children={
                                                <DeleteIcon fontSize="small" />
                                            }
                                        />
                                        <CustomButton
                                            id={sort.id + '-move up-button'}
                                            useIconButton
                                            color="secondary"
                                            disabled={index === 0}
                                            onClick={() => {
                                                setSortModel((data) => {
                                                    let updatedData = [...data];

                                                    let currentItem =
                                                        updatedData[index];
                                                    if (index > 0) {
                                                        let beforeItem =
                                                            updatedData[
                                                                Number(
                                                                    index - 1
                                                                )
                                                            ];
                                                        updatedData =
                                                            updatedData.map(
                                                                (item, k) =>
                                                                    k ===
                                                                    index - 1
                                                                        ? currentItem
                                                                        : k ===
                                                                          index
                                                                        ? beforeItem
                                                                        : item
                                                            );
                                                    }
                                                    return updatedData;
                                                });
                                            }}
                                            children={
                                                <SwitchAccessShortcutRoundedIcon fontSize="small" />
                                            }
                                        />
                                    </Stack>
                                </Stack>
                            ),
                        };
                    })}
                />
            </CustomDialog>
        );
    } else return null;
}
