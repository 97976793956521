import { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { darken } from '@mui/system';
import { SxProps, Theme } from '@mui/system';

import useCustomColor from 'hooks/useCustomColor';

/**
 * Styled input component that supports different variants: 'standard', 'outlined', and 'filled'.
 */
const StyledTextField = styled(TextField)(({ theme, ...props }) => {
    let { main, lighter } = useCustomColor(props.color);

    return {
        ...(props.variant === 'standard' && {
            '& .MuiInputBase-root': {
                color: main,
                height: '100%',
                alignItems: 'flex-start',
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    borderWidth: '1px',
                    borderColor: main,
                },
                '::after': {
                    borderBottom: `2px solid ${main}`,
                },
            },
        }),
        ...(props.variant === 'filled' && {
            '& .MuiFilledInput-root': {
                backgroundColor: lighter,
                color: 'text.primary',
                height: '100%',
                alignItems: 'flex-start',
                ':hover:not(.Mui-disabled, .Mui-focused)': {
                    backgroundColor: darken(lighter, 0.03),
                    color: 'text.primary',
                },
                '.Mui-disabled': {
                    backgroundColor: 'disabled.lighter',
                },
            },
        }),
        ...(props.variant === 'outlined' && {
            '& .MuiOutlinedInput-root': {
                color: main,
                height: '100%',
                alignItems: 'stretch',
                '& input': { height: 'auto' },
                '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
                    borderColor: main,
                },
                '&.Mui-focused fieldset': {
                    borderColor: main,
                },
                '&.Mui-error fieldset': {
                    borderColor: 'error.main',
                },
                '&.Mui-disabled fieldset': {
                    borderColor: 'disabled.light',
                },
            },
        }),
        ...(props.type === 'number' && {
            '& input[type=number]::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
                display: 'none',
            },
            '& input[type=number]::-webkit-outer-spin-button ': {
                WebkitAppearance: 'none',
                margin: 0,
                display: 'none',
            },
        }),
    };
});

/**
 * CustomInput component that handles different input types, variants, and form control options.
 *
 * @param {Object} props - Component props
 * @param {string} props.name - Input name
 * @param {string} [props.id] - Input ID
 * @param {string} [props.type='text'] - Input type
 * @param {string} [props.value] - Input value (controlled)
 * @param {function} [props.onChange] - OnChange handler for input (required if controlled)
 * @param {function} [props.onKeyDown] - OnKeyDown handler
 * @param {string} [props.autoComplete] - Autocomplete attribute for the input
 * @param {boolean} [props.autoFocus=false] - Autofocus the input on mount
 * @param {string} [props.label] - Label for the input
 * @param {string} [props.error] - Error message
 * @param {string} [props.helperText] - Helper text
 * @param {string} [props.placeholder] - Placeholder text for input
 * @param {Object} [props.inputProps] - Native input element attributes
 * @param {Object} [props.InputLabelProps] - Props for the InputLabel component
 * @param {SxProps<Theme>} [props.sx] - Additional styles to apply to root component.
 * @param {Object} [props.FormHelperTextProps] - Props for the FormHelperText component
 * @param {Object} [props.inputRef] - Ref for the input element
 * @param {boolean} [props.fullWidth=true] - Full width of the input
 * @param {boolean} [props.required=false] - Marks the input as required
 * @param {boolean} [props.readOnly=false] - Marks the input as read-only
 * @param {boolean} [props.disabled=false] - Disables the input
 * @param {boolean} [props.multiline=false] - Multiline input support
 * @param {number} [props.rows] - Number of rows for multiline input
 * @param {number} [props.minRows] - Minimum number of rows for multiline input
 * @param {number} [props.maxRows] - Maximum number of rows for multiline input
 * @param {string} [props.variant='standard'] - Input variant (standard, outlined, filled)
 * @param {string} [props.color='primary'] - Color theme of the input
 * @param {string} [props.size='small'] - Size of the input (small or medium)
 * @param {string} [props.margin='none'] - Margin around the input
 * @param {React.Node} [props.startAdornment] - Start adornment (e.g., icon)
 * @param {React.Node} [props.endAdornment] - End adornment (e.g., icon)
 * @param {boolean} [props.disableOnWheel=true] - Disables mouse wheel events on number inputs
 * @param {number} [props.debounceTime=300] - Debounce time for input changes
 * @param {Object} [props.otherProps] - Other additional props
 */
function CustomTextField({
    name = 'name',
    id,
    type = 'text',
    value = '',
    onChange = () => {},
    onKeyDown = () => {},
    autoComplete,
    autoFocus,
    label = '',
    error = '',
    helperText = '',
    placeholder = '',

    inputProps = {},
    InputLabelProps = { shrink: true },
    sx = {},
    FormHelperTextProps,
    startAdornment = null,
    endAdornment = null,

    inputRef,
    fullWidth = true,
    required = false,
    readOnly = false,
    disabled = false,
    multiline = false,
    onBlur = () => {},
    onFocus = () => {},
    rows,
    minRows,
    maxRows,
    variant = 'standard',
    color = 'primary',
    size = 'small',
    margin = 'none',

    disableOnWheel = true,
    debounceTime = 300,

    ...otherProps
}) {
    const debounceTimeout = useRef(null);
    const [typingValue, setTypingValue] = useState(value || '');
    const isTyping = useRef(false);

    useEffect(() => {
        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, []);

    useEffect(() => {
        // update typingValue when value props change and user is not typing
        if (!isTyping.current) {
            setTypingValue(value || '');
        }
    }, [value]);

    const handleInputChange = (event) => {
        const { value } = event.target;
        setTypingValue(value);

        // Clear previous timeout
        clearTimeout(debounceTimeout.current);

        // Setup new timeout
        debounceTimeout.current = setTimeout(() => {
            isTyping.current = false;
            // Trigger onChange after debounce time
            onChange(value);
        }, debounceTime);
    };

    const inputId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    const defaultTextFieldProps = {
        variant,
        fullWidth,
        disabled,
        required,
        margin,
        color,
        size,
        error: Boolean(error),
        id: inputId,
        name,
        type,
        label,
        inputRef,
        autoComplete,
        autoFocus,
        multiline,
        rows,
        minRows,
        maxRows,
        onBlur,
        onKeyDown,
        onFocus,
        placeholder,
        onWheel: disableOnWheel ? (e) => e.target.blur() : undefined,
        helperText: error || helperText,
    };

    return (
        <StyledTextField
            {...defaultTextFieldProps}
            value={typingValue}
            onChange={handleInputChange}
            slotProps={{
                root: {
                    sx: (theme) => ({
                        ...(typeof sx === 'function' ? sx(theme) : sx),
                    }),
                },
                htmlInput: {
                    ...inputProps,
                    ...{
                        ...(type === 'number' && {
                            style: {
                                textAlign: 'right',
                            },
                        }),
                    },
                },
                inputLabel: InputLabelProps,
                input: {
                    readOnly,
                    startAdornment: startAdornment && (
                        <InputAdornment position="start">
                            {startAdornment}
                        </InputAdornment>
                    ),
                    endAdornment: endAdornment && (
                        <InputAdornment position="end">
                            {endAdornment}
                        </InputAdornment>
                    ),
                },
                formHelperText: FormHelperTextProps,
            }}
        />
    );
}

CustomTextField.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    label: PropTypes.string,
    error: PropTypes.string,
    helperText: PropTypes.string,
    placeholder: PropTypes.string,
    inputProps: PropTypes.object,
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    InputLabelProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    multiline: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    rows: PropTypes.number,
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string, // For custom colors
    ]),
    size: PropTypes.oneOf(['small', 'medium']),
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
    disableOnWheel: PropTypes.bool,
    debounceTime: PropTypes.number,
    otherProps: PropTypes.object,
};

CustomTextField.defaultProps = {
    name: 'name',
    id: undefined,
    type: 'text',
    value: '',
    onKeyDown: () => {},
    autoComplete: undefined,
    autoFocus: false,
    label: '',
    error: '',
    helperText: '',
    placeholder: '',
    inputProps: {},
    InputLabelProps: { shrink: true },
    sx: {},
    FormHelperTextProps: {},
    startAdornment: null,
    endAdornment: null,
    inputRef: null,
    fullWidth: true,
    required: false,
    readOnly: false,
    disabled: false,
    multiline: false,
    rows: undefined,
    minRows: undefined,
    maxRows: undefined,
    variant: 'standard',
    color: 'primary',
    size: 'small',
    margin: 'none',

    disableOnWheel: true,
    debounceTime: 300,
    otherProps: {},
};

export default CustomTextField;
