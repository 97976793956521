import { useMemo, isValidElement } from 'react';
import PropTypes from 'prop-types';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';

/**
 * CustomTable is a highly customizable table component built on MUI's Table,
 * offering flexible column definitions, row styles, and additional props for customization.
 *
 * @param {Object} props - Component properties.
 * @param {string} [props.id] - An optional unique ID for the table container.
 * @param {Array<Object>} [props.columns=[]] - Array of column definitions. Each column object can include:
 *    - `key` (string, required): Unique key for the column, used to access data.
 *    - `label` (string | React.ReactNode, required): Label for the column header.
 *    - `padding` (string, optional): Cell padding ('checkbox' | 'none' | 'normal').
 *    - `size` (string, optional): Cell size ('small' | 'medium').
 *    - `align` (string, optional): Text alignment ('left' | 'center' | 'right').
 *    - `sx` (object, optional): Additional styles for the column header cell.
 * @param {Array<Object>} [props.data=[]] - Array of row data objects where each object corresponds to a table row.
 *    Each key in the object represents a column key, and its value is either:
 *        - An object for detailed cell configuration.
 *        - A string or node element for simple cell content.
 *    If being an object, it can include:
 *    - `value` (string | React.ReactNode, required): The content to display in the cell. If not provided, the column key's value is used directly.
 *    - `colSpan` (number, optional): The number of columns the cell spans. Defaults to 1.
 *    - `rowSpan` (number, optional): The number of rows the cell spans. Defaults to 1.
 *    - `padding` (string): Cell padding ('checkbox' | 'none' | 'normal'). Defaults to 'normal'.
 *    - `size` (string): Cell size ('small' | 'medium'). Defaults to 'medium'.
 *    - `align` (string): Text alignment within the cell ('left' | 'center' | 'right' | 'justify' | 'inherit'). Defaults to 'inherit'
 *    - `sx`(object): Additional styles to apply to the cell using MUI's `sx` prop.
 *    Example:
 *      [
 *          {
 *              column1: { value: "Row 1 Col 1", colSpan: 2, align: 'right' },
 *              column2: { value: "Row 1 Col 2", sx: { color: "blue" } },
 *              column3: "Row 1 Col 3",
 *          },
 *          {
 *              column1: "Row 2 Col 1",
 *              column2: "Row 2 Col 2",
 *              column3: { value: "Row 2 Col 3", rowSpan: 2 },
 *          },
 *          {
 *              column1: "Row 3 Col 1",
 *              column2: "Row 3 Col 2",
 *          },
 *     ];
 *
 * @param {string} [props.padding='normal'] - Default padding for all cells ('checkbox' | 'none' | 'normal').
 * @param {string} [props.size='medium'] - Default size for all cells ('small' | 'medium').
 * @param {boolean} [props.stickyHeader=true] - If true, the table header will stick to the top.
 * @param {Object} [props.TableContainerProps={}] - Additional props to pass to the root TableContainer component.
 * @param {Object} [props.TableProps={}] - Additional props to pass to the root Table component.
 * @param {Object} [props.TableHeadProps={}] - Additional props to pass to the TableHead component.
 * @param {Object} [props.TableBodyProps={}] - Additional props to pass to the TableBody component.
 * @param {boolean} [props.showHeader=true] - Controls the visibility of the table header.
 * @param {function} [props.getTableRowStyle] - Function to apply custom styles to rows. Receives the row index (0 for header).
 * @returns {JSX.Element} Rendered table component.
 */

function CustomTable({
    id,
    columns = [],
    data = [],
    padding = 'normal',
    size = 'small',
    stickyHeader = true,
    TableContainerProps = {},
    TableProps = {},
    TableHeadProps = {},
    TableBodyProps = {},
    showHeader = true,
    getTableRowStyle = (rowIndex) => ({}),
}) {
    const tableId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    return (
        <TableContainer component={Paper} id={tableId} {...TableContainerProps}>
            <Table
                size={size}
                padding={padding}
                stickyHeader={stickyHeader}
                {...TableProps}>
                <TableHead {...TableHeadProps}>
                    <TableRow
                        sx={(theme) => ({
                            display: showHeader ? 'table-row' : 'none',
                            backgroundColor: `${theme.palette.secondary.main} !important`,
                            color: 'white !important',
                            ...getTableRowStyle(0),
                        })}>
                        {columns.map((column, idx) => (
                            <TableCell
                                key={`${column.key}-table-head-${idx}`}
                                padding={column.padding || padding}
                                size={column.size || size}
                                align={column.align || 'center'}
                                sx={{
                                    backgroundColor: 'inherit',
                                    color: 'inherit',
                                    ...(column?.sx && { ...column.sx }),
                                }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody {...TableBodyProps}>
                    {data.map((rowData, rowIndex) => {
                        let skipColumns = 0;
                        return (
                            <TableRow
                                key={rowIndex + 1}
                                sx={(theme) => ({
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: 'disabled.lighter',
                                    },
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                    ...getTableRowStyle(rowIndex + 1),
                                })}>
                                {columns.map((column) => {
                                    if (skipColumns > 0) {
                                        skipColumns--;
                                        return null; // Skip this column
                                    }

                                    const cellData =
                                        rowData?.[column.key] || {};
                                    const {
                                        value = '',
                                        colSpan = 1,
                                        rowSpan = 1,
                                        padding: cellPadding = padding,
                                        size: cellSize = size,
                                        align: cellAlign = column.align ||
                                            'inherit',
                                        sx: cellSx = undefined,
                                    } = typeof cellData === 'object' &&
                                    !isValidElement(cellData)
                                        ? cellData
                                        : { value: cellData || '' };

                                    // Update skipColumns if colSpan > 1
                                    skipColumns = colSpan - 1;

                                    return (
                                        <TableCell
                                            key={`${column.key}-table-body-${
                                                rowIndex + 1
                                            }`}
                                            colSpan={colSpan}
                                            rowSpan={rowSpan}
                                            padding={cellPadding}
                                            size={cellSize}
                                            align={cellAlign}
                                            sx={cellSx}>
                                            {value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

CustomTable.propTypes = {
    id: PropTypes.string,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
                .isRequired,
            padding: PropTypes.oneOf(['checkbox', 'none', 'normal']),
            size: PropTypes.oneOf(['small', 'medium']),
            align: PropTypes.oneOf(['left', 'center', 'right']),
            sx: PropTypes.object,
        })
    ),
    data: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
                PropTypes.shape({
                    value: PropTypes.string,
                    colSpan: PropTypes.number,
                    rowSpan: PropTypes.number,
                    padding: PropTypes.oneOf(['checkbox', 'none', 'normal']),
                    size: PropTypes.oneOf(['small', 'medium']),
                    align: PropTypes.oneOf([
                        'left',
                        'center',
                        'right',
                        'justify',
                        'inherit',
                    ]),
                    sx: PropTypes.object,
                }),
            ])
        )
    ),
    padding: PropTypes.oneOf(['checkbox', 'none', 'normal']),
    size: PropTypes.oneOf(['small', 'medium']),
    stickyHeader: PropTypes.bool,
    TableContainerProps: PropTypes.object,
    TableProps: PropTypes.object,
    TableHeadProps: PropTypes.object,
    TableBodyProps: PropTypes.object,
    showHeader: PropTypes.bool,
    getTableRowStyle: PropTypes.func,
};

CustomTable.defaultProps = {
    id: undefined,
    columns: [],
    data: [],
    padding: 'normal',
    size: 'small',
    stickyHeader: true,
    TableContainerProps: {},
    TableProps: {},
    TableHeadProps: {},
    TableBodyProps: {},
    showHeader: true,
    getTableRowStyle: () => ({}),
};

export default CustomTable;
