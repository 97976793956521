import { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, Grid2, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';

import { CustomButton } from 'UI';

const CustomBox = ({ spacing = 0, children }) => {
    const theme = useTheme();

    const matches = {
        xs: useMediaQuery(theme.breakpoints.up('xs')),
        sm: useMediaQuery(theme.breakpoints.up('sm')),
        md: useMediaQuery(theme.breakpoints.up('md')),
        lg: useMediaQuery(theme.breakpoints.up('lg')),
        xl: useMediaQuery(theme.breakpoints.up('xl')),
        xxl: useMediaQuery(theme.breakpoints.up('xxl')),
    };

    let modifiedPadding = spacing;
    if (typeof spacing === 'number') {
        modifiedPadding = spacing;
    }
    if (typeof spacing === 'object') {
        if (matches.xxl) {
            modifiedPadding =
                spacing.xxl ??
                spacing.xl ??
                spacing.lg ??
                spacing.md ??
                spacing.sm ??
                spacing.xs ??
                0;
        } else if (matches.xl) {
            modifiedPadding =
                spacing.xl ??
                spacing.lg ??
                spacing.md ??
                spacing.sm ??
                spacing.xs ??
                0;
        } else if (matches.lg) {
            modifiedPadding =
                spacing.lg ?? spacing.md ?? spacing.sm ?? spacing.xs ?? 0;
        } else if (matches.md) {
            modifiedPadding = spacing.md ?? spacing.sm ?? spacing.xs ?? 0;
        } else if (matches.sm) {
            modifiedPadding = spacing.sm ?? spacing.xs ?? 0;
        } else {
            modifiedPadding = spacing.xs ?? 0;
        }
    }

    return (
        <Box sx={{ p: Number(modifiedPadding * 8) + 'px', width: '100%' }}>
            {children}
        </Box>
    );
};

/**
 * Masonry is a flexible grid layout component that organizes child elements
 * into columns based on screen size and allows for optional scrolling with arrows.
 *
 * @param {Object} props - Component properties.
 * @param {React.ReactNode[]} props.children - The child elements to be displayed in the masonry layout.
 * @param {Object} [props.columns={ xs: 1, sm: 2, md: 3, lg: 4, xl: 6 }] - Number of columns based on breakpoints.
 * @param {number | Object} [props.spacing=2] - Spacing between items (multiplied by 8 for pixel value).
 * @param {boolean} [props.wrap=true] - If true, displays items in a grid layout. If false, allows horizontal scrolling.
 * @returns {JSX.Element} Rendered masonry layout.
 */
export const Masonry = ({
    children,
    columns = { xs: 1, sm: 2, md: 3, lg: 4, xl: 6 },
    spacing = 2,
    wrap = true,
}) => {
    const theme = useTheme();
    const matches = {
        xs: useMediaQuery(theme.breakpoints.up('xs')),
        sm: useMediaQuery(theme.breakpoints.up('sm')),
        md: useMediaQuery(theme.breakpoints.up('md')),
        lg: useMediaQuery(theme.breakpoints.up('lg')),
        xl: useMediaQuery(theme.breakpoints.up('xl')),
        xxl: useMediaQuery(theme.breakpoints.up('xxl')),
    };
    let modifiedColumns = {};
    if (columns.hasOwnProperty('xs')) {
        modifiedColumns.xs = columns.xs;
    } else {
        modifiedColumns.xs = 1;
    }
    if (columns.hasOwnProperty('sm')) {
        modifiedColumns.sm = columns.sm;
    } else {
        modifiedColumns.sm = modifiedColumns.xs;
    }
    if (columns.hasOwnProperty('md')) {
        modifiedColumns.md = columns.md;
    } else {
        modifiedColumns.md = modifiedColumns.sm;
    }
    if (columns.hasOwnProperty('lg')) {
        modifiedColumns.lg = columns.lg;
    } else {
        modifiedColumns.lg = modifiedColumns.md;
    }
    if (columns.hasOwnProperty('xl')) {
        modifiedColumns.xl = columns.xl;
    } else {
        modifiedColumns.xl = modifiedColumns.lg;
    }
    if (columns.hasOwnProperty('xxl')) {
        modifiedColumns.xxl = columns.xxl;
    } else {
        modifiedColumns.xxl = modifiedColumns.xl;
    }

    const getColumns = () => {
        if (matches.xxl) return modifiedColumns.xxl;
        if (matches.xl) return modifiedColumns.xl;
        if (matches.lg) return modifiedColumns.lg;
        if (matches.md) return modifiedColumns.md;
        if (matches.sm) return modifiedColumns.sm;
        return modifiedColumns.xs;
    };

    const columnsCount = getColumns();

    const createColumns = () => {
        const columnsArray = Array.from({ length: columnsCount }, () => []);
        children.forEach((child, index) => {
            columnsArray[index % columnsCount].push(
                <Box key={index} mb={spacing}>
                    {child}
                </Box>
            );
        });
        return columnsArray;
    };

    const columnsArray = createColumns();

    const scrollContainerRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollContainerRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } =
                    scrollContainerRef.current;
                setShowLeftArrow(scrollLeft > 0);
                setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
            }
        };

        handleScroll(); // Initial check
        scrollContainerRef.current?.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            scrollContainerRef.current?.removeEventListener(
                'scroll',
                handleScroll
            );
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    const scroll = (direction) => {
        const { current } = scrollContainerRef;
        if (current) {
            const scrollAmount = direction === 'left' ? -600 : 600;
            current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    if (!wrap) {
        return (
            <Box position="relative">
                {showLeftArrow && (
                    <CustomButton
                        id={`arrow-left-custom-button`}
                        useIconButton
                        onClick={() => scroll('left')}
                        color="disabled"
                        sx={{
                            position: 'absolute',
                            left: -20,
                            top: '40%',
                            zIndex: 1,
                            border: '1px solid whitesmoke',
                            boxShadow: 1,
                            backgroundColor: 'whitesmoke',
                            ':hover': {
                                backgroundColor: 'whitesmoke',
                            },
                            p: 0.25,
                        }}
                        children={
                            <KeyboardArrowLeftIcon
                                fontSize="large"
                                color="primary"
                            />
                        }
                    />
                )}
                {showRightArrow && (
                    <CustomButton
                        id={`arrow-right-custom-button`}
                        useIconButton
                        onClick={() => scroll('right')}
                        color="disabled"
                        sx={{
                            position: 'absolute',
                            right: -20,
                            top: '40%',
                            zIndex: 1,
                            border: '1px solid whitesmoke',
                            boxShadow: 1,
                            backgroundColor: 'whitesmoke',
                            ':hover': {
                                backgroundColor: 'whitesmoke',
                            },
                            p: 0.25,
                        }}
                        children={
                            <KeyboardArrowRightIcon
                                fontSize="large"
                                color="primary"
                            />
                        }
                    />
                )}
                <Box
                    pb={1}
                    display="flex"
                    overflow="auto"
                    ref={scrollContainerRef}
                    sx={(theme) => ({
                        overflow: 'auto',
                        msOverflowStyle: 'none' /* Internet Explorer 10+ */,
                        scrollbarWidth: 'none' /* Firefox */,
                        '&::-webkit-scrollbar': {
                            display: 'none' /* Safari and Chrome */,
                        },
                        gap:
                            typeof spacing === 'number'
                                ? `${spacing * 8}px`
                                : Object.fromEntries(
                                      Object.entries(spacing).map(
                                          ([key, value]) => [
                                              key,
                                              `${value * 8}px`,
                                          ]
                                      )
                                  ),
                    })}>
                    {children.map((child, index) => (
                        <Box
                            key={index}
                            sx={{
                                flex:
                                    typeof spacing === 'number'
                                        ? `0 0 calc((100% - ${
                                              (columnsCount - 1) * spacing * 8
                                          }px) / ${columnsCount})`
                                        : Object.fromEntries(
                                              Object.entries(spacing).map(
                                                  ([key, value]) => [
                                                      key,
                                                      `0 0 calc((100% - ${
                                                          (columnsCount - 1) *
                                                          value *
                                                          8
                                                      }px) / ${columnsCount})`,
                                                  ]
                                              )
                                          ),
                                minWidth:
                                    typeof spacing === 'number'
                                        ? `calc((100% - ${
                                              (columnsCount - 1) * spacing * 8
                                          }px) / ${columnsCount})`
                                        : Object.fromEntries(
                                              Object.entries(spacing).map(
                                                  ([key, value]) => [
                                                      key,
                                                      `calc((100% - ${
                                                          (columnsCount - 1) *
                                                          value *
                                                          8
                                                      }px) / ${columnsCount})`,
                                                  ]
                                              )
                                          ),
                            }}>
                            {child}
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    }

    return (
        <Grid2 container spacing={spacing}>
            {columnsArray.map((column, index) => (
                <Grid2 size={{ xs: 12 / columnsCount }} key={index}>
                    {column.map((item) => item)}
                </Grid2>
            ))}
        </Grid2>
    );
};
Masonry.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    columns: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
        xxl: PropTypes.number,
    }),
    spacing: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    wrap: PropTypes.bool,
};

Masonry.defaultProps = {
    columns: { xs: 1, sm: 2, md: 3, lg: 4, xl: 6 },
    spacing: 2,
    wrap: true,
};

/**
 * CustomMasonry provides a higher-level wrapper around the Masonry component.
 * Supports layout of items wrapped in MUI's Paper component with customizable elevation and styling.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.id - Unique identifier for the Masonry container.
 * @param {Array<Object>} [props.items=[]] - Array of item definitions. Each object can include:
 *    - `id` (string | number, optional): Unique identifier for the item.
 *    - `content` (React.ReactNode, required): The content to display inside the item.
 *    - `paperStyleProps` (object, optional): The style applied to Paper Component (which uses to wrap `content`)
 * @param {number} [props.elevation=2] - Elevation level for the Paper component of each item.
 * @param {Object} [props.columns={xs: 1, sm: 2, md: 3, lg: 4, xl: 6}] - Number of columns for each breakpoint.
 *    - `xs`, `sm`, `md`, `lg`, `xl`, `xxl` (number): Columns for extra-small to extra-large breakpoints.
 * @param {number | Object} [props.spacing=2] - Spacing between items, corresponding to MUI spacing (multiplied by 8px).
 * @param {number | string} [props.minHeight=200] - Minimum height of Paper Component for each item.
 * @param {boolean} [props.wrap=true] - If true, the masonry wraps items into columns. If false, items are displayed horizontally with scrolling support.
 * @returns {JSX.Element} Rendered CustomMasonry component.
 */
function CustomMasonry({
    id,
    items = [],
    elevation = 2,
    columns = { xs: 1, sm: 2, md: 3, lg: 4, xl: 6 },
    spacing = 2,
    minHeight = 200,
    wrap = true,
    ...otherProps
}) {
    const masonryId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    return (
        <CustomBox spacing={spacing}>
            <Masonry
                id={masonryId}
                spacing={spacing}
                columns={columns}
                wrap={wrap}>
                {items.map((item, idx) => {
                    const { content = null, paperStyleProps = {} } = item;
                    return (
                        <Paper
                            elevation={elevation}
                            key={item?.id || `${masonryId}-item-${idx}`}
                            sx={{
                                '&.MuiPaper-root': {
                                    backgroundColor: 'transparent',
                                    borderRadius: 0,
                                    boxShadow: 0,
                                    minHeight,
                                    ...paperStyleProps,
                                },
                            }}>
                            {content}
                        </Paper>
                    );
                })}
            </Masonry>
        </CustomBox>
    );
}

CustomMasonry.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            content: PropTypes.node.isRequired,
            paperStyleProps: PropTypes.object,
        })
    ),
    elevation: PropTypes.number,
    columns: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
        xxl: PropTypes.number,
    }),
    spacing: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    wrap: PropTypes.bool,
};

CustomMasonry.defaultProps = {
    items: [],
    elevation: 2,
    columns: { xs: 1, sm: 2, md: 3, lg: 4, xl: 6 },
    spacing: 2,
    minHeight: 200,
    wrap: true,
};

export default CustomMasonry;
