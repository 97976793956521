import { useRef } from 'react';

import { Box } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { CustomTabs } from 'UI';

export default function ShopNavbar({ ...otherProps }) {
    const navigate = useNavigate();

    let containerRef = useRef(null);
    //only render in desktop screen

    return (
        <Box
            ref={containerRef}
            sx={{
                width: '100%',
                backgroundColor: 'white',
            }}>
            <CustomTabs
                id="custom-shop-navbarId"
                value={false}
                centered={true}
                TabProps={{
                    sx: {
                        minWidth: 0,
                        fontWeight: 500,
                        fontSize: 16,
                        position: 'relative',
                        textTransform: 'initial',
                        color: 'primary.main',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 7,
                            left: 0,
                            width: '100%',
                            height: '1.5px',
                            borderRadius: '5px',
                            boxShadow: 1,
                            backgroundColor: 'primary.light',
                            transform: 'scaleX(0)',
                            transformOrigin: 'bottom right',
                            transition: 'transform 0.3s ease-in-out',
                        },
                        '&:hover::after': {
                            transform: 'scaleX(1)',
                            transformOrigin: 'bottom left',
                        },
                    },
                }}
                options={[
                    {
                        label: 'Thuốc',
                        onClick: () => navigate('/thuoc'),
                    },
                    {
                        label: 'Thực phẩm chức năng',
                        onClick: () => navigate('/thuc-pham-chuc-nang'),
                    },
                    {
                        label: 'Sản phẩm khác',
                        onClick: () => navigate('/san-pham-khac'),
                    },
                    {
                        label: 'Bài viết sức khỏe',
                        onClick: () => navigate('/bai-viet-suc-khoe'),
                    },
                ]}
            />
        </Box>
    );
}
