import { useContext } from 'react';

import { Stack, Typography, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { CustomChip, CustomButton } from 'UI';

import { ProductContext } from 'context/product-context';

export default function ActiveFilterBox({ ...props }) {
    const { activeFilterCount, dataFilter, deleteFilter, resetFilter } =
        useContext(ProductContext);

    if (activeFilterCount === 0) {
        return null;
    }

    let chips = Object.keys(dataFilter).reduce((acc, key) => {
        let { filteringOptions } = dataFilter[key];
        acc = acc.concat(
            filteringOptions.map((chip, idx) => {
                return (
                    <CustomChip
                        key={`${chip}-${idx}`}
                        color="disabled"
                        size="medium"
                        clickable={false}
                        label={chip}
                        sx={{ my: 0.5 }}
                        deleteIcon={<CloseIcon />}
                        onDelete={() => deleteFilter({ key, value: chip })}
                    />
                );
            })
        );
        return acc;
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <Stack
                sx={(theme) => ({
                    backgroundColor: 'white',
                    px: 2,
                    py: 1,
                    borderTop: {
                        xs: `1px solid ${theme.palette.disabled.lighter}`,
                        lg: 'none',
                    },
                    borderRadius: {
                        xs: '0px 0px 10px 10px',
                        lg: '10px',
                    },
                })}
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                spacing={1}>
                <Typography>{`Lọc theo (${activeFilterCount})`}</Typography>
                {chips}
                <CustomButton
                    id="clear-all-filter-custom-button"
                    varinat="contained"
                    disableEffect
                    onClick={resetFilter}
                    children={<b>Xóa tất cả</b>}
                />
            </Stack>
        </Box>
    );
}
