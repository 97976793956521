import { Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import { CustomButton, CustomAvatar } from 'UI';

export default function Page404({ ...otheProps }) {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Stack
            spacing={3}
            justifyContent="center"
            alignItems="center"
            px={2}
            py={8}>
            <CustomAvatar
                src="../assets/images/pharmacy-illustration.png"
                sx={{
                    width: '100%',
                    maxWidth: 500,
                    height: 200,
                }}
            />
            <Stack pt={2} spacing={0.5}>
                <Typography
                    sx={{
                        fontSize: { xs: 24, lg: 26 },
                        textAlign: 'center',
                        fontWeight: 600,
                    }}>
                    Đường dẫn đã hết hạn truy cập hoặc không tồn tại
                </Typography>
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: { xs: 14, lg: 16 },
                        textAlign: 'center',
                    }}>
                    Quý khách có thể liên hệ số Zalo{' '}
                    <a href="tel:0704430919" style={{ textDecoration: 'none' }}>
                        <b style={{ color: theme.palette.text.link }}>
                            070.443.0919
                        </b>
                    </a>{' '}
                    để được hỗ trợ
                </Typography>
            </Stack>
            <CustomButton
                id="go-to-home-custom-button"
                variant="contained"
                onClick={() => navigate('/')}
                size="large"
                sx={{
                    mt: 1,
                    py: 1,
                    width: 220,
                    fontSize: { xs: 18, lg: 20 },
                    fontWeight: 600,
                    borderRadius: '30px',
                }}
                children="VỀ TRANG CHỦ"
            />
        </Stack>
    );
}
