import { Stack, Box, Typography, useMediaQuery } from '@mui/material';
import { Inventory2 as Inventory2Icon } from '@mui/icons-material';

import { useQuery } from '@tanstack/react-query';
import { getSimpleQuery } from 'utils/http.js';

import { underlineWithTransition } from 'config/customCSS.js';

import { CustomMasonry } from 'UI';

import ProductCard from './ProductCard';

export default function SimilarProd({ subCategorySlug = '', ...otherProps }) {
    const isBiggerSmScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('sm') // >=600
    );
    const { data } = useQuery({
        queryKey: ['product-item', { subCategorySlug }],
        queryFn: ({ signal }) =>
            getSimpleQuery({
                signal,
                apiUrl: `/ecom/product-item/data?subCategorySlug=${subCategorySlug}&type=1&skip=0&take=20`,
            }),
        staleTime: 30000,
        retry: 0,
        enabled: !!subCategorySlug,
    });

    if (!subCategorySlug || !(data && data.data.length > 0)) {
        return null;
    }

    return (
        <Box>
            <Stack pl={2} direction="row" alignItems="center" spacing={1}>
                <Inventory2Icon fontSize="small" color="primary" />
                <Typography
                    variant="h6"
                    color="primary"
                    sx={underlineWithTransition}>
                    Sản phẩm tương tự
                </Typography>
            </Stack>
            <CustomMasonry
                spacing={isBiggerSmScreen ? 2 : 0.75}
                elevation={1}
                id="recentWatch-custom-masonry"
                columns={{ xs: 2, sm: 3, md: 4, xl: 6 }}
                items={data.data.map((prodData) => {
                    return {
                        id: prodData._id,
                        content: <ProductCard prodData={prodData} />,
                    };
                })}
                wrap={false}
            />
        </Box>
    );
}
