import { useState, useContext } from 'react';

import { Stack, Box, Typography, Collapse, Divider } from '@mui/material';
import { useTheme, lighten, darken } from '@mui/material/styles';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

import { useNavigate, useLocation } from 'react-router-dom';

import cateLv2Json from 'data/cateLv2.json';
import cateLv3Json from 'data/cateLv3.json';

import { CustomButton, CustomChip } from 'UI';

import { ProductContext } from 'context/product-context';

let groupData = {
    rxMedications: [
        {
            groupName:
                'Thuốc kháng sinh, kháng nấm, kháng virus và kí sinh trùng',
            categoryIds: ['01-1-1', '01-1-16', '01-1-30', '01-1-31', '01-1-33'],
        },
        {
            groupName: 'Thuốc điều trị bệnh tim và mạch máu',
            categoryIds: ['01-1-3', '01-1-5', '01-1-36', '01-1-38'],
        },
        {
            groupName: 'Thuốc điều trị bệnh thần kinh',
            categoryIds: [
                '01-1-4',
                '01-1-7',
                '01-1-8',
                '01-1-10',
                '01-1-11',
                '01-1-19',
                '01-1-22',
                '01-1-37',
                '01-1-41',
            ],
        },
        {
            groupName: 'Thuốc điều trị bệnh nội tiết',
            categoryIds: [
                '01-1-9',
                '01-1-17',
                '01-1-21',
                '01-1-28',
                '01-1-34',
                '01-1-39',
                '01-1-40',
                '01-1-42',
            ],
        },
        {
            groupName: 'Thuốc điều trị bệnh tiêu hóa, gan mật tụy',
            categoryIds: ['01-1-13', '01-1-18'],
        },
        {
            groupName: 'Thuốc điều trị bệnh cơ xương khớp',
            categoryIds: ['01-1-12', '01-1-27'],
        },
        {
            groupName: 'Thuốc điều trị bệnh hô hấp, tai mũi họng',
            categoryIds: ['01-1-20', '01-1-23', '01-1-26'],
        },
        {
            groupName: 'Thuốc giảm đau hạ sốt kháng viêm',
            categoryIds: ['01-1-25', '01-1-32'],
        },
        {
            groupName: 'Các nhóm thuốc khác',
            categoryIds: [
                '01-1-2',
                '01-1-6',
                '01-1-14',
                '01-1-15',
                '01-2-21',
                '01-1-24',
                '01-1-29',
                '01-1-35',
                '01-1-43',
            ],
        },
    ],
    otcMedications: [
        {
            groupName: 'Thuốc điều trị bệnh thần kinh',
            categoryIds: ['01-2-5', '01-2-7', '01-2-9'],
        },
        {
            groupName: 'Thuốc điều trị bệnh tiêu hóa, gan mật tụy',
            categoryIds: ['01-2-6', '01-2-22'],
        },
        {
            groupName: 'Thuốc điều trị bệnh cơ xương khớp',
            categoryIds: ['01-2-13'],
        },
        {
            groupName: 'Thuốc điều trị bệnh hô hấp, tai mũi họng',
            categoryIds: ['01-2-12', '01-2-17', '01-2-19', '01-2-24'],
        },
        {
            groupName: 'Thuốc dùng ngoài',
            categoryIds: [
                '01-2-1',
                '01-2-2',
                '01-2-3',
                '01-2-4',
                '01-2-10',
                '01-2-15',
            ],
        },
        {
            groupName: 'Các nhóm thuốc khác',
            categoryIds: [
                '01-2-8',
                '01-2-11',
                '01-2-14',
                '01-2-16',
                '01-2-18',
                '01-2-20',

                '01-2-23',
                '01-2-25',
            ],
        },
    ],
};

function CategoryCard({ children, ...props }) {
    const { currentCategoryTitle, totalProducts } = useContext(ProductContext);

    return (
        <Box sx={{ px: 2, pt: 1 }}>
            <Stack
                spacing={1}
                sx={{
                    backgroundColor: 'white',
                    p: 1.5,
                    borderRadius: '10px',
                }}>
                <Typography
                    component="h1"
                    sx={{ fontSize: 18, fontWeight: 600 }}>
                    {`${currentCategoryTitle} (${Number(
                        totalProducts
                    ).toLocaleString()} sản phẩm)`}
                </Typography>
                {children}
            </Stack>
        </Box>
    );
}

function CustomCollapseButton({ buttonTitle = '', children, ...props }) {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Box sx={{ width: '100%' }}>
            <CustomButton
                id={'categorySection-customId-' + buttonTitle}
                color={isOpen ? 'primary' : 'disabled'}
                disableEffect
                startIcon={
                    isOpen ? (
                        <KeyboardArrowDownIcon
                            sx={{
                                borderRadius: '50%',
                                backgroundColor: lighten(
                                    theme.palette.primary.lighter,
                                    0.6
                                ),
                            }}
                        />
                    ) : (
                        <ChevronRightIcon
                            sx={{
                                borderRadius: '50%',
                                backgroundColor: 'whitesmoke',
                            }}
                        />
                    )
                }
                onClick={() => setIsOpen((v) => !v)}
                sx={(theme) => ({
                    p: 0,
                    pl: 1,
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        width: 'calc(100% - 32px)',
                        height: '1px',
                        borderRadius: '5px',
                        boxShadow: 0,
                        backgroundColor: isOpen
                            ? 'transparent'
                            : lighten(theme.palette.disabled.main, 0.4),
                        transform: 'scaleX(0)',
                        transformOrigin: 'bottom right',
                        transition: 'transform 0.3s ease-in-out',
                    },
                    '&:hover::after': {
                        transform: 'scaleX(1)',
                        transformOrigin: 'bottom left',
                    },
                    maxWidth: '90%',
                })}
                children={
                    <Typography
                        variant="caption"
                        sx={(theme) => ({
                            fontSize: 16,
                            fontWeight: isOpen ? 500 : 400,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: isOpen
                                ? theme.palette.primary.main
                                : lighten(theme.palette.disabled.main, 0.1),
                        })}>
                        {buttonTitle}
                    </Typography>
                }
            />
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </Box>
    );
}
function CustomWatchAllButton({
    label = '',
    colorName = 'secondary',
    ...props
}) {
    return (
        <div>
            <CustomButton
                disableEffect
                variant="outlined"
                color={colorName}
                sx={(theme) => ({
                    py: 0.5,
                    px: 1.5,
                    width: 'fit-content',
                    fontWeight: 500,
                    borderRadius: '20px',
                    mt: 1,
                    mb: 0.5,
                    fontSize: 14,
                    color: darken(theme.palette[colorName].main, 0.2),
                    ':hover': {
                        backgroundColor: theme.palette[colorName].lighter,
                        color: lighten(theme.palette.disabled.main, 0.3),
                        fontWeight: 600,
                        boxShadow: 1,
                    },
                })}
                children={label}
                {...props}
            />
        </div>
    );
}
function CustomChipArray({ chips = [], ...props }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const locations = useLocation()
        .pathname.split('/')
        .filter((i) => i);

    return (
        <Stack
            sx={(theme) => ({
                width: 'calc(100% - 15px)',
                p: 1,
                ml: '15px',
                borderLeft: `1px solid ${theme.palette.primary.light}`,
            })}
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            spacing={1}>
            {chips.map((subCate, idx) => {
                return (
                    <CustomChip
                        key={idx}
                        label={subCate.name}
                        color={theme.palette.secondary.lighter}
                        sx={{
                            my: 0.5,
                            ml: 1,
                            fontWeight: 500,
                        }}
                        variant={'filled'}
                        onClick={() =>
                            navigate(`/${locations[0]}${subCate.slug}`)
                        }
                    />
                );
            })}
        </Stack>
    );
}

function DrugCategories({ ...props }) {
    const navigate = useNavigate();
    const { currentCategoryLevel, currentCategoryTitle } =
        useContext(ProductContext);

    if (currentCategoryLevel === 1) {
        return (
            <CategoryCard>
                <CustomCollapseButton buttonTitle="Danh mục: thuốc kê đơn">
                    <Stack sx={{ width: '100%', ml: '15px' }} spacing={0.5}>
                        <CustomWatchAllButton
                            id="watch-all-etc-meds-customId"
                            label="Xem tất cả thuốc kê đơn"
                            onClick={() => navigate('/thuoc/thuoc-ke-don')}
                        />
                        {groupData.rxMedications.map(
                            ({ groupName, categoryIds }, idx) => {
                                let data = cateLv3Json.filter(
                                    (i) =>
                                        i.parentName === 'Thuốc kê đơn' &&
                                        categoryIds.includes(i.id)
                                );
                                return (
                                    <CustomCollapseButton
                                        key={
                                            currentCategoryTitle +
                                            '-' +
                                            groupName
                                        }
                                        buttonTitle={groupName}>
                                        <CustomChipArray chips={data} />
                                    </CustomCollapseButton>
                                );
                            }
                        )}
                        <Divider sx={{ mt: 1, width: 'calc(100% -  15px)' }} />
                    </Stack>
                </CustomCollapseButton>
                <CustomCollapseButton buttonTitle="Danh mục: thuốc không kê đơn">
                    <Stack sx={{ width: '100%', ml: '15px' }} spacing={0.5}>
                        <CustomWatchAllButton
                            id="watch-all-otc-meds-customId"
                            label="Xem tất cả thuốc không kê đơn"
                            onClick={() =>
                                navigate('/thuoc/thuoc-khong-ke-don')
                            }
                        />
                        {groupData.otcMedications.map(
                            ({ groupName, categoryIds }, idx) => {
                                let data = cateLv3Json.filter(
                                    (i) =>
                                        i.parentName === 'Thuốc không kê đơn' &&
                                        categoryIds.includes(i.id)
                                );
                                return (
                                    <CustomCollapseButton
                                        key={
                                            currentCategoryTitle +
                                            '-' +
                                            groupName
                                        }
                                        buttonTitle={groupName}>
                                        <CustomChipArray chips={data} />
                                    </CustomCollapseButton>
                                );
                            }
                        )}
                    </Stack>
                </CustomCollapseButton>
            </CategoryCard>
        );
    } else if (currentCategoryLevel === 2) {
        const code =
            currentCategoryTitle === 'Thuốc kê đơn'
                ? 'rxMedications'
                : 'otcMedications';

        return (
            <CategoryCard>
                <Stack sx={{ width: '100%', ml: '15px' }} spacing={0.5}>
                    {groupData[code].map(({ groupName, categoryIds }, idx) => {
                        let data = cateLv3Json.filter(
                            (i) =>
                                i.parentName === currentCategoryTitle &&
                                categoryIds.includes(i.id)
                        );

                        return (
                            <CustomCollapseButton
                                buttonTitle={groupName}
                                key={currentCategoryTitle + '-' + groupName}>
                                <CustomChipArray chips={data} />
                            </CustomCollapseButton>
                        );
                    })}
                </Stack>
            </CategoryCard>
        );
    } else return null;
}

function OtherProductsCategories({ ...props }) {
    const navigate = useNavigate();
    const { currentCategoryLevel } = useContext(ProductContext);

    if (currentCategoryLevel === 1) {
        return (
            <CategoryCard>
                <CustomCollapseButton
                    buttonTitle={'Danh mục: chăm sóc cá nhân'}>
                    <CustomChipArray
                        chips={cateLv3Json.filter(
                            (i) => i.parentName === 'Chăm sóc cá nhân'
                        )}
                    />
                    <Box sx={{ ml: '15px' }}>
                        <Divider sx={{ mt: 1, width: 'calc(100% -  15px)' }} />
                        <CustomWatchAllButton
                            id="watch-all-otherProducts-1-customId"
                            label="Xem tất cả sản phẩm chăm sóc cá nhân"
                            onClick={() =>
                                navigate('/san-pham-khac/cham-soc-ca-nhan')
                            }
                            colorName="warning"
                        />
                    </Box>
                </CustomCollapseButton>
                <CustomCollapseButton buttonTitle={'Danh mục: thiết bị y tế'}>
                    <CustomChipArray
                        chips={cateLv3Json.filter(
                            (i) => i.parentName === 'Thiết bị y tế'
                        )}
                    />
                    <Box sx={{ ml: '15px' }}>
                        <Divider sx={{ mt: 1, width: 'calc(100% -  15px)' }} />
                        <CustomWatchAllButton
                            id="watch-all-otherProducts-2-customId"
                            label="Xem tất cả thiết bị y tế"
                            onClick={() =>
                                navigate('/san-pham-khac/thiet-bi-y-te')
                            }
                            colorName="warning"
                        />
                    </Box>
                </CustomCollapseButton>
            </CategoryCard>
        );
    } else return null;
}

export default function CategorySection({ ...props }) {
    const { mainCategoryData, totalProducts, activeFilterCount } =
        useContext(ProductContext);

    if (
        !mainCategoryData ||
        !mainCategoryData.hasOwnProperty('name') ||
        !['Thuốc', 'Sản phẩm khác'].includes(mainCategoryData.name) ||
        (totalProducts === 0 && activeFilterCount === 0)
    ) {
        return null;
    } else {
        const { name } = mainCategoryData;
        let Component =
            name === 'Thuốc' ? DrugCategories : OtherProductsCategories;

        return (
            <Component
                items={cateLv2Json.filter((i) => i.parentName === name)}
            />
        );
    }
}
