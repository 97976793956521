import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { InputAdornment, Stack, Typography } from '@mui/material';
import {
    Event as EventIcon,
    ChevronLeft as ChevronLeftIcon,
    KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon,
    ArrowDropDown as ArrowDropDownIcon,
    WarningAmberRounded as WarningAmberRoundedIcon,
} from '@mui/icons-material';

import { useTheme, styled } from '@mui/material/styles';
import { darken } from '@mui/system';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers';

import { CustomButton } from 'UI';

import moment from 'moment';

import useCustomColor from 'hooks/useCustomColor';

const CustomPickerDay = styled(PickersDay)(({ theme, ...props }) => {
    let { main, lighter, dark, contrastText } = useCustomColor(props.color);
    return {
        backgroundColor: 'transparent',
        color: 'text.primary',
        ':hover': {
            color: main,
            backgroundColor: lighter,
        },
        '&:active': {
            boxShadow: 'none',
            color: main,
            backgroundColor: lighter,
        },
        '&:focus': {
            boxShadow: 'none',
            color: main,
            backgroundColor: lighter,
        },
        '&.Mui-disabled': {
            color: 'disabled.light',
            backgroundColor: 'transparent',
        },
        '&.Mui-selected': {
            color: contrastText,
            backgroundColor: main,
            ':hover': {
                color: contrastText,
                backgroundColor: dark,
            },
            '&:active': {
                boxShadow: 'none',
                color: main,
                backgroundColor: lighter,
            },
            '&:focus': {
                boxShadow: 'none',
                color: contrastText,
                backgroundColor: dark,
            },
            '&.Mui-disabled': {
                color: contrastText,
                backgroundColor: main,
            },
        },
    };
});

/**
 * Custom input adornment with optional warning icon.
 * @param {object} props - Component props.
 * @param {boolean} props.hasError - Whether to display the error icon.
 */
const CustomInputAdornment = ({ hasError, color, children, sx, ...other }) => {
    return (
        <InputAdornment {...other} sx={{ mr: 1 }}>
            <WarningAmberRoundedIcon
                color="error"
                sx={{
                    width: '1.5rem',
                    height: '1.5rem',
                    ...(!hasError && { opacity: 0 }),
                }}
            />
            {children}
        </InputAdornment>
    );
};

const CustomCalendarHeaderRoot = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'center',
});
/**
 * Custom Calendar Header Component.
 * @param {object} props - Component props.
 * @param {moment.Moment} props.currentMonth - Current displayed month.
 * @param {Function} props.onMonthChange - Callback to change the displayed month.
 * @param {string} props.color - Button color.
 */
function CustomCalendarHeader({
    currentMonth,
    onMonthChange,
    color,
    ...otherProps
}) {
    const selectNextMonth = () =>
        onMonthChange(currentMonth.add(1, 'month'), 'left');
    const selectNextYear = () =>
        onMonthChange(currentMonth.add(1, 'year'), 'left');
    const selectPreviousMonth = () =>
        onMonthChange(currentMonth.subtract(1, 'month'), 'right');
    const selectPreviousYear = () =>
        onMonthChange(currentMonth.subtract(1, 'year'), 'right');

    let getCalendarHeaderTitle = (currentMonth) => {
        let monthMapping = {
            Jan: 'Tháng 1',
            Feb: 'Tháng 2',
            Mar: 'Tháng 3',
            Apr: 'Tháng 4',
            May: 'Tháng 5',
            Jun: 'Tháng 6',
            Jul: 'Tháng 7',
            Aug: 'Tháng 8',
            Sep: 'Tháng 9',
            Oct: 'Tháng 10',
            Nov: 'Tháng 11',
            Dec: 'Tháng 12',
        };
        let month = monthMapping[currentMonth.format('MMM')];
        let year = currentMonth.format('YYYY');
        return `${month} - ${year}`;
    };
    return (
        <CustomCalendarHeaderRoot>
            <Stack spacing={1} direction="row">
                <CustomButton
                    useIconButton
                    color={color}
                    onClick={selectPreviousYear}
                    title="Năm trước">
                    <KeyboardDoubleArrowLeftIcon />
                </CustomButton>
                <CustomButton
                    useIconButton
                    color={color}
                    onClick={selectPreviousMonth}
                    title="Tháng trước">
                    <ChevronLeftIcon />
                </CustomButton>
            </Stack>
            <Typography sx={{ fontWeight: 500 }} color={color}>
                {getCalendarHeaderTitle(currentMonth)}
            </Typography>
            <Stack spacing={1} direction="row">
                <CustomButton
                    useIconButton
                    color={color}
                    onClick={selectNextMonth}
                    title="Tháng tiếp theo">
                    <ChevronLeftIcon sx={{ transform: 'rotate(180deg)' }} />
                </CustomButton>
                <CustomButton
                    useIconButton
                    color={color}
                    onClick={selectNextYear}
                    title="Năm tiếp theo">
                    <KeyboardDoubleArrowLeftIcon
                        sx={{ transform: 'rotate(180deg)' }}
                    />
                </CustomButton>
            </Stack>
        </CustomCalendarHeaderRoot>
    );
}

moment.updateLocale('en', { week: { dow: 1 } });
/**
 * Custom DatePicker Component.
 * @param {object} props - Component props.
 * @param {string} [name=''] - Name attribute for the date picker input.
 * @param {string} [id] - Unique identifier for the date picker input.
 * @param {any} value - Current value of the date picker.
 * @param {function} [onChange=()=>{}] - Callback fired when the value changes.
 * @param {function} [onError=()=>{}] - Callback fired when an error occurs.
 * @param {string} [label=''] - Label text displayed above the date picker.
 * @param {string} [error=''] - Error message to display if there's a validation error.
 * @param {string} [helperText=''] - Helper text displayed below the date picker input.
 * @param {string} [placeholder=''] - Placeholder text displayed when the input is empty.
 * @param {object} [inputProps={}] - Additional properties for the input element.
 * @param {object} [InputLabelProps={ shrink: true }] - Props applied to the input label.
 * @param {object} inputRef - Reference for the input element.
 * @param {boolean} [fullWidth=true] - If `true`, the input will take the full width of its container.
 * @param {boolean} [required=false] - If `true`, the input will be marked as required.
 * @param {boolean} [readOnly=false] - If `true`, the date picker will be read-only.
 * @param {boolean} [disabled=false] - If `true`, the date picker will be disabled.
 * @param {boolean} [disableFuture=false] - If `true`, dates after today are disabled.
 * @param {boolean} [disablePast=false] - If `true`, dates before today are disabled.
 * @param {function} [shouldDisableDate=(date) => false] - A function to disable specific dates.
 * @param {function} [shouldDisableMonth=(month) => false] - A function to disable specific months.
 * @param {function} [shouldDisableYear=(year) => false] - A function to disable specific years.
 * @param {boolean} [loading=false] - If `true`, displays a loading indicator instead of calendar.
 * @param {number} [fixedWeekNumber=5] - Fixed number of weeks to display.
 * @param {boolean} [showDaysOutsideCurrentMonth=true] - If `true`, shows days outside the current month.
 * @param {function} [onBlur=()=>{}] - Callback fired when the input loses focus.
 * @param {function} [onFocus=()=>{}] - Callback fired when the input gains focus.
 * @param {string} [formatDensity='dense'] - Density format of the picker.
 * @param {string} [format='DD-MM-YYYY'] - Date format.
 * @param {any} [minDate=moment('2000-01-01')] - Minimum selectable date.
 * @param {any} [maxDate=moment('2099-12-31')] - Maximum selectable date.
 * @param {string} [variant='standard'] - Variant of the input field ('standard', 'filled', or 'outlined').
 * @param {string} [color='primary'] - Color of the date picker.
 * @param {string} [size='small'] - Size of the input field.
 * @param {string} [margin='none'] - Margin style of the input field.
 * @param {ReactNode} [startAdornment=null] - Element to display at the start of the input.
 * @param {ReactNode} [endAdornment=null] - Element to display at the end of the input.
 * @param {object} otherProps - Additional properties to pass to the DatePicker.
 */
function CustomDatePicker({
    name = '',
    id,
    value,
    onChange = () => {},
    onError = () => {},
    label = '',
    error = '',
    helperText = '',
    placeholder = '',
    inputProps = {},
    InputLabelProps = { shrink: true },
    inputRef,
    fullWidth = true,
    required = false,
    readOnly = false,
    disabled = false,
    disableFuture = false,
    disablePast = false,
    shouldDisableDate = (date) => false,
    shouldDisableMonth = (month) => false,
    shouldDisableYear = (year) => false,
    loading = false,
    fixedWeekNumber = 5,
    showDaysOutsideCurrentMonth = true,
    onBlur = () => {},
    onFocus = () => {},
    formatDensity = 'spacious',
    format = 'DD-MM-YYYY',
    minDate = moment('2000-01-01'),
    maxDate = moment('2099-12-31'),
    variant = 'standard',
    color = 'primary',
    size = 'small',
    margin = 'none',
    startAdornment = null,
    endAdornment = null,
    ...otherProps
}) {
    const theme = useTheme();
    let { main, lighter } = useCustomColor(color);

    const inputId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={label}
                required={required}
                readOnly={readOnly}
                disabled={disabled}
                disableFuture={disableFuture}
                disablePast={disablePast}
                shouldDisableDate={shouldDisableDate}
                shouldDisableMonth={shouldDisableMonth}
                shouldDisableYear={shouldDisableYear}
                format={format}
                formatDensity={formatDensity}
                fixedWeekNumber={fixedWeekNumber}
                showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
                error={Boolean(error)}
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                onChange={(newValue, context) => {
                    onChange(newValue);
                }}
                onError={(newError) => {
                    onError(newError);
                }}
                loading={loading}
                slots={{
                    openPickerIcon:
                        value !== null && !value.isValid()
                            ? ArrowDropDownIcon
                            : EventIcon,
                    calendarHeader: CustomCalendarHeader,
                    day: CustomPickerDay,
                    inputAdornment: CustomInputAdornment,
                }}
                slotProps={{
                    openPickerButton: {
                        color:
                            value !== null && !value.isValid()
                                ? 'disabled'
                                : color,
                    },
                    calendarHeader: {
                        color,
                    },
                    day: { color },
                    inputAdornment: {
                        hasError:
                            (value !== null && !value.isValid()) ||
                            Boolean(error),
                        color,
                    },
                    textField: {
                        variant,
                        fullWidth,
                        disabled,
                        margin,
                        color,
                        required,
                        size,
                        error: Boolean(error),
                        id: inputId,
                        name,
                        InputLabelProps,
                        inputRef,
                        onBlur,
                        onFocus,
                        inputProps,
                        placeholder,
                        helperText: error || helperText,
                        sx: {
                            ...(variant === 'standard' && {
                                '& .MuiInputBase-root': {
                                    color: main,
                                    height: '100%',
                                    // alignItems: 'flex-start',
                                    ':hover:not(.Mui-disabled, .Mui-error):before':
                                        {
                                            borderWidth: '1px',
                                            borderColor: main,
                                        },
                                    '::after': {
                                        borderBottom: `2px solid ${main}`,
                                    },
                                    '& .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root':
                                        {
                                            width: '1.25rem',
                                            height: '1.25rem',
                                        },
                                },
                            }),
                            ...(variant === 'filled' && {
                                '& .MuiFilledInput-root': {
                                    backgroundColor: lighter,
                                    color: 'text.primary',
                                    height: '100%',
                                    // alignItems: 'flex-start',
                                    ':hover:not(.Mui-disabled, .Mui-focused)': {
                                        backgroundColor: darken(lighter, 0.03),
                                        color: 'text.primary',
                                    },
                                    '& .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root':
                                        {
                                            width: '1.25rem',
                                            height: '1.25rem',
                                        },
                                },
                            }),
                            ...(variant === 'outlined' && {
                                '& label': {
                                    top: size === 'small' ? '3px' : 0,
                                    '&.Mui-focused': { top: 0 },
                                },

                                '& .MuiOutlinedInput-root': {
                                    color: main,
                                    height: '100%',
                                    // alignItems: 'stretch',
                                    '& input': { height: 'auto' },
                                    '&:hover:not(.Mui-disabled, .Mui-error) fieldset':
                                        {
                                            borderColor: main,
                                        },
                                    '&.Mui-focused fieldset': {
                                        borderColor: main,
                                    },
                                    '&.Mui-error fieldset': {
                                        borderColor: 'error.main',
                                    },
                                    '&.Mui-disabled fieldset': {
                                        borderColor: 'disabled.light',
                                    },
                                    '& .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root':
                                        {
                                            width: '1.25rem',
                                            height: '1.25rem',
                                        },
                                },
                            }),
                        },
                    },
                }}
                dayOfWeekFormatter={(day) => {
                    let dayMapping = {
                        Sun: 'CN',
                        Sat: 'T7',
                        Fri: 'T6',
                        Thu: 'T5',
                        Wed: 'T4',
                        Tue: 'T3',
                        Mon: 'T2',
                    };
                    return dayMapping[moment(day).format('ddd')];
                }}
                startAdornment={
                    startAdornment && (
                        <InputAdornment position="start">
                            {startAdornment}
                        </InputAdornment>
                    )
                }
                endAdornment={
                    endAdornment && (
                        <InputAdornment position="end">
                            {endAdornment}
                        </InputAdornment>
                    )
                }
                {...otherProps}
            />
        </LocalizationProvider>
    );
}
CustomDatePicker.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    helperText: PropTypes.string,
    placeholder: PropTypes.string,
    inputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    shouldDisableDate: PropTypes.func,
    loading: PropTypes.bool,
    fixedWeekNumber: PropTypes.number,
    showDaysOutsideCurrentMonth: PropTypes.bool,
    format: PropTypes.string,
    formatDensity: PropTypes.oneOf(['dense', 'spacious']),
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string, // For custom colors
    ]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    margin: PropTypes.oneOf(['none', 'dense']),
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
};

CustomDatePicker.defaultProps = {
    name: '',
    id: undefined,
    value: null,
    onChange: () => {},
    onError: () => {},
    label: '',
    error: '',
    helperText: '',
    placeholder: '',
    inputProps: {},
    InputLabelProps: { shrink: true },
    inputRef: null,
    fullWidth: true,
    required: false,
    readOnly: false,
    disabled: false,
    disableFuture: false,
    disablePast: false,
    shouldDisableDate: (date) => false,
    shouldDisableMonth: (month) => false,
    shouldDisableYear: (year) => false,
    loading: false,
    fixedWeekNumber: 5,
    showDaysOutsideCurrentMonth: true,
    onBlur: () => {},
    onFocus: () => {},
    formatDensity: 'spacious',
    format: 'DD-MM-YYYY',
    minDate: moment('2000-01-01'),
    maxDate: moment('2099-12-31'),
    variant: 'standard',
    color: 'primary',
    size: 'small',
    margin: 'none',
    startAdornment: null,
    endAdornment: null,
};

export default CustomDatePicker;
