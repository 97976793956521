import PropTypes from 'prop-types';

import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/system';

import useCustomColor from 'hooks/useCustomColor';
import { useMemo } from 'react';

/**
 * CustomChip Component
 * @param {Object} props
 * @param {string | React.ReactNode} props.label - Required label for the chip.
 * @param {'filled' | 'outlined'} [props.variant='outlined'] - The variant of the chip.
 * @param {'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'} [props.color='primary'] - The color of the chip.
 * @param {'small' | 'medium'} [props.size='small'] - The size of the chip.
 * @param {boolean} [props.disabled=false] - If true, the chip will be disabled.
 * @param {boolean} [props.clickable=true] - If true, the chip will be clickable.
 * @param {function} [props.onClick] - Function to call on click.
 * @param {React.ReactNode} [props.avatar] - The avatar element to display at the start of chip.
 * @param {React.ReactNode} [props.icon] - The icon element to display at the start of chip.
 * @param {React.ReactNode} [props.deleteIcon] - Override the default delete icon element. Shown only if onDelete is set.
 * @param {function} [props.onDelete] - Function to call on click to delete chip.
 * @param {SxProps<Theme>} [props.sx] - Additional styles to apply to the chip.
 * @returns {JSX.Element}
 */

function CustomChip({
    label = 'label',
    variant = 'outlined',
    color = 'primary',
    size = 'small',
    disabled = false,
    clickable = true,
    onClick = undefined,

    avatar = undefined,
    icon = undefined,
    deleteIcon = undefined,
    onDelete = undefined,

    sx = {},
    skipFocusWhenDisabled = true,
    ...otherProps
}) {
    const theme = useTheme();
    let customPalleteColor = useCustomColor(color);

    let chipStyles = useMemo(() => {
        let { main, light, lighter, contrastText } = customPalleteColor;

        let css = {
            filled: {
                '&.MuiChip-root': {
                    backgroundColor: main,
                    color: contrastText,
                    '& .MuiChip-icon': { color: contrastText },
                    '& .MuiChip-deleteIcon': { color: contrastText },
                    ':hover': {
                        backgroundColor: clickable ? light : main,
                        color: contrastText,
                    },
                    '&.Mui-disabled': {
                        color: 'disabled.dark',
                        backgroundColor: 'disabled.light',
                        '& .MuiChip-icon': { color: 'text.primary' },
                        '& .MuiChip-deleteIcon': { color: 'text.primary' },
                    },
                    ...sx,
                },
            },
            outlined: {
                '&.MuiChip-root': {
                    backgroundColor: 'transparent',
                    borderColor: light,
                    color: main,
                    '& .MuiChip-icon': { color: main },
                    '& .MuiChip-deleteIcon': { color: main },
                    ':hover': {
                        color: main,
                        backgroundColor: clickable ? lighter : 'transparent',
                        borderColor: light,
                    },
                    '&.Mui-disabled': {
                        color: 'disabled.dark',
                        backgroundColor: 'transparent',
                        borderColor: 'disabled.light',
                        '& .MuiChip-icon': { color: 'text.primary' },
                        '& .MuiChip-deleteIcon': { color: 'text.primary' },
                    },
                    ...sx,
                },
            },
        };

        return css[variant];
    }, [clickable, customPalleteColor, sx, variant]);

    let chipProps = {
        skipFocusWhenDisabled,
        label,
        variant,
        disabled,
        avatar,
        icon,
        deleteIcon,
        size,
        onClick,
        onDelete,
        clickable,
    };
    if (!label) return null;

    return <Chip {...chipProps} sx={chipStyles} {...otherProps} />;
}

CustomChip.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    variant: PropTypes.oneOf(['filled', 'outlined']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string, // For custom colors
    ]),
    size: PropTypes.oneOf(['small', 'medium']),
    disabled: PropTypes.bool,
    clickable: PropTypes.bool,
    onClick: PropTypes.func,
    avatar: PropTypes.node,
    icon: PropTypes.node,
    deleteIcon: PropTypes.node,
    onDelete: PropTypes.func,
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    skipFocusWhenDisabled: PropTypes.bool,
};

CustomChip.defaultProps = {
    label: 'label',
    variant: 'outlined',
    color: 'primary',
    size: 'small',
    disabled: false,
    clickable: true,
    onClick: undefined,
    avatar: undefined,
    icon: undefined,
    deleteIcon: undefined,
    onDelete: undefined,
    sx: {},
    skipFocusWhenDisabled: true,
};

export default CustomChip;
