import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Alert, AlertTitle } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

import { enqueueSnackbar, closeSnackbar } from 'notistack';

/**
 * CustomAlertBlock component that displays an alert with a title and optional close action.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.id] - An optional unique identifier for the alert.
 * @param {string | React.ReactNode} [props.title=''] - An optional title for the alert.
 * @param {string | React.ReactNode} [props.children=null] - Required content of the alert.
 * @param {function} [props.onClose=() => {}] - Function to call when the alert is closed.
 * @param {'error' | 'warning' | 'success' | 'info'} [props.severity='error'] - The severity level of the alert.
 * @param {'standard' | 'outlined' | 'filled'} [props.variant='standard'] - The variant style of the alert.
 * @param {React.ReactNode} [props.action] - Optional action element, can replace the default close button.
 * @param {SxProps<Theme>} [props.sx] - Additional styles to apply to the alert.
 * @param {Object} [props.AlertTitleProps={}] - Additional props for the AlertTitle component.
 * @param {Object} [otherProps] - Any other props to pass to the Alert component.
 * @returns {JSX.Element} The rendered alert component.
 */
function CustomAlertBlock({
    id,
    title = '',
    children = null,
    onClose = () => {},
    severity = 'error',
    variant = 'standard',
    action,
    sx = {},
    AlertTitleProps = {},
    ...otherProps
}) {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return; // prevent close alert by clicking outside box
        }

        onClose();
    };

    const alertId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    return (
        <Alert
            id={alertId}
            onClose={handleClose}
            color={severity} // overide styles of severity
            severity={severity}
            action={action}
            variant={variant}
            sx={(theme) => ({
                width: '100%',
                '& .MuiAlert-message': {
                    py: 0.5,
                },
                '& .MuiAlertTitle-root': {
                    pb: 1,
                    fontSize: 18,
                },
                '& .MuiAlert-action': { pt: 0.25 },
                ...(typeof sx === 'function' ? sx(theme) : sx),
            })}
            {...otherProps}>
            {title && (
                <AlertTitle
                    sx={{ m: 0, p: 0, fontWeight: 500, fontSize: 16 }}
                    {...AlertTitleProps}>
                    {title}
                </AlertTitle>
            )}
            {children}
        </Alert>
    );
}

CustomAlertBlock.propTypes = {
    id: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClose: PropTypes.func.isRequired,
    severity: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    action: PropTypes.node,
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    AlertTitleProps: PropTypes.object,
};

CustomAlertBlock.defaultProps = {
    id: undefined,
    title: '',
    children: null,
    onClose: () => {},
    severity: 'error',
    variant: 'standard',
    action: undefined,
    sx: {},
    AlertTitleProps: {},
};

export default CustomAlertBlock;

/**
 * Utility function to enqueue a snackbar with a CustomAlertBlock.
 *
 * @param {Object} options - The options for the snackbar.
 * @param {string} options.id - Unique key for the snackbar.
 * @param {string | React.ReactNode} options.title - Title of the alert block.
 * @param {string | React.ReactNode} options.children - Content of the alert block.
 * @param {string} [options.severity='error'] - Severity of the alert ('error', 'warning', 'info', 'success').
 * @param {function} [options.onClose=() => {}] - Callback for the close action.
 * @param {Object} [snackbarOptions={}] - Additional options for `enqueueSnackbar`.
 */
export const enqueueCustomSnackbar = ({
    id,
    title,
    children,
    severity = 'error',
    onClose = () => closeSnackbar(id),
    ...snackbarOptions
}) => {
    enqueueSnackbar(
        <CustomAlertBlock
            id={id}
            title={title}
            severity={severity}
            onClose={onClose}
            children={children}
        />,
        {
            key: id,
            variant: severity,
            ...snackbarOptions,
        }
    );
};
