import { Stack, Box, Typography, useMediaQuery } from '@mui/material';
import { Inventory2 as Inventory2Icon } from '@mui/icons-material';

import { useQuery } from '@tanstack/react-query';
import { getSimpleQuery } from 'utils/http.js';

import { underlineWithTransition } from 'config/customCSS.js';

import { CustomMasonry } from 'UI';
import ArticleCard from './ArticleCard';

export default function SimilarArticle({
    keywordIds = [],
    currentSlug = '',
    ...otherProps
}) {
    const isBiggerSmScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('sm') // >=600
    );
    const isBiggerMdScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('md') // >=800
    );

    const { data } = useQuery({
        queryKey: ['article-item', { keywordIds }],
        queryFn: ({ signal }) =>
            getSimpleQuery({
                signal,
                apiUrl: `/ecom/article-item/data?keywordIds=${keywordIds.join(
                    ','
                )}&type=1&skip=0&take=20`,
            }),
        staleTime: 30000,
        retry: 0,
        enabled: keywordIds.length > 0,
    });

    if (
        keywordIds.length === 0 ||
        !(data && data.data.filter((i) => i.slug !== currentSlug).length > 0)
    ) {
        return null;
    }

    return (
        <Box>
            <Stack pl={2} direction="row" alignItems="center" spacing={1}>
                <Inventory2Icon fontSize="small" color="primary" />
                <Typography
                    variant="h6"
                    color="primary"
                    sx={underlineWithTransition}>
                    Bài viết tương tự
                </Typography>
            </Stack>
            <CustomMasonry
                spacing={isBiggerSmScreen ? 2 : 0.75}
                elevation={1}
                id="similar-article-masonryId"
                columns={{ xs: 1, md: 2, xl: 3 }}
                items={data.data
                    .filter((i) => i.slug !== currentSlug)
                    .map((articleData) => {
                        return {
                            id: articleData._id,
                            content: (
                                <ArticleCard
                                    articleData={articleData}
                                    viewType={
                                        isBiggerMdScreen
                                            ? 'vertical'
                                            : 'horizontal'
                                    }
                                />
                            ),
                        };
                    })}
                wrap={false}
            />
        </Box>
    );
}
