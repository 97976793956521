import { useState, useEffect, useContext } from 'react';
import {
    Stack,
    Box,
    Typography,
    useMediaQuery,
    Card,
    CardHeader,
    Divider,
    ListItemButton,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material';
import { useTheme, darken } from '@mui/material/styles';
import {
    KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
    FormatListBulleted as FormatListBulletedIcon,
    Person as PersonIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import { ProductContentContext } from 'context/product-content-context.js';

import { CustomButton, CustomList } from 'UI';

import RenderContent from 'template/RenderContent.js';

function Header({
    isBiggerText = false,
    setIsBiggerText = () => {},
    ...props
}) {
    const { itemData } = useContext(ProductContentContext);
    const isBiggerMdScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('md') // >= 800
    );

    return (
        <div>
            <Typography
                component="h1"
                variant="h6"
                sx={{
                    fontSize: isBiggerMdScreen
                        ? isBiggerText
                            ? 30
                            : 28
                        : isBiggerText
                        ? 22
                        : 18,
                    fontWeight: 700,
                }}>
                {itemData.shortName}
            </Typography>
            <Divider />
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretch', sm: 'flex-start' }}
                justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                my={1}>
                <Stack
                    spacing={0.5}
                    direction="row"
                    alignItems="center"
                    order={{ xs: 2, sm: 1 }}>
                    <PersonIcon
                        sx={(theme) => ({
                            color: 'text.primary',
                            width: 15,
                            height: 15,
                        })}
                    />
                    <Typography
                        variant="subtitle1"
                        color="disabled"
                        sx={{ fontSize: 14, fontWeight: 500 }}>
                        {itemData?.author ?? 'Nhà thuốc Khánh Trang'}
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    justifyContent={{
                        xs: 'space-between',
                        sm: 'flex-end',
                    }}
                    order={{ xs: 1, sm: 2 }}>
                    <Typography
                        variant="body2"
                        sx={{ textAlign: { xs: 'left', sm: 'center' } }}>
                        Kích thước chữ
                    </Typography>
                    <ToggleButtonGroup
                        size="small"
                        value={isBiggerText}
                        exclusive
                        onChange={() => setIsBiggerText((v) => !v)}
                        aria-label="display button group"
                        sx={(theme) => ({
                            my: 'auto',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: 'primary.main',
                            borderRadius: 5,
                            height: 26,
                            '& .MuiToggleButtonGroup-grouped': {
                                margin: 0.5,
                                border: 'none',
                                '&.Mui-disabled': {
                                    border: 'none',
                                },
                                '&.Mui-selected': {
                                    backgroundColor: 'primary.light',
                                },
                                '&:last-of-type': {
                                    borderRadius: 5,
                                    p: 1,
                                    m: 0,
                                },
                                '&:first-of-type': {
                                    borderRadius: 5,
                                    p: 1,
                                    m: 0,
                                },
                            },
                        })}>
                        <ToggleButton
                            value={false}
                            disabled={!isBiggerText}
                            aria-label="fontSize standard"
                            sx={{ my: 0, ml: 0 }}>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: !isBiggerText ? 'white' : 'black',
                                    fontSize: 11,
                                    textTransform: 'initial',
                                }}>
                                Nhỏ
                            </Typography>
                        </ToggleButton>
                        <ToggleButton
                            value={true}
                            disabled={isBiggerText}
                            aria-label="fontSize large"
                            sx={{ my: 0, mr: 0 }}>
                            <Typography
                                variant="caption"
                                sx={{
                                    fontSize: 11,
                                    color: isBiggerText ? 'white' : 'black',
                                    textTransform: 'initial',
                                }}>
                                Lớn
                            </Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
            </Stack>
        </div>
    );
}
function Caution({ ...props }) {
    const { caution } = useContext(ProductContentContext);

    if (!caution) {
        return null;
    }

    return (
        <Box
            sx={(theme) => ({
                borderLeftColor: 'primary.main',
                borderLeftWidth: '4px',
                borderLeftStyle: 'solid',
                backgroundColor: 'aliceblue',
                borderRadius: '4px',
                width: '100%',
                py: 0.5,
                px: 1,
            })}>
            <Typography variant="caption" color="info" sx={{ fontWeight: 600 }}>
                {caution}
            </Typography>
        </Box>
    );
}

export default function ProductDetails({ ...otherProps }) {
    const { itemData } = useContext(ProductContentContext);

    const navigate = useNavigate();
    const theme = useTheme();
    const isBiggerMdScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('md') // >= 800
    );

    const [isBiggerText, setIsBiggerText] = useState(false);
    const [isShowMore, setIsShowMore] = useState(false);
    const toggleMoreDetails = () => setIsShowMore((v) => !v);

    const content = JSON.parse(itemData.contentInfo);
    const keywords = itemData.keywords
        .filter((i) => i.status && i.keyName.trim().length > 0)
        .map((i) => i.keyName.trim());

    let headerContent = content
        .filter((i) => i.type === 'header1' && i.content)
        .map((i) => i.content);

    const [selectedPart, setSelectedPart] = useState(headerContent?.[0] || '');

    const scrollToTop = () => {
        const box = document.getElementById('box-content-info');
        if (box) {
            box.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    const scrollToPart = (content) => {
        const h1Elements = document.querySelectorAll('h1');

        for (let h1 of h1Elements) {
            if (h1.textContent.toLowerCase() === content.toLowerCase()) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                h1.scrollIntoView({ behavior: 'smooth' });
                setTimeout(() => {
                    h1.scrollIntoView({ behavior: 'smooth' });
                    if (!isShowMore) {
                        setSelectedPart(content);
                    }
                }, 100);

                break;
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const h1Elements = document.querySelectorAll('h1');
            let found = false;

            for (let index = 0; index < h1Elements.length; index++) {
                const h1 = h1Elements[index];
                const nextH1 = h1Elements?.[index + 1] || null;

                const currentRect = h1.getBoundingClientRect();
                const offset = 10; // Adjust this value to control how far past the top the h1 needs to be

                // Condition 1: Current h1 is at or slightly past the top of the viewport
                if (currentRect.top <= offset && currentRect.bottom > offset) {
                    setSelectedPart(h1.textContent);
                    found = true;
                    break;
                }

                // Condition 2: Scrolling between this h1 and the next h1
                if (nextH1) {
                    const nextRect = nextH1.getBoundingClientRect();
                    if (currentRect.top < 0 && nextRect.top > offset) {
                        setSelectedPart(h1.textContent);
                        found = true;
                        break;
                    }
                }

                // Condition 3: Final h1 element
                if (!nextH1 && currentRect.top < 0) {
                    setSelectedPart(h1.textContent);
                    found = true;
                    break;
                }
            }

            if (!found) {
                setSelectedPart(headerContent?.[0] || '');
            }
        };
        if (isShowMore) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerContent, isShowMore]);

    const isUpdatingContent = content.length === 1 && content[0].isUpdating;
    const keywordsTypography =
        keywords.length > 0 ? (
            <div>
                <span>
                    <b>Từ khóa: </b>
                </span>
                {keywords.map((word, idx) => {
                    return (
                        <Typography
                            component="a"
                            key={word + '-' + idx}
                            onClick={() => navigate('/tim-kiem?s=' + word)}
                            sx={{
                                fontSize: 14,
                                color: 'text.link',
                                ':hover': {
                                    color: 'primary.dark',
                                },
                                textDecoration: 'none',
                                cursor: 'pointer',
                            }}>
                            {word + (idx === keywords.length - 1 ? '.' : ', ')}
                        </Typography>
                    );
                })}
            </div>
        ) : null;

    if (isUpdatingContent) {
        return (
            <Stack
                sx={{
                    backgroundColor: 'white',
                    borderRadius: { xs: 0, sm: '8px' },
                    py: 2,
                    px: 3,
                }}
                spacing={1}>
                <Card variant="outlined" sx={{ width: '100%', border: 'none' }}>
                    <CardHeader
                        sx={{ p: 0 }}
                        title={
                            <Header
                                isBiggerText={isBiggerText}
                                setIsBiggerText={setIsBiggerText}
                            />
                        }
                    />
                    <Typography sx={{ fontSize: isBiggerText ? 18 : 16 }}>
                        Nội dung đang được cập nhật...
                    </Typography>
                </Card>
                {keywordsTypography}
            </Stack>
        );
    }

    return (
        <Stack
            id="box-content-info"
            sx={{
                backgroundColor: 'white',
                borderRadius: { xs: 0, sm: '8px' },
                mt: 2,
            }}
            spacing={1}>
            <Stack
                direction="row"
                alignItems="stretch"
                justifyContent="space-between">
                {headerContent.length > 0 && isBiggerMdScreen && (
                    <Box p={3} sx={{ minHeight: '100%' }}>
                        <Box
                            width="250px"
                            sx={{
                                borderColor: 'disabled.light',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderRadius: '12px',
                                position: '-webkit-sticky',
                                // eslint-disable-next-line no-dupe-keys
                                position: 'sticky',
                                top: 10,
                                overflow: 'hidden',
                            }}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={1}
                                sx={{
                                    p: 1,
                                    pl: 2,
                                    backgroundColor: 'disabled.lighter',
                                }}>
                                <FormatListBulletedIcon color="disabled" />
                                <Typography
                                    color="disabled"
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                    }}>
                                    Nội dung chính
                                </Typography>
                            </Stack>
                            <CustomList
                                id="references-content-listId"
                                density="compact"
                                items={headerContent.map((content, idx) => ({
                                    name: content + idx,
                                    label: null,
                                    subLabel: (
                                        <ListItemButton
                                            sx={{
                                                py: '8px',
                                                backgroundColor:
                                                    selectedPart.toLowerCase() ===
                                                    content.toLowerCase()
                                                        ? 'aliceblue'
                                                        : 'transparent',
                                                ':hover': {
                                                    backgroundColor:
                                                        selectedPart.toLowerCase() ===
                                                        content.toLowerCase()
                                                            ? 'aliceblue'
                                                            : 'transparent',
                                                    color: 'text.link',
                                                },
                                            }}
                                            onClick={() =>
                                                scrollToPart(content)
                                            }>
                                            <Typography
                                                sx={{
                                                    m: 0,
                                                    fontWeight:
                                                        selectedPart.toLowerCase() ===
                                                        content.toLowerCase()
                                                            ? 600
                                                            : 500,
                                                    fontSize: 14,
                                                }}>
                                                {content}
                                            </Typography>
                                        </ListItemButton>
                                    ),
                                }))}
                            />
                        </Box>
                    </Box>
                )}
                <Box sx={{ width: '100%' }}>
                    <Card
                        variant="outlined"
                        sx={{ width: '100%', border: 'none' }}>
                        <CardHeader
                            sx={{
                                px: 3,
                                pb: 0,
                            }}
                            title={
                                <Header
                                    isBiggerText={isBiggerText}
                                    setIsBiggerText={setIsBiggerText}
                                />
                            }
                        />
                        <Box sx={{ px: { xs: 2, sm: 0 } }}>
                            <RenderContent
                                newContent={content}
                                isShowMore={isShowMore}
                                isBiggerText={isBiggerText}
                            />
                        </Box>
                    </Card>
                    {content.length > 0 && (
                        <Box
                            sx={{
                                p: 0,
                                position: 'relative',
                            }}>
                            {isShowMore ? (
                                <Stack alignItems="center" width="100%">
                                    <CustomButton
                                        id="show-less-content-buttonId"
                                        disableEffect
                                        onClick={() => {
                                            scrollToTop();
                                            if (headerContent.length > 0) {
                                                setSelectedPart(
                                                    headerContent[0]
                                                );
                                            }
                                            toggleMoreDetails();
                                        }}
                                        fullWidth
                                        sx={{
                                            width: 'fit-content',
                                            mb: 1,
                                            fontWeight: 500,
                                            fontSize: 14,
                                        }}
                                        startIcon={
                                            <KeyboardDoubleArrowDownIcon
                                                sx={{
                                                    transform: 'rotate(180deg)',
                                                }}
                                            />
                                        }
                                        children="Thu gọn"
                                    />
                                </Stack>
                            ) : (
                                <CustomButton
                                    id="show-more-content-buttonId"
                                    disableEffect
                                    onClick={() => {
                                        toggleMoreDetails();
                                        if (headerContent.length > 0) {
                                            scrollToPart(selectedPart);
                                        }
                                    }}
                                    fullWidth
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        background:
                                            'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 21.88%, rgba(255, 255, 255, 0.95) 45.31%, #fff 67.71%, #fff 100%)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        height: '76px',
                                        alignItems: 'center',
                                        fontWeight: 500,
                                        fontSize: 14,
                                    }}
                                    startIcon={<KeyboardDoubleArrowDownIcon />}
                                    children="Xem thêm"
                                />
                            )}
                        </Box>
                    )}
                    <Box sx={{ px: 3 }}>{keywordsTypography}</Box>
                </Box>
            </Stack>
            <Box py={2} px={3}>
                <Caution />
            </Box>
        </Stack>
    );
}
