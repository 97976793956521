import { createContext } from 'react';

export const LoginContext = createContext({
    tel: '',
    changeTel: () => {},
    telError: '',
    checkTelError: () => {},
    changeTelError: () => {},
    resetTel: () => {},
    stepName: '',
    changeStepName: () => {},
});
