import { Modal, Backdrop, Fade, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CustomCircularProgress } from 'UI';

import LogoBrand from '../layout/LogoBrand';

export default function LoadingModal({ isLoading = false, ...otherProps }) {
    const theme = useTheme();

    return (
        <Modal
            open={isLoading}
            onClose={() => {}}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            aria-labelledby="modal-loading-data-title"
            aria-describedby="modal-loading-data-title-description"
            {...otherProps}>
            <Fade in={isLoading}>
                <Box
                    sx={(theme) => ({
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '5px',
                        p: 2,
                        width: 'fit-content',
                        backgroundColor: 'transparent',
                        border: 'none',
                        boxShadow: 0,
                        outline: 'none',
                    })}>
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CustomCircularProgress
                            size={100}
                            animationDuration="1000ms"
                            thickness={2}
                            backgroundColor="disabled.lighter"
                        />
                        <Box
                            sx={{
                                top: 4,
                                left: 4,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '93px',
                                height: '93px',
                                overflow: 'hidden',
                                borderRadius: '50%',
                            }}>
                            <LogoBrand
                                width="120px"
                                height="120px"
                                styleProps={{
                                    backgroundColor:
                                        theme.palette.disabled.lighter,
                                    borderRadius: '50%',
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}
