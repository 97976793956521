import { Stack, Box } from '@mui/material';

import { useParams, useLoaderData } from 'react-router-dom';
import domain from 'config/domain.js';
import { ArticleContentContext } from 'context/article-content-context.js';

import { CustomHelmet } from 'UI';

import SectionBreadcrumbs from 'layout/section/SectionBreadcrumbs.jsx';
import Page404 from 'shop/404/Page404.jsx';
import ArticleDetails from './ArticleDetails.jsx';
import RecentWatchProd from 'shop/products/RecentWatchProd.jsx';
import SimilarArticle from '../SimilarArticle.jsx';

export default function PageArticleContent({ ...otherProps }) {
    const { itemSlug } = useParams();
    const { itemData } = useLoaderData();

    if (itemSlug && !itemData) {
        return <Page404 />;
    }

    let breadcrumbsData = [
        {
            to: '/bai-viet-suc-khoe',
            name: 'Bài viết sức khỏe',
        },
    ].concat(
        itemData.category.map((i, idx) => ({
            to: '/bai-viet-suc-khoe' + i.slug,
            name: i.name,
        })),
        [{ to: itemData.slug, name: itemData.webName, isNavLink: false }]
    );
    const contextValue = { itemData };

    return (
        <>
            <CustomHelmet
                slug={`https://nhathuockhanhtrang.com.vn${itemData.slug}`}
                title={itemData.webName + ' - Nhà thuốc Khánh Trang'}
                description={itemData.description}
                image={itemData.primaryImage.mainSrc}
            />
            <ArticleContentContext.Provider value={contextValue}>
                <Stack
                    sx={{ maxWidth: 1200, mx: 'auto', px: { xs: 0, sm: 2 } }}>
                    <Stack spacing={2.5} my={2.5}>
                        <Box pl={{ xs: 2, sm: 0 }}>
                            <SectionBreadcrumbs
                                breadcrumbsData={breadcrumbsData}
                            />
                        </Box>
                        <ArticleDetails />
                        <Box sx={{ px: { xs: '20px', sm: 0 } }}>
                            <SimilarArticle
                                keywordIds={itemData.keywords
                                    .filter(
                                        (i) =>
                                            i.status &&
                                            i.keyName.trim().length > 0
                                    )
                                    .map((i) => i._id)}
                                currentSlug={itemData.slug}
                            />
                            <RecentWatchProd />
                        </Box>
                    </Stack>
                </Stack>
            </ArticleContentContext.Provider>
        </>
    );
}

export const loader = async ({ params }) => {
    let { itemSlug } = params;
    if (itemSlug) {
        let response = await fetch(
            domain +
                '/ecom/article-item/content?itemSlug=' +
                itemSlug.replace('/', '')
        );
        if (!response.ok) {
            return { itemData: null };
        }
        let data = await response.json();
        return { itemData: data.data };
    }

    return { itemData: null };
};
