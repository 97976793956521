import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function QuoteBox({ children, styleProps = {}, ...otherProps }) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                borderRadius: '8px',
                padding: '20px',
                backgroundColor: 'disabled.lighter',
                width: '100%',
                position: 'relative',
                filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.32))',
                '::before': {
                    content: '"\\201C"',
                    position: 'absolute',
                    left: '5px',
                    top: '-5px',
                    fontSize: '30px',
                    fontFamily: 'Georgia',
                    fontWeight: 'bold',
                    color: 'primary.main',
                },
                '::after': {
                    content: '"\\201D"',
                    position: 'absolute',
                    right: '5px',
                    bottom: '-20px',
                    fontSize: '30px',
                    fontFamily: 'Georgia',
                    fontWeight: 'bold',
                    color: 'primary.main',
                },
                ...styleProps,
            }}>
            {children}
        </Box>
    );
}
