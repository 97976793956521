import { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    CheckBoxRounded as CheckBoxRoundedIcon,
    CheckBoxOutlineBlankRounded as CheckBoxOutlineBlankRoundedIcon,
} from '@mui/icons-material';
import { SxProps, Theme } from '@mui/system';

import useCustomColor from 'hooks/useCustomColor';

const StyledCheckbox = styled(Checkbox)(({ theme, ...props }) => {
    const { color } = props;
    let { main, dark } = useCustomColor(color);

    return {
        '&.MuiCheckbox-root': {
            color: 'text.primary',
            ':hover': { color: dark },
            '&.Mui-checked': {
                color: main,
            },
            '&.Mui-disabled': {
                color: 'disabled.light',
            },
        },
    };
});

/**
 * CustomCheckbox component to render a group of checkboxes with enhanced styling and functionalities.
 *
 * @param {Object} props - Props for the CustomCheckbox component.
 * @param {string} [props.id] - Unique identifier for the checkbox group.
 * @param {Array} [props.options=[]] - Array of options to display as checkboxes.
 * @param {Function} [props.formatOption] - Function to convert option to the desired format.
 * * @param {Function} [props.getOptionChecked] - Function to determine if an option is checked.
 * @param {Function} [props.getOptionDisabled] - Function to determine if an option is disabled.
 * @param {Function} [props.onChange] - Callback function when an option is changed.
 * @param {string} [props.label] - Label for the checkbox group.
 * @param {string} [props.helperText] - Helper text for the checkbox group.
 * @param {string} [props.error] - Error message to display.
 * @param {string} [props.color='primary'] - Color of the checkbox.
 * @param {string} [props.size='small'] - Size of the checkbox.
 * @param {string} [props.margin='none'] - Margin for the checkbox group.
 * @param {ReactNode} [props.icon] - Icon for the unchecked state.
 * @param {ReactNode} [props.checkedIcon] - Icon for the checked state.
 * @param {string} [props.labelPlacement='end'] - Placement of the label.
 * @param {boolean} [props.disabled=false] - Disable the checkbox group.
 * @param {boolean} [props.required=false] - Mark the checkbox group as required.
 * @param {boolean} [props.row=false] - Display checkboxes in a row.
 * @param {SxProps<Theme>} [props.sx] - Additional styles to apply to the checkbox.
 * @param {Object} [props.FormControlProps] - Additional props for FormControl.
 * @param {Object} [props.FormLabelProps] - Additional props for FormLabel.
 * @param {Object} [props.FormGroupProps] - Additional props for FormGroup.
 * @param {Object} [props.FormHelperTextProps] - Additional props for FormHelperText.
 * @param {...Object} otherProps - Other props to spread to the FormControl component.
 *
 * @returns {JSX.Element} The rendered component.
 */

function CustomCheckbox({
    id,
    options = [],
    formatOption = (opt) => ({
        name: opt,
        label: opt,
    }),
    getOptionChecked = (option) => false,
    getOptionDisabled = (option) => false,
    onChange = () => {},
    label: formLabel = '',
    helperText = '',
    error = '',
    color = 'primary',
    size = 'small',
    margin = 'none',
    icon = <CheckBoxOutlineBlankRoundedIcon />,
    checkedIcon = <CheckBoxRoundedIcon />,
    labelPlacement = 'end',
    disabled: formDisabled = false,
    required = false,
    row = false,
    sx = {},
    FormControlProps,
    FormLabelProps,
    FormGroupProps,
    FormHelperTextProps,
    ...otherProps
}) {
    const handleChange = (event) => {
        let name = event.target.name;
        let checked = event.target.checked;
        onChange({ name, checked });
    };

    let defaultFormControlProps = {
        component: 'fieldset',
        variant: 'standard',
        disabled: formDisabled,
        required,
        margin,
        color,
        error: Boolean(error),
    };
    let formLabelProps = {
        component: 'legend',
        margin,
        ...FormLabelProps,
    };
    let checkboxProps = {
        size,
        color,
        margin,
        icon,
        checkedIcon,
        onChange: handleChange,
    };
    const formId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    return (
        <FormControl {...defaultFormControlProps} {...FormControlProps}>
            <FormLabel {...formLabelProps}>{formLabel}</FormLabel>
            {options.length > 0 && (
                <FormGroup row={row} {...FormGroupProps}>
                    {options.map((opt, idx, array) => {
                        const { name, label } = formatOption(opt);

                        return (
                            <FormControlLabel
                                key={`${formId}-${idx + 1}`}
                                disabled={
                                    formDisabled || getOptionDisabled(opt)
                                }
                                labelPlacement={labelPlacement}
                                sx={(theme) => ({
                                    margin: margin === 'dense' ? '4px 0' : 0,
                                    ...(typeof sx === 'function'
                                        ? sx(theme)
                                        : sx),
                                })}
                                control={
                                    <StyledCheckbox
                                        checked={getOptionChecked(opt)}
                                        name={name}
                                        {...checkboxProps}
                                    />
                                }
                                label={label}
                            />
                        );
                    })}
                </FormGroup>
            )}
            {(error || helperText) && (
                <FormHelperText
                    id={`${formId}-helper-text`}
                    {...FormHelperTextProps}>
                    {error || helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

CustomCheckbox.propTypes = {
    id: PropTypes.string,
    options: PropTypes.array.isRequired,
    formatOption: PropTypes.func.isRequired,
    getOptionChecked: PropTypes.func.isRequired,
    getOptionDisabled: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    color: PropTypes.oneOfType([
        PropTypes.oneOf([
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning',
            'disabled',
        ]),
        PropTypes.string, // For custom colors
    ]),
    size: PropTypes.oneOf(['small', 'medium']),
    margin: PropTypes.oneOf(['none', 'dense']),
    icon: PropTypes.node,
    checkedIcon: PropTypes.node,
    labelPlacement: PropTypes.oneOf(['top', 'bottom', 'start', 'end']),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    row: PropTypes.bool,
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    FormControlProps: PropTypes.object,
    FormLabelProps: PropTypes.object,
    FormGroupProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
};

CustomCheckbox.defaultProps = {
    options: [],
    formatOption: (opt) => ({
        name: opt,
        label: opt,
        checked: false,
    }),
    getOptionChecked: () => false,
    getOptionDisabled: () => false,
    onChange: () => {},
    label: '',
    helperText: '',
    error: '',
    color: 'primary',
    size: 'small',
    margin: 'none',
    icon: <CheckBoxOutlineBlankRoundedIcon />,
    checkedIcon: <CheckBoxRoundedIcon />,
    labelPlacement: 'end',
    disabled: false,
    required: false,
    row: false,
    sx: {},
};

export default CustomCheckbox;
