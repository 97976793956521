import { useContext } from 'react';
import { Typography, Stack } from '@mui/material';

import { CustomButton } from 'UI';

import { ProductContext } from 'context/product-context';

export default function EndOfListWithClearFilter({ ...props }) {
    const { activeFilterCount, resetFilter } = useContext(ProductContext);

    return (
        <Stack spacing={1} alignItems="center" justifyContent="center">
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: 'center',
                }}>
                Bạn đã xem hết danh sách
            </Typography>
            {activeFilterCount > 0 && (
                <CustomButton
                    id="clear-filters-custom-button"
                    variant="contained"
                    onClick={resetFilter}
                    sx={{
                        width: 'fit-content',
                        borderRadius: '20px',
                    }}
                    children={
                        <Typography variant="h6" sx={{ fontSize: 16 }}>
                            Xóa tất cả bộ lọc
                        </Typography>
                    }
                />
            )}
        </Stack>
    );
}
