import { Typography, Stack } from '@mui/material';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { logout } from 'utils/auth.js';
import { userInfoActions } from 'store/userInfo-slice.js';

import { CustomDialog, CustomAvatar } from 'UI';

export default function LogoutDialog({
    open = false,
    onClose = () => {},
    ...props
}) {
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        dispatch(userInfoActions.deleteUserInfo());
        onClose();
        if (location.includes('/admin')) {
            navigate('/');
        }
    };
    return (
        <CustomDialog
            id="logout-custom-dialog"
            color="primary"
            title=""
            open={open}
            maxWidth="xs"
            onClose={() => {}}
            hideCloseIcon
            labelNoButton="Đăng xuất"
            onNo={handleLogout}
            noButtonProps={{
                fullWidth: true,
                variant: 'text',
                sx: {
                    borderRadius: '20px',
                    textTransform: 'initial',
                    fontWeight: 600,
                    fontSize: 16,
                    py: 1,
                    backgroundColor: 'aliceblue',
                    ':hover': {
                        backgroundColor: 'aliceblue',
                    },
                },
            }}
            labelYesButton="Đóng"
            onYes={onClose}
            yesButtonProps={{
                fullWidth: true,
                variant: 'text',
                sx: {
                    borderRadius: '20px',
                    textTransform: 'initial',
                    fontWeight: 600,
                    fontSize: 16,
                    py: 1,
                    backgroundColor: 'primary.main',
                    color: 'white',
                    ':hover': {
                        backgroundColor: 'primary.main',
                        color: 'white',
                    },
                },
            }}
            DialogContentProps={{
                sx: {
                    '&.MuiDialogContent-root': {
                        border: 'none',
                        pb: 1,
                    },
                },
            }}
            DialogActionsProps={{
                sx: {
                    px: 2,
                    pb: 2,
                },
            }}
            PaperProps={{
                sx: {
                    width: '330px',
                    height: '340px',
                    borderRadius: '10px',
                },
            }}>
            <Stack
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
                height="100%">
                <CustomAvatar
                    src="../assets/images/pharmacy-illustration.png"
                    sx={{ width: 240, height: 150 }}
                />
                <div>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: 2,
                        }}>
                        Đăng xuất?
                    </Typography>
                    <Typography sx={{ textAlign: 'center', fontSize: 14 }}>
                        Duy trì đăng nhập để xem các khuyến mãi dành riêng cho
                        thành viên.
                    </Typography>
                </div>
            </Stack>
        </CustomDialog>
    );
}
