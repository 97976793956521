import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@mui/material';
import { BrokenImage as BrokenImageIcon } from '@mui/icons-material';
import { SxProps, Theme } from '@mui/system';

/**
 * CustomAvatar component that displays a user avatar with fallback options.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.src='../assets/images/khanhtranglogo-02.png'] - The source of the avatar image.
 * @param {string} [props.srcSet] - The srcSet for responsive images.
 * @param {string} [props.sizes] - The sizes attribute for responsive images.
 * @param {React.ReactNode} [props.children] - The content to display if the image fails to load.
 * @param {string} [props.alt=''] - The alternative text for the avatar image.
 * @param {'rounded' | 'square' | 'circular'} [props.variant='circular'] - The variant of the avatar shape.
 * @param {number} [props.avatarSize=24] - The size of the avatar (width & height).
 * @param {Object} [props.imgProps] - Additional props for the <img/> tag if the src exists.
 * @param {SxProps<Theme>} [props.sx] - Additional styles to apply to the avatar.
 * @param {Object} [otherProps] - Any other props to pass to the Avatar component.
 * @returns {JSX.Element} The rendered avatar component.
 */

function CustomAvatar({
    src = '../assets/images/khanhtranglogo-02.png',
    srcSet,
    sizes,
    children = undefined,
    alt = '',
    variant = 'rounded',
    avatarSize = 24,
    imgProps = {},
    sx = {},
    ...otherProps
}) {
    const [newSrc, setNewSrc] = useState(
        src || '../assets/images/khanhtranglogo-02.png'
    );

    useEffect(() => {
        let timeout;
        if (src && !children) {
            timeout = setTimeout(() => {
                setNewSrc(src);
            }, 200);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [children, src]);

    if (children) {
        return (
            <Avatar
                variant={variant}
                sx={(theme) => ({
                    width: avatarSize,
                    height: avatarSize,
                    backgroundColor: 'transparent',
                    ...(typeof sx === 'function' ? sx(theme) : sx),
                })}
                {...otherProps}>
                {children ? children : <BrokenImageIcon />}
            </Avatar>
        );
    }

    return (
        <Avatar
            variant={variant}
            src={newSrc}
            imgProps={{
                ...imgProps,
                srcSet,
                sizes,
                onError: () => {
                    setNewSrc('../assets/images/khanhtranglogo-02.png');
                },
            }}
            alt={alt || Math.random().toString(36).slice(2, 11)}
            sx={(theme) => ({
                width: avatarSize,
                height: avatarSize,
                backgroundColor: 'transparent',
                ...(typeof sx === 'function' ? sx(theme) : sx),
            })}
            {...otherProps}
        />
    );
}

CustomAvatar.propTypes = {
    src: PropTypes.string,
    srcSet: PropTypes.string,
    sizes: PropTypes.string,
    children: PropTypes.node,
    alt: PropTypes.string,
    variant: PropTypes.oneOf(['rounded', 'square', 'circular']),
    avatarSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imgProps: PropTypes.object,
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

CustomAvatar.defaultProps = {
    src: '../assets/images/khanhtranglogo-02.png',
    srcSet: undefined,
    sizes: undefined,
    children: undefined,
    alt: '',
    variant: 'rounded',
    avatarSize: 24,
    imgProps: {},
    sx: {},
};

export default CustomAvatar;
