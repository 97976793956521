import PropTypes from 'prop-types';

import { Pagination } from '@mui/material';

/**
 * CustomPagination is a wrapper around MUI's Pagination component with simplified props
 * for managing the current page and total page count.
 *
 * @param {Object} props - Component properties.
 * @param {number} [props.page=1] - The current page number.
 * @param {function} [props.setPage] - Callback function to set the current page. Receives the new page number as an argument.
 * @param {number} [props.count=1] - The total number of pages.
 * @returns {JSX.Element} Rendered pagination component.
 */
function CustomPagination({
    page = 1,
    setPage = () => {},
    count = 1,
    ...props
}) {
    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <Pagination
            shape="rounded"
            size="small"
            hidePrevButton
            hideNextButton
            count={count}
            page={page}
            onChange={handleChange}
            {...props}
        />
    );
}

CustomPagination.propTypes = {
    page: PropTypes.number,
    setPage: PropTypes.func,
    count: PropTypes.number,
};

CustomPagination.defaultProps = {
    page: 1,
    setPage: () => {},
    count: 1,
};

export default CustomPagination;
