import { useContext, useState } from 'react';
import { Stack, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    ZoomIn as ZoomInIcon,
    ZoomOut as ZoomOutIcon,
} from '@mui/icons-material';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { ProductContentContext } from 'context/product-content-context.js';

import { CustomDialog, CustomChip, CustomAvatar, CustomButton } from 'UI';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function ZoomImagesCustomDialog({
    open = false,
    onClose = () => {},
    ...props
}) {
    const { itemData } = useContext(ProductContentContext);

    const theme = useTheme();
    const isBiggerLgScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('lg') // >= 1000
    );

    const [activeStep, setActiveStep] = useState(0);
    const [isZoomed, setIsZoomed] = useState(false);

    if (!itemData) {
        return null;
    }
    const images = itemData.secondaryImages
        .filter((i) => i.isActive && i.status)
        .map((i) => i.mainSrc);

    const handleNext = () => {
        if (activeStep === images.length - 1) {
            return;
        }
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            return;
        }
        setActiveStep(activeStep - 1);
    };

    return (
        <CustomDialog
            id="zoom-images-dialogId"
            isChildDialog
            title={isZoomed ? '' : itemData.webName}
            open={open}
            onClose={onClose}
            hideNoButton
            hideYesButton
            children={
                <Stack spacing={2} alignItems="center">
                    <Stack
                        direction={{ xs: 'column', lg: 'row' }}
                        alignItems="flex-start"
                        spacing={2}
                        sx={{
                            ...(isZoomed
                                ? {
                                      minHeight: '100%',
                                      width: 'auto',
                                  }
                                : {
                                      maxHeight: '475px',
                                      width: { xs: 'auto', sm: '460px' },
                                  }),
                        }}>
                        <Box
                            p={3}
                            sx={{
                                position: 'relative',
                                borderRadius: { xs: 0, lg: '15px' },
                                overflow: 'hidden',
                            }}>
                            {isBiggerLgScreen && (
                                <CustomButton
                                    id="arrow-left-custom-button"
                                    color="disabled"
                                    useIconButton
                                    onClick={handleBack}
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: '50%',
                                        zIndex: 1,
                                        p: 0,
                                    }}
                                    children={
                                        <KeyboardArrowLeftIcon fontSize="large" />
                                    }
                                />
                            )}
                            {isBiggerLgScreen && (
                                <CustomButton
                                    id="arrow-right-custom-button"
                                    color="disabled"
                                    useIconButton
                                    onClick={handleNext}
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '50%',
                                        zIndex: 1,
                                        p: 0,
                                    }}
                                    children={
                                        <KeyboardArrowLeftIcon
                                            fontSize="large"
                                            sx={{
                                                transform: 'rotate(180deg)',
                                            }}
                                        />
                                    }
                                />
                            )}
                            <AutoPlaySwipeableViews
                                interval={5000}
                                axis={'x'}
                                autoplay={false}
                                index={activeStep}
                                onChangeIndex={(index) => setActiveStep(index)}>
                                {images.map((src, idx) => {
                                    return (
                                        <CustomAvatar
                                            key={src + idx}
                                            loading="lazy"
                                            src={src}
                                            alt={'zoom-image-' + src + idx}
                                            sx={{
                                                width: 'auto',
                                                height: '100%',
                                            }}
                                        />
                                    );
                                })}
                            </AutoPlaySwipeableViews>
                        </Box>
                    </Stack>
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 10,
                            bottom: isZoomed ? 10 : 120,
                        }}>
                        <CustomButton
                            id="zoom-image-custom-button"
                            useIconButton
                            onClick={() => setIsZoomed((v) => !v)}
                            children={
                                isZoomed ? <ZoomOutIcon /> : <ZoomInIcon />
                            }
                        />
                        <CustomChip
                            variant="filled"
                            clickable={false}
                            label={activeStep + 1 + '/' + images.length}
                        />
                    </Box>
                    {!isZoomed && (
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            spacing={1.5}
                            flexWrap="nowrap"
                            sx={{ overflow: 'auto', maxWidth: '100%' }}>
                            {images.map((src, idx) => {
                                return (
                                    <CustomAvatar
                                        key={src + idx}
                                        src={src}
                                        alt={src}
                                        avatarSize={100}
                                        onClick={() => setActiveStep(idx)}
                                        sx={(theme) => ({
                                            '&.MuiAvatar-root': {
                                                ':hover': {
                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                },
                                                border: `1px solid ${
                                                    activeStep === idx
                                                        ? theme.palette.primary
                                                              .main
                                                        : theme.palette.disabled
                                                              .light
                                                }`,
                                            },
                                            p: 2,
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                        })}
                                    />
                                );
                            })}
                        </Stack>
                    )}
                </Stack>
            }
        />
    );
}
