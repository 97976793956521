import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { List, ListItem } from '@mui/material';

import { OptionRow } from 'template';

/**
 * CustomList component renders a list of items, where each item is displayed
 * as an `OptionRow` within an MUI `ListItem`. Provides custom styling and density
 * options for list padding.
 *
 * @param {Object} props - Component properties.
 * @param {string} [props.id] - Optional unique ID for the List element. A random ID will be generated if not provided.
 * @param {Array<Object>} [props.items=[]] - Array of item objects to display in the list. Each object should have:
 *    - `label` (any type): Label text for OptionRow, optional.
 *    - `name` (string, required): Unique name for the item, used as the key.
 *    - `subLabel` (any type, optional): Additional text shown below the label. Defaults to `name` if not provided.
 *    - `icon` (node, optional): Icon to display in the OptionRow.
 *    - `avatar` (node, optional): Avatar to display in the OptionRow.
 *    - `divider` (boolean, optional, default `false`): If true, adds a divider below the ListItem.
 * @param {string} [props.alignItems='flex-start'] - Controls alignment of items within each ListItem.
 * @param {string} [props.margin='none'] - Sets the vertical margin (padding) for the list: 'none' (default), 'dense', 'normal'.
 * @param {string} [props.density='standard'] - Controls ListItem padding: 'compact', 'standard' (default), or 'comfortable'.
 * @param {boolean} [props.disableGutters=false] - Whether to add padding left 12px in front of label
 * @param {Object} [props.ListProps={}] - Additional props for the MUI List component.
 * @param {Object} [props.ListItemProps={}] - Additional props for the MUI ListItem component.
 * @returns {JSX.Element} The rendered custom list.
 */

function CustomList({
    id,
    items = [],
    alignItems = 'flex-start',
    margin = 'none',
    density = 'standard',
    disableGutters = false,
    ListProps = {},
    ListItemProps = {},
    ...otherProps
}) {
    const listId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );
    

    return (
        <List
            id={listId}
            component="ul"
            sx={{
                py: margin === 'none' ? 0 : margin === 'dense' ? 0.5 : 1,
            }}
            {...ListProps}
            {...otherProps}>
            {items.map((item) => {
                let {
                    label,
                    name,
                    subLabel = undefined,
                    divider = false,
                    ...props
                } = item;
                return (
                    <ListItem
                        key={name}
                        divider={divider}
                        disableGutters
                        disablePadding
                        sx={{
                            '&.MuiListItem-root': {
                                py:
                                    density === 'compact'
                                        ? 0
                                        : density === 'standard'
                                        ? 0.5
                                        : density === 'comfortable'
                                        ? 1
                                        : 0,
                            },
                        }}
                        {...ListItemProps}>
                        <OptionRow
                            label={label}
                            subLabel={subLabel !== undefined ? subLabel : name}
                            disableGutters={disableGutters}
                            selected={false}
                            displayCheckmark={false}
                            displayHighlight={false}
                            alignItems={alignItems}
                            sx={{ cursor: 'default' }}
                            {...props}
                        />
                    </ListItem>
                );
            })}
        </List>
    );
}

CustomList.propTypes = {
    id: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            name: PropTypes.string.isRequired,
            subLabel: PropTypes.any,
            icon: PropTypes.any,
            avatar: PropTypes.any,
            divider: PropTypes.bool,
        })
    ),
    alignItems: PropTypes.oneOf([
        'flex-start',
        'center',
        'flex-end',
        'stretch',
    ]),
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    density: PropTypes.oneOf(['compact', 'standard', 'comfortable']),
    disableGutters: PropTypes.bool,
    ListProps: PropTypes.object,
    ListItemProps: PropTypes.object,
};

CustomList.defaultProps = {
    items: [],
    alignItems: 'flex-start',
    margin: 'none',
    density: 'standard',
    disableGutters: false,
    ListProps: {},
    ListItemProps: {},
};

export default CustomList;
