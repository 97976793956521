import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { WarningRounded as WarningRoundedIcon } from '@mui/icons-material';

import { CustomDialog, CustomInput } from 'UI';

/**
 * WarningDialog renders a confirmation dialog for deleting data. The dialog
 * requires the user to type a specific phrase to confirm the deletion action.
 *
 * @component
 * @param {Object} props - Properties passed to the component.
 * @param {string} props.id - ID of the dialog.
 * @param {string} props.warningType - Type to custom template (`delete`, `update`, `reset`).
 * @param {string} props.title - Title of the dialog.
 * @param {boolean} props.open - Controls the visibility of the dialog.
 * @param {Function} props.onClose - Callback fired when the dialog is closed.
 * @param {Function} props.onSubmit - Callback fired when the delete action is confirmed.
 * @param {string} props.compareString - The phrase the user must type to confirm deletion.
 * @param {Object} [props.otherProps] - Additional properties for CustomDialog component.
 * @returns {JSX.Element} The rendered component.
 */

function WarningDialog({
    id,
    warningType = 'delete',
    open = false,
    title = '',
    onClose = () => {},
    onSubmit = () => {},
    compareString = '',
    content = '',
    ...otherProps
}) {
    const theme = useTheme();
    const [userInput, setUserInput] = useState('');

    let warningDialogId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );
    return (
        <CustomDialog
            id={warningDialogId}
            color="warning"
            isChildDialog
            title={
                title || warningType === 'delete'
                    ? 'Xóa dữ liệu?'
                    : warningType === 'reset'
                    ? 'Khởi tạo lại dữ liệu mới'
                    : warningType === 'update'
                    ? 'Cập nhật dữ liệu?'
                    : ''
            }
            open={open}
            maxWidth="sm"
            onClose={onClose}
            hideCloseIcon
            labelNoButton="Quay lại"
            onNo={onClose}
            labelYesButton={
                warningType === 'delete'
                    ? 'Xóa'
                    : warningType === 'reset'
                    ? 'Tiếp tục'
                    : warningType === 'update'
                    ? 'Cập nhật'
                    : ''
            }
            hideYesButton={compareString && compareString !== userInput}
            onYes={() => {
                if (!compareString || compareString === userInput) {
                    onSubmit();
                }
            }}
            {...otherProps}>
            {warningType === 'delete' && (
                <>
                    {typeof content === 'string' && (
                        <Typography>
                            Bạn đang thực hiện xóa dữ liệu&nbsp;
                            {content && <b>{content}</b>}
                        </Typography>
                    )}
                    {typeof content !== 'string' && content}
                </>
            )}
            {warningType === 'reset' &&
                (content || (
                    <Typography>
                        Bạn đang xóa các dữ liệu đã nhập và tạo mới.
                    </Typography>
                ))}
            {warningType === 'update' &&
                (content || (
                    <Typography>
                        Bạn đang thực hiện cập nhật các dữ liệu đã tạo.
                    </Typography>
                ))}
            <Stack direction="row" alignItems="center" spacing={1} mt={1}>
                <WarningRoundedIcon color="error" fontSize="small" />
                <Typography>
                    Bạn&nbsp;
                    <span style={{ color: theme.palette.error.main }}>
                        không thể khôi phục dữ liệu cũ
                    </span>
                    &nbsp;nếu tiếp tục.
                </Typography>
            </Stack>
            {compareString && (
                <Typography sx={{ fontWeight: 600, pt: 2 }}>
                    Nhập&nbsp;
                    <i
                        style={{
                            color: theme.palette.secondary.contrastText,
                            letterSpacing: 1.4,
                            fontFamily: 'monospace',
                            fontStyle: 'normal',
                            backgroundColor: theme.palette.secondary.lighter,
                            padding: '2px 5px 2px 5px',
                            fontWeight: 400,
                        }}>
                        {compareString}
                    </i>
                    &nbsp;để tiếp tục?
                </Typography>
            )}
            {compareString && (
                <CustomInput
                    name={'delete-data-input'}
                    id={'delete-data-inputId'}
                    color="info"
                    placeholder={compareString}
                    value={userInput}
                    onChange={setUserInput}
                    helperText={`Nhập chính xác cụm từ '${compareString}' để xác nhận xóa`}
                    FormHelperTextProps={{
                        sx: { fontStyle: 'italic' },
                    }}
                />
            )}
        </CustomDialog>
    );
}

WarningDialog.propTypes = {
    id: PropTypes.string,
    warningType: PropTypes.oneOf(['delete', 'update', 'reset']),
    title: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    compareString: PropTypes.string,
};

WarningDialog.defaultProps = {
    warningType: 'delete',
    title: '',
    open: false,
    onClose: () => {},
    onSubmit: () => {},
    compareString: '',
};

export default WarningDialog;
