export const themeConfig = {
    palette: {
        contrastThreshold: 3.8,
        primary: {
            main: '#3843ad',
            dark: '#28318a',
            light: '#5e6ed6',
            lighter: '#d8daf0',
            contrastText: '#fff',
        },
        secondary: {
            main: '#00a5b4',
            dark: '#007783',
            light: '#33c7d5',
            lighter: '#d1f3f5',
            contrastText: '#000',
        },
        success: {
            main: '#2f7d32',
            dark: '#246024',
            light: '#5ba960',
            lighter: '#cfe9d0',
            contrastText: '#fff',
        },
        warning: {
            main: '#f37c04',
            dark: '#bf5d02',
            light: '#f9a53a',
            lighter: '#ffe3c2',
            contrastText: '#000',
        },
        info: {
            main: '#0188d1',
            dark: '#004b84',
            light: '#4bbcf3',
            lighter: '#cfeeff',
            contrastText: '#fff',
        },
        error: {
            main: '#d32f2f',
            dark: '#992121',
            light: '#e57373',
            lighter: '#fcdcdc',
            contrastText: '#fff',
        },
        disabled: {
            main: '#3c3c3c',
            dark: '#000000',
            light: '#b5b5b5',
            lighter: '#f5f5f5',
            contrastText: '#fff',
        },
        link: {
            main: '#1E90FF',
            dark: '#0056B3',
            light: '#62bbff',
            lighter: '#bce1ff',
            contrastText: '#fff',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            link: '#1E90FF',
            hint: '#22194D',
        },
        // action: {
        //     activatedOpacity: 0.12,
        //     active: 'rgba(0, 0, 0, 0.54)',
        //     disabled: 'rgba(0, 0, 0, 0.26)',
        //     disabledBackground: 'rgba(0, 0, 0, 0.12)',
        //     disabledOpacity: 0.38,
        //     focus: 'rgba(0, 0, 0, 0.12)',
        //     focusOpacity: 0.12,
        //     hover: 'rgba(0, 0, 0, 0.04)',
        //     hoverOpacity: 0.04,
        //     selected: 'rgba(0, 0, 0, 0.08)',
        //     selectedOpacity: 0.08,
        // },
        // grey: {
        //     50: '#fafafa',
        //     100: '#f5f5f5',
        //     200: '#eeeeee',
        //     300: '#e0e0e0',
        //     400: '#bdbdbd',
        //     500: '#9e9e9e',
        //     600: '#757575',
        //     700: '#616161',
        //     800: '#424242',
        //     900: '#212121',
        //     A100: '#f5f5f5',
        //     A200: '#eeeeee',
        //     A400: '#bdbdbd',
        //     A700: '#616161',
        // },
    },
    typography: {
        fontFamily: "'Inter', 'Roboto', Arial, sans-serif !important",
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 800,
            lg: 1000,
            xl: 1200,
            xxl: 1350,
        },
    },
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
        MuiStack: {
            defaultProps: {
                direction: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                spacing: 0,
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: ({ ownerState, theme }) => {
                    const colorMap = {
                        primary: theme.palette.primary.main,
                        secondary: theme.palette.secondary.main,
                        success: theme.palette.success.main,
                        warning: theme.palette.warning.main,
                        error: theme.palette.error.main,
                        info: theme.palette.info.main,
                        disabled: theme.palette.text.disabled,
                        inherit: 'inherit',
                    };

                    return {
                        color:
                            colorMap[ownerState.color] || 'rgba(0, 0, 0, 0.87)', // Fallback to match palette.text.primary
                    };
                },
            },
        },
    },
};
