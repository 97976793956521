import { Grid2, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import {
    Diversity3 as Diversity3Icon,
    LocalShipping as LocalShippingIcon,
    History as HistoryIcon,
    Bolt as BoltIcon,
} from '@mui/icons-material';

import { Link as RouterLink } from 'react-router-dom';

import { CustomAvatar } from 'UI';

export default function Commitment({ ...otherProps }) {
    return (
        <Grid2 container>
            {DATA.map(({ Icon, header, subheader, pathname }, idx) => {
                return (
                    <Grid2
                        size={{ xs: 6, lg: 3 }}
                        key={`commitment-box-${idx}`}>
                        <ListItem
                            sx={{
                                ml: { xs: 0, sm: 2 },
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: 'center',
                            }}>
                            <ListItemAvatar>
                                <CustomAvatar
                                    avatarSize={45}
                                    sx={{ backgroundColor: 'transparent' }}>
                                    {
                                        <Icon
                                            sx={{
                                                color: 'primary.main',
                                                width: 50,
                                                height: 50,
                                            }}
                                        />
                                    }
                                </CustomAvatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={header}
                                primaryTypographyProps={{
                                    color: 'primary.main',
                                    variant: 'h6',
                                    fontWeight: 600,
                                    fontSize: { xs: 16, sm: 18 },
                                    textAlign: { xs: 'center', sm: 'left' },
                                }}
                                secondary={subheader}
                                secondaryTypographyProps={{
                                    component: pathname ? RouterLink : 'p',
                                    ...(pathname && {
                                        to: pathname,
                                    }),
                                    sx: {
                                        fontSize: {
                                            xs: 12,
                                            sm: 14,
                                        },
                                        fontWeight: 400,
                                        textAlign: {
                                            xs: 'center',
                                            sm: 'left',
                                        },
                                        color: 'text.secondary',
                                        ':hover': {
                                            color: pathname
                                                ? 'text.link'
                                                : 'text.secondary',
                                        },
                                        textDecoration: 'none',
                                    },
                                }}
                            />
                        </ListItem>
                    </Grid2>
                );
            })}
        </Grid2>
    );
}

const DATA = [
    {
        Icon: Diversity3Icon,
        header: 'Cam kết 100%',
        subheader: 'hàng chính hãng và chất lượng',
        pathname: null,
    },
    {
        Icon: LocalShippingIcon,
        header: 'Miễn phí vận chuyển',
        subheader: 'Xem chi tiết',
        pathname: '/chinh-sach-giao-hang',
    },
    {
        Icon: BoltIcon,
        header: 'Giao nhanh 4 giờ',
        subheader: 'Xem chi tiết',
        pathname: '/chinh-sach-giao-hang',
    },
    {
        Icon: HistoryIcon,
        header: 'Đổi trả linh hoạt',
        subheader: 'Xem chi tiết',
        pathname: '/chinh-sach-doi-tra-hang',
    },
];
