import { Fragment, isValidElement } from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography, Checkbox, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CustomAvatar, CustomHighlightText } from 'UI';

import useCustomColor from 'hooks/useCustomColor';

const StyledCheckbox = styled(Checkbox)(({ theme, ...props }) => {
    const { color } = props;
    let { main, dark } = useCustomColor(color);

    return {
        '&.MuiCheckbox-root': {
            color: 'text.primary',
            ':hover': { color: dark },
            '&.Mui-checked': {
                color: main,
            },
            '&.Mui-disabled': {
                color: 'disabled.light',
            },
        },
    };
});
const OptionContainer = styled(Stack)(({ theme }) => ({
    width: '100%',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
}));
/**
 * Renders a custom styled option template with optional highlighting.
 *
 * @component
 * @param {Object} props - Properties passed to the component
 * @param {string} props.label - The main label text to display
 * @param {string|null} [props.subLabel=null] - Additional subLabel text
 * @param {string} props.compareString - Keyword to highlight in the label
 * @param {ReactNode|null} [props.icon=null] - Optional icon to display
 * @param {ReactNode|string|null} [props.avatar=null] - Optional avatar image or component
 * @param {boolean} [props.displayCheckmark=false] - Whether to use the checkmark at the start
 * @param {boolean} [props.displayHighlight=true] - Whether to highlight matched characters in the label
 * @param {boolean} [props.disableGutters=false] - Whether to add padding left 12px in front of label
 * @param {boolean} [props.selected=false] - Whether the option is selected
 * @param {string} [props.checkboxProps={}] - props to customize the checkbox.
 *
 * @returns {JSX.Element} The rendered component.
 */

function OptionRow({
    label,
    subLabel = null,
    compareString,
    icon = null,
    avatar = null,
    displayCheckmark = false,
    displayHighlight = true,
    disableGutters = false,
    selected = false,
    checkboxProps = {
        color: 'primary',
    },
    ...otherProps
}) {
    const hasValidSubLabel =
        typeof subLabel === 'string' || (subLabel && isValidElement(subLabel));

    const LabelWrapper = label && subLabel ? Stack : Fragment;
    return (
        <OptionContainer
            direction="row"
            alignItems={avatar ? 'flex-start' : 'center'}
            spacing={1}>
            {displayCheckmark && (
                <StyledCheckbox
                    size="small"
                    checked={selected}
                    {...checkboxProps}
                />
            )}
            {icon && !avatar && (
                <IconWrapper>
                    <CustomAvatar>{icon}</CustomAvatar>
                </IconWrapper>
            )}
            {avatar &&
                !icon &&
                (typeof avatar === 'string' ? (
                    <CustomAvatar
                        avatarSize={100}
                        src={avatar}
                        alt="avatar-data-thumbnail"
                        sx={{ p: 1 }}
                    />
                ) : (
                    avatar
                ))}
            <LabelWrapper
                {...(label &&
                    subLabel && {
                        justifyContent: 'flex-start',
                        width: '100%',
                    })}>
                {label && (
                    <Box
                        sx={{
                            width: '100%',
                            pl: disableGutters
                                ? 0
                                : hasValidSubLabel ||
                                  displayCheckmark ||
                                  icon ||
                                  avatar
                                ? 0
                                : 1.5,
                        }}>
                        {displayHighlight ? (
                            <CustomHighlightText
                                label={label}
                                keyword={compareString}
                            />
                        ) : (
                            label
                        )}
                    </Box>
                )}
                {hasValidSubLabel &&
                    (typeof subLabel === 'string' ? (
                        <Typography
                            variant="caption"
                            sx={{
                                fontStyle: 'italic',
                                whiteSpace: 'normal',
                            }}>
                            {subLabel}
                        </Typography>
                    ) : (
                        subLabel
                    ))}
            </LabelWrapper>
        </OptionContainer>
    );
}
OptionRow.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.node,
    ]),
    subLabel: PropTypes.any,
    compareString: PropTypes.string,
    icon: PropTypes.node,
    avatar: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    displayCheckmark: PropTypes.bool,
    displayHighlight: PropTypes.bool,
    disableGutters: PropTypes.bool,
    selected: PropTypes.bool,
    checkboxProps: PropTypes.object,
};

OptionRow.defaultProps = {
    subLabel: null,
    icon: null,
    avatar: null,
    displayCheckmark: false,
    displayHighlight: true,
    disableGutters: false,
    selected: false,
    checkboxProps: {
        color: 'primary',
    },
};
export default OptionRow;
