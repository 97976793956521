import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Tabs, Tab } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

/**
 * CustomTabs is a wrapper around MUI's Tabs and Tab components, providing additional
 * configuration options for rendering tabs with a custom style and behavior.
 *
 * @param {Object} props - Component properties.
 * @param {string} [props.id] - An unique ID for the Tabs component.
 * @param {number | boolean} props.value - The currently selected tab index (controlled component).
 * @param {boolean} [props.centered=false] - If true, the tabs are centered. Otherwise, they are scrollable.
 * @param {Array<Object>} [props.options=[]] - Array of tab options where each object should include:
 *    - `label` (string | ReactNode): The label or content for the tab (required).
 *    - `onClick` (function, optional): Callback function triggered when the tab is clicked.
 *    - Additional props for the individual Tab component.
 * @param {function} props.onChange - Callback function triggered when the selected tab changes.
 * @param {SxProps<Theme>} [props.sx] - Additional styles to apply to the tabs.
 * @param {Object} [props.TabProps={}] - Additional props to pass to each Tab component.
 * @returns {JSX.Element} Rendered tabs component.
 */
function CustomTabs({
    id,
    value: tabValue,
    centered = false,
    options = [],
    onChange = () => {},
    sx = {},
    TabProps = {},
    ...otherProps
}) {
    const tabId = useMemo(
        () => id || Math.random().toString(36).slice(2, 11),
        [id]
    );

    return (
        <Tabs
            id={tabId}
            value={tabValue}
            onChange={onChange}
            {...(centered
                ? { centered: true }
                : { variant: 'scrollable', scrollButtons: false })}
            sx={(theme) => ({
                p: 0,
                '& .MuiTabs-indicator': {
                    backgroundColor: 'warning.main',
                    height: '3px',
                    borderRadius: '5px',
                },
                '& button.MuiTab-root': {
                    p: '12px 0px 8px 0px',
                    mx: 2,
                },
                ...(typeof sx === 'function' ? sx(theme) : sx),
            })}
            {...otherProps}>
            {options.map(
                ({ label, onClick = () => {}, ...otherTabProps }, idx) => {
                    return (
                        <Tab
                            id={id + idx}
                            key={idx}
                            label={label}
                            disableRipple
                            disableFocusRipple
                            onClick={onClick}
                            {...TabProps}
                            {...otherTabProps}
                        />
                    );
                }
            )}
        </Tabs>
    );
}

CustomTabs.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    centered: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
                .isRequired,
            onClick: PropTypes.func,
        })
    ),
    onChange: PropTypes.func.isRequired,
    sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    TabProps: PropTypes.object,
};

CustomTabs.defaultProps = {
    id: undefined,
    centered: false,
    options: [],
    onChange: () => {},
    sx: {},
    TabProps: {},
};

export default CustomTabs;
