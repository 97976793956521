import * as React from 'react';

import {
    Button,
    Box,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    Menu,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Tooltip,
    Slider,
    Grid2,
} from '@mui/material';
import {
    Title as TitleIcon,
    Vignette as VignetteIcon,
    Padding as PaddingIcon,
    TextRotationAngleup as TextRotationAngleupIcon,
    FormatListBulleted as FormatListBulletedIcon,
    FormatListNumbered as FormatListNumberedIcon,
    PlaylistRemove as PlaylistRemoveIcon,
    RemoveRoad as RemoveRoadIcon,
    GridOff as GridOffIcon,
    CancelPresentation as CancelPresentationIcon,
    TableRows as TableRowsIcon,
    ViewColumn as ViewColumnIcon,
    FiberNew as FiberNewIcon,
    Widgets as WidgetsIcon,
    FormatIndentIncrease as FormatIndentIncreaseIcon,
    FormatIndentDecrease as FormatIndentDecreaseIcon,
    BorderAll as BorderAllIcon,
    BorderColor as BorderColorIcon,
    LineWeight as LineWeightIcon,
    TextIncrease as TextIncreaseIcon,
    TextDecrease as TextDecreaseIcon,
    FormatLineSpacing as FormatLineSpacingIcon,
    FormatColorText as FormatColorTextIcon,
    FormatAlignLeft as FormatAlignLeftIcon,
    FormatAlignCenter as FormatAlignCenterIcon,
    FormatAlignRight as FormatAlignRightIcon,
    FormatAlignJustify as FormatAlignJustifyIcon,
    FormatBold as FormatBoldIcon,
    FormatItalic as FormatItalicIcon,
    FormatUnderlined as FormatUnderlinedIcon,
    FormatColorFill as FormatColorFillIcon,
    ArrowDropDown as ArrowDropDownIcon,
    SquareRounded as SquareRoundedIcon,
    Vaccines as VaccinesIcon,
    Spa as SpaIcon,
    Article as ArticleIcon,
    ResetTv as ResetTvIcon,
    DeleteSweep as DeleteSweepIcon,
    PlaylistAdd as PlaylistAddIcon,
    SwapHoriz as SwapHorizIcon,
    North as NorthIcon,
    South as SouthIcon,
    West as WestIcon,
    East as EastIcon,
    JoinLeft as JoinLeftIcon,
    JoinRight as JoinRightIcon,
    Rotate90DegreesCw as Rotate90DegreesCwIcon,
    Upgrade as UpgradeIcon,
    FullscreenExit as FullscreenExitIcon,
    ViewComfyOutlined as ViewComfyOutlinedIcon,
    TableChartOutlined as TableChartOutlinedIcon,
    ViewDayTwoTone as ViewDayTwoToneIcon,
    MoreVert as MoreVertIcon,
    Settings as SettingsIcon,
    CropSquare as CropSquareIcon,
    GridOn as GridOnIcon,
    ViewDay as ViewDayIcon,
    ModelTraining as ModelTrainingIcon,
    Window as WindowIcon,
    FormatColorReset as FormatColorResetIcon,
    PlaylistAddCheckRounded as PlaylistAddCheckRoundedIcon,
    Rule as RuleIcon,
    ImageOutlined as ImageOutlinedIcon,
    PictureInPictureOutlined as PictureInPictureOutlinedIcon,
    PivotTableChart as PivotTableChartIcon,
    LooksOne as LooksOneIcon,
    LooksTwo as LooksTwoIcon,
    Image as ImageIcon,
    LineStyle as LineStyleIcon,
    FormatQuote as FormatQuoteIcon,
    BorderOuter as BorderOuterIcon,
    BorderBottom as BorderBottomIcon,
    BorderTop as BorderTopIcon,
    BorderLeft as BorderLeftIcon,
    BorderRight as BorderRightIcon,
    BorderClear as BorderClearIcon,
} from '@mui/icons-material';

import { customScrollbar } from 'config/customCSS';

import { CustomDialog } from 'UI';
import { WarningDialog } from 'template';

function CustomMenuFilter(props) {
    const { anchorEl, setAnchorEl, menuItems, arialLabel } = props;

    return (
        <Menu
            anchorEl={anchorEl}
            id={arialLabel}
            open={Boolean(anchorEl)}
            onClose={() => {
                setAnchorEl(null);
            }}
            onClick={() => {
                setAnchorEl(null);
            }}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    backgroundColor: 'white',
                    color: 'black',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'white',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
                style: {
                    // maxHeight: 200,
                    minWidth: '50px',
                    ...customScrollbar,
                },
            }}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
            }}>
            {menuItems.map(({ value, label, onClick }, idx) => {
                return (
                    <MenuItem
                        dense
                        key={'CustomMenuFilter-menuItems' + label + '-' + idx}
                        onClick={() => {
                            setAnchorEl(null);
                            onClick();
                        }}
                        sx={{
                            '&.MuiMenuItem-root.Mui-selected': {
                                color: 'darkblue',
                                backgroundColor: 'aliceblue',
                            },
                            ':hover': {
                                backgroundColor: 'transparent',
                                ...(value !== 'default' && {
                                    boxShadow: 1,
                                    borderTop: '0.5px solid lightgrey',
                                }),
                            },
                            p: 0,
                            px: 2,
                        }}
                        selected={false}>
                        {label}
                    </MenuItem>
                );
            })}
        </Menu>
    );
}
const applyDefaultTemplate = (templateName) => {
    if (!templateName || !templateSample?.[templateName]) {
        return null;
    }
    return templateSample[templateName];
};
let idxValidator = (obj) => {
    if (!obj || !obj?.type) {
        return null;
    } else {
        if (obj?.type === 'list') {
            let { type, idxData, idxList } = obj ?? {
                type: 'list',
                idxData: null,
                idxList: null,
            };
            if (!(parseInt(idxData) >= 0) || !(parseInt(idxList) >= 0)) {
                return null;
            } else {
                return { type, idxData, idxList };
            }
        } else if (obj?.type === 'table') {
            let { type, idxData, rowKey, idxColumn } = obj ?? {
                type: 'table',
                idxData: null,
                rowKey: null,
                idxColumn: null,
            };
            if (
                !(parseInt(idxData) >= 0) ||
                !(parseInt(rowKey) >= 0) ||
                !(parseInt(idxColumn) >= 0)
            ) {
                return null;
            } else {
                return { type, idxData, rowKey, idxColumn };
            }
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(obj?.type)
        ) {
            let { type, idxData } = obj ?? {
                type: obj?.type,
                idxData: null,
            };
            if (!type || !(parseInt(idxData) >= 0)) {
                return;
            } else {
                return { type, idxData };
            }
        } else if (obj?.type === 'image') {
            let { type, idxData } = obj ?? {
                type: 'image',
                idxData: null,
            };
            if (!(parseInt(idxData) >= 0)) {
                return null;
            } else {
                return { type, idxData };
            }
        } else return null;
    }
};
let fnApplyTypographyProps = (obj, property, value) => {
    if (!obj?.typographyProps) {
        return {
            ...obj,
            typographyProps: {
                [property]: value,
            },
        };
    } else {
        let updatedTypographyProps = { ...obj.typographyProps };
        if (value === 'default') {
            if (updatedTypographyProps.hasOwnProperty(property)) {
                delete updatedTypographyProps[property];
            }
        } else {
            updatedTypographyProps[property] = value;
        }
        return {
            ...obj,
            typographyProps: updatedTypographyProps,
        };
    }
};
let fnApplyBoxProps = (obj, property, value) => {
    if (!obj?.boxProps) {
        return {
            ...obj,
            boxProps: {
                [property]: value,
            },
        };
    } else {
        let updatedBoxProps = { ...obj.boxProps };
        if (value === 'default') {
            if (updatedBoxProps.hasOwnProperty(property)) {
                delete updatedBoxProps[property];
            }
        } else {
            updatedBoxProps[property] = value;
        }
        return {
            ...obj,
            boxProps: updatedBoxProps,
        };
    }
};
let fnApplyImageProps = (obj, property, value) => {
    if (!obj?.imageProps) {
        return {
            ...obj,
            imageProps: {
                [property]: value,
            },
        };
    } else {
        let updatedImageProps = { ...obj.imageProps };
        if (value === 'default') {
            if (updatedImageProps.hasOwnProperty(property)) {
                delete updatedImageProps[property];
            }
        } else {
            updatedImageProps[property] = value;
        }
        return {
            ...obj,
            imageProps: updatedImageProps,
        };
    }
};
let formatLineInListContent = (
    data,
    idxData,
    idxList,
    newLineInListcontent
) => {
    return [].concat(
        data.slice(0, idxData),
        [
            {
                ...data[idxData],
                listcontent: [].concat(
                    data[idxData].listcontent.slice(0, idxList),
                    newLineInListcontent,
                    data[idxData].listcontent.slice(
                        idxList + 1,
                        data[idxData].listcontent.length
                    )
                ),
            },
        ],
        data.slice(idxData + 1, data.length)
    );
};
let formatAllLineInListcontent = (data, idxData, newListcontent) => {
    return [].concat(
        data.slice(0, idxData),
        {
            ...data[idxData],
            listcontent: newListcontent,
        },
        data.slice(idxData + 1, data.length)
    );
};
let formatOneCellInRow = (data, idxData, rowKey, idxColumn, newCell) => {
    let fnFormatRows = (allRows) => {
        let selectedRow = allRows[rowKey];

        let newRowData = [].concat(
            selectedRow.slice(0, idxColumn),
            newCell,
            selectedRow.slice(idxColumn + 1, selectedRow.length)
        );

        return { ...allRows, [rowKey]: newRowData };
    };
    return [].concat(
        data.slice(0, idxData),
        [
            {
                ...data[idxData],
                rows: {
                    ...fnFormatRows(data[idxData].rows),
                },
            },
        ],
        data.slice(idxData + 1, data.length)
    );
};
let formatAllCellInTable = (data, idxData, rowKey, newRow) => {
    return [].concat(
        data.slice(0, idxData),
        [
            {
                ...data[idxData],
                rows: { ...data[idxData].rows, [rowKey]: newRow },
            },
        ],
        data.slice(idxData + 1, data.length)
    );
};
let formatBoxInOtherType = (data, idxData, newData) => {
    return [].concat(
        data.slice(0, idxData),
        [newData],
        data.slice(idxData + 1, data.length)
    );
};

export default function CustomBoxTools(props) {
    const { details, setDetails, selectedBoxData } = props;
    const [tableMode, setTableMode] = React.useState('row');
    const [listMode, setListMode] = React.useState('list');
    const [imageMode, setImageMode] = React.useState(false);
    // alert dialog because of details has typed when click change template
    const [alertTemplateChangedDialog, setAlertTemplateChangedDialog] =
        React.useState(null);

    //open menu in tools that has many selections
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openFilterMenu = Boolean(anchorEl);
    const [arialLabel, setArialLabel] = React.useState('');
    const [menuItems, setMenuItems] = React.useState([]);

    // combo 1
    const handleDefaultFormat = async () => {
        if (!selectedBoxData || !selectedBoxData?.idxData) {
            return;
        } else {
            let { idxData } = selectedBoxData ?? { idxData: null };
            if (!!(parseInt(idxData) >= 0)) {
                return;
            } else {
                let fnApplyDefaultBox = (i) => {
                    if (i.type === 'list') {
                        return {
                            type: i.type,
                            listcontent: i.listcontent.map((k) => {
                                return {
                                    content: k.content,
                                };
                            }),
                        };
                    } else if (i.type === 'image') {
                        return {
                            type: i.type,
                            src: i.src,
                        };
                    } else if (i.type === 'table') {
                        let formatRowsFn = (allRows) => {
                            let newRows = {};
                            for (
                                let j = 0;
                                j < Object.keys(allRows).length;
                                j++
                            ) {
                                newRows[j] = allRows[
                                    Object.keys(allRows)[j]
                                ].map((x) => {
                                    return {
                                        content: x.content,
                                    };
                                });
                            }
                            return newRows;
                        };
                        return {
                            type: i.type,
                            rows: formatRowsFn(i.rows),
                        };
                    } else {
                        return {
                            type: i.type,
                            content: i.content,
                        };
                    }
                };
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...fnApplyDefaultBox(details[idxData]),
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            }
        }
    };
    const templateSampleToggleButton = (
        <Tooltip arrow title="Chọn theme theo mẫu" placement="top-start">
            <ToggleButton
                value="templateSample"
                sx={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 38,
                    minWidth: 80,
                    py: 0,
                    '&.Mui-disabled': {
                        border: 'none',
                    },
                }}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('choose-template-sample-menu');
                    setMenuItems([
                        {
                            value: 'drug',
                            label: (
                                <Typography
                                    key={'drug-template-sample-menu'}
                                    variant="caption"
                                    sx={{ fontSize: 14 }}>
                                    <VaccinesIcon
                                        sx={{
                                            mt: -1,
                                            mr: 1,
                                            width: 20,
                                            height: 20,
                                            color: 'darkseagreen',
                                        }}
                                    />
                                    Thuốc
                                </Typography>
                            ),
                            onClick: async () => {
                                if (details.length > 1) {
                                    setAlertTemplateChangedDialog('drug');
                                } else {
                                    let newDetails = await applyDefaultTemplate(
                                        'drug'
                                    );
                                    setDetails(newDetails);
                                }
                            },
                        },
                        {
                            value: 'otherProducts',
                            label: (
                                <Typography
                                    key={'otherProducts-template-sample-menu'}
                                    variant="caption"
                                    sx={{ fontSize: 14 }}>
                                    <SpaIcon
                                        sx={{
                                            mt: -1,
                                            mr: 1,
                                            width: 20,
                                            height: 20,
                                            color: 'darkseagreen',
                                        }}
                                    />
                                    Sản phẩm khác
                                </Typography>
                            ),
                            onClick: async () => {
                                if (details.length > 1) {
                                    setAlertTemplateChangedDialog(
                                        'otherProducts'
                                    );
                                } else {
                                    let newDetails = await applyDefaultTemplate(
                                        'otherProducts'
                                    );
                                    setDetails(newDetails);
                                }
                            },
                        },
                        {
                            value: 'article',
                            label: (
                                <Typography
                                    key={'article-template-sample-menu'}
                                    variant="caption"
                                    sx={{ fontSize: 14 }}>
                                    <ArticleIcon
                                        sx={{
                                            mt: -1,
                                            mr: 1,
                                            width: 20,
                                            height: 20,
                                            color: 'darkseagreen',
                                        }}
                                    />
                                    Bài viết sức khỏe
                                </Typography>
                            ),
                            onClick: async () => {
                                if (details.length > 1) {
                                    setAlertTemplateChangedDialog('article');
                                } else {
                                    let newDetails = await applyDefaultTemplate(
                                        'article'
                                    );
                                    setDetails(newDetails);
                                }
                            },
                        },
                    ]);
                }}
                aria-controls={
                    openFilterMenu ? 'choose-template-sample-menu' : undefined
                }
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="choose template sample">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <FiberNewIcon
                        sx={{
                            color: 'darkseagreen',
                        }}
                        fontSize="small"
                    />
                    <ArrowDropDownIcon fontSize="small" />
                </Box>
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: 8,
                        textTransform: 'initial',
                        color: 'black',
                    }}>
                    Theme mẫu
                </Typography>
            </ToggleButton>
        </Tooltip>
    );
    const handleChangeBoxSample = async (sample) => {
        if (!selectedBoxData || !selectedBoxData?.type) {
            return;
        } else {
            let { type, idxData } = selectedBoxData ?? {
                type: null,
                idxData: null,
            };
            if (!type || !(parseInt(idxData) >= 0)) {
                return;
            } else {
                if (type === 'table') {
                    if (sample === 'list') {
                        let res = await [].concat(
                            details.slice(0, idxData),
                            [
                                {
                                    type: sample,
                                    bulletPoint: 'circle',
                                    listcontent: Object.values(
                                        details[idxData].rows
                                    ).map((arr) => {
                                        return {
                                            content: arr
                                                .map((i) => i.content)
                                                .join('')
                                                .trim(),
                                        };
                                    }),
                                },
                            ],
                            details.slice(idxData + 1, details.length)
                        );
                        setDetails(res);
                    } else if (
                        ['quote', 'paragraph', 'header1', 'header2'].includes(
                            sample
                        )
                    ) {
                        let res = await [].concat(
                            details.slice(0, idxData),
                            [
                                {
                                    type: sample,
                                    content: Object.values(
                                        details[idxData].rows
                                    )
                                        .map((arr) =>
                                            arr
                                                .map((i) => i.content)
                                                .join('')
                                                .trim()
                                        )
                                        .join('\n'),
                                },
                            ],
                            details.slice(idxData + 1, details.length)
                        );
                        setDetails(res);
                    } else if (sample === 'image') {
                        let res = await [].concat(
                            details.slice(0, idxData + 1),
                            {
                                type: sample,
                                src: '',
                            },
                            {
                                type: 'paragraph',
                                content: '',
                                typographyProps: {
                                    textAlign: 'center',
                                    fontStyle: 'italic',
                                    fontSize: 14,
                                },
                            },
                            details.slice(idxData + 1, details.length)
                        );
                        setDetails(res);
                    } else {
                        return;
                    }
                } else if (type === 'list') {
                    if (sample === 'table') {
                        let res = await [].concat(
                            details.slice(0, idxData),
                            [
                                {
                                    type: sample,
                                    rows: Object.fromEntries(
                                        Array.from(
                                            {
                                                length: details[idxData]
                                                    .listcontent.length,
                                            },
                                            (x, i) => i
                                        ).map((key) => {
                                            return [
                                                key,
                                                [
                                                    details[idxData]
                                                        .listcontent[key],
                                                    { content: '' },
                                                ],
                                            ];
                                        })
                                    ),
                                },
                            ],
                            details.slice(idxData + 1, details.length)
                        );
                        setDetails(res);
                    } else if (sample === 'image') {
                        let res = await [].concat(
                            details.slice(0, idxData + 1),
                            {
                                type: sample,
                                src: '',
                            },
                            {
                                type: 'paragraph',
                                content: '',
                                typographyProps: {
                                    textAlign: 'center',
                                    fontStyle: 'italic',
                                    fontSize: 14,
                                },
                            },
                            details.slice(idxData + 1, details.length)
                        );
                        setDetails(res);
                    } else if (
                        ['quote', 'paragraph', 'header1', 'header2'].includes(
                            sample
                        )
                    ) {
                        let res = await [].concat(
                            details.slice(0, idxData),
                            [
                                {
                                    type: sample,
                                    content: details[idxData].listcontent
                                        .map((i) => i.content)
                                        .join('\n'),
                                },
                            ],
                            details.slice(idxData + 1, details.length)
                        );
                        setDetails(res);
                    } else {
                        return;
                    }
                } else if (type === 'image') {
                    let res = await [].concat(
                        details.slice(0, idxData + 1),
                        [
                            {
                                ...applyDefaultTemplate(sample),
                            },
                        ],
                        details.slice(idxData + 1, details.length)
                    );
                    setDetails(res);
                } else {
                    if (sample === 'table') {
                        let listRow = details[idxData].content.split('\n');
                        let res = await [].concat(
                            details.slice(0, idxData),
                            [
                                {
                                    type: sample,
                                    rows: Object.fromEntries(
                                        Array.from(
                                            {
                                                length: listRow.length + 1,
                                            },
                                            (x, i) => i
                                        ).map((key) => {
                                            return [
                                                key,
                                                [
                                                    {
                                                        content: listRow[key],
                                                    },
                                                    { content: '' },
                                                ],
                                            ];
                                        })
                                    ),
                                },
                            ],
                            details.slice(idxData + 1, details.length)
                        );
                        setDetails(res);
                    } else if (sample === 'list') {
                        let listcontent = details[idxData].content.split('\n');
                        let res = await [].concat(
                            details.slice(0, idxData),
                            [
                                {
                                    type: sample,
                                    bulletPoint: 'circle',
                                    listcontent: listcontent.map((i) => {
                                        return { content: i };
                                    }),
                                },
                            ],
                            details.slice(idxData + 1, details.length)
                        );
                        setDetails(res);
                    } else if (sample === 'image') {
                        let res = await [].concat(
                            details.slice(0, idxData + 1),
                            {
                                type: sample,
                                src: '',
                            },
                            {
                                type: 'paragraph',
                                content: '',
                                typographyProps: {
                                    textAlign: 'center',
                                    fontStyle: 'italic',
                                    fontSize: 14,
                                },
                            },
                            details.slice(idxData + 1, details.length)
                        );
                        setDetails(res);
                    } else if (type === sample) {
                        return;
                    } else {
                        let res = await [].concat(
                            details.slice(0, idxData),
                            [
                                {
                                    type: sample,
                                    content: details[idxData].content,
                                },
                            ],
                            details.slice(idxData + 1, details.length)
                        );
                        setDetails(res);
                    }
                }
            }
        }
    };
    const boxSampleToggleButton = (
        <Tooltip arrow title="Đổi loại khung" placement="top-start">
            <ToggleButton
                value="boxSample"
                sx={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 38,
                    minWidth: 80,
                    py: 0,
                    '&.Mui-disabled': {
                        border: 'none',
                    },
                }}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('choose-box-sample-menu');
                    setMenuItems(
                        [
                            'header1',
                            'header2',
                            'list',
                            'image',
                            'quote',
                            'paragraph',
                            'table',
                        ].map((sample, idx) => {
                            return {
                                value: sample,
                                label: (
                                    <Typography
                                        key={
                                            'choose-box-sample-menu' +
                                            sample +
                                            '-' +
                                            idx
                                        }
                                        variant="caption"
                                        sx={{ fontSize: 14 }}>
                                        {templateNameMap[sample].icon}
                                        {templateNameMap[sample].title}
                                    </Typography>
                                ),
                                onClick: () => {
                                    handleChangeBoxSample(sample);
                                },
                            };
                        })
                    );
                }}
                aria-controls={
                    openFilterMenu ? 'choose-box-sample-menu' : undefined
                }
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="choose box sample">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <WidgetsIcon
                        sx={{
                            color: 'darkseagreen',
                        }}
                        fontSize="small"
                    />
                    <ArrowDropDownIcon fontSize="small" />
                </Box>
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: 8,
                        textTransform: 'initial',
                        color: 'black',
                    }}>
                    Đổi loại khung
                </Typography>
            </ToggleButton>
        </Tooltip>
    );
    const formatSpecialToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={!selectedBoxData || !selectedBoxData?.type}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="format special toggle button">
            {['clearAll'].map((i, idx) => {
                const formatSpecialNameMap = {
                    clearAll: {
                        title: 'Reset',
                        icon: <ResetTvIcon fontSize="small" />,
                    },
                };

                return (
                    <Tooltip
                        arrow
                        title={formatSpecialNameMap[i].title}
                        placement="top"
                        key={'format special toggle button-' + i + '-' + idx}>
                        <ToggleButton
                            value={i}
                            aria-label={i + 'format special button'}
                            sx={{
                                ...customSxToggleButton,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={handleDefaultFormat}>
                            {formatSpecialNameMap[i].icon}
                            <Typography
                                variant="caption"
                                sx={{
                                    fontSize: 8,
                                    textTransform: 'initial',
                                    color: 'black',
                                }}>
                                {formatSpecialNameMap[i].title}
                            </Typography>
                        </ToggleButton>
                    </Tooltip>
                );
            })}
        </ToggleButtonGroup>
    );

    // combo 2: typographyProps
    const fnFormatTextAlign = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyTypographyProps(
                        details[idxData].listcontent[idxList],
                        'textAlign',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyTypographyProps(i, 'textAlign', value),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = await fnApplyTypographyProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'textAlign',
                    value
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        return {
                            ...fnApplyTypographyProps(cell, 'textAlign', value),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return {
                            ...fnApplyTypographyProps(cell, 'textAlign', value),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let newData = await fnApplyTypographyProps(
                details[idxData],
                'textAlign',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);

            setDetails(res);
        } else return;
    };
    const textAlignmentToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={selectedBoxData?.type === 'image'}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="text alignment toggle button">
            {['left', 'center', 'right', 'justify'].map((i, idx) => {
                const textAlignNameMap = {
                    left: {
                        title: 'Canh trái',
                        icon: <FormatAlignLeftIcon fontSize="small" />,
                    },
                    center: {
                        title: 'Canh giữa',
                        icon: <FormatAlignCenterIcon fontSize="small" />,
                    },
                    right: {
                        title: 'Canh phải',
                        icon: <FormatAlignRightIcon fontSize="small" />,
                    },
                    justify: {
                        title: 'Canh đều hai bên',
                        icon: <FormatAlignJustifyIcon fontSize="small" />,
                    },
                };
                return (
                    <Tooltip
                        arrow
                        title={textAlignNameMap[i].title}
                        placement="top"
                        key={'text align toggle button-' + i + '-' + idx}>
                        <ToggleButton
                            value={i}
                            aria-label={i + ' aligned button'}
                            sx={{ ...customSxToggleButton }}
                            onClick={() => fnFormatTextAlign(i)}>
                            {textAlignNameMap[i].icon}
                        </ToggleButton>
                    </Tooltip>
                );
            })}
        </ToggleButtonGroup>
    );
    const fnFormatLineHeight = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyTypographyProps(
                        details[idxData].listcontent[idxList],
                        'lineHeight',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyTypographyProps(i, 'lineHeight', value),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = await fnApplyTypographyProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'lineHeight',
                    value
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'lineHeight',
                                value
                            ),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'lineHeight',
                                value
                            ),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let newData = await fnApplyTypographyProps(
                details[idxData],
                'lineHeight',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);

            setDetails(res);
        } else return;
    };
    const lineHeightToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={selectedBoxData?.type === 'image'}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="line height toggle button">
            <Tooltip arrow title="Giãn dòng" placement="top">
                <ToggleButton
                    value="lineHeight"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('line-height-menu');
                        setMenuItems(
                            [
                                'default',
                                '1,0',
                                '1,15',
                                '1,5',
                                '2,0',
                                '2,5',
                                '3,0',
                            ].map((i) => {
                                const lineHeightNameMap = {
                                    default: {
                                        title: 'Mặc định',
                                        value: 'default',
                                    },
                                    '1,0': {
                                        title: '1,0',
                                        value: 1,
                                    },
                                    '1,15': {
                                        title: '1,15',
                                        value: 1.15,
                                    },
                                    '1,5': {
                                        title: '1,5',
                                        value: 1.5,
                                    },
                                    '2,0': {
                                        title: '2,0',
                                        value: 2,
                                    },
                                    '2,5': {
                                        title: '2,5',
                                        value: 2.5,
                                    },
                                    '3,0': {
                                        title: '3,0',
                                        value: 3,
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={
                                                'line height toggle button-' + i
                                            }
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}
                                            variant="caption">
                                            {lineHeightNameMap[i].title}
                                        </Typography>
                                    ),
                                    onClick: () =>
                                        fnFormatLineHeight(
                                            lineHeightNameMap[i].value
                                        ),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'line-height-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="line height button">
                    <FormatLineSpacingIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatFontSize = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let oldFontSize = Number(
                    details[idxData].listcontent[idxList]?.typographyProps
                        ?.fontSize ?? 15
                );
                let newFontSizeValue =
                    value === '+1'
                        ? Number(oldFontSize + 1)
                        : value === '-1'
                        ? Number(oldFontSize - 1)
                        : value;
                let updatedLineInListcontent = await {
                    ...fnApplyTypographyProps(
                        details[idxData].listcontent[idxList],
                        'fontSize',
                        newFontSizeValue
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng

                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        let oldFontSize = Number(
                            i?.typographyProps?.fontSize ?? 15
                        );
                        let newFontSizeValue =
                            value === '+1'
                                ? Number(oldFontSize + 1)
                                : value === '-1'
                                ? Number(oldFontSize - 1)
                                : value;
                        return {
                            ...fnApplyTypographyProps(
                                i,
                                'fontSize',
                                newFontSizeValue
                            ),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let oldFontSize = Number(
                    details[idxData].rows[rowKey][idxColumn]?.typographyProps
                        ?.fontSize ?? 15
                );
                let newFontSizeValue =
                    value === '+1'
                        ? Number(oldFontSize + 1)
                        : value === '-1'
                        ? Number(oldFontSize - 1)
                        : value;
                let updatedCell = await fnApplyTypographyProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'fontSize',
                    newFontSizeValue
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        let oldFontSize = Number(
                            cell?.typographyProps?.fontSize ?? 15
                        );
                        let newFontSizeValue =
                            value === '+1'
                                ? Number(oldFontSize + 1)
                                : value === '-1'
                                ? Number(oldFontSize - 1)
                                : value;
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'fontSize',
                                newFontSizeValue
                            ),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        let oldFontSize = Number(
                            cell?.typographyProps?.fontSize ?? 15
                        );
                        let newFontSizeValue =
                            value === '+1'
                                ? Number(oldFontSize + 1)
                                : value === '-1'
                                ? Number(oldFontSize - 1)
                                : value;
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'fontSize',
                                newFontSizeValue
                            ),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let defaultFontSize =
                type === 'header1' ? 19 : type === 'header2' ? 17 : 15;
            let oldFontSize = Number(
                details[idxData]?.typographyProps?.fontSize ?? defaultFontSize
            );
            let newFontSizeValue =
                value === '+1'
                    ? Number(oldFontSize + 1)
                    : value === '-1'
                    ? Number(oldFontSize - 1)
                    : value;
            let newData = await fnApplyTypographyProps(
                details[idxData],
                'fontSize',
                newFontSizeValue
            );
            let res = await formatBoxInOtherType(details, idxData, newData);
            setDetails(res);
        } else return;
    };
    const fontSizeToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            disabled={selectedBoxData?.type === 'image'}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="font size toggle button">
            {['increaseFontSize', 'decreaseFontSize'].map((i, idx) => {
                const fontSizeNameMap = {
                    increaseFontSize: {
                        title: 'Tăng kích thước chữ thêm 1 đơn vị',
                        icon: <TextIncreaseIcon fontSize="small" />,
                        value: '+1',
                    },
                    decreaseFontSize: {
                        title: 'Giảm kích thước chữ đi 1 đơn vị',
                        icon: <TextDecreaseIcon fontSize="small" />,
                        value: '-1',
                    },
                };
                return (
                    <Tooltip
                        arrow
                        title={fontSizeNameMap[i].title}
                        placement="top"
                        key={'font size toggle button-' + i + '-' + idx}>
                        <ToggleButton
                            value={i}
                            aria-label={i + ' button'}
                            sx={{
                                ...customSxToggleButton,
                            }}
                            onClick={() =>
                                fnFormatFontSize(fontSizeNameMap[i].value)
                            }>
                            {fontSizeNameMap[i].icon}
                        </ToggleButton>
                    </Tooltip>
                );
            })}
            <ToggleButton
                value="fontSize"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('fontSize-menu');
                    setMenuItems(
                        ['default', 11, 12, 14, 16, 18, 20, 24, 28, 32].map(
                            (i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'fontSize-menu-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default' ? 'Mặc định' : i}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatFontSize(i),
                                };
                            }
                        )
                    );
                }}
                sx={{
                    ...customSxToggleButton,
                    minWidth: 20,
                }}
                aria-controls={openFilterMenu ? 'fontSize-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="font size button">
                <ArrowDropDownIcon fontSize="small" />
            </ToggleButton>
        </ToggleButtonGroup>
    );
    const fnFormatBold = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyTypographyProps(
                        details[idxData].listcontent[idxList],
                        'fontWeight',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyTypographyProps(i, 'fontWeight', value),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = await fnApplyTypographyProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'fontWeight',
                    value
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'fontWeight',
                                value
                            ),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'fontWeight',
                                value
                            ),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let newData = await fnApplyTypographyProps(
                details[idxData],
                'fontWeight',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);

            setDetails(res);
        } else return;
    };
    const boldToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={selectedBoxData?.type === 'image'}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="bold toggle button">
            <Tooltip arrow title="In đậm" placement="top">
                <ToggleButton
                    value="bold"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('bold-menu');
                        setMenuItems(
                            ['default', 300, 500, 700, 900].map((i) => {
                                const boldNameMap = {
                                    default: {
                                        title: 'Mặc định',
                                        value: 'default',
                                    },
                                    300: {
                                        title: 'Mỏng',
                                        value: 300,
                                    },
                                    500: {
                                        title: 'Dày',
                                        value: 500,
                                    },
                                    700: {
                                        title: 'Hơi dày',
                                        value: 700,
                                    },
                                    900: {
                                        title: 'Rất dày',
                                        value: 900,
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'bold toggle button-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 15,
                                                fontWeight:
                                                    boldNameMap[i].value ===
                                                    'default'
                                                        ? 400
                                                        : boldNameMap[i].value,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {boldNameMap[i].title}
                                        </Typography>
                                    ),
                                    onClick: () =>
                                        fnFormatBold(boldNameMap[i].value),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={openFilterMenu ? 'bold-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="bold button">
                    <FormatBoldIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatItalic = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyTypographyProps(
                        details[idxData].listcontent[idxList],
                        'fontStyle',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyTypographyProps(i, 'fontStyle', value),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = await fnApplyTypographyProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'fontStyle',
                    value
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        return {
                            ...fnApplyTypographyProps(cell, 'fontStyle', value),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return {
                            ...fnApplyTypographyProps(cell, 'fontStyle', value),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let newData = await fnApplyTypographyProps(
                details[idxData],
                'fontStyle',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);

            setDetails(res);
        } else return;
    };
    const italicToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={selectedBoxData?.type === 'image'}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="italic toggle button">
            <Tooltip arrow title="In nghiêng" placement="top">
                <ToggleButton
                    value="italic"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('italic-menu');
                        setMenuItems(
                            ['default', 'italic'].map((i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'italic toggle button-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 15,
                                                fontStyle:
                                                    i === 'default'
                                                        ? 'normal'
                                                        : 'italic',
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default'
                                                ? 'Mặc định'
                                                : 'In nghiêng'}
                                        </Typography>
                                    ),
                                    onClick: () =>
                                        fnFormatItalic(
                                            i === 'default'
                                                ? 'normal'
                                                : 'italic'
                                        ),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={openFilterMenu ? 'italic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="italic button">
                    <FormatItalicIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatUnderline = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyTypographyProps(
                        details[idxData].listcontent[idxList],
                        'textDecoration',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyTypographyProps(
                                i,
                                'textDecoration',
                                value
                            ),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = await fnApplyTypographyProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'textDecoration',
                    value
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'textDecoration',
                                value
                            ),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'textDecoration',
                                value
                            ),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let newData = await fnApplyTypographyProps(
                details[idxData],
                'textDecoration',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);

            setDetails(res);
        } else return;
    };
    const underlineToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={selectedBoxData?.type === 'image'}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="underline toggle button">
            <Tooltip arrow title="Gạch dưới" placement="top">
                <ToggleButton
                    value="underline"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('underline-menu');
                        setMenuItems(
                            [
                                'default',
                                'overline',
                                'underline',
                                'line-through',
                                'underline overline',
                            ].map((i) => {
                                const underlineNameMap = {
                                    default: {
                                        title: 'Mặc định',
                                        value: 'default',
                                    },
                                    overline: {
                                        title: 'Gạch trên',
                                        value: 'overline',
                                    },
                                    underline: {
                                        title: 'Gạch dưới',
                                        value: 'underline',
                                    },
                                    'line-through': {
                                        title: 'Gạch ngang',
                                        value: 'line-through',
                                    },
                                    'underline overline': {
                                        title: 'Gạch trên và dưới',
                                        value: 'underline overline',
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'underline toggle button-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 15,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                                textDecoration:
                                                    underlineNameMap[i]
                                                        .value === 'default'
                                                        ? 'none'
                                                        : underlineNameMap[i]
                                                              .value,
                                            }}>
                                            {underlineNameMap[i].title}
                                        </Typography>
                                    ),
                                    onClick: () =>
                                        fnFormatUnderline(
                                            underlineNameMap[i].value
                                        ),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'underline-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="underline button">
                    <FormatUnderlinedIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatTextColor = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyTypographyProps(
                        details[idxData].listcontent[idxList],
                        'color',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyTypographyProps(i, 'color', value),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = await fnApplyTypographyProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'color',
                    value
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        return {
                            ...fnApplyTypographyProps(cell, 'color', value),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return {
                            ...fnApplyTypographyProps(cell, 'color', value),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let newData = await fnApplyTypographyProps(
                details[idxData],
                'color',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);

            setDetails(res);
        } else return;
    };
    const textColorToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={selectedBoxData?.type === 'image'}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="text color toggle button">
            <Tooltip arrow title="Màu chữ" placement="top">
                <ToggleButton
                    value="textColor"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('text-color-menu');
                        setMenuItems(
                            ['default']
                                .concat(Object.keys(colorNameMap))
                                .map((color) => {
                                    return {
                                        value: color,
                                        label:
                                            color === 'default' ? (
                                                <Typography
                                                    key={
                                                        'text-color-menu-default'
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...defaultBoxCSS,
                                                    }}>
                                                    Mặc định
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    key={
                                                        'text-color-menu-' +
                                                        color
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...(color ===
                                                            'default' &&
                                                            defaultBoxCSS),
                                                    }}>
                                                    <SquareRoundedIcon
                                                        sx={{
                                                            width:
                                                                color ===
                                                                'white'
                                                                    ? 15
                                                                    : 20,
                                                            height:
                                                                color ===
                                                                'white'
                                                                    ? 15
                                                                    : 20,
                                                            ml:
                                                                color ===
                                                                'white'
                                                                    ? '3px'
                                                                    : 0,
                                                            borderRadius:
                                                                color ===
                                                                'white'
                                                                    ? '1px'
                                                                    : 'none',
                                                            mr: 0.5,
                                                            color: color,
                                                            border:
                                                                color ===
                                                                'white'
                                                                    ? '0.25px solid grey'
                                                                    : 'none',
                                                        }}
                                                    />
                                                    {colorNameMap[color]}
                                                </Typography>
                                            ),
                                        onClick: () => fnFormatTextColor(color),
                                    };
                                })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'text-color-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="text color button">
                    <FormatColorTextIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatTextRotation = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyTypographyProps(
                        details[idxData].listcontent[idxList],
                        'transform',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyTypographyProps(i, 'transform', value),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = await fnApplyTypographyProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'transform',
                    value
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        return {
                            ...fnApplyTypographyProps(cell, 'transform', value),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return {
                            ...fnApplyTypographyProps(cell, 'transform', value),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (
            ['header1', 'header2', 'paragraph', 'quote', 'image'].includes(type)
        ) {
            let { idxData } = check;
            let newData = await fnApplyTypographyProps(
                details[idxData],
                'transform',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);

            setDetails(res);
        } else return;
    };
    const textRotationToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={selectedBoxData?.type === 'image'}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="text rotation toggle button">
            <Tooltip
                arrow
                title="Xoay chữ theo chiều kim đồng hồ"
                placement="top">
                <ToggleButton
                    value="textRotation"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('text-rotation-menu');
                        setMenuItems(
                            [
                                'default',
                                45,
                                90,
                                135,
                                180,
                                225,
                                270,
                                315,
                                360,
                            ].map((i) => {
                                const textRotationNameMap = {
                                    default: {
                                        title: 'Mặc định',
                                        value: 'default',
                                    },
                                    45: {
                                        title: '45 độ',
                                        value: 'rotate(45deg)',
                                    },
                                    90: {
                                        title: '90 độ',
                                        value: 'rotate(90deg)',
                                    },
                                    135: {
                                        title: '135 độ',
                                        value: 'rotate(135deg)',
                                    },
                                    180: {
                                        title: '180 độ',
                                        value: 'rotate(180deg)',
                                    },
                                    225: {
                                        title: '225 độ',
                                        value: 'rotate(225deg)',
                                    },
                                    270: {
                                        title: '270 độ',
                                        value: 'rotate(270deg)',
                                    },
                                    315: {
                                        title: '315 độ',
                                        value: 'rotate(315deg)',
                                    },
                                    360: {
                                        title: '360 độ',
                                        value: 'rotate(360deg)',
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'text-rotation-button-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 15,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {textRotationNameMap[i].title}
                                        </Typography>
                                    ),
                                    onClick: () =>
                                        fnFormatTextRotation(
                                            textRotationNameMap[i].value
                                        ),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'text-rotation-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="text rotation button">
                    <TextRotationAngleupIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatTextTransform = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyTypographyProps(
                        details[idxData].listcontent[idxList],
                        'textTransform',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyTypographyProps(
                                i,
                                'textTransform',
                                value
                            ),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = await fnApplyTypographyProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'textTransform',
                    value
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'textTransform',
                                value
                            ),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'textTransform',
                                value
                            ),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let newData = await fnApplyTypographyProps(
                details[idxData],
                'textTransform',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);

            setDetails(res);
        } else return;
    };
    const textTransformToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={selectedBoxData?.type === 'image'}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="text transform toggle button">
            <Tooltip arrow title="Kiểu chữ" placement="top">
                <ToggleButton
                    value="textTransform"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('text-transform-menu');
                        setMenuItems(
                            [
                                'default',
                                'uppercase',
                                'lowercase',
                                'capitalize',
                            ].map((i) => {
                                const textTransformNameMap = {
                                    default: {
                                        title: 'Mặc định',
                                        value: 'default',
                                    },
                                    uppercase: {
                                        title: 'CHỮ IN HOA',
                                        value: 'uppercase',
                                    },
                                    lowercase: {
                                        title: 'chữ in thường',
                                        value: 'lowercase',
                                    },
                                    capitalize: {
                                        title: 'Chữ In Đầu Mỗi Ký Tự',
                                        value: 'capitalize',
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={
                                                'text transform toggle button-' +
                                                i
                                            }
                                            variant="caption"
                                            sx={{
                                                fontSize: 15,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                                textTransform:
                                                    textTransformNameMap[i]
                                                        .value === 'default'
                                                        ? 'initial'
                                                        : textTransformNameMap[
                                                              i
                                                          ].value,
                                            }}>
                                            {textTransformNameMap[i].title}
                                        </Typography>
                                    ),
                                    onClick: () =>
                                        fnFormatTextTransform(
                                            textTransformNameMap[i].value
                                        ),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'text-transform-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="text transform button">
                    <TitleIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatTextIndent = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let oldTextIndentValue = Number(
                    `${
                        details[idxData].listcontent[idxList]?.typographyProps
                            ?.textIndent ?? '0px'
                    }`
                        .toString()
                        .replace('px', '')
                );
                let newTextIndentValue =
                    value === 'more'
                        ? `${Number(oldTextIndentValue + 10)}px`
                        : value === 'less'
                        ? `${Number(oldTextIndentValue - 10)}px`
                        : value;

                let updatedLineInListcontent = await {
                    ...fnApplyTypographyProps(
                        details[idxData].listcontent[idxList],
                        'textIndent',
                        newTextIndentValue
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng

                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        let oldTextIndentValue = Number(
                            `${i?.typographyProps?.textIndent ?? '0px'}`
                                .toString()
                                .replace('px', '')
                        );
                        let newTextIndentValue =
                            value === 'more'
                                ? `${Number(oldTextIndentValue + 10)}px`
                                : value === 'less'
                                ? `${Number(oldTextIndentValue - 10)}px`
                                : value;

                        return {
                            ...fnApplyTypographyProps(
                                i,
                                'textIndent',
                                newTextIndentValue
                            ),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let oldTextIndentValue = Number(
                    `${
                        details[idxData].rows[rowKey][idxColumn]
                            ?.typographyProps?.textIndent ?? '0px'
                    }`
                        .toString()
                        .replace('px', '')
                );
                let newTextIndentValue =
                    value === 'more'
                        ? `${Number(oldTextIndentValue + 10)}px`
                        : value === 'less'
                        ? `${Number(oldTextIndentValue - 10)}px`
                        : value;

                let updatedCell = await fnApplyTypographyProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'textIndent',
                    newTextIndentValue
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        let oldTextIndentValue = Number(
                            `${cell?.typographyProps?.textIndent ?? '0px'}`
                                .toString()
                                .replace('px', '')
                        );
                        let newTextIndentValue =
                            value === 'more'
                                ? `${Number(oldTextIndentValue + 10)}px`
                                : value === 'less'
                                ? `${Number(oldTextIndentValue - 10)}px`
                                : value;
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'textIndent',
                                newTextIndentValue
                            ),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        let oldTextIndentValue = Number(
                            `${cell?.typographyProps?.textIndent ?? '0px'}`
                                .toString()
                                .replace('px', '')
                        );
                        let newTextIndentValue =
                            value === 'more'
                                ? `${Number(oldTextIndentValue + 10)}px`
                                : value === 'less'
                                ? `${Number(oldTextIndentValue - 10)}px`
                                : value;
                        return {
                            ...fnApplyTypographyProps(
                                cell,
                                'textIndent',
                                newTextIndentValue
                            ),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let oldTextIndentValue = Number(
                `${details[idxData]?.typographyProps?.textIndent ?? '0px'}`
                    .toString()
                    .replace('px', '')
            );
            let newTextIndentValue =
                value === 'more'
                    ? `${Number(oldTextIndentValue + 10)}px`
                    : value === 'less'
                    ? `${Number(oldTextIndentValue - 10)}px`
                    : value;

            let newData = await fnApplyTypographyProps(
                details[idxData],
                'textIndent',
                newTextIndentValue
            );
            let res = await formatBoxInOtherType(details, idxData, newData);
            setDetails(res);
        } else return;
    };
    const textIndentToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            disabled={selectedBoxData?.type === 'image'}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="text indent toggle button">
            {['increaseTextIndent', 'decreaseTextIndent'].map((i, idx) => {
                const textIndentNameMap = {
                    increaseTextIndent: {
                        title: 'Thụt vào đầu dòng thêm 10px',
                        icon: <FormatIndentIncreaseIcon fontSize="small" />,
                        value: 'more',
                    },
                    decreaseTextIndent: {
                        title: 'Thụt ra đầu dòng đi 10px',
                        icon: <FormatIndentDecreaseIcon fontSize="small" />,
                        value: 'less',
                    },
                };
                return (
                    <Tooltip
                        arrow
                        title={textIndentNameMap[i].title}
                        placement="top"
                        key={'text indent toggle button-' + i + '-' + idx}>
                        <ToggleButton
                            value={i}
                            aria-label={i + ' button'}
                            sx={{
                                ...customSxToggleButton,
                            }}
                            onClick={() =>
                                fnFormatTextIndent(textIndentNameMap[i].value)
                            }>
                            {textIndentNameMap[i].icon}
                        </ToggleButton>
                    </Tooltip>
                );
            })}
            <ToggleButton
                value="textIndent"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('textIndent-menu');
                    setMenuItems(
                        [
                            'default',
                            '10px',
                            '20px',
                            '50px',
                            '80px',
                            '100px',
                            '150px',
                            '200px',
                            '300px',
                        ].map((i) => {
                            return {
                                value: i,
                                label: (
                                    <Typography
                                        key={'textIndent-menu-' + i}
                                        variant="caption"
                                        sx={{
                                            fontSize: 13,
                                            ...(i === 'default' &&
                                                defaultBoxCSS),
                                        }}>
                                        {i === 'default' ? 'Mặc định' : i}
                                    </Typography>
                                ),
                                onClick: () => fnFormatTextIndent(i),
                            };
                        })
                    );
                }}
                sx={{
                    ...customSxToggleButton,
                    minWidth: 20,
                }}
                aria-controls={openFilterMenu ? 'textIndent-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="text indent button">
                <ArrowDropDownIcon fontSize="small" />
            </ToggleButton>
        </ToggleButtonGroup>
    );

    // combo 3: boxProps
    const fnFormatBoxDefaultSetting = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (
            type === 'quote' ||
            type === 'header1' ||
            type === 'header2' ||
            type === 'paragraph'
        ) {
            if (value === 'formatClear') {
                let { idxData } = check;
                let newData = { ...details[idxData] };
                if (newData.hasOwnProperty('boxProps')) {
                    delete newData.boxProps;
                }
                if (newData.hasOwnProperty('typographyProps')) {
                    delete newData.typographyProps;
                }
                let res = await formatBoxInOtherType(details, idxData, newData);

                setDetails(res);
            } else return;
        } else return;
    };
    const boxDefaultSettingToggleButton = (
        <Tooltip arrow title="Khôi phục cài đặt mặc định" placement="top">
            <ToggleButton
                value="boxDefaultSetting"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('box-default-setting-menu');
                    setMenuItems(
                        ['formatClear'].map((i) => {
                            const boxDefaultSettingNameMap = {
                                formatClear: {
                                    title: 'Bỏ các định dạng đang áp dụng',
                                    icon: (
                                        <FormatColorResetIcon fontSize="small" />
                                    ),
                                },
                            };
                            return {
                                value: i,
                                label: (
                                    <Box
                                        key={'box-default-setting-menu' + i}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        {boxDefaultSettingNameMap[i].icon}
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                ml: 1,
                                                fontSize: 14,
                                            }}>
                                            {boxDefaultSettingNameMap[i].title}
                                        </Typography>
                                    </Box>
                                ),
                                onClick: () => fnFormatBoxDefaultSetting(i),
                            };
                        })
                    );
                }}
                sx={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 38,
                    minWidth: 60,
                    p: 0,
                    '&.Mui-disabled': {
                        border: 'none',
                    },
                }}
                disabled={
                    !['quote', 'paragraph', 'header1', 'header2'].includes(
                        selectedBoxData?.type
                    ) || !selectedBoxData
                }
                aria-controls={
                    openFilterMenu ? 'box-default-setting-menu' : undefined
                }
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="box default setting button">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <SettingsIcon
                        sx={{
                            color:
                                ![
                                    'quote',
                                    'paragraph',
                                    'header1',
                                    'header2',
                                ].includes(selectedBoxData?.type) ||
                                !selectedBoxData
                                    ? 'lightgrey'
                                    : 'grey',
                        }}
                        fontSize="small"
                    />
                    <MoreVertIcon fontSize="small" />
                </Box>
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: 8,
                        textTransform: 'initial',
                        fontWeight: 600,
                        color:
                            ![
                                'quote',
                                'paragraph',
                                'header1',
                                'header2',
                            ].includes(selectedBoxData?.type) ||
                            !selectedBoxData
                                ? 'lightgrey'
                                : 'grey',
                    }}>
                    Cài đặt gốc
                </Typography>
            </ToggleButton>
        </Tooltip>
    );
    const fnFormatBoxRotation = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { idxData } = check;
        let newData = await fnApplyBoxProps(
            details[idxData],
            'transform',
            value
        );
        let res = await formatBoxInOtherType(details, idxData, newData);

        setDetails(res);
    };
    const boxRotationToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="box rotation toggle button">
            <Tooltip
                arrow
                title="Xoay khung theo chiều kim đồng hồ"
                placement="top">
                <ToggleButton
                    value="boxRotation"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('box-rotation-menu');
                        setMenuItems(
                            [
                                'default',
                                45,
                                90,
                                135,
                                180,
                                225,
                                270,
                                315,
                                360,
                            ].map((i) => {
                                const boxRotationNameMap = {
                                    default: {
                                        title: 'Mặc định',
                                        value: 'default',
                                    },
                                    45: {
                                        title: '45 độ',
                                        value: 'rotate(45deg)',
                                    },
                                    90: {
                                        title: '90 độ',
                                        value: 'rotate(90deg)',
                                    },
                                    135: {
                                        title: '135 độ',
                                        value: 'rotate(135deg)',
                                    },
                                    180: {
                                        title: '180 độ',
                                        value: 'rotate(180deg)',
                                    },
                                    225: {
                                        title: '225 độ',
                                        value: 'rotate(225deg)',
                                    },
                                    270: {
                                        title: '270 độ',
                                        value: 'rotate(270deg)',
                                    },
                                    315: {
                                        title: '315 độ',
                                        value: 'rotate(315deg)',
                                    },
                                    360: {
                                        title: '360 độ',
                                        value: 'rotate(360deg)',
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'box-rotation-button-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 15,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {boxRotationNameMap[i].title}
                                        </Typography>
                                    ),
                                    onClick: () =>
                                        fnFormatBoxRotation(
                                            boxRotationNameMap[i].value
                                        ),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'box-rotation-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="box rotation button">
                    <Rotate90DegreesCwIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatBoxPadding = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { idxData } = check;
        let newData = await fnApplyBoxProps(details[idxData], 'padding', value);
        let res = await formatBoxInOtherType(details, idxData, newData);

        setDetails(res);
    };
    const boxPaddingToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="box padding toggle button">
            <Tooltip
                arrow
                title="Khoảng cách giữa nội dung và khung"
                placement="top">
                <ToggleButton
                    value="boxPadding"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('box-padding-menu');
                        setMenuItems(
                            [
                                'default',
                                '10px',
                                '20px',
                                '30px',
                                '50px',
                                '60px',
                                '80px',
                                '100px',
                                '120px',
                                '150px',
                                '200px',
                                '250px',
                                '300px',
                            ].map((i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'box-padding-menu-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default' ? 'Mặc định' : i}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatBoxPadding(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'box-padding-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="box padding button">
                    <PaddingIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatBoxMarginTop = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { idxData } = check;
        let newData = await fnApplyBoxProps(
            details[idxData],
            'marginTop',
            value
        );
        let res = await formatBoxInOtherType(details, idxData, newData);

        setDetails(res);
    };
    const boxMarginTopToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="box margin top toggle button">
            <Tooltip
                arrow
                title="Khoảng cách so với khung phía trên"
                placement="top">
                <ToggleButton
                    value="boxMagrinTop"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('box-margin-top-menu');
                        setMenuItems(
                            [
                                'default',
                                '10px',
                                '20px',
                                '30px',
                                '50px',
                                '60px',
                                '80px',
                                '100px',
                                '120px',
                                '150px',
                                '200px',
                                '250px',
                                '300px',
                            ].map((i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'box-margin-top-menu-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default' ? 'Mặc định' : i}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatBoxMarginTop(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'box-margin-top-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="box margin top button">
                    <UpgradeIcon
                        fontSize="small"
                        sx={{
                            width: 20,
                            height: 20,
                            borderTop: '2px solid grey',
                            borderRadius: '2px',
                        }}
                    />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatBoxBackgroundColor = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { idxData } = check;
        let newData = await fnApplyBoxProps(
            details[idxData],
            'backgroundColor',
            value
        );
        let res = await formatBoxInOtherType(details, idxData, newData);

        setDetails(res);
    };
    const boxBackgroundColorToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="box background color toggle button">
            <Tooltip arrow title="Màu nền khung" placement="top">
                <ToggleButton
                    value="boxBackgroundColor"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('boxBackground-color-menu');
                        setMenuItems(
                            ['default']
                                .concat(Object.keys(colorNameMap))
                                .map((color) => {
                                    return {
                                        value: color,
                                        label:
                                            color === 'default' ? (
                                                <Typography
                                                    key={
                                                        'box-background-color-menu-default'
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...defaultBoxCSS,
                                                    }}>
                                                    Mặc định
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    key={
                                                        'box-background-color-menu-' +
                                                        color
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...(color ===
                                                            'default' &&
                                                            defaultBoxCSS),
                                                    }}>
                                                    <SquareRoundedIcon
                                                        sx={{
                                                            width:
                                                                color ===
                                                                'white'
                                                                    ? 15
                                                                    : 20,
                                                            height:
                                                                color ===
                                                                'white'
                                                                    ? 15
                                                                    : 20,
                                                            ml:
                                                                color ===
                                                                'white'
                                                                    ? '3px'
                                                                    : 0,
                                                            borderRadius:
                                                                color ===
                                                                'white'
                                                                    ? '1px'
                                                                    : 'none',
                                                            mr: 0.5,
                                                            color: color,
                                                            border:
                                                                color ===
                                                                'white'
                                                                    ? '0.25px solid grey'
                                                                    : 'none',
                                                        }}
                                                    />
                                                    {colorNameMap[color]}
                                                </Typography>
                                            ),
                                        onClick: () =>
                                            fnFormatBoxBackgroundColor(color),
                                    };
                                })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'box-background-color-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="box background color button">
                    <FormatColorFillIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );

    const [openBorderFormattingDialog, setOpenBorderFormattingDialog] =
        React.useState(false);

    const borderFormattingToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="border formatting toggle button">
            {['borderFormatting'].map((i, idx) => {
                const borderFormattingNameMap = {
                    borderFormatting: {
                        title: 'Định dạng viền và bo góc',
                        icon: <BorderAllIcon fontSize="small" />,
                    },
                };
                return (
                    <Tooltip
                        arrow
                        title={borderFormattingNameMap[i].title}
                        placement="top"
                        key={'borderFormatting-' + i + '-' + idx}>
                        <ToggleButton
                            value={i}
                            aria-label={i + ' button'}
                            sx={{ ...customSxToggleButton }}
                            onClick={() => {
                                setBorderSideValue([
                                    {
                                        name: 'borderTop',
                                        style: 'solid',
                                        width: '1px',
                                        color: 'black',
                                        isChosen: false,
                                    },
                                    {
                                        name: 'borderBottom',
                                        style: 'solid',
                                        width: '1px',
                                        color: 'black',
                                        isChosen: false,
                                    },
                                    {
                                        name: 'borderLeft',
                                        style: 'solid',
                                        width: '1px',
                                        color: 'black',
                                        isChosen: false,
                                    },
                                    {
                                        name: 'borderRight',
                                        style: 'solid',
                                        width: '1px',
                                        color: 'black',
                                        isChosen: false,
                                    },
                                ]);
                                setBorderRadiusValue([
                                    {
                                        name: 'borderTopLeftRadius',
                                        width: '1px',
                                        isChosen: false,
                                    },
                                    {
                                        name: 'borderTopRightRadius',
                                        width: '1px',
                                        isChosen: false,
                                    },
                                    {
                                        name: 'borderBottomLeftRadius',
                                        width: '1px',
                                        isChosen: false,
                                    },
                                    {
                                        name: 'borderBottomRightRadius',
                                        width: '1px',
                                        isChosen: false,
                                    },
                                ]);
                                setBorderSideHasChosen(['default']);
                                setBorderRadiusHasChosen(['default']);
                                setOpenBorderFormattingDialog(true);
                            }}>
                            {borderFormattingNameMap[i].icon}
                        </ToggleButton>
                    </Tooltip>
                );
            })}
        </ToggleButtonGroup>
    );

    const [borderSideValue, setBorderSideValue] = React.useState([
        {
            name: 'borderTop',
            style: 'solid',
            width: '1px',
            color: 'black',
            isChosen: false,
        },
        {
            name: 'borderBottom',
            style: 'solid',
            width: '1px',
            color: 'black',
            isChosen: false,
        },
        {
            name: 'borderLeft',
            style: 'solid',
            width: '1px',
            color: 'black',
            isChosen: false,
        },
        {
            name: 'borderRight',
            style: 'solid',
            width: '1px',
            color: 'black',
            isChosen: false,
        },
    ]);
    const [borderRadiusValue, setBorderRadiusValue] = React.useState([
        { name: 'borderTopLeftRadius', width: '1px', isChosen: false },
        { name: 'borderTopRightRadius', width: '1px', isChosen: false },
        { name: 'borderBottomLeftRadius', width: '1px', isChosen: false },
        { name: 'borderBottomRightRadius', width: '1px', isChosen: false },
    ]);
    const [borderSideHasChosen, setBorderSideHasChosen] = React.useState([
        'default',
    ]);
    const [borderRadiusHasChosen, setBorderRadiusHasChosen] = React.useState([
        'default',
    ]);
    const handleApplyFormatBorder = async () => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        let x = borderSideValue.map((i) => {
            return {
                isChosen: i.isChosen,
                name: i.name,
                value: i.width + ' ' + i.style + ' ' + i.color,
            };
        });
        let y = borderRadiusValue.map((i) => {
            return {
                isChosen: i.isChosen,
                name: i.name,
                value: i.width,
            };
        });
        let z = [].concat(x, y);

        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent =
                    details[idxData].listcontent[idxList];
                for (let index = 0; index < z.length; index++) {
                    const elem = z[index];

                    updatedLineInListcontent = await {
                        ...fnApplyBoxProps(
                            updatedLineInListcontent,
                            elem.name,
                            elem.isChosen ? elem.value : 'default'
                        ),
                    };
                }

                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'line') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        let updatedLineInListcontent = i;
                        for (let index = 0; index < z.length; index++) {
                            const elem = z[index];
                            updatedLineInListcontent = {
                                ...fnApplyBoxProps(
                                    updatedLineInListcontent,
                                    elem.name,
                                    elem.isChosen ? elem.value : 'default'
                                ),
                            };
                        }
                        return updatedLineInListcontent;
                    }
                );

                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = details[idxData].rows[rowKey][idxColumn];
                for (let index = 0; index < z.length; index++) {
                    const elem = z[index];

                    updatedCell = await {
                        ...fnApplyBoxProps(
                            updatedCell,
                            elem.name,
                            elem.isChosen ? elem.value : 'default'
                        ),
                    };
                }

                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        let updatedCell = cell;
                        for (let index = 0; index < z.length; index++) {
                            const elem = z[index];
                            updatedCell = {
                                ...fnApplyBoxProps(
                                    updatedCell,
                                    elem.name,
                                    elem.isChosen ? elem.value : 'default'
                                ),
                            };
                        }
                        return updatedCell;
                    }
                );

                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };
                // Lấy cập nhật của tất cả các hàng
                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        let updatedCell = cell;
                        for (let index = 0; index < z.length; index++) {
                            const elem = z[index];
                            updatedCell = {
                                ...fnApplyBoxProps(
                                    updatedCell,
                                    elem.name,
                                    elem.isChosen ? elem.value : 'default'
                                ),
                            };
                        }
                        return updatedCell;
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else if (type === 'image') {
            let { idxData } = check;
            let updatedData = details[idxData];
            for (let index = 0; index < z.length; index++) {
                const elem = z[index];

                updatedData = (await imageMode)
                    ? {
                          ...fnApplyImageProps(
                              updatedData,
                              elem.name,
                              elem.isChosen ? elem.value : 'default'
                          ),
                      }
                    : {
                          ...fnApplyBoxProps(
                              updatedData,
                              elem.name,
                              elem.isChosen ? elem.value : 'default'
                          ),
                      };
            }

            let res = await formatBoxInOtherType(details, idxData, updatedData);

            setDetails(res);
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let updatedData = details[idxData];
            for (let index = 0; index < z.length; index++) {
                const elem = z[index];

                updatedData = await {
                    ...fnApplyBoxProps(
                        updatedData,
                        elem.name,
                        elem.isChosen ? elem.value : 'default'
                    ),
                };
            }

            let res = await formatBoxInOtherType(details, idxData, updatedData);

            setDetails(res);
        } else return;

        setOpenBorderFormattingDialog(false);
        return;
    };
    // lấy các giá trị borderSideValue và borderRadiusValue để đưa vào trong preview xem trước
    const borderSideProps = React.useMemo(() => {
        let chosenList = borderSideValue.filter((i) => i.isChosen);
        let result = {};
        if (chosenList.length === 0) {
            return result;
        } else {
            let array = [
                'borderTop',
                'borderBottom',
                'borderLeft',
                'borderRight',
            ];
            for (let k = 0; k < array.length; k++) {
                const elem = array[k];
                if (chosenList.map((i) => i.name).includes(elem)) {
                    let { style, width, color } = chosenList.filter(
                        (i) => i.name === elem
                    )[0];
                    result[elem] = width + ' ' + style + ' ' + color;
                }
            }

            return result;
        }
    }, [borderSideValue]);
    const borderRadiusProps = React.useMemo(() => {
        let chosenList = borderRadiusValue.filter((i) => i.isChosen);
        let result = {};
        if (chosenList.length === 0) {
            return result;
        } else {
            let array = [
                'borderTopLeftRadius',
                'borderTopRightRadius',
                'borderBottomLeftRadius',
                'borderBottomRightRadius',
            ];
            for (let k = 0; k < array.length; k++) {
                const elem = array[k];
                if (chosenList.map((i) => i.name).includes(elem)) {
                    let { width } = chosenList.filter(
                        (i) => i.name === elem
                    )[0];
                    result[elem] = width;
                }
            }

            return result;
        }
    }, [borderRadiusValue]);

    // function apply format box opacity
    const [boxOpacitySliderValue, setBoxOpacitySliderValue] =
        React.useState(100);
    React.useEffect(() => {
        let input = setTimeout(async () => {
            let value = Number(boxOpacitySliderValue / 100);
            let check = await idxValidator(selectedBoxData);
            if (!check) {
                return;
            }
            let { idxData } = check;
            let newData = await fnApplyBoxProps(
                details[idxData],
                'opacity',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);

            setDetails(res);
        }, 1000);
        return () => {
            clearTimeout(input);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boxOpacitySliderValue]);

    // combo 4: list
    const listModeToggleButton = (
        <Tooltip
            arrow
            title="Chế độ áp dụng khi định dạng danh sách"
            placement="top-start">
            <ToggleButton
                value="listMode"
                sx={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 38,
                    minWidth: 60,
                    p: 0,
                    '&.Mui-disabled': {
                        border: 'none',
                    },
                }}
                disabled={selectedBoxData?.type !== 'list' || !selectedBoxData}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('list-mode-menu');
                    setMenuItems(
                        ['line', 'list'].map((i, idx) => {
                            const listModeNameMap = {
                                line: {
                                    title: 'Áp dụng định dạng cho dòng đang chọn',
                                    icon: <RuleIcon fontSize="small" />,
                                },
                                list: {
                                    title: 'Áp dụng định dạng cho tất cả các dòng của danh sách',
                                    icon: (
                                        <PlaylistAddCheckRoundedIcon fontSize="small" />
                                    ),
                                },
                            };
                            return {
                                value: i,
                                label: (
                                    <Box
                                        key={'list-mode-menu' + i}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        {listModeNameMap[i].icon}
                                        <Typography
                                            variant="caption"
                                            sx={{ fontSize: 14, ml: 1 }}>
                                            {listModeNameMap[i].title}
                                        </Typography>
                                    </Box>
                                ),
                                onClick: () => {
                                    setListMode(i);
                                },
                            };
                        })
                    );
                }}
                aria-controls={openFilterMenu ? 'list-mode-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="list mode">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <ModelTrainingIcon
                        sx={{
                            color:
                                selectedBoxData?.type !== 'list' ||
                                !selectedBoxData
                                    ? 'lightgrey'
                                    : 'gold',
                        }}
                        fontSize="small"
                    />
                    <ArrowDropDownIcon
                        fontSize="small"
                        sx={{
                            color:
                                selectedBoxData?.type !== 'list' ||
                                !selectedBoxData
                                    ? 'lightgrey'
                                    : 'darkblue',
                        }}
                    />
                </Box>
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: 8,
                        textTransform: 'initial',
                        fontWeight: 500,
                        color:
                            selectedBoxData?.type !== 'list' || !selectedBoxData
                                ? 'lightgrey'
                                : 'darkblue',
                    }}>
                    {listMode === 'line' ? 'Dòng hiện tại' : 'Cả danh sách'}
                </Typography>
            </ToggleButton>
        </Tooltip>
    );
    const fnFormatListDefaultSetting = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (value === 'formatClear') {
                if (listMode === 'line') {
                    let { idxData, idxList } = check;
                    // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                    let res = await formatLineInListContent(
                        details,
                        idxData,
                        idxList,
                        {
                            content:
                                details[idxData].listcontent[idxList].content,
                        }
                    );
                    setDetails(res);
                } else if (listMode === 'list') {
                    let { idxData } = check;
                    // Lấy cập nhật của tất cả các hàng
                    let updatedListcontent = await details[
                        idxData
                    ].listcontent.map((i) => {
                        return {
                            content: i.content,
                        };
                    });
                    let res = await formatAllLineInListcontent(
                        details,
                        idxData,
                        updatedListcontent
                    );
                    setDetails(res);
                } else return;
            } else return;
        } else return;
    };
    const listDefaultSettingToggleButton = (
        <Tooltip arrow title="Khôi phục cài đặt mặc định" placement="top">
            <ToggleButton
                value="listDefaultSetting"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('list-default-setting-menu');
                    setMenuItems(
                        ['formatClear'].map((i) => {
                            const listDefaultSettingNameMap = {
                                formatClear: {
                                    title: 'Bỏ các định dạng đang áp dụng',
                                    icon: (
                                        <FormatColorResetIcon fontSize="small" />
                                    ),
                                },
                            };
                            return {
                                value: i,
                                label: (
                                    <Box
                                        key={'list-default-setting-menu' + i}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        {listDefaultSettingNameMap[i].icon}
                                        <Typography
                                            variant="caption"
                                            sx={{ fontSize: 14, ml: 1 }}>
                                            {listDefaultSettingNameMap[i].title}
                                        </Typography>
                                    </Box>
                                ),
                                onClick: () => fnFormatListDefaultSetting(i),
                            };
                        })
                    );
                }}
                sx={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 38,
                    minWidth: 60,
                    p: 0,
                    '&.Mui-disabled': {
                        border: 'none',
                    },
                }}
                disabled={selectedBoxData?.type !== 'list' || !selectedBoxData}
                aria-controls={
                    openFilterMenu ? 'list-default-setting-menu' : undefined
                }
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="list default setting button">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <SettingsIcon
                        sx={{
                            color:
                                selectedBoxData?.type !== 'list' ||
                                !selectedBoxData
                                    ? 'lightgrey'
                                    : 'grey',
                        }}
                        fontSize="small"
                    />
                    <MoreVertIcon fontSize="small" />
                </Box>
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: 8,
                        textTransform: 'initial',
                        fontWeight: 600,
                        color:
                            selectedBoxData?.type !== 'list' || !selectedBoxData
                                ? 'lightgrey'
                                : 'grey',
                    }}>
                    Cài đặt gốc
                </Typography>
            </ToggleButton>
        </Tooltip>
    );
    const fnFormatBulletinAndNumbering = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            let { idxData } = check;
            let res = [].concat(
                details.slice(0, idxData),
                [
                    {
                        ...details[idxData],
                        bulletPoint: value,
                    },
                ],
                details.slice(idxData + 1, details.length)
            );
            setDetails(res);
        } else if (
            ['header1', 'header2', 'paragraph', 'quote'].includes(type)
        ) {
            let { idxData } = check;
            let listcontent = details[idxData].content.split('\n');
            let res = [].concat(
                details.slice(0, idxData),
                [
                    {
                        type: 'list',
                        bulletPoint: value,
                        listcontent: listcontent.map((i) => {
                            return { content: i };
                        }),
                    },
                ],
                details.slice(idxData + 1, details.length)
            );
            setDetails(res);
        } else {
            return;
        }
    };
    const bulletinTypeToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={
                selectedBoxData?.type === 'image' ||
                selectedBoxData?.type === 'table' ||
                !selectedBoxData
            }
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="bulletin type toggle button">
            <Tooltip
                arrow
                title="Tạo danh sách với các biểu tượng"
                placement="top">
                <ToggleButton
                    value="bulletinType"
                    onClick={(event) => {
                        const bulletinNameMap = {
                            circle: {
                                title: 'Hình tròn',
                                icon: '● ',
                            },
                            radio: {
                                title: 'Nút radio',
                                icon: '⦿ ',
                            },
                            square: {
                                title: 'Hình vuông',
                                icon: '▪ ',
                            },
                            check: {
                                title: 'Dấu check',
                                icon: '✔ ',
                            },
                            add: {
                                title: 'Dấu cộng',
                                icon: '﹢ ',
                            },
                            minus: {
                                title: 'Dấu trừ',
                                icon: '﹣ ',
                            },
                            diamond: {
                                title: 'Hình kim cương',
                                icon: '◆ ',
                            },
                        };

                        setAnchorEl(event.currentTarget);
                        setArialLabel('bulletin-type-menu');
                        setMenuItems(
                            ['none']
                                .concat(Object.keys(bulletinNameMap))
                                .map((type) => {
                                    if (type === 'none') {
                                        return {
                                            value: type,
                                            label: (
                                                <Box
                                                    key={
                                                        'numbering-type-menu' +
                                                        type
                                                    }
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                    <Typography
                                                        key={
                                                            'numbering-type-menu-none'
                                                        }
                                                        variant="caption"
                                                        sx={{
                                                            fontSize: 14,
                                                            ...defaultBoxCSS,
                                                        }}>
                                                        Không dùng
                                                    </Typography>
                                                </Box>
                                            ),
                                            onClick: () =>
                                                fnFormatBulletinAndNumbering(
                                                    type
                                                ),
                                        };
                                    }
                                    return {
                                        value: type,
                                        label: (
                                            <Box
                                                key={
                                                    'bulletin-type-menu' + type
                                                }
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 17,
                                                        width: 15,
                                                        textAlign: 'center',
                                                    }}>
                                                    {bulletinNameMap[type].icon}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ml: 1,
                                                    }}>
                                                    {
                                                        bulletinNameMap[type]
                                                            .title
                                                    }
                                                </Typography>
                                            </Box>
                                        ),
                                        onClick: () =>
                                            fnFormatBulletinAndNumbering(type),
                                    };
                                })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'bulletin-type-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="bulletin type button">
                    <FormatListBulletedIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const numberingTypeToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            disabled={
                selectedBoxData?.type === 'image' ||
                selectedBoxData?.type === 'table' ||
                !selectedBoxData
            }
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="numbering type toggle button">
            <Tooltip arrow title="Tạo danh sách với dãy thứ tự" placement="top">
                <ToggleButton
                    value="numberingType"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('numbering-type-menu');
                        setMenuItems(
                            [
                                'none',
                                'uppercaseRoman',
                                'lowercaseRoman',
                                'uppercaseAlphabet',
                                'lowercaseAlphabet',
                                'number',
                            ].map((type) => {
                                const numberingTypeNameMap = {
                                    uppercaseRoman: {
                                        title1: 'I.',
                                        title2: 'II.',
                                    },
                                    lowercaseRoman: {
                                        title1: 'i.',
                                        title2: 'ii.',
                                    },
                                    uppercaseAlphabet: {
                                        title1: 'A.',
                                        title2: 'B.',
                                    },
                                    lowercaseAlphabet: {
                                        title1: 'a.',
                                        title2: 'b.',
                                    },
                                    number: {
                                        title1: '1.',
                                        title2: '2.',
                                    },
                                };
                                if (type === 'none') {
                                    return {
                                        value: type,
                                        label: (
                                            <Box
                                                key={
                                                    'numbering-type-menu' + type
                                                }
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                <Typography
                                                    key={
                                                        'numbering-type-menu-none'
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...defaultBoxCSS,
                                                    }}>
                                                    Không dùng
                                                </Typography>
                                            </Box>
                                        ),
                                        onClick: () =>
                                            fnFormatBulletinAndNumbering(type),
                                    };
                                }
                                return {
                                    value: type,
                                    label: (
                                        <Box
                                            key={'numbering-type-menu' + type}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                my: 0.5,
                                            }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        width: 10,
                                                    }}>
                                                    {
                                                        numberingTypeNameMap[
                                                            type
                                                        ].title1
                                                    }
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        ml: 1,
                                                        mb: -0.25,
                                                        height: 1.5,
                                                        width: 100,
                                                        backgroundColor:
                                                            'lightgrey',
                                                        color: 'lightgrey',
                                                    }}></Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        width: 10,
                                                    }}>
                                                    {
                                                        numberingTypeNameMap[
                                                            type
                                                        ].title2
                                                    }
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        ml: 1,
                                                        mb: -0.25,
                                                        mt: 0,
                                                        height: 1.5,
                                                        width: 100,
                                                        backgroundColor:
                                                            'lightgrey',
                                                        color: 'lightgrey',
                                                    }}></Box>
                                            </Box>
                                        </Box>
                                    ),
                                    onClick: () =>
                                        fnFormatBulletinAndNumbering(type),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'numbering-type-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="numbering type button">
                    <FormatListNumberedIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatListRotation = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyBoxProps(
                        details[idxData].listcontent[idxList],
                        'transform',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyBoxProps(i, 'transform', value),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else return;
    };
    const listRotationToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="list rotation toggle button">
            <Tooltip
                arrow
                title="Xoay khung theo chiều kim đồng hồ"
                placement="top">
                <ToggleButton
                    value="listRotation"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('list-rotation-menu');
                        setMenuItems(
                            [
                                'default',
                                45,
                                90,
                                135,
                                180,
                                225,
                                270,
                                315,
                                360,
                            ].map((i) => {
                                const listRotationNameMap = {
                                    default: {
                                        title: 'Mặc định',
                                        value: 'default',
                                    },
                                    45: {
                                        title: '45 độ',
                                        value: 'rotate(45deg)',
                                    },
                                    90: {
                                        title: '90 độ',
                                        value: 'rotate(90deg)',
                                    },
                                    135: {
                                        title: '135 độ',
                                        value: 'rotate(135deg)',
                                    },
                                    180: {
                                        title: '180 độ',
                                        value: 'rotate(180deg)',
                                    },
                                    225: {
                                        title: '225 độ',
                                        value: 'rotate(225deg)',
                                    },
                                    270: {
                                        title: '270 độ',
                                        value: 'rotate(270deg)',
                                    },
                                    315: {
                                        title: '315 độ',
                                        value: 'rotate(315deg)',
                                    },
                                    360: {
                                        title: '360 độ',
                                        value: 'rotate(360deg)',
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'list-rotation-button-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 15,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {listRotationNameMap[i].title}
                                        </Typography>
                                    ),
                                    onClick: () =>
                                        fnFormatListRotation(
                                            listRotationNameMap[i].value
                                        ),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'list-rotation-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="list rotation button">
                    <Rotate90DegreesCwIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatListPadding = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyBoxProps(
                        details[idxData].listcontent[idxList],
                        'padding',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyBoxProps(i, 'padding', value),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else return;
    };
    const listPaddingToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            disabled={selectedBoxData?.type !== 'list' || !selectedBoxData}
            aria-label="list padding toggle button">
            <Tooltip
                arrow
                title="Khoảng cách giữa nội dung và phần viền"
                placement="top">
                <ToggleButton
                    value="listPadding"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('list-padding-menu');
                        setMenuItems(
                            [
                                'default',
                                '10px',
                                '20px',
                                '30px',
                                '50px',
                                '60px',
                                '80px',
                                '100px',
                                '120px',
                                '150px',
                                '200px',
                                '250px',
                                '300px',
                            ].map((i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'list-padding-menu-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default' ? 'Mặc định' : i}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatListPadding(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'list-padding-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="list padding button">
                    <PaddingIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatListMarginTop = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyBoxProps(
                        details[idxData].listcontent[idxList],
                        'marginTop',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyBoxProps(i, 'marginTop', value),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else return;
    };
    const listMarginTopToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            disabled={selectedBoxData?.type !== 'list' || !selectedBoxData}
            aria-label="list margin top toggle button">
            <Tooltip
                arrow
                title="Khoảng cách so với nội dung phía trên"
                placement="top">
                <ToggleButton
                    value="listMarginTop"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('list-margin-top-menu');
                        setMenuItems(
                            [
                                'default',
                                '10px',
                                '20px',
                                '30px',
                                '50px',
                                '60px',
                                '80px',
                                '100px',
                                '120px',
                                '150px',
                                '200px',
                                '250px',
                                '300px',
                            ].map((i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'list-margin-top-menu-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default' ? 'Mặc định' : i}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatListMarginTop(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'list-margin-top-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="list margin top button">
                    <UpgradeIcon
                        fontSize="small"
                        sx={{
                            width: 20,
                            height: 20,
                            borderTop: `2px solid ${
                                selectedBoxData?.type === 'list'
                                    ? 'grey'
                                    : 'lightgrey'
                            }`,
                            borderRadius: '2px',
                        }}
                    />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatListBackgroundColor = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'list') {
            if (listMode === 'line') {
                let { idxData, idxList } = check;
                // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                let updatedLineInListcontent = await {
                    ...fnApplyBoxProps(
                        details[idxData].listcontent[idxList],
                        'backgroundColor',
                        value
                    ),
                };
                let res = await formatLineInListContent(
                    details,
                    idxData,
                    idxList,
                    updatedLineInListcontent
                );
                setDetails(res);
            } else if (listMode === 'list') {
                let { idxData } = check;
                // Lấy cập nhật của tất cả các hàng
                let updatedListcontent = await details[idxData].listcontent.map(
                    (i) => {
                        return {
                            ...fnApplyBoxProps(i, 'backgroundColor', value),
                        };
                    }
                );
                let res = await formatAllLineInListcontent(
                    details,
                    idxData,
                    updatedListcontent
                );
                setDetails(res);
            } else return;
        } else return;
    };
    const listBackgroundColorToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            disabled={selectedBoxData?.type !== 'list' || !selectedBoxData}
            aria-label="list background color toggle button">
            <Tooltip arrow title="Màu nền" placement="top">
                <ToggleButton
                    value="listBackgroundColor"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('list-background-color-menu');
                        setMenuItems(
                            ['default']
                                .concat(Object.keys(colorNameMap))
                                .map((color) => {
                                    return {
                                        value: color,
                                        label:
                                            color === 'default' ? (
                                                <Typography
                                                    key={
                                                        'list-background-color-menu-default'
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...defaultBoxCSS,
                                                    }}>
                                                    Mặc định
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    key={
                                                        'list-background-color-menu-' +
                                                        color
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...(color ===
                                                            'default' &&
                                                            defaultBoxCSS),
                                                    }}>
                                                    <SquareRoundedIcon
                                                        sx={{
                                                            width:
                                                                color ===
                                                                'white'
                                                                    ? 15
                                                                    : 20,
                                                            height:
                                                                color ===
                                                                'white'
                                                                    ? 15
                                                                    : 20,
                                                            ml:
                                                                color ===
                                                                'white'
                                                                    ? '3px'
                                                                    : 0,
                                                            borderRadius:
                                                                color ===
                                                                'white'
                                                                    ? '1px'
                                                                    : 'none',
                                                            mr: 0.5,
                                                            color: color,
                                                            border:
                                                                color ===
                                                                'white'
                                                                    ? '0.25px solid grey'
                                                                    : 'none',
                                                        }}
                                                    />
                                                    {colorNameMap[color]}
                                                </Typography>
                                            ),
                                        onClick: () =>
                                            fnFormatListBackgroundColor(color),
                                    };
                                })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu
                            ? 'list-background-color-menu'
                            : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="list background color button">
                    <FormatColorFillIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    // function apply format list opacity
    const [listOpacitySliderValue, setListOpacitySliderValue] =
        React.useState(100);
    React.useEffect(() => {
        let input = setTimeout(async () => {
            let value = Number(listOpacitySliderValue / 100);
            let check = await idxValidator(selectedBoxData);
            if (!check) {
                return;
            }
            let { type } = check;
            if (type === 'list') {
                if (listMode === 'line') {
                    let { idxData, idxList } = check;
                    // Lấy cập nhật của hàng được chọn trong list, các hàng khác giữ nguyên
                    let updatedLineInListcontent = await {
                        ...fnApplyBoxProps(
                            details[idxData].listcontent[idxList],
                            'opacity',
                            value
                        ),
                    };
                    let res = await formatLineInListContent(
                        details,
                        idxData,
                        idxList,
                        updatedLineInListcontent
                    );
                    setDetails(res);
                } else if (listMode === 'list') {
                    let { idxData } = check;
                    // Lấy cập nhật của tất cả các hàng
                    let updatedListcontent = await details[
                        idxData
                    ].listcontent.map((i) => {
                        return {
                            ...fnApplyBoxProps(i, 'opacity', value),
                        };
                    });
                    let res = await formatAllLineInListcontent(
                        details,
                        idxData,
                        updatedListcontent
                    );
                    setDetails(res);
                } else return;
            } else return;
        }, 1000);
        return () => {
            clearTimeout(input);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listOpacitySliderValue]);

    // combo 5: table
    const tableModeToggleButton = (
        <Tooltip
            arrow
            title="Chế độ áp dụng khi định dạng bảng"
            placement="top-start">
            <ToggleButton
                value="tableMode"
                sx={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 38,
                    minWidth: 60,
                    p: 0,
                    '&.Mui-disabled': {
                        border: 'none',
                    },
                }}
                disabled={selectedBoxData?.type !== 'table' || !selectedBoxData}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('table-mode-menu');
                    setMenuItems(
                        ['cell', 'row', 'table'].map((i, idx) => {
                            const tableModeNameMap = {
                                cell: {
                                    title: 'Áp dụng định dạng cho ô đang chọn',
                                    icon: <CropSquareIcon fontSize="small" />,
                                },
                                row: {
                                    title: 'Áp dụng định dạng cho hàng đang chọn',
                                    icon: <ViewDayIcon fontSize="small" />,
                                },
                                table: {
                                    title: 'Áp dụng định dạng cho tất cả các ô của bảng',
                                    icon: <GridOnIcon fontSize="small" />,
                                },
                            };
                            return {
                                value: i,
                                label: (
                                    <Box
                                        key={'table-mode-menu' + i}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        {tableModeNameMap[i].icon}
                                        <Typography
                                            variant="caption"
                                            sx={{ fontSize: 14, ml: 1 }}>
                                            {tableModeNameMap[i].title}
                                        </Typography>
                                    </Box>
                                ),
                                onClick: () => {
                                    setTableMode(i);
                                },
                            };
                        })
                    );
                }}
                aria-controls={openFilterMenu ? 'table-mode-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="table mode">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <ModelTrainingIcon
                        sx={{
                            color:
                                selectedBoxData?.type !== 'table' ||
                                !selectedBoxData
                                    ? 'lightgrey'
                                    : 'gold',
                        }}
                        fontSize="small"
                    />
                    <ArrowDropDownIcon
                        fontSize="small"
                        sx={{
                            color:
                                selectedBoxData?.type !== 'table' ||
                                !selectedBoxData
                                    ? 'lightgrey'
                                    : 'darkblue',
                        }}
                    />
                </Box>
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: 8,
                        textTransform: 'initial',
                        fontWeight: 500,
                        color:
                            selectedBoxData?.type !== 'table' ||
                            !selectedBoxData
                                ? 'lightgrey'
                                : 'darkblue',
                    }}>
                    {tableMode === 'cell'
                        ? 'Ô hiện tại'
                        : tableMode === 'row'
                        ? 'Hàng hiện tại'
                        : 'Bảng hiện tại'}
                </Typography>
            </ToggleButton>
        </Tooltip>
    );
    const fnFormatTableDefaultSetting = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'table') {
            if (value === 'bandedHeader') {
                let { idxData } = check;
                // Xóa bỏ boxProps trong hàng hàng đầu tiên của rows, các hàng khác giữ nguyên
                let firstRow = Object.keys(details[idxData].rows).sort(
                    (a, b) => Number(a) - Number(b)
                )[0];
                let updatedRows = await details[idxData].rows[firstRow].map(
                    (cell) => {
                        return {
                            content: cell.content,
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    firstRow,
                    updatedRows
                );
                setDetails(res);
            } else if (value === 'unmergedAllCells') {
                let { idxData } = check;
                let updatedRows = details[idxData].rows;
                for (
                    let index = 0;
                    index < Object.keys(details[idxData].rows).length;
                    index++
                ) {
                    const rowKey = Object.keys(details[idxData].rows)[index];
                    updatedRows[rowKey] = updatedRows[rowKey]
                        .map((currentCell) => {
                            let mergeCount =
                                currentCell?.boxProps?.mergeCount ?? 0;
                            if (mergeCount === 0) {
                                return currentCell;
                            } else {
                                let {
                                    width,
                                    mergeCount,
                                    ...oldBoxPropsOfCurrentCell
                                } = currentCell?.boxProps ?? {};
                                return Array.from(
                                    { length: Number(mergeCount) + 1 },
                                    (x, i) => {
                                        if (i === 0) {
                                            return {
                                                ...(oldBoxPropsOfCurrentCell && {
                                                    boxProps: {
                                                        ...oldBoxPropsOfCurrentCell,
                                                    },
                                                }),
                                                content: currentCell.content,
                                            };
                                        }
                                        return {
                                            ...(oldBoxPropsOfCurrentCell && {
                                                boxProps: {
                                                    ...oldBoxPropsOfCurrentCell,
                                                },
                                            }),
                                            content: ' ',
                                        };
                                    }
                                );
                            }
                        })
                        .flat();
                }
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else if (value === 'formatClear') {
                if (tableMode === 'cell') {
                    let { idxData, rowKey, idxColumn } = check;
                    // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                    let { boxProps, content } =
                        details[idxData].rows[rowKey][idxColumn];
                    let mergeCount = boxProps?.mergeCount ?? 0;
                    let res = await formatOneCellInRow(
                        details,
                        idxData,
                        rowKey,
                        idxColumn,
                        {
                            ...(mergeCount > 0 && { boxProps: { mergeCount } }),
                            content: content,
                        }
                    );
                    setDetails(res);
                } else if (tableMode === 'row') {
                    let { idxData, rowKey } = check;
                    // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                    let updatedRows = await details[idxData].rows[rowKey].map(
                        (cell) => {
                            let { boxProps, content } = cell;
                            let mergeCount = boxProps?.mergeCount ?? 0;
                            return {
                                ...(mergeCount > 0 && {
                                    boxProps: { mergeCount },
                                }),
                                content: content,
                            };
                        }
                    );
                    let res = await formatAllCellInTable(
                        details,
                        idxData,
                        rowKey,
                        updatedRows
                    );
                    setDetails(res);
                } else if (tableMode === 'table') {
                    let { idxData } = check;
                    let updatedRows = { ...details[idxData].rows };

                    Object.keys(details[idxData].rows).map((i) => {
                        updatedRows[i] = details[idxData].rows[i].map(
                            (cell, k) => {
                                let { boxProps, content } = cell;
                                let mergeCount = boxProps?.mergeCount ?? 0;
                                return {
                                    ...(mergeCount > 0 && {
                                        boxProps: { mergeCount },
                                    }),
                                    content: content,
                                };
                            }
                        );
                        return null;
                    });
                    let res = await [].concat(
                        details.slice(0, idxData),
                        [
                            {
                                ...details[idxData],
                                rows: updatedRows,
                            },
                        ],
                        details.slice(idxData + 1, details.length)
                    );
                    setDetails(res);
                } else return;
            } else return;
        } else return;
    };
    const tableDefaultSettingToggleButton = (
        <Tooltip arrow title="Khôi phục cài đặt mặc định" placement="top">
            <ToggleButton
                value="tableDefaultSetting"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('table-default-setting-menu');
                    setMenuItems(
                        ['bandedHeader', 'unmergedAllCells', 'formatClear'].map(
                            (i) => {
                                const tableDefaultSettingNameMap = {
                                    bandedHeader: {
                                        title: 'Mặc định hàng đầu tiên là tiêu đề',
                                        icon: (
                                            <ViewDayTwoToneIcon fontSize="small" />
                                        ),
                                    },
                                    unmergedAllCells: {
                                        title: 'Bỏ gộp các ô đang có trong bảng',
                                        icon: <WindowIcon fontSize="small" />,
                                    },
                                    formatClear: {
                                        title: 'Bỏ các định dạng đang áp dụng',
                                        icon: (
                                            <FormatColorResetIcon fontSize="small" />
                                        ),
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Box
                                            key={
                                                'table-default-setting-menu' + i
                                            }
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            {tableDefaultSettingNameMap[i].icon}
                                            <Typography
                                                variant="caption"
                                                sx={{ fontSize: 14, ml: 1 }}>
                                                {
                                                    tableDefaultSettingNameMap[
                                                        i
                                                    ].title
                                                }
                                            </Typography>
                                        </Box>
                                    ),
                                    onClick: () =>
                                        fnFormatTableDefaultSetting(i),
                                };
                            }
                        )
                    );
                }}
                sx={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 38,
                    minWidth: 60,
                    p: 0,
                    '&.Mui-disabled': {
                        border: 'none',
                    },
                }}
                disabled={selectedBoxData?.type !== 'table' || !selectedBoxData}
                aria-controls={
                    openFilterMenu ? 'table-default-setting-menu' : undefined
                }
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="table default setting button">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <SettingsIcon
                        sx={{
                            color:
                                selectedBoxData?.type !== 'table' ||
                                !selectedBoxData
                                    ? 'lightgrey'
                                    : 'grey',
                        }}
                        fontSize="small"
                    />
                    <MoreVertIcon fontSize="small" />
                </Box>
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: 8,
                        textTransform: 'initial',
                        fontWeight: 600,
                        color:
                            selectedBoxData?.type !== 'table' ||
                            !selectedBoxData
                                ? 'lightgrey'
                                : 'grey',
                    }}>
                    Cài đặt gốc
                </Typography>
            </ToggleButton>
        </Tooltip>
    );
    const fnFormatTableWidth = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                let updatedRows = { ...details[idxData].rows };

                updatedRows[rowKey] = updatedRows[rowKey].map((cell, k) => {
                    if (k === Number(idxColumn)) {
                        return { ...fnApplyBoxProps(cell, 'width', value) };
                    } else return cell;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, idxColumn } = check;
                let updatedRows = { ...details[idxData].rows };

                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        if (k === Number(idxColumn)) {
                            return { ...fnApplyBoxProps(cell, 'width', value) };
                        } else return cell;
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };

                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return { ...fnApplyBoxProps(cell, 'width', value) };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else return;
    };
    const cellWidthFormattingToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            disabled={selectedBoxData?.type !== 'table' || !selectedBoxData}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="cell width formatting">
            <Tooltip
                arrow
                title={'Thay đổi chiều rộng của cột'}
                placement="top"
                key={'cell width formatting'}>
                <ToggleButton
                    value={'width'}
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('cell-width-menu');
                        setMenuItems(
                            [
                                'default',
                                '0%',
                                '10%',
                                '20%',
                                '30%',
                                '40%',
                                '50%',
                                '60%',
                                '70%',
                                '80%',
                                '90%',
                                '100%',
                                '110%',
                                '130%',
                                '150%',
                                '200%',
                                '250%',
                            ].map((v) => {
                                return {
                                    value: v,
                                    label: (
                                        <Typography
                                            key={'cell-width-menu-' + v}
                                            variant="caption"
                                            sx={{
                                                fontSize: 14,
                                                ...(v === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {v === 'default' ? 'Mặc định' : v}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatTableWidth(v),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'cell-width-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label={'cell width button'}>
                    <SwapHorizIcon
                        fontSize="small"
                        sx={{
                            borderLeft: `2px solid ${
                                selectedBoxData?.type !== 'table'
                                    ? 'lightgrey'
                                    : 'grey'
                            }`,
                            borderRight: `2px solid ${
                                selectedBoxData?.type !== 'table'
                                    ? 'lightgrey'
                                    : 'grey'
                            }`,
                            borderRadius: '2px',
                        }}
                    />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatTablePadding = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = await fnApplyBoxProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'padding',
                    value
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        return {
                            ...fnApplyBoxProps(cell, 'padding', value),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };

                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return { ...fnApplyBoxProps(cell, 'padding', value) };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else return;
    };
    const tablePaddingToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            disabled={selectedBoxData?.type !== 'table' || !selectedBoxData}
            aria-label="table padding toggle button">
            <Tooltip
                arrow
                title="Khoảng cách giữa nội dung và phần viền"
                placement="top">
                <ToggleButton
                    value="tablePadding"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('table-padding-menu');
                        setMenuItems(
                            [
                                'default',
                                '10px',
                                '20px',
                                '30px',
                                '50px',
                                '60px',
                                '80px',
                                '100px',
                                '120px',
                                '150px',
                                '200px',
                                '250px',
                                '300px',
                            ].map((i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'table-padding-menu-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default' ? 'Mặc định' : i}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatTablePadding(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'table-padding-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="table padding button">
                    <PaddingIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatTableMargin = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'table') {
            // Áp dụng trong cả 3 chế độ của tableMode
            let { idxData } = check;
            let updatedRows = await details[idxData].rows;
            for (
                let index = 0;
                index < Object.keys(details[idxData].rows).length;
                index++
            ) {
                const key = Object.keys(details[idxData].rows)[index];
                updatedRows[key] = updatedRows[key].map((cell) => {
                    return {
                        ...fnApplyBoxProps(cell, 'margin', value),
                    };
                });
            }

            let res = await [].concat(
                details.slice(0, idxData),
                [
                    {
                        ...details[idxData],
                        rows: updatedRows,
                    },
                ],
                details.slice(idxData + 1, details.length)
            );
            setDetails(res);
        } else return;
    };
    const tableMarginToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            disabled={selectedBoxData?.type !== 'table' || !selectedBoxData}
            aria-label="table margin toggle button">
            <Tooltip arrow title="Khoảng cách giữa các ô" placement="top">
                <ToggleButton
                    value="table-margin"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('table-margin-menu');
                        setMenuItems(
                            [
                                'default',
                                '0px',
                                '1px',
                                '2px',
                                '3px',
                                '5px',
                                '8px',
                                '10px',
                                '12px',
                                '15px',
                                '20px',
                            ].map((i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'table-margin-menu-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default' ? 'Mặc định' : i}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatTableMargin(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'table-margin-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="table margin top button">
                    <FullscreenExitIcon
                        fontSize="small"
                        sx={{
                            border: `2px solid ${
                                selectedBoxData?.type === 'table'
                                    ? 'grey'
                                    : 'lightgrey'
                            }`,
                            borderRadius: '2px',
                        }}
                    />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatTableBackgroundColor = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'table') {
            if (tableMode === 'cell') {
                let { idxData, rowKey, idxColumn } = check;
                // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                let updatedCell = await fnApplyBoxProps(
                    details[idxData].rows[rowKey][idxColumn],
                    'backgroundColor',
                    value
                );
                let res = await formatOneCellInRow(
                    details,
                    idxData,
                    rowKey,
                    idxColumn,
                    updatedCell
                );
                setDetails(res);
            } else if (tableMode === 'row') {
                let { idxData, rowKey } = check;
                // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                let updatedRows = await details[idxData].rows[rowKey].map(
                    (cell) => {
                        return {
                            ...fnApplyBoxProps(cell, 'backgroundColor', value),
                        };
                    }
                );
                let res = await formatAllCellInTable(
                    details,
                    idxData,
                    rowKey,
                    updatedRows
                );
                setDetails(res);
            } else if (tableMode === 'table') {
                let { idxData } = check;
                let updatedRows = { ...details[idxData].rows };

                Object.keys(details[idxData].rows).map((i) => {
                    updatedRows[i] = details[idxData].rows[i].map((cell, k) => {
                        return {
                            ...fnApplyBoxProps(cell, 'backgroundColor', value),
                        };
                    });
                    return null;
                });
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else return;
    };
    const tableBackgroundColorToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            disabled={selectedBoxData?.type !== 'table' || !selectedBoxData}
            aria-label="table background color toggle button">
            <Tooltip arrow title="Màu nền" placement="top">
                <ToggleButton
                    value="tableBackgroundColor"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('table-background-color-menu');
                        setMenuItems(
                            ['default']
                                .concat(Object.keys(colorNameMap))
                                .map((color) => {
                                    return {
                                        value: color,
                                        label:
                                            color === 'default' ? (
                                                <Typography
                                                    key={
                                                        'table-background-color-menu-default'
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...defaultBoxCSS,
                                                    }}>
                                                    Mặc định
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    key={
                                                        'table-background-color-menu-' +
                                                        color
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...(color ===
                                                            'default' &&
                                                            defaultBoxCSS),
                                                    }}>
                                                    <SquareRoundedIcon
                                                        sx={{
                                                            width:
                                                                color ===
                                                                'white'
                                                                    ? 15
                                                                    : 20,
                                                            height:
                                                                color ===
                                                                'white'
                                                                    ? 15
                                                                    : 20,
                                                            ml:
                                                                color ===
                                                                'white'
                                                                    ? '3px'
                                                                    : 0,
                                                            borderRadius:
                                                                color ===
                                                                'white'
                                                                    ? '1px'
                                                                    : 'none',
                                                            mr: 0.5,
                                                            color: color,
                                                            border:
                                                                color ===
                                                                'white'
                                                                    ? '0.25px solid grey'
                                                                    : 'none',
                                                        }}
                                                    />
                                                    {colorNameMap[color]}
                                                </Typography>
                                            ),
                                        onClick: () =>
                                            fnFormatTableBackgroundColor(color),
                                    };
                                })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu
                            ? 'table-background-color-menu'
                            : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="table background color button">
                    <FormatColorFillIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );

    // function apply format table opacity
    const [tableOpacitySliderValue, setTableOpacitySliderValue] =
        React.useState(100);
    React.useEffect(() => {
        let input = setTimeout(async () => {
            let value = Number(tableOpacitySliderValue / 100);
            let check = await idxValidator(selectedBoxData);
            if (!check) {
                return;
            }
            let { type } = check;
            if (type === 'table') {
                if (tableMode === 'cell') {
                    let { idxData, rowKey, idxColumn } = check;
                    // Lấy cập nhật của ô được chọn trong table, các ô khác giữ nguyên
                    let updatedCell = await fnApplyBoxProps(
                        details[idxData].rows[rowKey][idxColumn],
                        'opacity',
                        value
                    );
                    let res = await formatOneCellInRow(
                        details,
                        idxData,
                        rowKey,
                        idxColumn,
                        updatedCell
                    );
                    setDetails(res);
                } else if (tableMode === 'row') {
                    let { idxData, rowKey } = check;
                    // Lấy cập nhật của tất cả các ô trong hàng được chọn, các hàng khác giữ nguyên
                    let updatedRows = await details[idxData].rows[rowKey].map(
                        (cell) => {
                            return {
                                ...fnApplyBoxProps(cell, 'opacity', value),
                            };
                        }
                    );
                    let res = await formatAllCellInTable(
                        details,
                        idxData,
                        rowKey,
                        updatedRows
                    );
                    setDetails(res);
                } else if (tableMode === 'table') {
                    let { idxData } = check;
                    let updatedRows = { ...details[idxData].rows };

                    Object.keys(details[idxData].rows).map((i) => {
                        updatedRows[i] = details[idxData].rows[i].map(
                            (cell, k) => {
                                return {
                                    ...fnApplyBoxProps(cell, 'opacity', value),
                                };
                            }
                        );
                        return null;
                    });
                    let res = await [].concat(
                        details.slice(0, idxData),
                        [
                            {
                                ...details[idxData],
                                rows: updatedRows,
                            },
                        ],
                        details.slice(idxData + 1, details.length)
                    );
                    setDetails(res);
                } else return;
            } else return;
        }, 1000);
        return () => {
            clearTimeout(input);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableOpacitySliderValue]);

    const fnFormatTableSize = async (name, number) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'table') {
            let { idxData } = check;
            if (name === 'row' && number) {
                let fnDecreaseRows = (allRows) => {
                    return Object.fromEntries(
                        Array.from({ length: number }, (x, i) => i).map(
                            (key) => [key, allRows[key]]
                        )
                    );
                };
                let fnIncreaseRows = (allRows) => {
                    let newRows = { ...allRows };
                    let oldRowsLength = Object.keys(allRows).length;
                    for (let i = 0; i < Number(number - oldRowsLength); i++) {
                        newRows[Number(oldRowsLength + i)] = allRows[
                            oldRowsLength - 1
                        ].map((i) => {
                            return {
                                ...i,
                                content: '',
                            };
                        });
                    }
                    return newRows;
                };

                let oldRows = details[idxData].rows;
                let oldRowsLength = Object.keys(oldRows).length;
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows:
                                number === oldRowsLength
                                    ? oldRows
                                    : number < oldRowsLength
                                    ? fnDecreaseRows(oldRows)
                                    : fnIncreaseRows(oldRows),
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else if (name === 'column' && number) {
                let fnDecreaseColumns = (allRows) => {
                    return Object.fromEntries(
                        Array.from(
                            {
                                length: Object.keys(allRows).length,
                            },
                            (x, i) => i
                        ).map((key) => [key, allRows[key].slice(0, number)])
                    );
                };
                let fnIncreaseColumns = (allRows) => {
                    let oldColumnsLength = allRows[0].length;
                    return Object.fromEntries(
                        Array.from(
                            {
                                length: Object.keys(allRows).length,
                            },
                            (x, v) => v
                        ).map((key) => [
                            key,
                            allRows[key].concat(
                                Array.from(
                                    {
                                        length: number - oldColumnsLength,
                                    },
                                    (x, v) => {
                                        return {
                                            ...allRows[key][
                                                oldColumnsLength - 1
                                            ],
                                            content: '',
                                        };
                                    }
                                )
                            ),
                        ])
                    );
                };
                let oldRows = details[idxData].rows;
                let oldColumnsLength = details[idxData].rows[0].length;
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows:
                                number === oldColumnsLength
                                    ? oldRows
                                    : number < oldColumnsLength
                                    ? fnDecreaseColumns(oldRows)
                                    : fnIncreaseColumns(oldRows),
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else return;
    };
    const tableSizeToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            disabled={selectedBoxData?.type !== 'table' || !selectedBoxData}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="table formatting">
            {['column', 'row'].map((i, idx) => {
                const tableFormattingNameMap = {
                    column: {
                        title: 'Thay đổi số cột trong bảng',
                        icon: <ViewColumnIcon fontSize="small" />,
                        name: 'cột',
                    },
                    row: {
                        title: 'Thay đổi hàng trong bảng',
                        icon: <TableRowsIcon fontSize="small" />,
                        name: 'hàng',
                    },
                };
                return (
                    <Tooltip
                        arrow
                        title={tableFormattingNameMap[i].title}
                        placement="top"
                        key={'table formatting-' + i + '-' + idx}>
                        <ToggleButton
                            value={i}
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setArialLabel(i + '-menu');
                                setMenuItems(
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => {
                                        return {
                                            value: v,
                                            label: (
                                                <Typography
                                                    key={i + '-menu-' + v}
                                                    variant="caption"
                                                    sx={{ fontSize: 14 }}>
                                                    {`${v} ${tableFormattingNameMap[i].name}`}
                                                </Typography>
                                            ),
                                            onClick: () =>
                                                fnFormatTableSize(i, v),
                                        };
                                    })
                                );
                            }}
                            sx={{
                                ...customSxToggleButton,
                                minWidth: 40,
                            }}
                            aria-controls={
                                openFilterMenu ? i + '-menu' : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openFilterMenu ? 'true' : undefined}
                            aria-label={i + ' button'}>
                            {tableFormattingNameMap[i].icon}
                            <ArrowDropDownIcon fontSize="small" />
                        </ToggleButton>
                    </Tooltip>
                );
            })}
        </ToggleButtonGroup>
    );
    const fnFormatTableDeletion = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'table') {
            let { idxData, rowKey, idxColumn } = check;
            if (value === 'cell') {
                let updatedRows = details[idxData].rows;
                let updatedRow = updatedRows[rowKey].filter(
                    (i, k) => k !== Number(idxColumn)
                );
                updatedRows[rowKey] = updatedRow;
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else if (value === 'row') {
                let updatedRows = details[idxData].rows;
                delete updatedRows[rowKey];
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else if (value === 'column') {
                let updatedRows = details[idxData].rows;
                let rowKeyList = Object.keys(updatedRows);
                for (let index = 0; index < rowKeyList.length; index++) {
                    const key = rowKeyList[index];
                    const updatedRow = details[idxData].rows[key].filter(
                        (i, k) => k !== Number(idxColumn)
                    );
                    updatedRows[key] = updatedRow;
                }

                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else {
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            type: 'paragraph',
                            content: '',
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            }
        } else return;
    };
    const tableDeletionToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            disabled={selectedBoxData?.type !== 'table' || !selectedBoxData}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="table deletion">
            <Tooltip
                arrow
                title={'Xóa ô/hàng/cột/bảng'}
                placement="top"
                key={'table deletion tooltip'}>
                <ToggleButton
                    value="tableDeletion"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('table-deletion-menu');
                        setMenuItems(
                            ['cell', 'row', 'column', 'table'].map((i) => {
                                const tableDeletionNameMap = {
                                    cell: {
                                        title: 'Xóa ô hiện tại',
                                        icon: (
                                            <CancelPresentationIcon fontSize="small" />
                                        ),
                                    },
                                    column: {
                                        title: 'Xóa cột hiện tại',
                                        icon: (
                                            <RemoveRoadIcon fontSize="small" />
                                        ),
                                    },
                                    row: {
                                        title: 'Xóa hàng hiện tại',
                                        icon: (
                                            <PlaylistRemoveIcon fontSize="small" />
                                        ),
                                    },
                                    table: {
                                        title: 'Xóa bảng hiện tại',
                                        icon: <GridOffIcon fontSize="small" />,
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Box
                                            key={'table-deletion-menu' + i}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            {tableDeletionNameMap[i].icon}
                                            <Typography
                                                variant="caption"
                                                sx={{ fontSize: 14, ml: 1 }}>
                                                {tableDeletionNameMap[i].title}
                                            </Typography>
                                        </Box>
                                    ),
                                    onClick: () => fnFormatTableDeletion(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'table-deletion-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="table deletion button">
                    <DeleteSweepIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatTableInsertion = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'table') {
            let { idxData, rowKey, idxColumn } = check;
            if (value === 'above' || value === 'below') {
                let oldRows = details[idxData].rows;
                let selectedRow = oldRows[rowKey].map((cell) => {
                    return { ...cell, content: ' ' };
                });
                let pos = Object.keys(oldRows).indexOf(
                    Number(rowKey).toString()
                );
                const updatedRowsValue = Object.values(oldRows);
                updatedRowsValue.splice(
                    value === 'below' ? pos + 1 : pos,
                    0,
                    selectedRow
                );

                let newRows = {};
                for (let index = 0; index < updatedRowsValue.length; index++) {
                    const element = updatedRowsValue[index];
                    newRows[index] = element;
                }
                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: newRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else if (value === 'left' || value === 'right') {
                let updatedRows = details[idxData].rows;
                let rowKeyList = Object.keys(updatedRows);
                for (let index = 0; index < rowKeyList.length; index++) {
                    const key = rowKeyList[index];
                    const updatedRow = updatedRows[key];

                    updatedRow.splice(
                        value === 'right'
                            ? Number(idxColumn) + 1
                            : Number(idxColumn),
                        0,
                        { ...updatedRows[key][idxColumn], content: ' ' }
                    );

                    updatedRows[key] = updatedRow;
                }

                let res = await [].concat(
                    details.slice(0, idxData),
                    [
                        {
                            ...details[idxData],
                            rows: updatedRows,
                        },
                    ],
                    details.slice(idxData + 1, details.length)
                );
                setDetails(res);
            } else return;
        } else return;
    };
    const tableInsertionToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            disabled={selectedBoxData?.type !== 'table' || !selectedBoxData}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="table insertion">
            <Tooltip
                arrow
                title={'Chèn hàng/cột'}
                placement="top"
                key={'table insertion tooltip'}>
                <ToggleButton
                    value="tableInsertion"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('table-insertion-menu');
                        setMenuItems(
                            ['left', 'right', 'above', 'below'].map((i) => {
                                const tableInsertionNameMap = {
                                    above: {
                                        title: 'Chèn thêm 1 hàng ở trên',
                                        icon: (
                                            <NorthIcon
                                                fontSize="small"
                                                sx={{
                                                    width: 15,
                                                    height: 15,
                                                    borderTop:
                                                        '2px solid black',
                                                    borderRadius: '2px',
                                                }}
                                            />
                                        ),
                                    },
                                    below: {
                                        title: 'Chèn thêm 1 hàng ở dưới',
                                        icon: (
                                            <SouthIcon
                                                fontSize="small"
                                                sx={{
                                                    width: 15,
                                                    height: 15,
                                                    borderBottom:
                                                        '2px solid black',
                                                    borderRadius: '2px',
                                                }}
                                            />
                                        ),
                                    },
                                    left: {
                                        title: 'Chèn thêm 1 cột bên trái',
                                        icon: (
                                            <WestIcon
                                                fontSize="small"
                                                sx={{
                                                    width: 15,
                                                    height: 15,
                                                    borderLeft:
                                                        '2px solid black',
                                                    borderRadius: '2px',
                                                }}
                                            />
                                        ),
                                    },
                                    right: {
                                        title: 'Chèn thêm 1 cột bên phải',
                                        icon: (
                                            <EastIcon
                                                fontSize="small"
                                                sx={{
                                                    width: 15,
                                                    height: 15,
                                                    borderRight:
                                                        '2px solid black',
                                                    borderRadius: '2px',
                                                }}
                                            />
                                        ),
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Box
                                            key={'table-deletion-menu' + i}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            {tableInsertionNameMap[i].icon}
                                            <Typography
                                                variant="caption"
                                                sx={{ fontSize: 14, ml: 1 }}>
                                                {tableInsertionNameMap[i].title}
                                            </Typography>
                                        </Box>
                                    ),
                                    onClick: () => fnFormatTableInsertion(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'table-insertion-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="table insertion button">
                    <PlaylistAddIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatMergeCell = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'table') {
            let { idxData, rowKey, idxColumn } = check;
            let updatedRows = details[idxData].rows;
            let currentCell = updatedRows[rowKey][idxColumn];
            if (value === 'left') {
                if (Number(idxColumn) === 0) {
                    return;
                } else {
                    let leftCell = updatedRows[rowKey][Number(idxColumn) - 1];
                    updatedRows[rowKey] = [].concat(
                        updatedRows[rowKey].slice(0, Number(idxColumn) - 1),
                        [
                            {
                                ...leftCell,
                                boxProps: {
                                    ...(leftCell?.boxProps ?? {}),
                                    mergeCount:
                                        Number(
                                            leftCell?.boxProps?.mergeCount ?? 0
                                        ) +
                                        Number(
                                            currentCell?.boxProps?.mergeCount ??
                                                0
                                        ) +
                                        1,
                                },
                                content: leftCell.content + currentCell.content,
                            },
                        ],
                        updatedRows[rowKey].slice(
                            Number(idxColumn) + 1,
                            updatedRows[rowKey].length
                        )
                    );
                }
            } else if (value === 'right') {
                if (Number(idxColumn) + 1 === updatedRows[rowKey].length) {
                    return;
                } else {
                    let rightCell = updatedRows[rowKey][Number(idxColumn) + 1];
                    updatedRows[rowKey] = [].concat(
                        updatedRows[rowKey].slice(0, idxColumn),
                        [
                            {
                                ...updatedRows[rowKey][idxColumn],
                                boxProps: {
                                    ...(currentCell?.boxProps ?? {}),
                                    mergeCount:
                                        Number(
                                            rightCell?.boxProps?.mergeCount ?? 0
                                        ) +
                                        Number(
                                            currentCell?.boxProps?.mergeCount ??
                                                0
                                        ) +
                                        1,
                                },
                                content:
                                    currentCell.content + rightCell.content,
                            },
                        ],
                        updatedRows[rowKey].slice(
                            Number(idxColumn) + 2,
                            updatedRows[rowKey].length
                        )
                    );
                }
            } else {
                let mergeCount = currentCell?.boxProps?.mergeCount ?? 0;

                if (mergeCount === 0) {
                    return;
                } else {
                    let { width, mergeCount, ...oldBoxPropsOfCurrentCell } =
                        currentCell?.boxProps ?? {};
                    let newData = Array.from(
                        { length: Number(mergeCount) + 1 },
                        (x, i) => {
                            if (i === 0) {
                                return {
                                    ...(oldBoxPropsOfCurrentCell && {
                                        boxProps: {
                                            ...oldBoxPropsOfCurrentCell,
                                        },
                                    }),
                                    content: currentCell.content,
                                };
                            }
                            return {
                                ...(oldBoxPropsOfCurrentCell && {
                                    boxProps: {
                                        ...oldBoxPropsOfCurrentCell,
                                    },
                                }),
                                content: ' ',
                            };
                        }
                    );
                    updatedRows[rowKey] = [].concat(
                        updatedRows[rowKey].slice(0, idxColumn),
                        newData,
                        updatedRows[rowKey].slice(
                            Number(idxColumn) + 1,
                            updatedRows[rowKey].length
                        )
                    );
                }
            }

            let res = await [].concat(
                details.slice(0, idxData),
                [
                    {
                        ...details[idxData],
                        rows: updatedRows,
                    },
                ],
                details.slice(idxData + 1, details.length)
            );
            setDetails(res);
        } else return;
    };
    const mergeCellsToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            disabled={selectedBoxData?.type !== 'table' || !selectedBoxData}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="merge cells">
            <Tooltip
                arrow
                title={'Gộp ô'}
                placement="top"
                key={'merge cell tooltip'}>
                <ToggleButton
                    value="mergeCells"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('merge-cells-menu');
                        setMenuItems(
                            ['none', 'left', 'right'].map((i) => {
                                const mergeCellsNameMap = {
                                    left: {
                                        title: 'Gộp ô hiện đang chọn với ô bên trái',
                                        icon: (
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    mb: -1,
                                                }}>
                                                <WestIcon
                                                    fontSize="small"
                                                    sx={{
                                                        width: 40,
                                                        height: 15,
                                                        position: 'absolute',
                                                        top: -6,
                                                        left: -14,
                                                    }}
                                                />
                                                <JoinLeftIcon
                                                    sx={{
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                />
                                            </Box>
                                        ),
                                    },
                                    right: {
                                        title: 'Gộp ô hiện đang chọn với ô bên phải',
                                        icon: (
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    mb: -1,
                                                }}>
                                                <EastIcon
                                                    fontSize="small"
                                                    sx={{
                                                        width: 40,
                                                        height: 15,
                                                        position: 'absolute',
                                                        top: -6,
                                                        left: -10,
                                                    }}
                                                />
                                                <JoinRightIcon
                                                    sx={{
                                                        width: 15,
                                                        height: 15,
                                                    }}
                                                />
                                            </Box>
                                        ),
                                    },
                                    none: {
                                        title: 'Bỏ gộp ô đang chọn',
                                        icon: (
                                            <Box sx={{ position: 'relative' }}>
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 10,
                                                        left: -2,
                                                        transform:
                                                            'rotate(45deg)',
                                                        backgroundColor:
                                                            'black',
                                                        width: '25px',
                                                        height: '2px',
                                                    }}
                                                    component={'span'}></Box>
                                                <ViewComfyOutlinedIcon />
                                            </Box>
                                        ),
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Box
                                            key={'merge-cells-menu' + i}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            {mergeCellsNameMap[i].icon}
                                            <Typography
                                                variant="caption"
                                                sx={{ fontSize: 14, ml: 1 }}>
                                                {mergeCellsNameMap[i].title}
                                            </Typography>
                                        </Box>
                                    ),
                                    onClick: () => fnFormatMergeCell(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'merge-cells-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="merge cells button">
                    <TableChartOutlinedIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatTableRotation = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { idxData } = check;
        let newData = await fnApplyBoxProps(
            details[idxData],
            'transform',
            value
        );
        let res = await formatBoxInOtherType(details, idxData, newData);

        setDetails(res);
    };
    const tableRotationToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="table rotation toggle button">
            <Tooltip
                arrow
                title="Xoay khung theo chiều kim đồng hồ"
                placement="top">
                <ToggleButton
                    value="tableRotation"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('table-rotation-menu');
                        setMenuItems(
                            [
                                'default',
                                45,
                                90,
                                135,
                                180,
                                225,
                                270,
                                315,
                                360,
                            ].map((i) => {
                                const tableRotationNameMap = {
                                    default: {
                                        title: 'Mặc định',
                                        value: 'default',
                                    },
                                    45: {
                                        title: '45 độ',
                                        value: 'rotate(45deg)',
                                    },
                                    90: {
                                        title: '90 độ',
                                        value: 'rotate(90deg)',
                                    },
                                    135: {
                                        title: '135 độ',
                                        value: 'rotate(135deg)',
                                    },
                                    180: {
                                        title: '180 độ',
                                        value: 'rotate(180deg)',
                                    },
                                    225: {
                                        title: '225 độ',
                                        value: 'rotate(225deg)',
                                    },
                                    270: {
                                        title: '270 độ',
                                        value: 'rotate(270deg)',
                                    },
                                    315: {
                                        title: '315 độ',
                                        value: 'rotate(315deg)',
                                    },
                                    360: {
                                        title: '360 độ',
                                        value: 'rotate(360deg)',
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'table-rotation-button-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 15,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {tableRotationNameMap[i].title}
                                        </Typography>
                                    ),
                                    onClick: () =>
                                        fnFormatTableRotation(
                                            tableRotationNameMap[i].value
                                        ),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'table-rotation-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="table rotation button">
                    <Rotate90DegreesCwIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatTableBoxMarginTop = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { idxData } = check;
        let newData = await fnApplyBoxProps(
            details[idxData],
            'marginTop',
            value
        );
        let res = await formatBoxInOtherType(details, idxData, newData);

        setDetails(res);
    };
    const tableBoxMarginTopToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="table box margin top toggle button">
            <Tooltip
                arrow
                title="Khoảng cách so với khung phía trên"
                placement="top">
                <ToggleButton
                    value="tableBoxMagrinTop"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('table-box-margin-top-menu');
                        setMenuItems(
                            [
                                'default',
                                '10px',
                                '20px',
                                '30px',
                                '50px',
                                '60px',
                                '80px',
                                '100px',
                                '120px',
                                '150px',
                                '200px',
                                '250px',
                                '300px',
                            ].map((i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={
                                                'table-box-margin-top-menu-' + i
                                            }
                                            variant="caption"
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default' ? 'Mặc định' : i}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatTableBoxMarginTop(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'table-box-margin-top-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="table box margin top button">
                    <UpgradeIcon
                        fontSize="small"
                        sx={{
                            width: 20,
                            height: 20,
                            borderTop: '2px solid grey',
                            borderRadius: '2px',
                        }}
                    />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    // combo 6: image
    const imageModeToggleButton = (
        <Tooltip
            arrow
            title="Chế độ áp dụng khi định dạng ảnh"
            placement="top-start">
            <ToggleButton
                value="imageMode"
                sx={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 38,
                    minWidth: 60,
                    p: 0,
                    '&.Mui-disabled': {
                        border: 'none',
                    },
                }}
                disabled={selectedBoxData?.type !== 'image' || !selectedBoxData}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('image-mode-menu');
                    setMenuItems(
                        [0, 1].map((i, idx) => {
                            const imageModeNameMap = {
                                1: {
                                    title: 'Áp dụng định dạng cho hình đang chọn',
                                    icon: (
                                        <ImageOutlinedIcon fontSize="small" />
                                    ),
                                },
                                0: {
                                    title: 'Áp dụng định dạng cho khung chứa hình',
                                    icon: (
                                        <PictureInPictureOutlinedIcon fontSize="small" />
                                    ),
                                },
                            };
                            return {
                                value: i,
                                label: (
                                    <Box
                                        key={'image-mode-menu' + i}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        {imageModeNameMap[i].icon}
                                        <Typography
                                            variant="caption"
                                            sx={{ fontSize: 14, ml: 1 }}>
                                            {imageModeNameMap[i].title}
                                        </Typography>
                                    </Box>
                                ),
                                onClick: () => {
                                    setImageMode(Boolean(i));
                                },
                            };
                        })
                    );
                }}
                aria-controls={openFilterMenu ? 'image-mode-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="image mode">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <ModelTrainingIcon
                        sx={{
                            color:
                                selectedBoxData?.type !== 'image' ||
                                !selectedBoxData
                                    ? 'lightgrey'
                                    : 'gold',
                        }}
                        fontSize="small"
                    />
                    <ArrowDropDownIcon
                        fontSize="small"
                        sx={{
                            color:
                                selectedBoxData?.type !== 'image' ||
                                !selectedBoxData
                                    ? 'lightgrey'
                                    : 'darkblue',
                        }}
                    />
                </Box>
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: 8,
                        textTransform: 'initial',
                        fontWeight: 500,
                        color:
                            selectedBoxData?.type !== 'image' ||
                            !selectedBoxData
                                ? 'lightgrey'
                                : 'darkblue',
                    }}>
                    {imageMode ? 'Ảnh hiện tại' : 'Khung chứa ảnh'}
                </Typography>
            </ToggleButton>
        </Tooltip>
    );
    const fnFormatImageDefaultSetting = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'image') {
            if (value === 'formatClear') {
                let { idxData } = check;
                let newData = { ...details[idxData] };
                if (newData.hasOwnProperty('imageProps')) {
                    delete newData.imageProps;
                }
                if (newData.hasOwnProperty('boxProps')) {
                    delete newData.boxProps;
                }
                let res = await formatBoxInOtherType(details, idxData, newData);

                setDetails(res);
            } else return;
        } else return;
    };
    const imageDefaultSettingToggleButton = (
        <Tooltip arrow title="Khôi phục cài đặt mặc định" placement="top">
            <ToggleButton
                value="imageDefaultSetting"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setArialLabel('image-default-setting-menu');
                    setMenuItems(
                        ['formatClear'].map((i) => {
                            const imageDefaultSettingNameMap = {
                                formatClear: {
                                    title: 'Bỏ các định dạng đang áp dụng',
                                    icon: (
                                        <FormatColorResetIcon fontSize="small" />
                                    ),
                                },
                            };
                            return {
                                value: i,
                                label: (
                                    <Box
                                        key={'image-default-setting-menu' + i}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        {imageDefaultSettingNameMap[i].icon}
                                        <Typography
                                            variant="caption"
                                            sx={{ fontSize: 14, ml: 1 }}>
                                            {
                                                imageDefaultSettingNameMap[i]
                                                    .title
                                            }
                                        </Typography>
                                    </Box>
                                ),
                                onClick: () => fnFormatImageDefaultSetting(i),
                            };
                        })
                    );
                }}
                sx={{
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 38,
                    minWidth: 60,
                    p: 0,
                    '&.Mui-disabled': {
                        border: 'none',
                    },
                }}
                disabled={selectedBoxData?.type !== 'image' || !selectedBoxData}
                aria-controls={
                    openFilterMenu ? 'image-default-setting-menu' : undefined
                }
                aria-haspopup="true"
                aria-expanded={openFilterMenu ? 'true' : undefined}
                aria-label="image default setting button">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <SettingsIcon
                        sx={{
                            color:
                                selectedBoxData?.type !== 'image' ||
                                !selectedBoxData
                                    ? 'lightgrey'
                                    : 'grey',
                        }}
                        fontSize="small"
                    />
                    <MoreVertIcon fontSize="small" />
                </Box>
                <Typography
                    variant="caption"
                    sx={{
                        fontSize: 8,
                        textTransform: 'initial',
                        fontWeight: 600,
                        color:
                            selectedBoxData?.type !== 'image' ||
                            !selectedBoxData
                                ? 'lightgrey'
                                : 'grey',
                    }}>
                    Cài đặt gốc
                </Typography>
            </ToggleButton>
        </Tooltip>
    );
    const fnFormatImagePadding = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'image') {
            let { idxData } = check;
            if (imageMode) {
                let newData = await fnApplyImageProps(
                    details[idxData],
                    'padding',
                    value
                );
                let res = await formatBoxInOtherType(details, idxData, newData);

                setDetails(res);
            } else {
                let newData = await fnApplyBoxProps(
                    details[idxData],
                    'padding',
                    value
                );
                let res = await formatBoxInOtherType(details, idxData, newData);
                setDetails(res);
            }
        } else return;
    };
    const imagePaddingToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            disabled={selectedBoxData?.type !== 'image' || !selectedBoxData}
            aria-label="image padding toggle button">
            <Tooltip
                arrow
                title="Khoảng cách giữa nội dung và phần viền"
                placement="top">
                <ToggleButton
                    value="imagePadding"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('image-padding-menu');
                        setMenuItems(
                            [
                                'default',
                                '10px',
                                '20px',
                                '30px',
                                '50px',
                                '60px',
                                '80px',
                                '100px',
                                '120px',
                                '150px',
                                '200px',
                                '250px',
                                '300px',
                            ].map((i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'image-padding-menu-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default' ? 'Mặc định' : i}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatImagePadding(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'image-padding-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="image padding button">
                    <PaddingIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatImageMarginTop = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'image') {
            let { idxData } = check;
            if (imageMode) {
                let newData = await fnApplyImageProps(
                    details[idxData],
                    'marginTop',
                    value
                );
                let res = await formatBoxInOtherType(details, idxData, newData);

                setDetails(res);
            } else {
                let newData = await fnApplyBoxProps(
                    details[idxData],
                    'marginTop',
                    value
                );
                let res = await formatBoxInOtherType(details, idxData, newData);
                setDetails(res);
            }
        } else return;
    };
    const imageMarginTopToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            disabled={selectedBoxData?.type !== 'image' || !selectedBoxData}
            aria-label="image margin top toggle button">
            <Tooltip
                arrow
                title="Khoảng cách so với nội dung phía trên"
                placement="top">
                <ToggleButton
                    value="imageMarginTop"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('image-margin-top-menu');
                        setMenuItems(
                            [
                                'default',
                                '10px',
                                '20px',
                                '30px',
                                '50px',
                                '60px',
                                '80px',
                                '100px',
                                '120px',
                                '150px',
                                '200px',
                                '250px',
                                '300px',
                            ].map((i) => {
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'image-margin-top-menu-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 13,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {i === 'default' ? 'Mặc định' : i}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatImageMarginTop(i),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'image-margin-top-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="image margin top button">
                    <UpgradeIcon
                        fontSize="small"
                        sx={{
                            width: 20,
                            height: 20,
                            borderTop: `2px solid ${
                                selectedBoxData?.type === 'image'
                                    ? 'grey'
                                    : 'lightgrey'
                            }`,
                            borderRadius: '2px',
                        }}
                    />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatImageWidth = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type } = check;
        if (type === 'image') {
            let { idxData } = check;
            if (imageMode) {
                let newData = await fnApplyImageProps(
                    details[idxData],
                    'width',
                    value
                );
                let res = await formatBoxInOtherType(details, idxData, newData);

                setDetails(res);
            } else {
                let newData = await fnApplyBoxProps(
                    details[idxData],
                    'width',
                    value
                );
                let res = await formatBoxInOtherType(details, idxData, newData);
                setDetails(res);
            }
        } else return;
    };
    const imageWidthFormattingToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            onChange={() => {}}
            disabled={selectedBoxData?.type !== 'image' || !selectedBoxData}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="image width formatting">
            <Tooltip
                arrow
                title={'Thay đổi chiều rộng của ảnh'}
                placement="top"
                key={'image width formatting'}>
                <ToggleButton
                    value={'imageWidth'}
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('image-width-menu');
                        setMenuItems(
                            [
                                'default',
                                '0%',
                                '10%',
                                '20%',
                                '30%',
                                '40%',
                                '50%',
                                '60%',
                                '70%',
                                '80%',
                                '90%',
                                '100%',
                                '110%',
                                '130%',
                                '150%',
                                '200%',
                                '250%',
                            ].map((v) => {
                                return {
                                    value: v,
                                    label: (
                                        <Typography
                                            key={'image-width-menu-' + v}
                                            variant="caption"
                                            sx={{
                                                fontSize: 14,
                                                ...(v === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {v === 'default' ? 'Mặc định' : v}
                                        </Typography>
                                    ),
                                    onClick: () => fnFormatImageWidth(v),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'image-width-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label={'image width button'}>
                    <SwapHorizIcon
                        fontSize="small"
                        sx={{
                            borderLeft: `2px solid ${
                                selectedBoxData?.type !== 'image'
                                    ? 'lightgrey'
                                    : 'grey'
                            }`,
                            borderRight: `2px solid ${
                                selectedBoxData?.type !== 'image'
                                    ? 'lightgrey'
                                    : 'grey'
                            }`,
                            borderRadius: '2px',
                        }}
                    />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatImageRotation = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { type, idxData } = check;
        if (type === 'image') {
            if (imageMode) {
                let newData = await fnApplyImageProps(
                    details[idxData],
                    'transform',
                    value
                );
                let res = await formatBoxInOtherType(details, idxData, newData);

                setDetails(res);
            } else {
                let newData = await fnApplyBoxProps(
                    details[idxData],
                    'transform',
                    value
                );
                let res = await formatBoxInOtherType(details, idxData, newData);
                setDetails(res);
            }
        } else return;
    };
    const imageRotationToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="image rotation toggle button">
            <Tooltip
                arrow
                title="Xoay khung theo chiều kim đồng hồ"
                placement="top">
                <ToggleButton
                    value="imageRotation"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('image-rotation-menu');
                        setMenuItems(
                            [
                                'default',
                                45,
                                90,
                                135,
                                180,
                                225,
                                270,
                                315,
                                360,
                            ].map((i) => {
                                const imageRotationNameMap = {
                                    default: {
                                        title: 'Mặc định',
                                        value: 'default',
                                    },
                                    45: {
                                        title: '45 độ',
                                        value: 'rotate(45deg)',
                                    },
                                    90: {
                                        title: '90 độ',
                                        value: 'rotate(90deg)',
                                    },
                                    135: {
                                        title: '135 độ',
                                        value: 'rotate(135deg)',
                                    },
                                    180: {
                                        title: '180 độ',
                                        value: 'rotate(180deg)',
                                    },
                                    225: {
                                        title: '225 độ',
                                        value: 'rotate(225deg)',
                                    },
                                    270: {
                                        title: '270 độ',
                                        value: 'rotate(270deg)',
                                    },
                                    315: {
                                        title: '315 độ',
                                        value: 'rotate(315deg)',
                                    },
                                    360: {
                                        title: '360 độ',
                                        value: 'rotate(360deg)',
                                    },
                                };
                                return {
                                    value: i,
                                    label: (
                                        <Typography
                                            key={'image-rotation-button-' + i}
                                            variant="caption"
                                            sx={{
                                                fontSize: 15,
                                                ...(i === 'default' &&
                                                    defaultBoxCSS),
                                            }}>
                                            {imageRotationNameMap[i].title}
                                        </Typography>
                                    ),
                                    onClick: () =>
                                        fnFormatImageRotation(
                                            imageRotationNameMap[i].value
                                        ),
                                };
                            })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu ? 'image-rotation-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="image rotation button">
                    <Rotate90DegreesCwIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    const fnFormatImageBackgroundColor = async (value) => {
        let check = await idxValidator(selectedBoxData);
        if (!check) {
            return;
        }
        let { idxData } = check;
        if (imageMode) {
            let newData = await fnApplyImageProps(
                details[idxData],
                'backgroundColor',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);

            setDetails(res);
        } else {
            let newData = await fnApplyBoxProps(
                details[idxData],
                'backgroundColor',
                value
            );
            let res = await formatBoxInOtherType(details, idxData, newData);
            setDetails(res);
        }
    };
    const imageBackgroundColorToggleButton = (
        <ToggleButtonGroup
            size="small"
            value={''}
            exclusive
            onChange={() => {}}
            sx={{
                ...customSxToggleButtonGroup,
            }}
            aria-label="image background color toggle button">
            <Tooltip arrow title="Màu nền" placement="top">
                <ToggleButton
                    value="imageBackgroundColor"
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setArialLabel('image-background-color-menu');
                        setMenuItems(
                            ['default']
                                .concat(Object.keys(colorNameMap))
                                .map((color) => {
                                    return {
                                        value: color,
                                        label:
                                            color === 'default' ? (
                                                <Typography
                                                    key={
                                                        'image-background-color-menu-default'
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...defaultBoxCSS,
                                                    }}>
                                                    Mặc định
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    key={
                                                        'image-background-color-menu-' +
                                                        color
                                                    }
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: 14,
                                                        ...(color ===
                                                            'default' &&
                                                            defaultBoxCSS),
                                                    }}>
                                                    <SquareRoundedIcon
                                                        sx={{
                                                            width:
                                                                color ===
                                                                'white'
                                                                    ? 15
                                                                    : 20,
                                                            height:
                                                                color ===
                                                                'white'
                                                                    ? 15
                                                                    : 20,
                                                            ml:
                                                                color ===
                                                                'white'
                                                                    ? '3px'
                                                                    : 0,
                                                            borderRadius:
                                                                color ===
                                                                'white'
                                                                    ? '1px'
                                                                    : 'none',
                                                            mr: 0.5,
                                                            color: color,
                                                            border:
                                                                color ===
                                                                'white'
                                                                    ? '0.25px solid grey'
                                                                    : 'none',
                                                        }}
                                                    />
                                                    {colorNameMap[color]}
                                                </Typography>
                                            ),
                                        onClick: () =>
                                            fnFormatImageBackgroundColor(color),
                                    };
                                })
                        );
                    }}
                    sx={{
                        ...customSxToggleButton,
                        minWidth: 40,
                    }}
                    aria-controls={
                        openFilterMenu
                            ? 'image-background-color-menu'
                            : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFilterMenu ? 'true' : undefined}
                    aria-label="image background color button">
                    <FormatColorFillIcon fontSize="small" />
                    <ArrowDropDownIcon fontSize="small" />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
    // function apply format box opacity
    const [imageOpacitySliderValue, setImageOpacitySliderValue] =
        React.useState(100);
    React.useEffect(() => {
        let input = setTimeout(async () => {
            let value = Number(imageOpacitySliderValue / 100);
            let check = await idxValidator(selectedBoxData);
            if (!check) {
                return;
            }
            let { idxData } = check;
            if (imageMode) {
                let newData = await fnApplyImageProps(
                    details[idxData],
                    'opacity',
                    value
                );
                let res = await formatBoxInOtherType(details, idxData, newData);

                setDetails(res);
            } else {
                let newData = await fnApplyBoxProps(
                    details[idxData],
                    'opacity',
                    value
                );
                let res = await formatBoxInOtherType(details, idxData, newData);
                setDetails(res);
            }
        }, 1000);
        return () => {
            clearTimeout(input);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageOpacitySliderValue]);

    return (
        <Box
            sx={{
                minHeight: 110,
                p: 0,
                position: '-webkit-sticky',
                // eslint-disable-next-line no-dupe-keys
                position: 'sticky',
                top: -17,
                backgroundColor: 'white',
                zIndex: 500,
            }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    borderBottom: '0.25px solid lightgrey',
                    py: 1,
                    ...customScrollbar,
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}>
                    <Typography
                        variant="caption"
                        sx={{
                            fontSize: 11,
                            width: 55,
                            height: 18,
                            backgroundColor: 'aliceblue',
                            borderRadius: '0 0 5px 0',
                            color: 'black',
                            border: ' 0.25px solid lightgrey',
                            borderTop: 'none',
                            px: 0.5,
                            mt: -1,
                            mb: 0.5,
                        }}>
                        Công cụ
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            width: '100%',
                        }}>
                        {templateSampleToggleButton}
                        {verticalDivider}
                        {boxSampleToggleButton}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-evenly',
                            width: '100%',
                        }}>
                        {formatSpecialToggleButton}
                    </Box>
                </Box>
                {fullVerticalDivider}
                {/* Định dạng chữ chỉ ẩn trong type image */}
                {selectedBoxData?.type !== 'image' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Typography
                            variant="caption"
                            sx={{
                                fontSize: 11,
                                height: 18,
                                backgroundColor: 'aliceblue',
                                borderRadius: '0 0 5px 0',
                                color: 'black',
                                border: ' 0.25px solid lightgrey',
                                borderTop: 'none',
                                px: 0.5,
                                mt: -1,
                                mb: 0.5,
                            }}>
                            Định dạng chữ
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                            {textAlignmentToggleButton}
                            {verticalDivider}
                            {lineHeightToggleButton}
                            {verticalDivider}
                            {textIndentToggleButton}
                            {verticalDivider}
                            {textTransformToggleButton}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                            {fontSizeToggleButton}
                            {verticalDivider}
                            {boldToggleButton}
                            {italicToggleButton}
                            {underlineToggleButton}
                            {verticalDivider}
                            {textColorToggleButton}
                            {textRotationToggleButton}
                        </Box>
                    </Box>
                )}
                {/* Định dạng khung ẩn trong type image, list, table */}
                {fullVerticalDivider}
                {!['image', 'list', 'table'].includes(
                    selectedBoxData?.type
                ) && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Typography
                            variant="caption"
                            sx={{
                                fontSize: 11,
                                height: 18,
                                backgroundColor: 'aliceblue',
                                borderRadius: '0 0 5px 0',
                                color: 'black',
                                border: ' 0.25px solid lightgrey',
                                borderTop: 'none',
                                px: 0.5,
                                mt: -1,
                                mb: 0.5,
                            }}>
                            Định dạng khung
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                            {boxDefaultSettingToggleButton}
                            {verticalDivider}
                            {bulletinTypeToggleButton}
                            {numberingTypeToggleButton}
                            {verticalDivider}
                            {boxPaddingToggleButton}
                            {boxMarginTopToggleButton}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                width: '100%',
                                borderTop: '0.5px solid lightgrey',
                                borderRadius: '2px',
                            }}>
                            {borderFormattingToggleButton}
                            {boxRotationToggleButton}
                            {verticalDivider}
                            {boxBackgroundColorToggleButton}
                            {verticalDivider}
                            <Box
                                sx={{
                                    width: '92%',
                                    height: 35,
                                    pl: 1,
                                    pr: 1.5,
                                    mx: 0.5,
                                    minWidth: 100,
                                    position: 'relative',
                                }}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    sx={{
                                        fontSize: 8,
                                        position: 'absolute',
                                        width: 36,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        top: -7,
                                        left: 7,
                                        backgroundColor: 'white',
                                    }}>
                                    Opacity
                                </Typography>
                                <Slider
                                    size="small"
                                    valueLabelDisplay="auto"
                                    defaultValue={100}
                                    onChange={(event, newValue) => {
                                        setBoxOpacitySliderValue(newValue);
                                    }}
                                    aria-label="box-opacity-slider"
                                    sx={customSliderCSS}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
                {selectedBoxData?.type === 'list' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Typography
                            variant="caption"
                            sx={{
                                fontSize: 11,
                                height: 18,
                                backgroundColor: 'aliceblue',
                                borderRadius: '0 0 5px 0',
                                color: 'black',
                                border: ' 0.25px solid lightgrey',
                                borderTop: 'none',
                                px: 0.5,
                                mt: -1,
                                mb: 0.5,
                            }}>
                            Định dạng danh sách
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                            {listModeToggleButton}
                            {listDefaultSettingToggleButton}
                            {verticalDivider}
                            {bulletinTypeToggleButton}
                            {numberingTypeToggleButton}
                            {verticalDivider}
                            {borderFormattingToggleButton}
                            {listRotationToggleButton}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                width: '100%',
                                borderTop: '0.5px solid lightgrey',
                                borderRadius: '2px',
                            }}>
                            {listPaddingToggleButton}
                            {listMarginTopToggleButton}
                            {verticalDivider}
                            {listBackgroundColorToggleButton}
                            {verticalDivider}
                            <Box
                                sx={{
                                    width: '92%',
                                    height: 35,
                                    pl: 1,
                                    pr: 1.5,
                                    mx: 0.5,
                                    minWidth: 100,
                                    position: 'relative',
                                }}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    sx={{
                                        fontSize: 8,
                                        position: 'absolute',
                                        width: 36,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        top: -7,
                                        left: 7,
                                        backgroundColor: 'white',
                                    }}>
                                    Opacity
                                </Typography>
                                <Slider
                                    size="small"
                                    valueLabelDisplay="auto"
                                    defaultValue={100}
                                    disabled={
                                        selectedBoxData?.type !== 'list' ||
                                        !selectedBoxData
                                    }
                                    onChange={(event, newValue) => {
                                        setListOpacitySliderValue(newValue);
                                    }}
                                    aria-label="list-opacity-slider"
                                    sx={customSliderCSS}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
                {selectedBoxData?.type === 'table' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Typography
                            variant="caption"
                            sx={{
                                fontSize: 11,
                                height: 18,
                                backgroundColor: 'aliceblue',
                                borderRadius: '0 0 5px 0',
                                color: 'black',
                                border: ' 0.25px solid lightgrey',
                                borderTop: 'none',
                                px: 0.5,
                                mt: -1,
                                mb: 0.5,
                            }}>
                            Định dạng bảng
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                            {tableModeToggleButton}
                            {tableDefaultSettingToggleButton}
                            {verticalDivider}
                            {cellWidthFormattingToggleButton}
                            {tablePaddingToggleButton}
                            {verticalDivider}
                            {borderFormattingToggleButton}
                            {tableBackgroundColorToggleButton}
                            {verticalDivider}
                            <Box
                                sx={{
                                    width: '92%',
                                    height: 35,
                                    pl: 1,
                                    pr: 1.5,
                                    mx: 0.5,
                                    minWidth: 100,
                                    position: 'relative',
                                }}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    sx={{
                                        fontSize: 8,
                                        position: 'absolute',
                                        width: 36,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        top: -7,
                                        left: 7,
                                        backgroundColor: 'white',
                                    }}>
                                    Opacity
                                </Typography>
                                <Slider
                                    size="small"
                                    valueLabelDisplay="auto"
                                    defaultValue={100}
                                    disabled={
                                        selectedBoxData?.type !== 'table' ||
                                        !selectedBoxData
                                    }
                                    onChange={(event, newValue) => {
                                        setTableOpacitySliderValue(newValue);
                                    }}
                                    aria-label="table-opacity-slider"
                                    sx={customSliderCSS}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                width: '100%',
                                borderTop: '0.5px solid lightgrey',
                                borderRadius: '2px',
                            }}>
                            {tableSizeToggleButton}
                            {verticalDivider}
                            {tableDeletionToggleButton}
                            {tableInsertionToggleButton}
                            {mergeCellsToggleButton}
                            {verticalDivider}
                            {tableRotationToggleButton}
                            {tableBoxMarginTopToggleButton}
                            {tableMarginToggleButton}
                        </Box>
                    </Box>
                )}
                {selectedBoxData?.type === 'image' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        <Typography
                            variant="caption"
                            sx={{
                                fontSize: 11,
                                height: 18,
                                backgroundColor: 'aliceblue',
                                borderRadius: '0 0 5px 0',
                                color: 'black',
                                border: ' 0.25px solid lightgrey',
                                borderTop: 'none',
                                px: 0.5,
                                mt: -1,
                                mb: 0.5,
                            }}>
                            Định dạng ảnh
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                            {imageModeToggleButton}
                            {imageDefaultSettingToggleButton}
                            {verticalDivider}

                            {imagePaddingToggleButton}
                            {imageMarginTopToggleButton}
                            {imageRotationToggleButton}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                width: '100%',
                                borderTop: '0.5px solid lightgrey',
                                borderRadius: '2px',
                            }}>
                            {imageWidthFormattingToggleButton}
                            {borderFormattingToggleButton}
                            {imageBackgroundColorToggleButton}
                            {verticalDivider}
                            <Box
                                sx={{
                                    width: '92%',
                                    height: 35,
                                    pl: 1,
                                    pr: 1.5,
                                    mx: 0.5,
                                    minWidth: 100,
                                    position: 'relative',
                                }}>
                                <Typography
                                    variant="caption"
                                    gutterBottom
                                    sx={{
                                        fontSize: 8,
                                        position: 'absolute',
                                        width: 36,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        top: -7,
                                        left: 7,
                                        backgroundColor: 'white',
                                    }}>
                                    Opacity
                                </Typography>
                                <Slider
                                    size="small"
                                    valueLabelDisplay="auto"
                                    defaultValue={100}
                                    onChange={(event, newValue) => {
                                        setImageOpacitySliderValue(newValue);
                                    }}
                                    aria-label="image-opacity-slider"
                                    sx={customSliderCSS}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            {openFilterMenu && (
                <CustomMenuFilter
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    arialLabel={arialLabel}
                    menuItems={menuItems}
                />
            )}
            {alertTemplateChangedDialog && (
                <WarningDialog
                    id="warning-dialog-update-template"
                    warningType="update"
                    open={Boolean(alertTemplateChangedDialog)}
                    onClose={() => setAlertTemplateChangedDialog(null)}
                    onSubmit={async () => {
                        let newDetails = await applyDefaultTemplate(
                            alertTemplateChangedDialog
                        );
                        setDetails(newDetails);
                        setTimeout(() => {
                            setAlertTemplateChangedDialog(null);
                        }, 1000);
                    }}
                    content={
                        <Typography>
                            Bạn đang thay đổi template mới. Nếu tiếp tục, dữ
                            liệu trong template cũ sẽ bị xóa bỏ và không thể
                            khôi phục lại.
                        </Typography>
                    }
                />
            )}
            {openBorderFormattingDialog && (
                <CustomDialog
                    id="border-formatting-custom-dialog"
                    isChildDialog
                    open={openBorderFormattingDialog}
                    title="Định dạng viền và bo góc"
                    color="default"
                    onClose={() => {
                        setOpenBorderFormattingDialog(false);
                    }}
                    labelYesButton="Áp dụng"
                    onYes={handleApplyFormatBorder}>
                    <Box
                        sx={{
                            p: { xs: 2, sm: 0 },
                            minHeight: 300,
                            backgroundColor: 'white',
                        }}>
                        <Grid2 sx={{ mt: { xs: 1, sm: 0 } }} container>
                            <Grid2
                                size={{ xs: 12, sm: 6 }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    backgroundColor: 'lightgrey',
                                    p: { xs: 2, sm: 0 },
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        width: {
                                            xs: '100%',
                                            sm: ' 70%',
                                        },
                                        height: {
                                            xs: 100,
                                            sm: '70%',
                                        },
                                        ...borderSideProps,
                                        ...borderRadiusProps,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        p: 1,
                                    }}>
                                    <Typography
                                        variant="caption"
                                        sx={{ fontSize: 8 }}>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis
                                        nostrud exercitation ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit
                                        in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim
                                        id est laborum.
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        sx={{ fontSize: 8 }}>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis
                                        nostrud exercitation ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit
                                        in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim
                                        id est laborum.
                                    </Typography>
                                </Box>
                            </Grid2>
                            <Grid2
                                size={{ xs: 12, sm: 6 }}
                                display="flex"
                                justifyContent="center"
                                alignItems="flex-start"
                                sx={{
                                    px: { xs: 0, sm: 2 },
                                }}>
                                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                                    Vị trí cạnh
                                </Typography>
                                <ToggleButtonGroup
                                    size="small"
                                    value={''}
                                    exclusive
                                    onChange={() => {}}
                                    aria-label="border side toggle button">
                                    {[
                                        'borderLeft',
                                        'borderRight',
                                        'borderTop',
                                        'borderBottom',
                                        'all',
                                        'default',
                                    ].map((i, idx) => {
                                        return (
                                            <Tooltip
                                                arrow
                                                title={
                                                    borderSideNameMap[i].title
                                                }
                                                placement="top">
                                                <FormGroup
                                                    sx={{
                                                        minWidth: 40,
                                                        borderRadius:
                                                            '2px 0 0 2px',
                                                        borderRight: 'none',
                                                        height: 38,
                                                    }}>
                                                    <FormControlLabel
                                                        sx={{
                                                            mx: 0,
                                                            mt: 0.5,
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                sx={{
                                                                    '&.MuiCheckbox-root':
                                                                        {
                                                                            p: 0,
                                                                            color: 'lightgrey',
                                                                            '&.Mui-checked':
                                                                                {
                                                                                    color: 'darkblue',
                                                                                },
                                                                        },
                                                                }}
                                                                checked={borderSideHasChosen.includes(
                                                                    i
                                                                )}
                                                                checkedIcon={
                                                                    borderSideNameMap[
                                                                        i
                                                                    ].icon
                                                                }
                                                                icon={
                                                                    borderSideNameMap[
                                                                        i
                                                                    ].icon
                                                                }
                                                                onChange={() => {}}
                                                                onClick={() => {
                                                                    if (
                                                                        i ===
                                                                            'all' ||
                                                                        i ===
                                                                            'default'
                                                                    ) {
                                                                        setBorderSideHasChosen(
                                                                            [i]
                                                                        );
                                                                        setBorderSideValue(
                                                                            (
                                                                                borderSideValue
                                                                            ) =>
                                                                                borderSideValue.map(
                                                                                    (
                                                                                        k
                                                                                    ) => {
                                                                                        return {
                                                                                            ...k,
                                                                                            isChosen:
                                                                                                i ===
                                                                                                'all'
                                                                                                    ? true
                                                                                                    : false,
                                                                                        };
                                                                                    }
                                                                                )
                                                                        );
                                                                    } else {
                                                                        setBorderSideHasChosen(
                                                                            (
                                                                                borderSideHasChosen
                                                                            ) => {
                                                                                if (
                                                                                    borderSideHasChosen.includes(
                                                                                        'all'
                                                                                    )
                                                                                ) {
                                                                                    return [
                                                                                        'borderLeft',
                                                                                        'borderRight',
                                                                                        'borderTop',
                                                                                        'borderBottom',
                                                                                    ].filter(
                                                                                        (
                                                                                            k
                                                                                        ) =>
                                                                                            k !==
                                                                                            i
                                                                                    );
                                                                                } else if (
                                                                                    borderSideHasChosen.includes(
                                                                                        'default'
                                                                                    )
                                                                                ) {
                                                                                    return [
                                                                                        i,
                                                                                    ];
                                                                                } else {
                                                                                    return borderSideHasChosen.includes(
                                                                                        i
                                                                                    )
                                                                                        ? borderSideHasChosen.filter(
                                                                                              (
                                                                                                  k
                                                                                              ) =>
                                                                                                  k !==
                                                                                                  i
                                                                                          )
                                                                                        : [
                                                                                              ...borderSideHasChosen,
                                                                                              i,
                                                                                          ];
                                                                                }
                                                                            }
                                                                        );
                                                                        setBorderSideValue(
                                                                            (
                                                                                borderSideValue
                                                                            ) =>
                                                                                borderSideValue.map(
                                                                                    (
                                                                                        k
                                                                                    ) =>
                                                                                        k.name ===
                                                                                        i
                                                                                            ? {
                                                                                                  ...k,
                                                                                                  isChosen:
                                                                                                      !k.isChosen,
                                                                                              }
                                                                                            : k
                                                                                )
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        labelPlacement="bottom"
                                                        label={
                                                            <Typography
                                                                variant="caption"
                                                                sx={{
                                                                    fontSize: 8,
                                                                    textAlign:
                                                                        'center',
                                                                }}>
                                                                {
                                                                    borderSideNameMap[
                                                                        i
                                                                    ].name
                                                                }
                                                            </Typography>
                                                        }
                                                    />
                                                </FormGroup>
                                            </Tooltip>
                                        );
                                    })}
                                </ToggleButtonGroup>
                                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                                    Loại đường viền
                                </Typography>
                                {['solid', 'dotted', 'dashed'].map((type) => {
                                    return (
                                        <Box
                                            key={'border side style-' + type}
                                            component={Button}
                                            disabled={borderSideHasChosen.includes(
                                                'default'
                                            )}
                                            onClick={() => {
                                                if (
                                                    borderSideHasChosen.includes(
                                                        'default'
                                                    )
                                                ) {
                                                    return;
                                                } else if (
                                                    borderSideHasChosen.includes(
                                                        'all'
                                                    )
                                                ) {
                                                    setBorderSideValue(
                                                        (borderSideValue) =>
                                                            borderSideValue.map(
                                                                (k) => {
                                                                    return {
                                                                        ...k,
                                                                        style: type,
                                                                    };
                                                                }
                                                            )
                                                    );
                                                } else {
                                                    setBorderSideValue(
                                                        (borderSideValue) =>
                                                            borderSideValue.map(
                                                                (k) =>
                                                                    borderSideHasChosen.includes(
                                                                        k.name
                                                                    )
                                                                        ? {
                                                                              ...k,
                                                                              style: type,
                                                                          }
                                                                        : k
                                                            )
                                                    );
                                                }
                                            }}
                                            sx={{
                                                width: '100%',
                                                p: 2,
                                                my: 1,
                                                border: '0.5px solid lightgrey',
                                                backgroundColor: 'white',
                                                ':hover': {
                                                    backgroundColor:
                                                        'lightgrey',
                                                },
                                                borderRadius: '3px',
                                            }}>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: 0.5,
                                                    border: `1px ${type} black`,
                                                    backgroundColor:
                                                        'transparent',
                                                }}></Box>
                                        </Box>
                                    );
                                })}
                                <Grid2 container spacing={1} sx={{ mt: 1 }}>
                                    <Grid2
                                        size={{ xs: 6 }}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                        <Typography variant="subtitle2">
                                            Màu viền
                                        </Typography>
                                        <ToggleButton
                                            value={'borderColor'}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                            disabled={borderSideHasChosen.includes(
                                                'default'
                                            )}
                                            onClick={(event) => {
                                                setAnchorEl(
                                                    event.currentTarget
                                                );
                                                setArialLabel(
                                                    'borderColor-menu'
                                                );
                                                setMenuItems(
                                                    ['default']
                                                        .concat(
                                                            Object.keys(
                                                                colorNameMap
                                                            )
                                                        )
                                                        .map((color) => {
                                                            return {
                                                                value: color,
                                                                label:
                                                                    color ===
                                                                    'default' ? (
                                                                        <Typography
                                                                            key={
                                                                                'border-color-menu-default'
                                                                            }
                                                                            variant="caption"
                                                                            sx={{
                                                                                fontSize: 14,
                                                                                ...defaultBoxCSS,
                                                                            }}>
                                                                            Mặc
                                                                            định
                                                                        </Typography>
                                                                    ) : (
                                                                        <Typography
                                                                            key={
                                                                                'border-color-menu-' +
                                                                                color
                                                                            }
                                                                            variant="caption"
                                                                            sx={{
                                                                                fontSize: 14,
                                                                                ...(color ===
                                                                                    'default' &&
                                                                                    defaultBoxCSS),
                                                                            }}>
                                                                            <SquareRoundedIcon
                                                                                sx={{
                                                                                    width:
                                                                                        color ===
                                                                                        'white'
                                                                                            ? 15
                                                                                            : 20,
                                                                                    height:
                                                                                        color ===
                                                                                        'white'
                                                                                            ? 15
                                                                                            : 20,
                                                                                    ml:
                                                                                        color ===
                                                                                        'white'
                                                                                            ? '3px'
                                                                                            : 0,
                                                                                    borderRadius:
                                                                                        color ===
                                                                                        'white'
                                                                                            ? '1px'
                                                                                            : 'none',
                                                                                    mr: 0.5,
                                                                                    color: color,
                                                                                    border:
                                                                                        color ===
                                                                                        'white'
                                                                                            ? '0.25px solid grey'
                                                                                            : 'none',
                                                                                }}
                                                                            />
                                                                            {
                                                                                colorNameMap[
                                                                                    color
                                                                                ]
                                                                            }
                                                                        </Typography>
                                                                    ),
                                                                onClick: () => {
                                                                    if (
                                                                        borderSideHasChosen.includes(
                                                                            'default'
                                                                        )
                                                                    ) {
                                                                        return;
                                                                    } else if (
                                                                        borderSideHasChosen.includes(
                                                                            'all'
                                                                        )
                                                                    ) {
                                                                        setBorderSideValue(
                                                                            (
                                                                                borderSideValue
                                                                            ) =>
                                                                                borderSideValue.map(
                                                                                    (
                                                                                        k
                                                                                    ) => {
                                                                                        return {
                                                                                            ...k,
                                                                                            color: color,
                                                                                        };
                                                                                    }
                                                                                )
                                                                        );
                                                                    } else {
                                                                        setBorderSideValue(
                                                                            (
                                                                                borderSideValue
                                                                            ) =>
                                                                                borderSideValue.map(
                                                                                    (
                                                                                        k
                                                                                    ) =>
                                                                                        borderSideHasChosen.includes(
                                                                                            k.name
                                                                                        )
                                                                                            ? {
                                                                                                  ...k,
                                                                                                  color: color,
                                                                                              }
                                                                                            : k
                                                                                )
                                                                        );
                                                                    }
                                                                },
                                                            };
                                                        })
                                                );
                                            }}
                                            aria-controls={
                                                openFilterMenu
                                                    ? 'borderColor-menu'
                                                    : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                                openFilterMenu
                                                    ? 'true'
                                                    : undefined
                                            }
                                            aria-label={'borderColor'}>
                                            <BorderColorIcon fontSize="small" />
                                            <ArrowDropDownIcon fontSize="small" />
                                        </ToggleButton>
                                    </Grid2>
                                    <Grid2
                                        size={{ xs: 6 }}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                        <Typography variant="subtitle2">
                                            Độ dày viền
                                        </Typography>
                                        <ToggleButton
                                            value={'borderWidth'}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                            disabled={borderSideHasChosen.includes(
                                                'default'
                                            )}
                                            onClick={(event) => {
                                                setAnchorEl(
                                                    event.currentTarget
                                                );
                                                setArialLabel(
                                                    'borderWidth-menu'
                                                );
                                                setMenuItems(
                                                    [
                                                        '0px',
                                                        '0.5px',
                                                        '1px',
                                                        '1.25px',
                                                        '1.75px',
                                                        '2px',
                                                        '3px',
                                                        '6px',
                                                        '10px',
                                                        '15px',
                                                    ].map((i) => {
                                                        return {
                                                            value: i,
                                                            label: (
                                                                <Typography
                                                                    key={
                                                                        'borderWidth-menu-' +
                                                                        i
                                                                    }
                                                                    sx={{
                                                                        fontSize: 8,
                                                                        width: '100%',
                                                                        m: 1,
                                                                        border:
                                                                            i +
                                                                            ' solid black',
                                                                    }}
                                                                    variant="caption">
                                                                    {i === '0px'
                                                                        ? '0px (Không viền)'
                                                                        : i}
                                                                </Typography>
                                                            ),
                                                            onClick: () => {
                                                                if (
                                                                    borderSideHasChosen.includes(
                                                                        'default'
                                                                    )
                                                                ) {
                                                                    return;
                                                                } else if (
                                                                    borderSideHasChosen.includes(
                                                                        'all'
                                                                    )
                                                                ) {
                                                                    setBorderSideValue(
                                                                        (
                                                                            borderSideValue
                                                                        ) =>
                                                                            borderSideValue.map(
                                                                                (
                                                                                    k
                                                                                ) => {
                                                                                    return {
                                                                                        ...k,
                                                                                        width: i,
                                                                                    };
                                                                                }
                                                                            )
                                                                    );
                                                                } else {
                                                                    setBorderSideValue(
                                                                        (
                                                                            borderSideValue
                                                                        ) =>
                                                                            borderSideValue.map(
                                                                                (
                                                                                    k
                                                                                ) =>
                                                                                    borderSideHasChosen.includes(
                                                                                        k.name
                                                                                    )
                                                                                        ? {
                                                                                              ...k,
                                                                                              width: i,
                                                                                          }
                                                                                        : k
                                                                            )
                                                                    );
                                                                }
                                                            },
                                                        };
                                                    })
                                                );
                                            }}
                                            aria-controls={
                                                openFilterMenu
                                                    ? 'borderWidth-menu'
                                                    : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                                openFilterMenu
                                                    ? 'true'
                                                    : undefined
                                            }
                                            aria-label={'borderWidth'}>
                                            <LineWeightIcon fontSize="small" />
                                            <ArrowDropDownIcon fontSize="small" />
                                        </ToggleButton>
                                    </Grid2>
                                </Grid2>
                                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                                    Vị trí góc
                                </Typography>
                                <ToggleButtonGroup
                                    size="small"
                                    value={''}
                                    exclusive
                                    onChange={() => {}}
                                    aria-label="border radius toggle button">
                                    {[
                                        'borderTopLeftRadius',
                                        'borderTopRightRadius',
                                        'borderBottomLeftRadius',
                                        'borderBottomRightRadius',
                                        'all',
                                        'default',
                                    ].map((i, idx) => {
                                        return (
                                            <Tooltip
                                                arrow
                                                title={
                                                    borderRadiusNameMap[i].title
                                                }
                                                placement="top">
                                                <FormGroup
                                                    sx={{
                                                        minWidth: 40,
                                                        borderRadius:
                                                            '2px 0 0 2px',
                                                        borderRight: 'none',
                                                        height: 38,
                                                    }}>
                                                    <FormControlLabel
                                                        sx={{
                                                            mx: 0,
                                                            mt: 0.5,
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                sx={{
                                                                    '&.MuiCheckbox-root':
                                                                        {
                                                                            p: 0,
                                                                            color: 'lightgrey',
                                                                            '&.Mui-checked':
                                                                                {
                                                                                    color: 'darkblue',
                                                                                },
                                                                        },
                                                                }}
                                                                checked={borderRadiusHasChosen.includes(
                                                                    i
                                                                )}
                                                                onChange={() => {}}
                                                                onClick={() => {
                                                                    if (
                                                                        i ===
                                                                            'all' ||
                                                                        i ===
                                                                            'default'
                                                                    ) {
                                                                        setBorderRadiusHasChosen(
                                                                            [i]
                                                                        );
                                                                        setBorderRadiusValue(
                                                                            (
                                                                                borderRadiusValue
                                                                            ) =>
                                                                                borderRadiusValue.map(
                                                                                    (
                                                                                        k
                                                                                    ) => {
                                                                                        return {
                                                                                            ...k,
                                                                                            isChosen:
                                                                                                i ===
                                                                                                'all'
                                                                                                    ? true
                                                                                                    : false,
                                                                                        };
                                                                                    }
                                                                                )
                                                                        );
                                                                    } else {
                                                                        setBorderRadiusHasChosen(
                                                                            (
                                                                                borderRadiusHasChosen
                                                                            ) => {
                                                                                if (
                                                                                    borderRadiusHasChosen.includes(
                                                                                        'all'
                                                                                    )
                                                                                ) {
                                                                                    return [
                                                                                        'borderTopLeftRadius',
                                                                                        'borderTopRightRadius',
                                                                                        'borderBottomLeftRadius',
                                                                                        'borderBottomRightRadius',
                                                                                    ].filter(
                                                                                        (
                                                                                            k
                                                                                        ) =>
                                                                                            k !==
                                                                                            i
                                                                                    );
                                                                                } else if (
                                                                                    borderRadiusHasChosen.includes(
                                                                                        'default'
                                                                                    )
                                                                                ) {
                                                                                    return [
                                                                                        i,
                                                                                    ];
                                                                                } else {
                                                                                    return borderRadiusHasChosen.includes(
                                                                                        i
                                                                                    )
                                                                                        ? borderRadiusHasChosen.filter(
                                                                                              (
                                                                                                  k
                                                                                              ) =>
                                                                                                  k !==
                                                                                                  i
                                                                                          )
                                                                                        : [
                                                                                              ...borderRadiusHasChosen,
                                                                                              i,
                                                                                          ];
                                                                                }
                                                                            }
                                                                        );
                                                                        setBorderRadiusValue(
                                                                            (
                                                                                borderRadiusValue
                                                                            ) =>
                                                                                borderRadiusValue.map(
                                                                                    (
                                                                                        k
                                                                                    ) =>
                                                                                        k.name ===
                                                                                        i
                                                                                            ? {
                                                                                                  ...k,
                                                                                                  isChosen:
                                                                                                      !k.isChosen,
                                                                                              }
                                                                                            : k
                                                                                )
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        labelPlacement="bottom"
                                                        label={
                                                            <Typography
                                                                variant="caption"
                                                                sx={{
                                                                    fontSize: 8,
                                                                    textAlign:
                                                                        'center',
                                                                }}>
                                                                {
                                                                    borderRadiusNameMap[
                                                                        i
                                                                    ].name
                                                                }
                                                            </Typography>
                                                        }
                                                    />
                                                </FormGroup>
                                            </Tooltip>
                                        );
                                    })}
                                </ToggleButtonGroup>
                                <Grid2
                                    container
                                    spacing={1}
                                    sx={{
                                        mt: 1,
                                        mb: { xs: 0, sm: 1 },
                                    }}>
                                    <Grid2
                                        size={{ xs: 6 }}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                        <Typography variant="subtitle2">
                                            Độ cong góc bo
                                        </Typography>
                                        <ToggleButton
                                            value={'borderRadius'}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                            disabled={borderRadiusHasChosen.includes(
                                                'default'
                                            )}
                                            onClick={(event) => {
                                                setAnchorEl(
                                                    event.currentTarget
                                                );
                                                setArialLabel(
                                                    'borderRadius-menu'
                                                );
                                                setMenuItems(
                                                    [
                                                        '0px',
                                                        '3px',
                                                        '5px',
                                                        '8px',
                                                        '10px',
                                                        '12px',
                                                        '15px',
                                                        '25px',
                                                    ].map((i, idx) => {
                                                        return {
                                                            value: i,
                                                            label: (
                                                                <Typography
                                                                    key={
                                                                        'borderRadius-menu-' +
                                                                        i +
                                                                        '-' +
                                                                        idx
                                                                    }
                                                                    sx={{
                                                                        fontSize: 8,
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        width: '100%',
                                                                        px: 2,
                                                                        py: 0.5,
                                                                        m: 1,
                                                                        height:
                                                                            idx <
                                                                            6
                                                                                ? '25px'
                                                                                : i ===
                                                                                  '50px'
                                                                                ? '80px'
                                                                                : '50px',
                                                                        border: '1px solid black',
                                                                        borderRadius:
                                                                            i,
                                                                    }}
                                                                    variant="caption">
                                                                    {i === '0px'
                                                                        ? '0px (Không bo góc)'
                                                                        : i}
                                                                </Typography>
                                                            ),
                                                            onClick: () => {
                                                                if (
                                                                    borderRadiusHasChosen.includes(
                                                                        'default'
                                                                    )
                                                                ) {
                                                                    return;
                                                                } else if (
                                                                    borderRadiusHasChosen.includes(
                                                                        'all'
                                                                    )
                                                                ) {
                                                                    setBorderRadiusValue(
                                                                        (
                                                                            borderRadiusValue
                                                                        ) =>
                                                                            borderRadiusValue.map(
                                                                                (
                                                                                    k
                                                                                ) => {
                                                                                    return {
                                                                                        ...k,
                                                                                        width: i,
                                                                                    };
                                                                                }
                                                                            )
                                                                    );
                                                                } else {
                                                                    setBorderRadiusValue(
                                                                        (
                                                                            borderRadiusValue
                                                                        ) =>
                                                                            borderRadiusValue.map(
                                                                                (
                                                                                    k
                                                                                ) =>
                                                                                    borderRadiusHasChosen.includes(
                                                                                        k.name
                                                                                    )
                                                                                        ? {
                                                                                              ...k,
                                                                                              width: i,
                                                                                          }
                                                                                        : k
                                                                            )
                                                                    );
                                                                }
                                                            },
                                                        };
                                                    })
                                                );
                                            }}
                                            aria-controls={
                                                openFilterMenu
                                                    ? 'borderRadius-menu'
                                                    : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                                openFilterMenu
                                                    ? 'true'
                                                    : undefined
                                            }
                                            aria-label={'borderRadius'}>
                                            <VignetteIcon fontSize="small" />
                                            <ArrowDropDownIcon fontSize="small" />
                                        </ToggleButton>
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Box>
                </CustomDialog>
            )}
        </Box>
    );
}

const customSxToggleButtonGroup = {
    '&.MuiToggleButtonGroup-root': {
        border: 'none',
        alignItems: 'center',
    },
    '& .MuiToggleButtonGroup-grouped': {
        height: 30,
        width: 30,
        p: 0,
        m: 0.5,
        border: 'none',
        '&.Mui-disabled': {
            borderRadius: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: '5px',
        },
        '&:first-of-type': {
            borderRadius: '5px',
        },
    },
};
const customSxToggleButton = {
    '&.Mui-disabled': {
        border: 'none',
    },
};
const defaultBoxCSS = {
    px: 2.75,
    py: 0.25,
    border: '1px solid black',
    borderRadius: '3px',
    backgroundImage: 'linear-gradient(darkblue 0 0)',
    backgroundPosition: '0 100%',
    backgroundSize: '0% 1px',
    backgroundRepeat: 'no-repeat',
    transition: 'background-size 0.3s , background-position 0s 0.3s',
    width: '100%',
    textAlign: 'center',
    ':hover': {
        px: 2.75,
        py: 0.25,
        color: 'white',
        fontWeight: 500,
        boxShadow: 2,
        backgroundColor: 'transparent',
        backgroundPosition: '100% 100%',
        backgroundSize: '100% 100%',
    },
};
const customSliderCSS = {
    color: 'transparent',
    backgroundImage:
        'linear-gradient(to right, rgb(56, 128, 255, 0) , rgb(56, 128, 255, 1))',
    height: 4,
    padding: 0,
    mb: -0.75,
    '& .MuiSlider-thumb': {
        height: 12,
        width: 12,
        backgroundColor: 'aliceblue',
        boxShadow:
            '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
        '&:focus, &:hover, &.Mui-active': {
            border: '1px solid white',
            backgroundColor: 'darkblue',
            opacity: 0.85,
            boxShadow:
                '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                border: '1px solid white',
                backgroundColor: 'darkblue',
                opacity: 0.85,
                boxShadow:
                    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
            },
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 8,
        background: 'unset',
        padding: '2px',
        width: 15,
        height: 15,
        border: '0.25px solid darkblue',
        borderRadius: '50% 50% 50% 0',
        backgroundColor: 'aliceblue',
        color: 'darkblue',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
        top: '-5px !important',
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    },
};
const verticalDivider = (
    <Box
        sx={{
            backgroundColor: 'lightgrey',
            height: '25px',
            width: '0.25px',
            my: 'auto',
            fontSize: 1,
        }}>
        .
    </Box>
);
const fullVerticalDivider = (
    <Box
        sx={{
            backgroundColor: 'lightgrey',
            minHeight: '105px',
            width: '0.25px',
            fontSize: 1,
            mt: -1,
            mb: -1,
        }}>
        .
    </Box>
);
const colorNameMap = {
    white: 'Trắng',
    black: 'Đen',
    grey: 'Xám',
    darkblue: 'Xanh đậm',
    aliceblue: 'Xanh da trời',
    darkseagreen: 'Xanh rêu',
    orangered: 'Cam đỏ',
    mistyrose: 'Hồng nhạt',
    cornsilk: 'Vàng nhạt',
};
const borderSideNameMap = {
    borderLeft: {
        title: 'Viền trái',
        icon: <BorderLeftIcon fontSize="small" />,
        name: 'Trái',
    },
    borderRight: {
        title: 'Viền phải',
        icon: <BorderRightIcon fontSize="small" />,
        name: 'Phải',
    },
    borderTop: {
        title: 'Viền trên',
        icon: <BorderTopIcon fontSize="small" />,
        name: 'Trên',
    },
    borderBottom: {
        title: 'Viền dưới',
        icon: <BorderBottomIcon fontSize="small" />,
        name: 'Dưới',
    },
    all: {
        title: 'Viền toàn bộ khung',
        icon: <BorderOuterIcon fontSize="small" />,
        name: 'Tất cả',
    },
    default: {
        title: 'Viền mặc định',
        icon: <BorderClearIcon fontSize="small" />,
        name: 'Mặc định',
    },
};
const borderRadiusNameMap = {
    borderTopLeftRadius: {
        title: 'Góc trái trên',
        name: 'Trái trên',
    },
    borderTopRightRadius: {
        title: 'Góc phải trên',
        name: 'Phải trên',
    },
    borderBottomLeftRadius: {
        title: 'Góc trái dưới',
        name: 'Trái dưới',
    },
    borderBottomRightRadius: {
        title: 'Góc phải dưới',
        name: 'Phải dưới',
    },
    all: {
        title: 'Tất cả các góc',
        name: 'Tất cả',
    },
    default: {
        title: 'Bo góc mặc định',
        name: 'Mặc định',
    },
};
const templateSample = {
    drug: [
        {
            type: 'quote',
            content:
                'Mọi thông tin dưới đây chỉ mang tính chất tham khảo. Việc sử dụng thuốc phải tuân theo hướng dẫn của bác sĩ, dược sĩ. Vui lòng đọc kĩ thông tin chi tiết ở tờ rơi bên trong hộp sản phẩm.',
        },
        {
            type: 'header1',
            content: `Thành phần`,
        },
        {
            type: 'paragraph',
            content: 'Thành phần cho 1 đơn vị',
        },
        {
            type: 'table',
            rows: {
                0: [
                    {
                        content: 'Thành phần',
                    },
                    {
                        content: 'Hàm lượng',
                    },
                ],
                1: [
                    {
                        content: 'Hoạt chất 1',
                    },
                    {
                        content: 'Hàm lượng 1',
                    },
                ],
                2: [
                    {
                        content: 'Hoạt chất 2',
                    },
                    {
                        content: 'Hàm lượng 2',
                    },
                ],
            },
        },
        {
            type: 'header1',
            content: `Công dụng`,
        },
        {
            type: 'header2',
            content: 'Chỉ định',
        },
        {
            type: 'paragraph',
            content: 'Thuốc được chỉ định dùng trong các trường hợp sau:',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Chỉ định 1.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Dược lực học',
        },
        {
            type: 'list',
            bulletPoint: 'minnus',
            listcontent: [
                {
                    content: 'Nội dung 1.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Dược động học',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Nội dung hấp thu...',
                },
                {
                    content: 'Nội dung phân bố...',
                },
                {
                    content: 'Nội dung chuyển hóa...',
                },
                {
                    content: 'Nội dung thải trừ...',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header1',
            content: `Liều dùng`,
        },
        {
            type: 'header2',
            content: 'Cách dùng',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Thuốc dùng qua đường uống, nên uống với nước.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Liều dùng',
        },
        {
            type: 'paragraph',
            content: 'Liều dùng thông thường:',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Người lớn và trẻ em từ 12 tuổi trở lên: ...',
                },
                {
                    content:
                        'Người cao tuổi: Không cần chỉnh liều ở người cao tuổi.',
                },
                {
                    content:
                        'Suy thận: Không cần chỉnh liều ở bệnh nhân suy thận.',
                },
                {
                    content:
                        'Suy gan: Không cần chỉnh liều ở bệnh nhân suy gan.',
                },
                {
                    content:
                        'Lưu ý: Liều dùng trên chỉ mang tính chất tham khảo. Liều dùng cụ thể tùy thuộc vào thể trạng và mức độ diễn tiến của bệnh. Để có liều dùng phù hợp, bạn cần tham khảo ý kiến bác sĩ hoặc chuyên viên y tế.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Làm gì khi dùng quá liều?',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Xử trí quá liều 1.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Làm gì khi quên 1 liều?',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Xử trí quên liều 1.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header1',
            content: `Tác dụng không mong muốn`,
        },
        {
            type: 'header2',
            content: 'Thường gặp, ADR > 1/100',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Thần kinh: Đau đầu.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Ít gặp, 1/1000 < ADR < 1/100',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Thần kinh: Chóng mặt, ngủ gà.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Hiếm gặp, 1/10000 < ADR < 1/1000',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content:
                        'Thần kinh: Mất ý thức, sững sờ, giảm nhận thức, tăng trương lực cơ, bất thường điều phối vận động.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Hướng dẫn cách xử trí ADR',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content:
                        'Thông báo cho bác sĩ những tác dụng không mong muốn gặp phải khi sử dụng thuốc.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header1',
            content: `Lưu ý`,
        },
        {
            type: 'header2',
            content: 'Chống chỉ định',
        },
        {
            type: 'paragraph',
            content: 'Thuốc chống chỉ định trong các trường hợp sau:',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Chống chỉ định 1.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Thận trọng khi sử dụng',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content:
                        'Bệnh nhân đã biết bị quá mẫn cảm với thuốc hay bất kỳ thành phần nào của thuốc.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Cảnh báo đặc biệt',
        },
        {
            type: 'paragraph',
            content:
                'Không nên sử dụng thuốc mà không có sự hướng dẫn của bác sỹ, đặc biệt trong các trường hợp sau:',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Cảnh báo 1.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Khả năng lái xe và vận hành máy móc',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content:
                        'Mất ý thức, giảm nhận thức, mệt mỏi, choáng váng, hoặc buồn ngủ có thể xảy ra khi điều trị bằng thuốc này. Vì thế, nên thận trọng dùng thuốc này trong khi đang lái xe hay vận hành máy móc (xem tác dụng không mong muốn).',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Thời kỳ mang thai',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content:
                        'Tính an toàn trên phụ nữ có thai chưa được thiết lập. Như các thuốc khác, không nên dùng thuốc này cho phụ nữ có thai, đặc biệt trong 3 tháng đầu thai kỳ. Do đó, phụ nữ có thai nên tham khảo ý kiến bác sỹ để có trị liệu phù hợp.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Thời kỳ cho con bú',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content:
                        'Thuốc này không được khuyến cáo dùng khi đang cho con bú. Do đó phụ nữ đang cho con bú nên tham khảo ý kiến bác sỹ để có trị liệu phù hợp.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header2',
            content: 'Tương tác thuốc',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Dữ liệu 1.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header1',
            content: `Bảo quản`,
        },
        {
            type: 'paragraph',
            content:
                'Bảo quản nơi thoáng mát dưới 30˚C. Không sử dụng khi hết hạn sử dụng',
        },
    ],
    otherProducts: [
        {
            type: 'quote',
            content:
                'Mọi thông tin dưới đây chỉ mang tính chất tham khảo. Vui lòng đọc kĩ thông tin chi tiết ở tờ rơi bên trong hộp sản phẩm.',
        },
        {
            type: 'header1',
            content: 'Thông tin sản phẩm',
        },
        {
            type: 'header2',
            content: 'Tiêu đề 1...',
        },
        {
            type: 'paragraph',
            content: 'Nội dung 1...',
        },
        {
            type: 'image',
            src: '',
        },
        {
            type: 'paragraph',
            content: 'Nội dung 2...',
        },
        {
            type: 'paragraph',
            content: 'Nội dung 3...',
        },
        {
            type: 'header2',
            content: 'Tiêu đề 2...',
        },
        {
            type: 'paragraph',
            content: 'Nội dung 1...',
        },
        {
            type: 'paragraph',
            content: 'Nội dung 2...',
        },
        {
            type: 'paragraph',
            content: 'Nội dung 3...',
        },
        {
            type: 'header2',
            content: 'Tiêu đề 3...',
        },
        {
            type: 'paragraph',
            content: 'Nội dung 1...',
        },
        {
            type: 'image',
            src: '',
        },
        {
            type: 'paragraph',
            content: 'Nội dung 2...',
        },
        {
            type: 'paragraph',
            content: 'Nội dung 3...',
        },
        {
            type: 'header1',
            content: 'Thành phần',
        },
        {
            type: 'paragraph',
            content: 'Thành phần cho 1 đơn vị',
        },
        {
            type: 'table',
            rows: {
                0: [
                    {
                        content: 'Thành phần',
                    },
                    {
                        content: 'Hàm lượng',
                    },
                ],
                1: [
                    {
                        content: 'Hoạt chất 1',
                    },
                    {
                        content: 'Hàm lượng 1',
                    },
                ],
                2: [
                    {
                        content: 'Hoạt chất 2',
                    },
                    {
                        content: 'Hàm lượng 2',
                    },
                ],
            },
        },
        {
            type: 'header1',
            content: 'Công dụng',
        },
        {
            type: 'paragraph',
            content: 'Sản phẩm được dùng trong các trường hợp 1...',
        },
        {
            type: 'paragraph',
            content: 'Sản phẩm được dùng trong các trường hợp 2...',
        },
        {
            type: 'paragraph',
            content: 'Sản phẩm được dùng trong các trường hợp 3...',
        },
        {
            type: 'header1',
            content: 'Liều dùng',
        },
        {
            type: 'paragraph',
            content: 'Liều dùng thông thường:',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: 'Người lớn và trẻ em từ 12 tuổi trở lên: ...',
                },
                {
                    content:
                        'Người cao tuổi: Không cần chỉnh liều ở người cao tuổi.',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'header1',
            content: 'Bảo quản',
        },
        {
            type: 'paragraph',
            content:
                'Bảo quản nơi thoáng mát dưới 30˚C. Không sử dụng khi hết hạn sử dụng',
        },
    ],
    article: [
        {
            type: 'quote',
            content: '',
        },
        {
            type: 'header1',
            content: 'Tiêu đề cấp 1',
        },
        {
            type: 'header2',
            content: 'Tiêu đề cấp 2',
        },
        {
            type: 'paragraph',
            content: '',
        },
        {
            type: 'paragraph',
            content: '',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: '',
                },
                {
                    content: '',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'paragraph',
            content: '',
        },
        {
            type: 'image',
            src: '',
        },
        {
            type: 'header1',
            content: 'Tiêu đề cấp 1',
        },
        {
            type: 'header2',
            content: 'Tiêu đề cấp 2',
        },
        {
            type: 'paragraph',
            content: '',
        },
        {
            type: 'paragraph',
            content: '',
        },
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: '',
                },
                {
                    content: '',
                },
                {
                    content: '',
                },
            ],
        },
        {
            type: 'paragraph',
            content: '',
        },
        { type: 'paragraph', content: 'Nguồn: ' },
    ],
    image: [
        {
            type: 'image',
            src: '',
        },
    ],
    quote: [
        {
            type: 'quote',
            content: '',
        },
    ],
    header1: [
        {
            type: 'header1',
            content: '',
        },
    ],
    header2: [
        {
            type: 'header2',
            content: '',
        },
    ],
    paragraph: [
        {
            type: 'paragraph',
            content: '',
        },
    ],
    table: [
        {
            type: 'table',
            rows: {
                0: [
                    {
                        content: '',
                    },
                    {
                        content: '',
                    },
                ],
                1: [
                    {
                        content: '',
                    },
                    {
                        content: '',
                    },
                ],
            },
        },
    ],
    list: [
        {
            type: 'list',
            bulletPoint: 'minus',
            listcontent: [
                {
                    content: '',
                },
            ],
        },
    ],
};
const templateNameMap = {
    table: {
        title: 'Bảng',
        icon: (
            <PivotTableChartIcon
                sx={{
                    mt: -0.5,
                    mr: 1,
                    width: 20,
                    height: 20,
                    color: 'darkseagreen',
                }}
            />
        ),
    },
    list: {
        title: 'Danh sách',
        icon: (
            <FormatListBulletedIcon
                sx={{
                    mt: -0.5,
                    mr: 1,
                    width: 20,
                    height: 20,
                    color: 'darkseagreen',
                }}
            />
        ),
    },
    image: {
        title: 'Chèn ảnh',
        icon: (
            <ImageIcon
                sx={{
                    mt: -0.5,
                    mr: 1,
                    width: 20,
                    height: 20,
                    color: 'darkseagreen',
                }}
            />
        ),
    },
    header1: {
        title: 'Tiêu đề cấp 1',
        icon: (
            <LooksOneIcon
                sx={{
                    mt: -0.5,
                    mr: 1,
                    width: 20,
                    height: 20,
                    color: 'darkseagreen',
                }}
            />
        ),
    },
    header2: {
        title: 'Tiêu đề cấp 2',
        icon: (
            <LooksTwoIcon
                sx={{
                    mt: -0.5,
                    mr: 1,
                    width: 20,
                    height: 20,
                    color: 'darkseagreen',
                }}
            />
        ),
    },
    paragraph: {
        title: 'Đoạn văn',
        icon: (
            <LineStyleIcon
                sx={{
                    mt: -0.5,
                    mr: 1,
                    width: 20,
                    height: 20,
                    color: 'darkseagreen',
                }}
            />
        ),
    },
    quote: {
        title: 'Trích dẫn',
        icon: (
            <FormatQuoteIcon
                sx={{
                    mt: -0.5,
                    mr: 1,
                    width: 20,
                    height: 20,
                    color: 'darkseagreen',
                }}
            />
        ),
    },
};
