export const customScrollbar = {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '0.05em',
        height: '0.05em',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(3,37,126, 0.8)',
        borderRadius: '25px',
    },
    '&::-webkit-scrollbar-track-piece:start': {
        backgroundColor: 'transparent',
        marginTop: '10px',
        marginLeft: '10px',
    },
    '&::-webkit-scrollbar-track-piece:end': {
        backgroundColor: 'transparent',
        marginBottom: '10px',
        marginRight: '10px',
    },
};
export const underlineWithTransition = {
    pb: '2px',
    backgroundImage: 'linear-gradient(#3843ad 0 0)',
    backgroundPosition: '0 100%',
    backgroundSize: '0% 1px',
    backgroundRepeat: 'no-repeat',
    transition: 'background-size 0.3s , background-position 0s 0.3s',
    ':hover': {
        backgroundColor: 'transparent',
        backgroundPosition: ' 100% 100%',
        backgroundSize: '100% 2px',
    },
};
export const borderWithTransition = {
    borderColor: 'rgba(0,0,0,0.08)',
    borderRadius: '10px',
    transition: 'all 0.3s ease-in-out',
    '&: hover': {
        borderColor: '#0062cc',
        boxShadow: 2,
    },
    backgroundColor: '#f5f5f5',
};
