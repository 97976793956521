import { useState, useRef } from 'react';

import { Box, Typography } from '@mui/material';
import {
    Inventory as InventoryIcon,
    Store as StoreIcon,
    Storage as StorageIcon,
    Payments as PaymentsIcon,
    GroupsRounded as GroupsRoundedIcon,
    CollectionsBookmark as CollectionsBookmarkIcon,
    Person as PersonIcon,
    ShoppingBasket as ShoppingBasketIcon,
    PinDrop as PinDropIcon,
    Topic as TopicIcon,
    Newspaper as NewspaperIcon,
    Storefront as StorefrontIcon,
    Factory as FactoryIcon,
    Key as KeyIcon,
    MedicationLiquid as MedicationLiquidIcon,
    Home as HomeIcon,
} from '@mui/icons-material';

import { useLocation, useNavigate } from 'react-router-dom';

import breadcrumbNameMapping from 'data/breadcrumbNameMapping.json';

import { CustomMenu, CustomTabs, CustomButton } from 'UI';

export default function AdminNavbar({ ...props }) {
    const navigate = useNavigate();

    let containerRef = useRef(null);

    const location = useLocation().pathname;
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [menuTabItem, setMenuTabItem] = useState([]);

    //only render in desktop screen

    const customButtonProps = {
        sx: { minWidth: 0, pl: 0.5 },
        disableEffect: true,
        component: 'div',
        'aria-controls': openMenu ? 'admin-navbar-menuId' : undefined,
        'aria-haspopup': 'true',
        'aria-expanded': openMenu ? 'true' : undefined,
    };

    let tabOptions = [
        <CustomButton
            id="store-page-button"
            {...customButtonProps}
            onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setMenuTabItem([
                    '/',
                    '/thuoc',
                    '/thuc-pham-chuc-nang',
                    '/san-pham-khac',
                    '/bai-viet-suc-khoe',
                ]);
            }}
            startIcon={<StoreIcon />}
            children="Nhà thuốc"
        />,
        <CustomButton
            id="page-management-button"
            {...customButtonProps}
            onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setMenuTabItem([
                    '/admin/homepage',
                    '/admin/product-item',
                    '/admin/manufacture',
                    '/admin/ingredient',
                    '/admin/article-item',
                    '/admin/keyword',
                    '/admin/market',
                ]);
            }}
            startIcon={<InventoryIcon />}
            children="Quản lý Page"
        />,
        <CustomButton
            id="list-page-button"
            {...customButtonProps}
            onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setMenuTabItem([
                    '/admin/staff',
                    '/admin/commission-products',
                    '/admin/salary',
                ]);
            }}
            startIcon={<StorageIcon />}
            children="Danh sách"
        />,
    ];

    return (
        <Box
            ref={containerRef}
            sx={{
                width: '100%',
                backgroundColor: 'white',
            }}>
            <CustomTabs
                id="custom-admin-navbarId"
                value={false}
                centered={true}
                TabProps={{
                    sx: {
                        minWidth: 0,
                        fontWeight: 500,
                        fontSize: 16,
                        position: 'relative',
                        textTransform: 'initial',
                        color: 'primary.main',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 7,
                            left: 0,
                            width: '100%',
                            height: '1.5px',
                            borderRadius: '5px',
                            boxShadow: 1,
                            backgroundColor: 'primary.light',
                            transform: 'scaleX(0)',
                            transformOrigin: 'bottom right',
                            transition: 'transform 0.3s ease-in-out',
                        },
                        '&:hover::after': {
                            transform: 'scaleX(1)',
                            transformOrigin: 'bottom left',
                        },
                    },
                }}
                options={tabOptions.map((button) => ({
                    label: button,
                }))}
            />
            {openMenu && (
                <CustomMenu
                    id="admin-navbar-menuId"
                    anchorEl={anchorEl}
                    density="comfortable"
                    onClose={() => setAnchorEl(null)}
                    items={Object.entries(breadcrumbNameMapping)
                        .filter(([key]) => menuTabItem.includes(key))
                        .map(([pathname, label]) => {
                            let Icon = iconMapping?.[pathname] ?? null;
                            return {
                                name: pathname,
                                icon: <Icon fontSize="small" color="warning" />,
                                label: (
                                    <Typography color="primary" sx={{ pr: 2 }}>
                                        {label}
                                    </Typography>
                                ),
                                subLabel: null,
                            };
                        })}
                    onSelectItem={(item) => {
                        setMenuTabItem([]);
                        setTimeout(() => navigate(item.name), 100);
                    }}
                    getSelectedItem={(item) => item.name === location}
                />
            )}
        </Box>
    );
}

const iconMapping = {
    '/': StorefrontIcon,
    '/thuoc': TopicIcon,
    '/thuc-pham-chuc-nang': TopicIcon,
    '/san-pham-khac': TopicIcon,
    '/bai-viet-suc-khoe': NewspaperIcon,
    '/thong-tin-tai-khoan': PersonIcon,
    '/lich-su-mua-hang': ShoppingBasketIcon,
    '/dia-chi-nhan-hang': PinDropIcon,
    '/admin/homepage': HomeIcon,
    '/admin/market': StorefrontIcon,
    '/admin/product-item': TopicIcon,
    '/admin/manufacture': FactoryIcon,
    '/admin/ingredient': MedicationLiquidIcon,
    '/admin/article-item': NewspaperIcon,
    '/admin/keyword': KeyIcon,
    '/admin/staff': GroupsRoundedIcon,
    '/admin/commission-products': CollectionsBookmarkIcon,
    '/admin/salary': PaymentsIcon,
};
