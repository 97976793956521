import { useContext } from 'react';

import { Stack, Typography } from '@mui/material';

import { CustomButton, CustomAvatar } from 'UI';

import { ProductContext } from 'context/product-context';

export default function NoFoundData({ ...postMessagerops }) {
    const { activeFilterCount, resetFilter } = useContext(ProductContext);

    return (
        <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            px={2}
            py={{ xs: 2, sm: 4 }}>
            <CustomAvatar
                src="../assets/images/pharmacy-illustration.png"
                sx={{
                    width: '100%',
                    maxWidth: 500,
                    opacity: 0.5,
                    height: 200,
                }}
            />
            <Stack pt={2} spacing={0.5}>
                <Typography
                    sx={{ fontSize: 26, fontWeight: 600, textAlign: 'center' }}>
                    {activeFilterCount > 0
                        ? 'Không tìm thấy kết quả nào phù hợp với điều kiện lọc'
                        : 'Chưa có sản phẩm nào trong nhóm này'}
                </Typography>
                <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                    {activeFilterCount > 0
                        ? 'Hãy thử lại bằng cách thay đổi điều kiện lọc hoặc'
                        : 'Dữ liệu đang cập nhật, bạn hãy quay lại sau nhé...'}
                </Typography>
            </Stack>
            {activeFilterCount > 0 && (
                <CustomButton
                    id="clear-all-filter-custom-button"
                    variant="contained"
                    onClick={resetFilter}
                    sx={{
                        width: 'fit-content',
                        borderRadius: '20px',
                        px: 2,
                        py: 1,
                    }}
                    children={
                        <Typography variant="h6" sx={{ fontSize: 18 }}>
                            Xóa tất cả bộ lọc
                        </Typography>
                    }
                />
            )}
        </Stack>
    );
}
