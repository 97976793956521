import PropTypes from 'prop-types';

import { MobileStepper } from '@mui/material';
import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';

import { CustomButton } from 'UI';

/**
 * CustomMobileStepper is a wrapper around MUI's MobileStepper component.
 * It provides a flexible way to navigate through steps with custom button styles,
 * and supports both "dots" and "text" variants depending on the number of steps.
 *
 * @param {Object} props - Component properties.
 * @param {'dots' | 'text' | null} [props.variant=null] - The display variant for the stepper. Defaults to 'dots' if steps <= 6; otherwise, 'text'.
 * @param {number} [props.activeStep=0] - The currently active step.
 * @param {function} props.onChangeStep - Callback function triggered when the step changes. Receives the new step index as an argument.
 * @param {number} [props.steps=6] - Total number of steps.
 * @param {'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'disabled'} [props.color='primary'] - Color theme for the stepper and buttons.
 * @param {'small' | 'medium' | 'large'} [props.size='medium'] - Button size and font size for the stepper.
 * @param {boolean} [props.hideBackButton=false] - If true, hides the "Back" button.
 * @param {boolean} [props.hideNextButton=false] - If true, hides the "Next" button.
 * @returns {JSX.Element | null} Rendered MobileStepper component or `null` if steps is `1`.
 */
function CustomMobileStepper({
    variant = null,
    activeStep = 0,
    onChangeStep = () => {},
    steps = 6,
    color = 'primary',
    size = 'medium',
    hideBackButton = false,
    hideNextButton = false,
    ...otherProps
}) {
    const handleNext = () => {
        if (activeStep === steps - 1) return;
        onChangeStep(activeStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) return;
        onChangeStep(activeStep - 1);
    };

    if (steps === 1) return null;

    let customVariant = variant || steps <= 6 ? 'dots' : 'text';
    return (
        <MobileStepper
            variant={customVariant}
            steps={steps}
            position="static"
            activeStep={activeStep}
            sx={(theme) => ({
                maxWidth: 250,
                width: '100%',
                backgroundColor: 'transparent',
                color: theme.palette[color].main,
                fontSize: size === 'small' ? 14 : size === 'medium' ? 15 : 16,
                '.MuiMobileStepper-dot': {
                    backgroundColor: theme.palette[color].lighter,
                },
                '.MuiMobileStepper-dotActive': {
                    backgroundColor: theme.palette[color].main,
                },
            })}
            backButton={
                <CustomButton
                    id="next-step-custom-button"
                    useIconButton={customVariant === 'dots'}
                    disableEffect
                    size={size}
                    color={color}
                    sx={{ display: hideBackButton ? 'none' : 'flex' }}
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    startIcon={
                        customVariant !== 'dots' && (
                            <KeyboardArrowLeftIcon fontSize={size} />
                        )
                    }
                    children={
                        customVariant === 'dots' ? (
                            <KeyboardArrowLeftIcon fontSize={size} />
                        ) : (
                            'Trước'
                        )
                    }
                />
            }
            nextButton={
                <CustomButton
                    id="back-step-custom-button"
                    useIconButton={customVariant === 'dots'}
                    disableEffect
                    size={size}
                    color={color}
                    sx={{ display: hideNextButton ? 'none' : 'flex' }}
                    onClick={handleNext}
                    disabled={activeStep === steps - 1}
                    endIcon={
                        customVariant !== 'dots' && (
                            <KeyboardArrowRightIcon fontSize={size} />
                        )
                    }
                    children={
                        customVariant === 'dots' ? (
                            <KeyboardArrowRightIcon fontSize={size} />
                        ) : (
                            'Sau'
                        )
                    }
                />
            }
        />
    );
}

CustomMobileStepper.propTypes = {
    variant: PropTypes.oneOf(['dots', 'text', null]),
    activeStep: PropTypes.number,
    onChangeStep: PropTypes.func.isRequired,
    steps: PropTypes.number,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'error',
        'info',
        'warning',
        'disabled',
    ]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    hideBackButton: PropTypes.bool,
    hideNextButton: PropTypes.bool,
};

CustomMobileStepper.defaultProps = {
    variant: null,
    activeStep: 0,
    onChangeStep: () => {},
    steps: 6,
    color: 'primary',
    size: 'medium',
    hideBackButton: false,
    hideNextButton: false,
};

export default CustomMobileStepper;
