import {
    Stack,
    Skeleton,
    Box,
    Card,
    CardContent,
    CardActions,
} from '@mui/material';

import { CustomMasonry } from 'UI';
import { LoadingModal } from 'template';

import { borderWithTransition } from 'config/customCSS.js';

function ArticleCardSkeleton({ id = '', ...otherProps }) {
    return (
        <Card
            variant="outlined"
            key={id}
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                ...borderWithTransition,
            }}>
            <Skeleton
                variant="rounded"
                width={130}
                height={130}
                sx={{ mx: 'auto', my: 2 }}
            />
            <Skeleton
                variant="square"
                width={120}
                height={28}
                sx={{ borderRadius: '15px', ml: -2 }}
            />
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    mt: -1,
                }}>
                <Skeleton variant="text" width={'90%'} height={24} />
                <Skeleton variant="text" width={'100%'} height={24} />
                <Skeleton variant="text" width={'60%'} height={24} />
                <Skeleton variant="text" width={'100%'} height={18} />
                <Skeleton variant="text" width={'20%'} height={18} />
            </CardContent>
            <CardActions disableSpacing>
                <Skeleton
                    variant="square"
                    width={'100%'}
                    height={36}
                    sx={{ mx: 1, borderRadius: '20px', mt: 1.25, mb: 0.5 }}
                />
            </CardActions>
        </Card>
    );
}

export default function PageArticleSkeleton({ ...otherProps }) {
    return (
        <Stack sx={{ maxWidth: 1200, mx: 'auto', px: { xs: 0, sm: 2 } }}>
            <LoadingModal isLoading={true} />
            <Stack spacing={1} mt={1} pl={{ xs: 3, lg: 0 }}>
                <Skeleton variant="text" width={300} height={24} />
                <Skeleton variant="text" width={200} height={30} />
            </Stack>
            <Box width="100%" pt={2}>
                <Stack
                    mx={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Skeleton variant="text" width={200} height={30} />
                    <Skeleton variant="rounded" width={50} height={20} />
                </Stack>
                <CustomMasonry
                    spacing={2}
                    elevation={1}
                    id="article-card-sekeleton-masonryId"
                    columns={{ xs: 1 }}
                    items={Array.from({ length: 8 }, (x, i) => i).map(
                        (_, idx) => ({
                            id: 'skeleton-' + idx,
                            content: (
                                <ArticleCardSkeleton id={'skeleton-' + idx} />
                            ),
                        })
                    )}
                />
            </Box>
        </Stack>
    );
}
