import { useState, useMemo, useCallback } from 'react';
import { Stack, Box, useMediaQuery } from '@mui/material';
import { KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon } from '@mui/icons-material';

import { useParams, useLoaderData, useLocation } from 'react-router-dom';
import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query';
import { postAdvancedQuery } from 'utils/http.js';

import cateLv1Json from 'data/cateLv1.json';
import cateLv2Json from 'data/cateLv2.json';
import cateLv3Json from 'data/cateLv3.json';

import { CustomHelmet, CustomMasonry, CustomButton } from 'UI';
import { LoadingModal } from 'template';
import { ProductContext } from 'context/product-context.js';

import SectionBreadcrumbs from '../../layout/section/SectionBreadcrumbs.jsx';
import Page404 from '../404/Page404.jsx';

import Header from './subComponents/Header.jsx';
import FilterBox from './subComponents/FilterBox.jsx';
import CategorySection from './subComponents/CategorySection.jsx';
import ActiveFilterBox from './subComponents/ActiveFilterBox.jsx';
import NoFoundData from './subComponents/NoFoundData.jsx';
import EndOfListWithClearFilter from './subComponents/EndOfListWithClearFilter.jsx';

import PageProductSkeleton from './PageProductSkeleton.jsx';
import ProductCard from './ProductCard.jsx';

const PAGE_SIZE = 12;

export default function PageProductList({ ...otherProps }) {
    const isBiggerSmScreen = useMediaQuery((theme) =>
        theme.breakpoints.up('sm')
    );
    let location = useLocation();
    const { mainCategorySlug, subCategorySlug } = useParams();
    const {
        mainCategoryData,
        subCategoryData,
        currentCategoryLevel,
        currentCategoryTitle,
        breadcrumbsData,
    } = useLoaderData();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [viewType, setViewType] = useState('vertical');
    const [filters, setFilters] = useState({
        manuName: [],
        manuNation: [],
        administration: [],
        dosageForm: [],
        prescription: [],
        prodCate: [],
    });

    const {
        data,
        isLoading, // True only on the initial load.
        isFetching, // True when fetching new data (subsequent requests).
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: [
            'product-item',
            {
                mainCategorySlug,
                subCategorySlug,
                filters,
            },
        ],
        queryFn: ({ pageParam = 0, signal }) =>
            postAdvancedQuery({
                signal,
                filterModel: Object.keys(filters).map((key) => ({
                    field: key,
                    value: filters[key],
                })),
                pageSize: PAGE_SIZE,
                page: pageParam + 1,
                apiUrl: `/ecom/product-item/filter?${
                    mainCategorySlug
                        ? `mainCategorySlug=${mainCategorySlug}&`
                        : ''
                }${
                    subCategorySlug ? `subCategorySlug=${subCategorySlug}&` : ''
                }type=1`,
            }),
        getNextPageParam: (lastPage, allPages) => {
            const totalFetchedItems = allPages.flatMap(
                (page) => page.data
            ).length;

            return totalFetchedItems < lastPage.totalCount
                ? totalFetchedItems
                : undefined;
        },
        placeholderData: keepPreviousData,
        staleTime: 30000,
        retry: 0,
        enabled: Boolean(mainCategoryData),
    });

    const activeFilterCount = useMemo(() => {
        return Object.values(filters).reduce(
            (acc, listFilter) => acc + listFilter.length,
            0
        );
    }, [filters]);

    const changeFilter = useCallback((filterName, data) => {
        let { name, checked } = data;

        setFilters((prevValue) => {
            let updatedValue = { ...prevValue };
            let updatedFilter = updatedValue[filterName];
            if (name === 'Tất cả') {
                updatedFilter = [];
            } else {
                if (checked) {
                    updatedFilter.push(name);
                } else {
                    updatedFilter = updatedFilter.filter((i) => i !== name);
                }
            }
            updatedValue[filterName] = updatedFilter;
            return updatedValue;
        });
    }, []);

    const deleteFilter = useCallback(({ key, value }) => {
        setFilters((prevValue) => {
            let updatedValue = { ...prevValue };
            let updatedFilter = updatedValue[key];
            updatedFilter = updatedFilter.filter((i) => i !== value);

            updatedValue[key] = updatedFilter;
            return updatedValue;
        });
    }, []);

    const resetFilter = useCallback(() => {
        setFilters({
            manuName: [],
            manuNation: [],
            administration: [],
            dosageForm: [],
            prescription: [],
            prodCate: [],
        });
    }, []);

    const dataFilter = useMemo(() => {
        return {
            prodCate: {
                name: 'prodCate',
                label: 'Nhóm sản phẩm',
                sources: data?.pages?.[0]?.prodCates || [],
                filteringOptions: filters.prodCate,
            },
            manuName: {
                name: 'manuName',
                label: 'Nhà sản xuất',
                sources: data?.pages?.[0]?.manufactures || [],
                filteringOptions: filters.manuName,
            },
            manuNation: {
                name: 'manuNation',
                label: 'Nước sản xuất',
                sources: data?.pages?.[0]?.nations || [],
                filteringOptions: filters.manuNation,
            },
            prescription: {
                name: 'prescription',
                label: 'Loại sản phẩm',
                sources: data?.pages?.[0]?.prescriptions || [],
                filteringOptions: filters.prescription,
            },
            administration: {
                name: 'administration',
                label: 'Đường dùng',
                sources: data?.pages?.[0]?.administrations || [],
                filteringOptions: filters.administration,
            },
            dosageForm: {
                name: 'dosageForm',
                label: 'Dạng bào chế',
                sources: data?.pages?.[0]?.dosageForms || [],
                filteringOptions: filters.dosageForm,
            },
        };
    }, [data?.pages, filters]);

    if (
        (mainCategorySlug && !mainCategoryData) ||
        (subCategorySlug && !subCategoryData)
    ) {
        return <Page404 />;
    }

    if (isLoading) {
        return <PageProductSkeleton />;
    }

    const mergedProducts = data?.pages?.flatMap((page) => page.data) || [];
    const totalLoadedProducts = mergedProducts.length;
    const totalProducts = parseInt(data?.pages?.[0]?.totalCount || 0);
    const contextValue = {
        mainCategoryData,
        subCategoryData,
        currentCategoryLevel,
        currentCategoryTitle,
        isDrawerOpen,
        setIsDrawerOpen,
        viewType,
        toggleViewType: () =>
            setViewType((v) => (v === 'vertical' ? 'horizontal' : 'vertical')),
        activeFilterCount,
        dataFilter,
        changeFilter,
        deleteFilter,
        resetFilter,
        totalProducts,
    };

    return (
        <>
            <CustomHelmet
                title={
                    currentCategoryTitle +
                    ' chính hãng, giá tốt - Nhà thuốc Khánh Trang'
                }
                description={
                    'Mua ' +
                    currentCategoryTitle.toLowerCase() +
                    ' chính hãng, giá tốt tại nhà thuốc Khánh Trang'
                }
                slug={'https://nhathuockhanhtrang.com.vn' + location.pathname}
            />
            <ProductContext.Provider value={contextValue}>
                <Stack sx={{ maxWidth: 1200, mx: 'auto' }}>
                    <LoadingModal isLoading={isFetching && !isDrawerOpen} />
                    <Box
                        sx={{
                            px: { xs: 2, sm: 3 },
                            pt: 2,
                        }}>
                        <SectionBreadcrumbs breadcrumbsData={breadcrumbsData} />
                    </Box>
                    {activeFilterCount === 0 && totalLoadedProducts > 0 && (
                        <CategorySection />
                    )}
                    <Stack
                        direction="row"
                        alignItems="stretch"
                        pt={2}
                        pl={{ xs: 0, lg: 2 }}
                        pb={{ xs: 0, lg: 2 }}
                        spacing={{ xs: 0, lg: 0.5 }}>
                        <FilterBox />
                        <div style={{ width: '100%' }}>
                            <Box px={2} pb={{ xs: 1, sm: 0 }}>
                                <Header />
                                <ActiveFilterBox />
                            </Box>
                            {totalLoadedProducts === 0 ? (
                                <NoFoundData />
                            ) : (
                                <Box px={{ xs: 1, sm: 0 }}>
                                    <CustomMasonry
                                        spacing={isBiggerSmScreen ? 2 : 1}
                                        elevation={1}
                                        id="page-product-list-masonryId"
                                        columns={
                                            viewType === 'vertical'
                                                ? { xs: 2, lg: 3, xl: 4 }
                                                : { xs: 1, md: 2, lg: 2, xl: 2 }
                                        }
                                        items={mergedProducts.map(
                                            (prodData) => ({
                                                id: prodData._id,
                                                content: (
                                                    <ProductCard
                                                        prodData={prodData}
                                                    />
                                                ),
                                            })
                                        )}
                                    />
                                </Box>
                            )}
                            {hasNextPage ? (
                                <CustomButton
                                    id="see-more-products-buttonId"
                                    color="disabled"
                                    fullWidth
                                    disableEffect
                                    onClick={fetchNextPage}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        p: 0,
                                        mb: { xs: 1, sm: 2 },
                                    }}
                                    startIcon={
                                        !isFetchingNextPage && (
                                            <KeyboardDoubleArrowDownIcon />
                                        )
                                    }
                                    children={
                                        isFetchingNextPage
                                            ? 'Đang tải...'
                                            : `Xem thêm ${Number(
                                                  totalProducts -
                                                      totalLoadedProducts
                                              ).toLocaleString()} sản phẩm`
                                    }
                                />
                            ) : (
                                totalProducts > PAGE_SIZE && (
                                    <EndOfListWithClearFilter />
                                )
                            )}
                        </div>
                    </Stack>
                </Stack>
            </ProductContext.Provider>
        </>
    );
}

export const loader = ({ params }) => {
    let { mainCategorySlug, subCategorySlug } = params;
    const findMainCategory =
        cateLv1Json.find((item) => item.slug === '/' + mainCategorySlug) ||
        null;
    const findSubCategory =
        [...cateLv2Json, ...cateLv3Json].find(
            (item) => item.slug === '/' + subCategorySlug
        ) || null;
    const currentCategoryLevel = Number(findSubCategory?.level ?? 1);
    let breadcrumbsData = [
        {
            to: `/${mainCategorySlug}`,
            name: findMainCategory?.name || '',
            isNavLink: currentCategoryLevel !== 1,
        },
    ];
    if (currentCategoryLevel === 2) {
        breadcrumbsData.push({
            to: `/${mainCategorySlug}/${subCategorySlug}`,
            name: findSubCategory.name,
            isNavLink: false,
        });
    }
    if (currentCategoryLevel === 3) {
        const parentCategory = cateLv2Json.find(
            (item) => item.name === findSubCategory.parentName
        );
        breadcrumbsData.push(
            {
                to: `/${mainCategorySlug}${parentCategory.slug}`,
                name: findSubCategory.parentName,
            },
            {
                to: `/${mainCategorySlug}/${subCategorySlug}`,
                name: findSubCategory.name,
                isNavLink: false,
            }
        );
    }
    let currentCategoryTitle = findSubCategory
        ? findSubCategory.name
        : findMainCategory
        ? findMainCategory.name
        : 'Thuốc';
    return {
        mainCategoryData: findMainCategory,
        subCategoryData: findSubCategory,
        currentCategoryLevel,
        currentCategoryTitle,
        breadcrumbsData,
    };
};
