import { useContext } from 'react';

import { Stack, Typography, Box } from '@mui/material';
import { useTheme, darken } from '@mui/material/styles';
import {
    ListAlt as ListAltIcon,
    GridViewOutlined as GridViewOutlinedIcon,
    GridViewSharp as GridViewSharpIcon,
    FilterListOutlined as FilterListOutlinedIcon,
} from '@mui/icons-material';

import { ProductContext } from 'context/product-context';

import { CustomButton } from 'UI';

export default function Header({ ...props }) {
    const theme = useTheme();
    const {
        totalProducts,
        setIsDrawerOpen,
        viewType,
        toggleViewType,
        activeFilterCount,
    } = useContext(ProductContext);

    if (totalProducts === 0 && activeFilterCount === 0) {
        return null;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stack
                px={2}
                py={1}
                sx={{
                    backgroundColor: { xs: 'white', lg: 'transparent' },
                    borderRadius: {
                        xs:
                            activeFilterCount === 0
                                ? '10px'
                                : '10px 10px 0px 0px',
                        lg: '0px',
                    },
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}>
                <Typography
                    component="h2"
                    sx={{ fontSize: 18, fontWeight: 600 }}>
                    Danh sách sản phẩm
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <CustomButton
                        id={'product-header-filter-button'}
                        variant="outlined"
                        color="disabled"
                        onClick={() => setIsDrawerOpen(true)}
                        sx={(theme) => ({
                            borderRadius: '20px',
                            border: `0.5px solid ${theme.palette.disabled.light}`,
                            display: { lg: 'none' },
                            '&:active': {
                                boxShadow: 'none',
                                backgroundColor: 'white',
                            },
                            '&:focus': {
                                boxShadow: 'none',
                                backgroundColor: 'white',
                            },
                        })}
                        startIcon={<FilterListOutlinedIcon />}
                        children="Lọc"
                    />
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={(theme) => ({
                            borderRadius: '20px',
                            border: `0.5px solid ${theme.palette.disabled.light}`,
                        })}>
                        <CustomButton
                            id="vertical-toggleButtonId"
                            color="disabled"
                            useIconButton
                            disableEffect={viewType === 'horizontal'}
                            onClick={toggleViewType}
                            sx={(theme) => ({
                                borderRadius: '20px',
                                border:
                                    viewType === 'vertical'
                                        ? `0.5px solid ${theme.palette.disabled.light}`
                                        : 'none',
                                backgroundColor:
                                    viewType === 'vertical'
                                        ? theme.palette.disabled.lighter
                                        : 'transparent',
                                transition:
                                    'background-color 0.3s ease-in-out, border 0.3s ease-in-out',
                                p: 0.75,
                            })}
                            aria-label="toggle-button-vertical">
                            {viewType === 'vertical' ? (
                                <GridViewSharpIcon
                                    fontSize="small"
                                    sx={(theme) => ({
                                        color: darken(
                                            theme.palette.disabled.light,
                                            0.4
                                        ),
                                        width: 18,
                                        height: 18,
                                    })}
                                />
                            ) : (
                                <GridViewOutlinedIcon
                                    fontSize="small"
                                    sx={(theme) => ({
                                        color: darken(
                                            theme.palette.disabled.lighter,
                                            0.4
                                        ),
                                        width: 18,
                                        height: 18,
                                    })}
                                />
                            )}
                        </CustomButton>
                        <CustomButton
                            id="horizontal-toggleButtonId"
                            color="disabled"
                            useIconButton
                            disableEffect={viewType === 'vertical'}
                            onClick={toggleViewType}
                            sx={(theme) => ({
                                borderRadius: '20px',
                                border:
                                    viewType === 'horizontal'
                                        ? `0.5px solid ${theme.palette.disabled.light}`
                                        : 'none',
                                backgroundColor:
                                    viewType === 'horizontal'
                                        ? theme.palette.disabled.lighter
                                        : 'transparent',
                                p: 0.75,
                                transition:
                                    'background-color 0.3s ease-in-out, border 0.3s ease-in-out',
                            })}
                            aria-label="toggle-button-horizontal">
                            <ListAltIcon
                                fontSize="small"
                                sx={(theme) => ({
                                    color: darken(
                                        theme.palette.disabled[
                                            viewType === 'vertical'
                                                ? 'lighter'
                                                : 'light'
                                        ],
                                        0.4
                                    ),
                                    width: 18,
                                    height: 18,
                                })}
                            />
                        </CustomButton>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}
