import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useTheme, lighten, darken } from '@mui/material/styles';
import { HomeRounded as HomeRoundedIcon } from '@mui/icons-material';

import { Link as RouterLink } from 'react-router-dom';

export default function SectionBreadcrumbs({ breadcrumbsData = [], ...props }) {
    const theme = useTheme();

    return (
        <div role="presentation">
            <Breadcrumbs
                sx={{
                    '.MuiBreadcrumbs-li:last-of-type': {
                        flex: 1,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        '.MuiTypography-root': {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        },
                    },
                }}
                aria-label="breadcrumbs">
                <Link
                    underline="none"
                    component={RouterLink}
                    sx={{
                        fontSize: { xs: 14, lg: 16 },
                        color: lighten(theme.palette.text.link, 0.2),
                        ':hover': {
                            color: darken(theme.palette.text.link, 0.2),
                        },
                    }}
                    to="/">
                    <HomeRoundedIcon fontSize="small" sx={{ mt: -0.5 }} />
                </Link>

                {breadcrumbsData.map((breadcrumb, idx) => {
                    const isNavLink = breadcrumb?.isNavLink ?? true;

                    return isNavLink ? (
                        <Link
                            key={'breadcrumb-' + breadcrumb.name + idx}
                            underline="none"
                            component={RouterLink}
                            sx={{
                                fontSize: { xs: 14, lg: 16 },
                                color: lighten(theme.palette.text.link, 0.2),
                                fontWeight: 500,
                                ':hover': {
                                    color: darken(theme.palette.text.link, 0.2),
                                },
                            }}
                            to={breadcrumb.to}>
                            {breadcrumb.name}
                        </Link>
                    ) : (
                        <Typography
                            key={'breadcrumb-' + breadcrumb.name}
                            variant="caption"
                            sx={{
                                color: 'text.primary',
                                fontSize: { xs: 14, lg: 16 },
                            }}>
                            {breadcrumb.name}
                        </Typography>
                    );
                })}
            </Breadcrumbs>
        </div>
    );
}
