import { useState } from 'react';

import { Stack, Box, Typography, Collapse } from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import articleLv1Json from 'data/articleLv1.json';
import articleLv2Json from 'data/articleLv2.json';

import { CustomButton, CustomChip } from 'UI';

const getUnderlineEffect = (color) => ({
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 'calc(100% - 32px)',
        height: '1.5px',
        borderRadius: '5px',
        boxShadow: color === 'transparent' ? 0 : 1,
        backgroundColor: color,
        transform: 'scaleX(0)',
        transformOrigin: 'bottom right',
        transition: 'transform 0.3s ease-in-out',
    },
    '&:hover::after': {
        transform: 'scaleX(1)',
        transformOrigin: 'bottom left',
    },
});
function CustomButtonWithCollapse({ buttonTitle = '', children, ...props }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Box sx={{ width: '100%' }}>
            <CustomButton
                id={'categorySection-customId-' + buttonTitle}
                color={isOpen ? 'warning' : 'primary'}
                disableEffect
                startIcon={
                    isOpen ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />
                }
                endIcon={
                    isOpen && (
                        <Typography variant="caption" color="warning">
                            •
                        </Typography>
                    )
                }
                onClick={() => setIsOpen((v) => !v)}
                sx={{
                    p: 0,
                    pl: 1,
                    ...getUnderlineEffect(
                        isOpen ? 'transparent' : 'primary.light'
                    ),
                    maxWidth: '90%',
                }}
                children={
                    <Typography
                        variant="caption"
                        sx={(theme) => ({
                            fontSize: 16,
                            fontWeight: 500,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: isOpen ? 'warning.light' : 'primary.main',
                        })}>
                        {buttonTitle}
                    </Typography>
                }
            />
            <Collapse in={isOpen} timeout="auto">
                {children}
            </Collapse>
        </Box>
    );
}
export default function CategorySection({
    currentCategoryLevel = 1,
    currentCategoryTitle = '',
    ...otherProps
}) {
    const navigate = useNavigate();

    if (currentCategoryLevel === 1) {
        return articleLv1Json.map((cate, index) => {
            let currentCategoryName = cate.name;
            return (
                <CustomButtonWithCollapse
                    key={cate.id}
                    buttonTitle={'Chủ đề: ' + currentCategoryName}>
                    <Stack
                        sx={(theme) => ({
                            width: 'calc(100% - 15px)',
                            p: 1,
                            ml: '15px',
                            borderLeftWidth: '1px',
                            borderLeftStyle: 'solid',
                            borderLeftColor: 'text.primary',
                        })}
                        direction="row"
                        flexWrap="wrap"
                        alignItems="center"
                        spacing={1}>
                        {[
                            {
                                id: cate.id + '-0',
                                name: 'Tất cả',
                                parentName: 'Bài viết sức khỏe',
                                slug: cate.slug,
                                level: 2,
                            },
                        ]
                            .concat(
                                articleLv2Json.filter(
                                    (i) => i.parentName === currentCategoryName
                                )
                            )
                            .map((subCate, idx) => {
                                return (
                                    <CustomChip
                                        key={idx}
                                        label={subCate.name}
                                        color={
                                            idx === 0 ? 'primary' : 'disabled'
                                        }
                                        sx={{
                                            my: 0.5,
                                            ml: idx === 0 ? 0 : 1,
                                            fontWeight: 500,
                                        }}
                                        variant={
                                            idx === 0 ? 'filled' : 'outlined'
                                        }
                                        onClick={() =>
                                            navigate(
                                                `/bai-viet-suc-khoe${subCate.slug}`
                                            )
                                        }
                                    />
                                );
                            })}
                    </Stack>
                </CustomButtonWithCollapse>
            );
        });
    } else if (currentCategoryLevel === 2) {
        let currentCategoryName = currentCategoryTitle;
        return (
            <CustomButtonWithCollapse
                buttonTitle={'Chủ đề: ' + currentCategoryName}>
                <Stack
                    sx={(theme) => ({
                        width: 'calc(100% - 15px)',
                        p: 1,
                        ml: '15px',
                        borderLeftWidth: '1px',
                        borderLeftStyle: 'solid',
                        borderLeftColor: 'text.primary',
                    })}
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    spacing={1}>
                    {articleLv2Json
                        .filter((i) => i.parentName === currentCategoryName)
                        .map((subCate, idx) => {
                            return (
                                <CustomChip
                                    key={idx}
                                    label={subCate.name}
                                    color={'primary'}
                                    sx={{
                                        my: 0.5,
                                        ml: idx === 0 ? 0 : 1,
                                        fontWeight: 500,
                                    }}
                                    variant={'filled'}
                                    onClick={() =>
                                        navigate(
                                            `/bai-viet-suc-khoe${subCate.slug}`
                                        )
                                    }
                                />
                            );
                        })}
                </Stack>
            </CustomButtonWithCollapse>
        );
    } else return null;
}
