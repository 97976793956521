import { Box, Fab, Fade, useScrollTrigger } from '@mui/material';

import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';

function ScrollTop(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor'
        );

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 80, right: 16, zIndex: 1000 }}>
                {children}
            </Box>
        </Fade>
    );
}

export default function BackToTopButton(props) {
    return (
        <ScrollTop {...props}>
            <div
                className="zalo-chat-widget"
                data-oaid="840599482837287192"
                data-welcome-message="Rất vui khi được hỗ trợ bạn!"
                data-autopopup="0"
                data-width="100px"
                data-height="100px"></div>
            <Fab size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
    );
}
