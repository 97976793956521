import { useState, useContext } from 'react';
import {
    Stack,
    Box,
    Typography,
    Collapse,
    useMediaQuery,
    Drawer,
} from '@mui/material';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    FilterListOutlined as FilterListOutlinedIcon,
    Search as SearchIcon,
    Clear as ClearIcon,
} from '@mui/icons-material';

import { CustomInput, CustomList, CustomCheckbox, CustomButton } from 'UI';

import { ProductContext } from 'context/product-context';
import { customScrollbar } from 'config/customCSS';

import ActiveFilterBox from './ActiveFilterBox';

function CustomListItem({
    idx = 0,
    label = '',
    sources = [],
    filteringOptions = [],
    onChange = () => {},
    ...otherProps
}) {
    const isBiggerSmScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('sm') // >=600
    );
    const isBiggerLgScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('lg') // >=1000
    );
    const [keyword, setKeyword] = useState('');
    const [isOpenCollapse, setIsOpenCollapse] = useState(!isBiggerLgScreen);

    let matchedKeywordOptions = (
        keyword.trim().length > 0 ? [] : ['Tất cả']
    ).concat(
        sources.filter((i) =>
            keyword.trim().length > 0 ? includesTerm(i, keyword) : i
        )
    );

    return (
        <Box width={'100%'} key={label}>
            <CustomButton
                id={label + 'collapse-buttonId'}
                color="disabled"
                disableEffect
                fullWidth
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 500,
                    fontSize: 16,
                    pl: 0.75,
                    pr: 1.25,
                    py: 0,
                }}
                endIcon={
                    isOpenCollapse ? (
                        <KeyboardArrowDownIcon
                            fontSize="small"
                            sx={{
                                transform: 'rotate(180deg)',
                                transition: 'all 0.3s ease-in-out',
                            }}
                        />
                    ) : (
                        <KeyboardArrowDownIcon fontSize="small" />
                    )
                }
                children={label}
                onClick={() => setIsOpenCollapse((v) => !v)}
            />
            <Collapse in={isOpenCollapse} timeout="auto">
                {(matchedKeywordOptions.length > 5 || keyword.trim()) && (
                    <CustomInput
                        name={label + '-search-input'}
                        id={label + '-search-inputId'}
                        color="disbaled"
                        value={keyword}
                        onChange={(value) => setKeyword(value)}
                        onKeyDown={handleKeyDown}
                        startAdornment={<SearchIcon fontSize="small" />}
                        placeholder="Tìm theo tên"
                        sx={{ borderRadius: '20px !important' }}
                    />
                )}
                <CustomCheckbox
                    id={`${label}-checkbox`}
                    options={matchedKeywordOptions}
                    formatOption={(opt) => ({ name: opt, label: opt })}
                    getOptionChecked={(opt) =>
                        opt === 'Tất cả'
                            ? filteringOptions.length === 0
                            : filteringOptions.indexOf(opt) > -1
                    }
                    onChange={({ name, checked }) => {
                        if (
                            checked &&
                            sources.length > 1 &&
                            filteringOptions.length === sources.length - 1
                        ) {
                            onChange({ name: 'Tất cả', checked });
                        } else {
                            onChange({ name, checked });
                        }
                    }}
                    row={isBiggerLgScreen || !isBiggerSmScreen ? false : true}
                    sx={{
                        width: { xs: 'auto', sm: 290, md: 260, lg: 'auto' },
                        whiteSpace: 'normal',
                    }}
                />
            </Collapse>
        </Box>
    );
}

function Header({ onCloseDrawer = () => {}, ...props }) {
    const isBiggerLgScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('lg') // >=1000
    );
    if (isBiggerLgScreen) {
        return (
            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={(theme) => ({
                    position: '-webkit-sticky',
                    // eslint-disable-next-line no-dupe-keys
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 100,
                    px: 2,
                    py: 1,
                    borderBottom: `1px solid ${theme.palette.disabled.lighter}`,
                })}>
                <FilterListOutlinedIcon fontSize="small" />
                <Typography
                    component="h2"
                    sx={{ fontSize: 16, fontWeight: 600 }}>
                    Bộ lọc nâng cao
                </Typography>
            </Stack>
        );
    }
    return (
        <Box
            sx={(theme) => ({
                width: '100vw',
                backgroundColor: 'white',
                px: 1,
                py: 2,
                borderBottom: `1px solid ${theme.palette.disabled.lighter}`,
            })}>
            <Stack direction="row" width="100%" alignItems="center">
                <Typography
                    flexGrow={1}
                    component="h2"
                    sx={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}>
                    Bộ lọc nâng cao
                </Typography>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <CustomButton
                        color="disabled"
                        disableEffect
                        useIconButton
                        onClick={onCloseDrawer}
                        children={<ClearIcon />}
                    />
                </Box>
            </Stack>
        </Box>
    );
}

export default function FilterBox({ ...props }) {
    const {
        dataFilter,
        isDrawerOpen,
        setIsDrawerOpen,
        totalProducts,
        activeFilterCount,
        resetFilter,
        changeFilter,
    } = useContext(ProductContext);
    const isBiggerLgScreen = useMediaQuery(
        (theme) => theme.breakpoints.up('lg') // >=1000
    );

    let filters = Object.keys(dataFilter).reduce((acc, key) => {
        let { sources } = dataFilter[key];
        if (sources.length > 0) {
            acc.push(dataFilter[key]);
        }
        return acc;
    }, []);

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    if (totalProducts === 0 && activeFilterCount === 0) {
        return null;
    } else if (isBiggerLgScreen) {
        return (
            <div>
                <Box
                    sx={{
                        position: '-webkit-sticky',
                        // eslint-disable-next-line no-dupe-keys
                        position: 'sticky',
                        top: 10,
                        maxHeight: '97vh',
                        borderRadius: '10px',
                        ...customScrollbar,
                    }}>
                    <Stack
                        width={{ xs: '100%', lg: '250px' }}
                        height={{ xs: '100%', lg: 'auto' }}
                        sx={(theme) => ({
                            backgroundColor: 'white',
                            border: `1px solid ${theme.palette.disabled.lighter}`,
                        })}>
                        <Header />
                        <CustomList
                            id="page-product-filter-listId"
                            density="comfortable"
                            items={filters.map((opt, idx) => {
                                return {
                                    name: opt.label + idx,
                                    subLabel: null,
                                    label: (
                                        <CustomListItem
                                            idx={idx}
                                            {...opt}
                                            onChange={({ name, checked }) =>
                                                changeFilter(opt.name, {
                                                    name,
                                                    checked,
                                                })
                                            }
                                        />
                                    ),
                                    divider: true,
                                };
                            })}
                        />
                    </Stack>
                </Box>
            </div>
        );
    } else {
        return (
            <div>
                <Drawer
                    sx={{
                        '&.MuiDrawer-root .MuiPaper-root ': {
                            height: { xs: '100vh', sm: '90vh', md: '80vh' },
                        },
                    }}
                    open={isDrawerOpen}
                    variant="temporary"
                    anchor="bottom"
                    ModalProps={{ keepMounted: true }}
                    onClose={handleCloseDrawer}>
                    <Stack
                        sx={(theme) => ({
                            width: { xs: '100vw', lg: '250px' },
                            height: { xs: '100%', lg: 'auto' },
                            backgroundColor: 'white',
                        })}>
                        <Header onCloseDrawer={handleCloseDrawer} />
                        <Box
                            flexGrow={1}
                            sx={{
                                width: '100vw',
                                overflow: 'scroll',
                                pb: '215px',
                            }}>
                            <CustomList
                                id="page-product-filter-listId"
                                density="comfortable"
                                items={filters.map((opt, idx) => {
                                    return {
                                        name: opt.label + idx,
                                        subLabel: null,
                                        label: (
                                            <CustomListItem
                                                idx={idx}
                                                {...opt}
                                                onChange={({ name, checked }) =>
                                                    changeFilter(opt.name, {
                                                        name,
                                                        checked,
                                                    })
                                                }
                                            />
                                        ),
                                        divider: true,
                                    };
                                })}
                                ListProps={{
                                    sx: {
                                        py: 0,
                                        width: '100%',
                                        maxHeight: 'calc(100% - 210px)',
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={(theme) => ({
                                borderTop: `1px solid ${theme.palette.disabled.lighter}`,
                                position: 'absolute',
                                bottom: 0,
                                zIndex: 100,
                                width: '100vw',
                                backgroundColor: 'white',
                            })}>
                            <Box
                                sx={{
                                    maxHeight: 137,
                                    overflow: 'scroll',
                                }}>
                                <ActiveFilterBox />
                            </Box>
                            <Stack
                                p={2}
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                sx={(theme) => ({
                                    borderTop: `1px solid ${theme.palette.disabled.lighter}`,
                                    width: '100%',
                                    backgroundColor: 'white',
                                })}>
                                <CustomButton
                                    id="reset-filter-buttonId"
                                    fullWidth
                                    variant="text"
                                    sx={{
                                        borderRadius: '20px',
                                        textTransform: 'initial',
                                        fontWeight: 600,
                                        fontSize: 16,
                                        py: 1,
                                        backgroundColor: 'aliceblue',
                                        ':hover': {
                                            backgroundColor: 'aliceblue',
                                        },
                                        '&:active': {
                                            boxShadow: 'none',
                                            backgroundColor: 'aliceblue',
                                        },
                                        '&:focus': {
                                            boxShadow: 'none',
                                            backgroundColor: 'aliceblue',
                                        },
                                    }}
                                    onClick={resetFilter}>
                                    Thiết lập lại
                                </CustomButton>
                                <CustomButton
                                    id="apply-filters-buttonId"
                                    fullWidth
                                    variant="text"
                                    sx={(theme) => ({
                                        borderRadius: '20px',
                                        textTransform: 'initial',
                                        fontWeight: 600,
                                        fontSize: 16,
                                        py: 1,
                                        backgroundColor:
                                            theme.palette.primary.main,
                                        color: 'white',
                                        ':hover': {
                                            backgroundColor:
                                                theme.palette.primary.main,
                                            color: 'white',
                                        },
                                    })}
                                    onClick={() => setIsDrawerOpen(false)}>
                                    Xem kết quả
                                </CustomButton>
                            </Stack>
                        </Box>
                    </Stack>
                </Drawer>
            </div>
        );
    }
}

function replaceVietnameseCharacters(inputString) {
    // Define a mapping of Vietnamese characters to normal characters
    const charMap = {
        á: 'a',
        à: 'a',
        ả: 'a',
        ã: 'a',
        ạ: 'a',
        ă: 'a',
        ắ: 'a',
        ằ: 'a',
        ẳ: 'a',
        ẵ: 'a',
        ặ: 'a',
        â: 'a',
        ấ: 'a',
        ầ: 'a',
        ẩ: 'a',
        ẫ: 'a',
        ậ: 'a',
        é: 'e',
        è: 'e',
        ẻ: 'e',
        ẽ: 'e',
        ẹ: 'e',
        ê: 'e',
        ế: 'e',
        ề: 'e',
        ể: 'e',
        ễ: 'e',
        ệ: 'e',
        í: 'i',
        ì: 'i',
        ỉ: 'i',
        ĩ: 'i',
        ị: 'i',
        ó: 'o',
        ò: 'o',
        ỏ: 'o',
        õ: 'o',
        ọ: 'o',
        ô: 'o',
        ố: 'o',
        ồ: 'o',
        ổ: 'o',
        ỗ: 'o',
        ộ: 'o',
        ơ: 'o',
        ớ: 'o',
        ờ: 'o',
        ở: 'o',
        ỡ: 'o',
        ợ: 'o',
        ú: 'u',
        ù: 'u',
        ủ: 'u',
        ũ: 'u',
        ụ: 'u',
        ư: 'u',
        ứ: 'u',
        ừ: 'u',
        ử: 'u',
        ữ: 'u',
        ự: 'u',
        ý: 'y',
        ỳ: 'y',
        ỷ: 'y',
        ỹ: 'y',
        ỵ: 'y',
        Á: 'A',
        À: 'A',
        Ả: 'A',
        Ã: 'A',
        Ạ: 'A',
        Ă: 'A',
        Ắ: 'A',
        Ằ: 'A',
        Ẳ: 'A',
        Ẵ: 'A',
        Ặ: 'A',
        Â: 'A',
        Ấ: 'A',
        Ầ: 'A',
        Ẩ: 'A',
        Ẫ: 'A',
        Ậ: 'A',
        É: 'E',
        È: 'E',
        Ẻ: 'E',
        Ẽ: 'E',
        Ẹ: 'E',
        Ê: 'E',
        Ế: 'E',
        Ề: 'E',
        Ể: 'E',
        Ễ: 'E',
        Ệ: 'E',
        Í: 'I',
        Ì: 'I',
        Ỉ: 'I',
        Ĩ: 'I',
        Ị: 'I',
        Ó: 'O',
        Ò: 'O',
        Ỏ: 'O',
        Õ: 'O',
        Ọ: 'O',
        Ô: 'O',
        Ố: 'O',
        Ồ: 'O',
        Ổ: 'O',
        Ỗ: 'O',
        Ộ: 'O',
        Ơ: 'O',
        Ớ: 'O',
        Ờ: 'O',
        Ở: 'O',
        Ỡ: 'O',
        Ợ: 'O',
        Ú: 'U',
        Ù: 'U',
        Ủ: 'U',
        Ũ: 'U',
        Ụ: 'U',
        Ư: 'U',
        Ứ: 'U',
        Ừ: 'U',
        Ử: 'U',
        Ữ: 'U',
        Ự: 'U',
        Ý: 'Y',
        Ỳ: 'Y',
        Ỷ: 'Y',
        Ỹ: 'Y',
        Ỵ: 'Y',
        Đ: 'D',
        đ: 'd',
    };

    // Use the replace method with a callback function
    const result = inputString.replace(
        /[^A-Za-z0-9]/g,
        (match) => charMap[match] || match
    );
    return result;
}

function includesTerm(term, comparedString) {
    let replacedCharacterTerm = replaceVietnameseCharacters(term).toLowerCase();
    let replacedCharacterComparedString = replaceVietnameseCharacters(
        comparedString.trim()
    ).toLowerCase();

    return replacedCharacterTerm.includes(replacedCharacterComparedString)
        ? term
        : null;
}

function handleKeyDown(event) {
    const forbiddenKeys = [
        '!',
        '@',
        '#',
        '$',
        '%',
        '^',
        '&',
        '*',
        '(',
        ')',
        '-',
        '_',
        '=',
        '+',
        '[',
        ']',
        '{',
        '}',
        '\\',
        '|',
        ';',
        ':',
        // "'",
        '"',
        ',',
        '.',
        '<',
        '>',
        '/',
        '?',
        '`',
        '~',
    ];

    if (forbiddenKeys.includes(event.key)) {
        event.preventDefault();
    }
}
